import { action } from 'typesafe-actions'

import { MachineError } from './machine-error'

export enum machineErrorsActionType {
  FETCH_MACHINE_ERRORS = 'FETCH_MACHINE_ERRORS',
  FETCH_MACHINE_ERRORS_SUCCEEDED = 'FETCH_MACHINE_ERRORS_SUCCEEDED',
  THROW_ERROR = 'THROW_ERROR',
}

export const machineErrorsActions = {
  fetchMachineErrors: () =>
    action(machineErrorsActionType.FETCH_MACHINE_ERRORS),
  fetchMachineErrorsSucceeded: (machineErrors: MachineError[]) =>
    action(machineErrorsActionType.FETCH_MACHINE_ERRORS_SUCCEEDED, {
      machineErrors,
    }),
  throwError: (error: Error) =>
    action(machineErrorsActionType.THROW_ERROR, { error }),
}
