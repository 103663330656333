import React, { useEffect } from 'react'
import { Dispatch } from 'redux'
import {
  PointItemOrder,
  pointItemOrdersActions,
} from '../../core/point-item-orders'
import { strings } from '../components/point-item-orders/point-item-orders-content'
import Layout from '../layouts/layout'
import Wrapper from '../layouts/wrapper'
import { connect } from 'react-redux'
import RootState from '../../core/root-state'
import PointItemOrdersTable from '../components/point-item-orders/point-item-orders-table'

interface Props {
  pointItemOrders: PointItemOrder[]
  isLoading: boolean
  fetchPointItemOrders: () => void
  completePointItemOrder: (id: number) => void
  cancelPointItemOrder: (id: number) => void
  returnPointItemOrder: (id: number) => void
}

const PointItemOrdersScreen: React.FC<Props> = ({
  pointItemOrders,
  fetchPointItemOrders,
  completePointItemOrder,
  cancelPointItemOrder,
  returnPointItemOrder,
}) => {
  useEffect(() => {
    fetchPointItemOrders()
  }, [fetchPointItemOrders])

  const handleOnClickCompletePointItemOrder = (id: number) => {
    const response = window.confirm('発送完了しますか？')
    if (response) {
      completePointItemOrder(id)
    }
  }

  const handleOnClickCancelPointItemOrder = (id: number) => {
    const response = window.confirm('受注をキャンセルしますか？')
    if (response) {
      cancelPointItemOrder(id)
    }
  }

  const handleOnClickReturnPointItemOrder = (id: number) => {
    const response = window.confirm('返品しますか？')
    if (response) {
      returnPointItemOrder(id)
    }
  }

  return (
    <Layout location={'point_item_orders'}>
      <Wrapper title={strings.title}>
        <PointItemOrdersTable
          pointItemOrders={pointItemOrders}
          onClickComplete={handleOnClickCompletePointItemOrder}
          onClickCancel={handleOnClickCancelPointItemOrder}
          onClickReturn={handleOnClickReturnPointItemOrder}
        />
      </Wrapper>
    </Layout>
  )
}

const mapStateToProps = (state: RootState) => ({
  pointItemOrders: state.pointItemOrders.pointItemOrders,
  isLoading: state.pointItemOrders.isLoading,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchPointItemOrders: () =>
    dispatch(pointItemOrdersActions.fetchPointItemOrders()),
  completePointItemOrder: (id: number) =>
    dispatch(pointItemOrdersActions.completePointItemOrder(id)),
  cancelPointItemOrder: (id: number) =>
    dispatch(pointItemOrdersActions.cancelPointItemOrder(id)),
  returnPointItemOrder: (id: number) =>
    dispatch(pointItemOrdersActions.returnPointItemOrder(id)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PointItemOrdersScreen)
