import React from 'react'

import { OrderProvisionStatus } from '../../../core/orders'
import { strings } from './orders-content'
import styles from '../../styles/modules/orders/orders-query.module.scss'

interface Props {
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void
  value: string
}

const QueryStatus: React.FC<Props> = props => {

  const statusList = Object.values(OrderProvisionStatus)

  return (
    <div className={styles.container}>
      <span className={styles.title}>{strings.queryProvisionStatus}</span>
      <div className={styles.stationsWrapper}>
        <select
          className={styles.input}
          value={props.value}
          onChange={event => props.onChange(event)}
        >
          <option value={'@all'}>{strings.queryPlaceHolder}</option>
          {statusList.map(status => (
            <option key={status}>
              {status}
            </option>
          ))}
        </select>
      </div>
    </div>
  )
}

export default QueryStatus
