export const strings = {
  title: 'バナー',
  createSignageBannerButton: 'バナーを作成',
  deleteSignageBannerButton: '削除する',
  editButton: '編集',
  description:
    '※バナーを登録した後、表示を希望する場合は、サイネージの更新タイミングに合わせて編集してください。サイネージは0時、4時、8時、12時、16時、20時に更新されます。',
}

export const SignageTableContent = [
  '',
  '表示順序',
  'ID',
  'タイトル',
  'バナー',
  '広告文',
  '表示時間(s)',
  '表示/非表示',
  '表示時間編集',
  '',
]
