import { ActionType } from 'typesafe-actions'

import { promotionImagesActionType, promotionImagesActions } from './actions'
import { PromotionImage } from './promotion-images'

export interface PromotionImagesState {
  readonly promotionImages: PromotionImage[]
  readonly error: Error
  readonly isLoading: boolean
}

export const initialState: PromotionImagesState = {
  promotionImages: [],
  error: null,
  isLoading: false,
}

export const promotionImagesReducer = (
  state: PromotionImagesState = initialState,
  action: ActionType<typeof promotionImagesActions>
) => {
  switch (action.type) {
    case promotionImagesActionType.FETCH_PROMOTION_IMAGE:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case promotionImagesActionType.FETCH_PROMOTION_IMAGE_SUCCEEDED:
      return {
        ...state,
        promotionImages: action.payload.promotionImages,
        isLoading: false,
        error: null,
      }
    case promotionImagesActionType.CREATE_PROMOTION_IMAGE:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case promotionImagesActionType.UPDATE_PROMOTION_IMAGE:
        return {
          ...state,
          isLoading: true,
          error: null,
        }
    case promotionImagesActionType.DELETE_PROMOTION_IMAGE:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case promotionImagesActionType.UPDATE_PROMOTION_IMAGES_INDEX:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case promotionImagesActionType.SET_PROMOTION_IMAGES:
      return {
        ...state,
        promotionImages: action.payload.promotionImages,
      }
    case promotionImagesActionType.THROW_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      }
    default:
      return state
  }
}
