import React from 'react'
import moment from 'moment'
import classnames from 'classnames'
import { Link } from 'react-router-dom'

import { strings, subscriptionTableContent } from './users-content'
import { Subscription } from '../../../core/subscription'
import LinkToSubscriptionsButton from "./link-to-subscriptions-button";
import styles from '../../styles/modules/table.module.scss'
import textStyles from '../../styles/modules/users/users.module.scss'

interface Props {
  subscription?: Subscription
}

const SubscriptionTable: React.FC<Props> = props => {
  const setSwitchGracePeriodSentence = (subscription: Subscription) => {
    if (subscription.switchGracePeriod) {
      const planName = subscription.switchGracePeriod.plan.name
      const expirationDatetime = moment(props.subscription.switchGracePeriod.expirationDatetime).format('YYYY/MM/DD HH:mm:ss')
      return `前プラン(${planName})は${expirationDatetime}まで有効`
    }
  }

  return (
    <>
      <h3 className={styles.title}>{strings.subscriptionTableTitle}</h3>
      {(props.subscription && props.subscription.status === 'active') ? (
        <div
          className={classnames({
            [styles.wrapper]: true,
            [styles.stocker]: true,
          })}
        >
          <table className={styles.container}>
            <tbody>
              <tr className={styles.rowStatic}>
                <td className={styles.cell}>{subscriptionTableContent.id}</td>
                <td className={styles.cell}>
                  <Link to={`/subscriptions/${props.subscription.id}`} className={styles.container}>
                    <span>{props.subscription.id}</span>
                  </Link>
                </td>
              </tr>
              <tr className={styles.rowStatic}>
                <td className={styles.cell}>
                  {subscriptionTableContent.planName}
                </td>
                <td className={styles.cell}>
                  {props.subscription.plan.name}
                </td>
              </tr>
              <tr className={styles.rowStatic}>
                <td className={styles.cell}>
                  {subscriptionTableContent.subscribedAt}
                </td>
                <td className={styles.cell}>
                  {moment(props.subscription.subscribedAt).format('YYYY/MM/DD/ HH:mm:ss')}
                </td>
              </tr>
              <tr className={styles.rowStatic}>
                <td className={styles.cell}>
                  {subscriptionTableContent.cancelledAt}
                </td>
                <td className={styles.cell}>
                  { props.subscription.cancelledAt && (moment(props.subscription.cancelledAt).format('YYYY/MM/DD/ HH:mm:ss'))}
                </td>
              </tr>
              <tr className={styles.rowStatic}>
                <td className={styles.cell}/>
                <td className={styles.cell}>
                  { setSwitchGracePeriodSentence(props.subscription)}
                </td>
              </tr>
            </tbody>
          </table>
          <LinkToSubscriptionsButton
              userId={props.subscription.userID}
          />
        </div>
      ) : (
        <div className={textStyles.subscriptionTextContainer}>
          <div className={textStyles.subscriptionText}>
            {strings.activeSubscriptionNotFound}
          </div>
        </div>
      )}
    </>
  )
}

export default SubscriptionTable
