import React from 'react'
import moment from 'moment'

import { MachineError } from '../../../core/machine-errors'
import TriangleDown from '../../assets/images/icon-triangle-down.png'
import TriangleRight from '../../assets/images/icon-triangle-right.png'
import styles from '../../styles/modules/emergencies/emergencies-table.module.scss'

interface Props {
  machineError: MachineError
}

interface State {
  readonly isExpanded: boolean
}

class MachineErrorsTableItem extends React.PureComponent<Props, State> {
  readonly state = {
    isExpanded: false,
  }
  render() {
    const { machineError } = this.props
    const { isExpanded } = this.state
    const cellStyle = isExpanded ? styles.expandedCell : styles.cell
    return (
      <tr
        className={styles.row}
        key={machineError.id}
        onClick={_ => this.setState({ isExpanded: !isExpanded })}
      >
        <td className={cellStyle}>
          <span
            className={styles.icon}
            style={{
              backgroundImage: isExpanded
                ? `url(${TriangleDown})`
                : `url(${TriangleRight})`,
            }}
          />
        </td>
        <td className={cellStyle}>{machineError.id}</td>
        <td className={cellStyle}>{machineError.stationID}</td>
        <td className={cellStyle}>{machineError.machineID}</td>
        <td className={cellStyle}>{machineError.orderID}</td>
        <td className={cellStyle}>{machineError.code}</td>
        <td className={cellStyle}>{machineError.level}</td>
        <td className={cellStyle}>{machineError.message}</td>
        <td className={cellStyle}>
          {moment(machineError.reportedAt).format('YYYY/MM/DD/ HH:mm:ss')}
        </td>
      </tr>
    )
  }
}

export default MachineErrorsTableItem
