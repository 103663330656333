import { ActionType } from 'typesafe-actions'
import { timeSlotPresetsActions, timeSlotPresetsActionType } from './actions'
import { TimeSlot } from '../stations/stations'
import { TimeSlotPreset } from './time-slot-presets'

export interface TimeSlotPresetsState {
  readonly error: Error
  readonly isLoading: boolean
  readonly timeSlots: TimeSlot[]
  readonly timeSlotPresets: TimeSlotPreset[]
}

export const initialState: TimeSlotPresetsState = {
  error: null,
  isLoading: false,
  timeSlots: [],
  timeSlotPresets: [],
}

export const timeSlotPresetsReducer = (
  state: TimeSlotPresetsState = initialState,
  action: ActionType<typeof timeSlotPresetsActions>
) => {
  switch (action.type) {
    case timeSlotPresetsActionType.FETCH_TIME_SLOT_PRESETS:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case timeSlotPresetsActionType.FETCH_TIME_SLOT_PRESETS_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        error: null,
        timeSlots: action.payload.timeSlots,
        timeSlotPresets: action.payload.timeSlotPresets,
      }
    case timeSlotPresetsActionType.UPDATE_TIME_SLOT_PRESETS:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case timeSlotPresetsActionType.THROW_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      }
    default:
      return state
  }
}
