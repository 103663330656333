export const strings = {
  title: 'グッズ受注管理',
  completeButton: '発送完了',
  cancelButton: 'キャンセル',
  returnButton: '返品',
  firstOrder: '初',
}

export const pointItemOrdersTableContent = [
  '受注ID',
  'グッズID',
  'グッズ名',
  '個数',
  'ユーザーID',
  '初注文',
  'ステータス',
  '発送完了',
  '発送完了日時',
  '郵便番号',
  '都道府県',
  '市町村',
  '配送先住所',
  '宛名',
  '電話番号',
  'キャンセル・返品',
]
