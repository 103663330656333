import React from 'react'

import Button from '../button'
import Input from '../input'
import FileInput from '../file-input'
import styles from '../../styles/modules/modal/modal.module.scss'
import { strings } from './modal-content'
import { Config } from '../../../core/toast'

interface Props {
  isLoading: boolean
  createPromotionImage: (file: File, linkUrl: string, title: string, description: string) => void
  show: (config: Config) => void
}

interface State {
  readonly file?: File
  readonly linkUrl: string
  readonly title: string
  readonly description: string
}

export class CreatePromotionImageForm extends React.PureComponent<
  Props,
  State
> {
  readonly state = {
    file: null,
    linkUrl: '',
    title: '',
    description: '',
  }

  handleOnClickSubmit = () => {
    const { file, linkUrl, title, description } = this.state

    if (!file || !linkUrl || !title || !description ) {
      const config: Config = {
        type: 'error',
        title: strings.toastError.title,
        body: strings.toastError.body,
      }
      this.props.show(config)
      return
    }
    this.props.createPromotionImage(file, linkUrl, title, description)
  }

  render() {
    const { isLoading } = this.props
    return (
      <main className={styles.main}>
        <Input
          label={strings.promotionImage.title}
          type={'text'}
          onChange={event => {
            this.setState({ title: event.target.value })
          }}
          value={this.state.title}
        />
        <Input
          label={strings.promotionImage.description}
          type={'text'}
          onChange={event => {
            this.setState({ description: event.target.value })
          }}
          value={this.state.description}
        />
        <FileInput
          label={strings.promotionImage.image}
          type={'file'}
          onChange={event => {
            this.setState({ file: event.target.files.item(0) })
          }}
        />
        <Input
          label={strings.promotionImage.linkUrl}
          type={'text'}
          onChange={event => {
            this.setState({ linkUrl: event.target.value })
          }}
          value={this.state.linkUrl}
        />
        <Button
          title={strings.promotionImage.createButton}
          onClick={this.handleOnClickSubmit}
          className={styles.report}
          isLoading={isLoading}
          loadingClassName={styles.loadingReport}
        />
      </main>
    )
  }
}
