import React from 'react'
import moment from 'moment'

import { Emergency } from '../../../core/emergencies'
import { strings } from './emergencies-content'
import TriangleDown from '../../assets/images/icon-triangle-down.png'
import TriangleRight from '../../assets/images/icon-triangle-right.png'
import styles from '../../styles/modules/emergencies/emergencies-table.module.scss'

interface Props {
  emergency: Emergency
}

interface State {
  readonly isExpanded: boolean
}

class EmergenciesTableItem extends React.PureComponent<Props, State> {
  readonly state = {
    isExpanded: false,
  }
  render() {
    const { emergency } = this.props
    const { isExpanded } = this.state
    const cellStyle = isExpanded ? styles.expandedCell : styles.cell
    return (
      <tr
        className={styles.row}
        key={emergency.id}
        onClick={_ => this.setState({ isExpanded: !isExpanded })}
      >
        <td className={cellStyle}>
          <span
            className={styles.icon}
            style={{
              backgroundImage: isExpanded
                ? `url(${TriangleDown})`
                : `url(${TriangleRight})`,
            }}
          />
        </td>
        <td className={cellStyle}>{emergency.id}</td>
        <td className={cellStyle}>{emergency.stationID}</td>
        <td className={cellStyle}>{emergency.machineID}</td>
        <td className={cellStyle}>{emergency.group}</td>
        <td className={cellStyle}>{emergency.message}</td>
        <td className={cellStyle}>{emergency.stackTrace}</td>
        <td className={styles.cell}>
          {emergency.canOpenLockerBox
            ? strings.availableOpenLockerBox
            : strings.unavailableOpenLockerBox}
        </td>
        <td className={cellStyle}>
          {moment(emergency.reportedAt).format('YYYY/MM/DD/ HH:mm:ss')}
        </td>
        <td className={cellStyle}>
          {emergency.resolvedAt
            ? moment(emergency.resolvedAt).format('YYYY/MM/DD/ HH:mm:ss')
            : null}
        </td>
        <td className={cellStyle}>{emergency.nucVersion}</td>
      </tr>
    )
  }
}

export default EmergenciesTableItem
