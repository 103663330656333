import { put, call, takeLatest, fork, select, delay} from "redux-saga/effects";
import RootState from '../root-state'

import { machineEmbeddedComputersActionType, machineEmbeddedComputersActions } from './actions';
import { getMachineEmbeddedComputers, deleteMachineEmbeddedComputer } from "../api";
import { toastActions } from "../toast";


export function* fetchMachineEmbeddedComputers(
  action: ReturnType<typeof machineEmbeddedComputersActions.fetchMachineEmbeddedComputers>
){
  try {
    const state: RootState = yield select()
    const token = state.auth0.auth.idToken
    const response = yield call(getMachineEmbeddedComputers, token)
    yield put(machineEmbeddedComputersActions.fetchMachineEmbeddedComputersSucceeded(response.machineEmbeddedComputers))
  } catch(error) {
    yield put(machineEmbeddedComputersActions.throwError(error))
  }
}

export function* destroyMachineEmbeddedComputer(
  action: ReturnType<typeof machineEmbeddedComputersActions.deleteMachineEmbeddedComputer>
){
  try {
    const state: RootState = yield select()
    const token = state.auth0.auth.idToken
    yield call(
      deleteMachineEmbeddedComputer,
      token,
      action.payload.machineEmbeddedComputerId
    )
    yield delay(100)
    yield put(machineEmbeddedComputersActions.fetchMachineEmbeddedComputers())
    yield put(
      toastActions.show({
        title: '成功！',
        body: 'SDカードを削除しました'
      })
    )
  }catch(error){
    yield put(machineEmbeddedComputersActions.throwError(error))
    yield put(
      toastActions.show({
        type: 'error',
        title: '失敗...',
        body: 'SDカードの削除に失敗しました',
      })
    )
  }
}

export function* watchFetchMachineEmbeddedComputers() {
  yield takeLatest(machineEmbeddedComputersActionType.FETCH_MACHINE_EMBEDDED_COMPUTERS, fetchMachineEmbeddedComputers)
}

export function* watchDestroyMachineEmbeddedComputer() {
  yield takeLatest(machineEmbeddedComputersActionType.DELETE_MACHINE_EMBEDDED_COMPUTER, destroyMachineEmbeddedComputer)
}

export const machineEmbeddedComputersSagas = [
  fork(watchFetchMachineEmbeddedComputers),
  fork(watchDestroyMachineEmbeddedComputer),
]
