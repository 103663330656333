import { useEffect } from 'react'
import { PointItemSupportLog } from '../../core/point-item-support-logs/point-item-support-logs'
import RootState from '../../core/root-state'
import { Dispatch } from 'redux'
import { pointItemSupportLogsActions } from '../../core/point-item-support-logs'
import { connect } from 'react-redux'
import Layout from '../layouts/layout'
import Wrapper from '../layouts/wrapper'
import { strings } from '../components/point-item-support-logs/point-item-support-logs-content'
import styles from '../styles/modules/point-item-support-logs/point-item-support-logs.module.scss'
import React from 'react'
import PointItemSupportLogsTable from '../components/point-item-support-logs/point-item-support-logs-table'

interface Props {
  pointItemSupportLogs: PointItemSupportLog[]
  isLoading: boolean
  fetchPointItemSupportLogs: () => void
}

const PointItemSupportLogsScreen: React.FC<Props> = ({
  pointItemSupportLogs,
  fetchPointItemSupportLogs,
}) => {
  useEffect(() => {
    fetchPointItemSupportLogs()
  }, [fetchPointItemSupportLogs])

  return (
    <Layout location={'point_item_support_logs'}>
      <Wrapper title={strings.title}>
        <div className={styles.container}>
          <PointItemSupportLogsTable
            pointItemSupportLogs={pointItemSupportLogs}
          />
        </div>
      </Wrapper>
    </Layout>
  )
}

const mapStateToProps = (state: RootState) => ({
  pointItemSupportLogs: state.pointItemSupportLogs.pointItemSupportLogs,
  isLoading: state.pointItemSupportLogs.isLoading,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchPointItemSupportLogs: () =>
    dispatch(pointItemSupportLogsActions.fetchPointItemSupportLogs()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PointItemSupportLogsScreen)
