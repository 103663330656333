import React from 'react'
import classnames from 'classnames'

import { Item } from '../../../core/items'
import { StationWithItemRanks } from '../../../core/station-with-item-ranks'
import {
  strings,
  stationWithItemRankTableContent,
} from './station-with-item-ranks-content'
import styles from '../../styles/modules/table.module.scss'
import Button from '../button'

interface Props {
  stationWithItemRanks: StationWithItemRanks[]
  items: Item[]
  handleClickOnOpenCreateModal: (
    itemStationWithItemRanks: StationWithItemRanks,
    items: Item[]
  ) => void
}

const StationWithItemRanksTable: React.FC<Props> = props => {
  return (
    <>
      <h3 className={styles.title}>{strings.stationWithItemRankTableTitle}</h3>
      <div
        className={classnames({
          [styles.wrapper]: true,
          [styles.zebra]: true,
          [styles.stocker]: true,
        })}
      >
        <br />
        <div>
          表示件数:{' '}
          {props.stationWithItemRanks && props.stationWithItemRanks.length}件
        </div>
        <br />
        {props.stationWithItemRanks &&
          props.stationWithItemRanks.length === 0 && (
            <div>ステーションが見つかりません</div>
          )}
        {props.stationWithItemRanks && props.stationWithItemRanks.length > 0 && (
          <table className={styles.container}>
            <tbody>
              <tr className={styles.row}>
                {stationWithItemRankTableContent.map(item => (
                  <th key={item} className={styles.cell}>
                    {item}
                  </th>
                ))}
              </tr>
              {props.stationWithItemRanks &&
                props.stationWithItemRanks.map(item => (
                  <tr
                    key={item.stationID}
                    className={classnames({
                      [styles.row]: true,
                    })}
                  >
                    <td className={styles.cell}>{item.stationID}</td>
                    <td className={styles.cell}>{item.stationName}</td>
                    <td className={styles.cell}>
                      {item.itemRanks[0] ? item.itemRanks[0].itemName : '-'}
                    </td>
                    <td className={styles.cell}>
                      {item.itemRanks[1] ? item.itemRanks[1].itemName : '-'}
                    </td>
                    <td className={styles.cell}>
                      {item.itemRanks[2] ? item.itemRanks[2].itemName : '-'}
                    </td>
                    <td className={styles.buttonCell}>
                      <Button
                        title={strings.updateStationWithItemRanks}
                        onClick={() => {
                          props.handleClickOnOpenCreateModal(item, props.items)
                        }}
                        className={styles.createStationItemRankButton}
                      />
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        )}
      </div>
    </>
  )
}

export default StationWithItemRanksTable
