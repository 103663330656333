import { ActionType } from 'typesafe-actions'
import { salesDataActionType, salesDataActions } from './actions'
import { SalesData } from './sales-data'

export interface SalesDataState {
  readonly error: Error
  readonly isLoading: boolean
  readonly monthlySalesData: SalesData
  readonly dailySalesData: SalesData
}

export const initialState: SalesDataState = {
  error: null,
  isLoading: false,
  monthlySalesData: null,
  dailySalesData: null,
}

export const salesDataReducer = (
  state: SalesDataState = initialState,
  action: ActionType<typeof salesDataActions>
) => {
  switch (action.type) {
    case salesDataActionType.FETCH_MONTHLY_SALES_DATA:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case salesDataActionType.FETCH_MONTHLY_SALES_DATA_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        error: null,
        monthlySalesData: action.payload.monthlySalesData,
      }
    case salesDataActionType.FETCH_DAILY_SALES_DATA:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case salesDataActionType.FETCH_DAILY_SALES_DATA_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        error: null,
        dailySalesData: action.payload.dailySalesData,
      }
    case salesDataActionType.THROW_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      }
    default:
      return state
  }
}
