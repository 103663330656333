import React, { useState } from 'react'
import Button from '../button'
import styles from '../../styles/modules/modal/modal.module.scss'
import { strings } from './modal-content'
import { Config } from '../../../core/toast'
import { Station } from '../../../core/stations'
import { SoracomSIM } from '../../../core/soracom-sims'
import { Kiosk } from '../../../core/kiosks'

interface Props {
  isLoading: boolean
  stations: Station[]
  soracomSIMs: SoracomSIM[]
  kiosks: Kiosk[]
  kiosk: Kiosk
  setKioskRelation: (
    kioskId: number,
    stationId: number,
    soracomSIMId: number
  ) => void
  show: (config: Config) => void
}

export const EditKioskRelationForm: React.FC<Props> = (props: Props) => {
  const [stationId, setStationId] = useState<number | undefined>(
    props.kiosk.stationID
  )
  const selectedSoracomSIM = props.soracomSIMs.find(
    soracomSIM => soracomSIM.kioskID === props.kiosk.id
  )
  const [soracomSIMId, setSoracomSIMId] = useState<number | undefined>(
    selectedSoracomSIM ? selectedSoracomSIM.id : undefined
  )

  const handleOnClickSubmit = () => {
    if (!stationId && !soracomSIMId) {
      const config: Config = {
        type: 'error',
        title: strings.toastError.title,
        body: strings.toastError.body,
      }
      props.show(config)
      return
    }
    props.setKioskRelation(props.kiosk.id, stationId, soracomSIMId)
  }

  return (
    <main className={styles.main}>
      <div className={styles.form}>
        <label className={styles.label}>{strings.kioskRelation.kioskId}</label>
        <div className={styles.contentText}>{props.kiosk.id}</div>
      </div>
      <div className={styles.form}>
        <label className={styles.label}>
          {strings.kioskRelation.kioskSerial}
        </label>
        <div className={styles.contentText}>{props.kiosk.serial}</div>
      </div>
      <div className={styles.form}>
        <label className={styles.label}>{strings.kioskRelation.station}</label>
        <div>
          <select
            className={styles.input}
            value={stationId}
            onChange={e =>
              setStationId(
                e.target.value ? parseInt(e.target.value, 10) : undefined
              )
            }
          >
            <option value="">{strings.kioskRelation.unset}</option>
            {props.stations
              .filter(station => station.id !== props.kiosk.stationID)
              .map(station => (
                <option value={`${station.id}`} key={station.id}>
                  {`ID: ${station.id} ${station.name}`}
                </option>
              ))}
          </select>
        </div>
      </div>
      <div className={styles.form}>
        <label className={styles.label}>
          {strings.kioskRelation.soracomSIM}
        </label>
        <div>
          <select
            className={styles.input}
            value={soracomSIMId}
            onChange={e =>
              setSoracomSIMId(
                e.target.value ? parseInt(e.target.value, 10) : undefined
              )
            }
          >
            <option value="">{strings.kioskRelation.unset}</option>
            {props.soracomSIMs
              .filter(
                soracomSIM =>
                  !soracomSIM.machineID &&
                  (!soracomSIM.kioskID || soracomSIM.kioskID === props.kiosk.id)
              )
              .map(soracomSIM => (
                <option value={`${soracomSIM.id}`} key={soracomSIM.id}>
                  {`ID: ${soracomSIM.id}, SIM 製造番号: ${soracomSIM.serial}`}
                </option>
              ))}
          </select>
        </div>
      </div>
      <Button
        title={strings.kioskRelation.editButton}
        onClick={() => handleOnClickSubmit()}
        className={styles.report}
        isLoading={props.isLoading}
        loadingClassName={styles.loadingReport}
      />
    </main>
  )
}
