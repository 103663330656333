import React from 'react'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { Redirect } from 'react-router'
import ReactSVG from 'react-svg'

import RootState from '../../core/root-state'
import { auth0Actions } from '../../core/auth0'
import loadingBlue from '../assets/animations/loading_blue.svg'
import styles from '../styles/modules/callback.module.scss'

interface Props {
  isAuthenticated: boolean
  checkAuthenticated: () => void
}

class CallbackScreen extends React.Component<Props, {}> {
  componentDidMount = () => {
    this.props.checkAuthenticated()
  }

  render() {
    if (this.props.isAuthenticated) {
      return <Redirect to={'/'} />
    } else {
      return (
        <div className={styles.container}>
          <div className={styles.content}>
            <ReactSVG
              src={loadingBlue}
              beforeInjection={svg => {
                svg.classList.add(styles.loading)
              }}
            />
            <span className={styles.title}>{'Login processing...'}</span>
          </div>
        </div>
      )
    }
  }
}

const mapStateToProps = (state: RootState) => ({
  isAuthenticated: state.auth0.isAuthenticated,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  checkAuthenticated: () => dispatch(auth0Actions.checkAuthenticated()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CallbackScreen)
