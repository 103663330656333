export const orderTableContent = [
  'オーダー日時',
  'オーダーID',
  'HashID',
  '注文形式',
  'ロッカー',
  '受取時間スロット',
  '調理開始時間',
  '調理完了時間',
  '受取開始時間',
  '受取完了時間',
  '提供状況',
  'ステータス',
  'キャンセル状態',
  'トラブル状態',
  'アイテム名',
  'coffeeLogID',
  'ユーザーID',
  'KIOSK ID',
  'ステーション名',
  '決済手段',
  '支払額',
  'ポイント使用額',
  'クーポン使用額',
  'OMC割引額',
  'KIOSK購入画像',
  'キャンセル',
]

export const strings = {
  title: 'オーダー',
  loadMoreButton: 'もっと読み込む',
  queryButton: '絞り込む',
  queryPlaceHolder: 'すべて',
  queryStationTitle: 'ステーションで絞り込む',
  queryDateTitle: '期間で絞り込む',
  queryProvisionStatus: '提供状況で絞り込む',
  datePlaceholder: '指定なし',
  ordersTableTitle: 'オーダー情報',
  kioskPurchaseImage: 'KIOSK購入画像',
  cancel: 'キャンセル',
  alert: '検索結果が多すぎます。\n最新の1,000件を表示します。',
  canceled: 'キャンセル済',
  hasTrouble: 'トラブル有',
}
