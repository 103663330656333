import React from 'react'
import classnames from 'classnames'

import { MachineEmbeddedComputer } from '../../../core/machine-embedded-computers'
import {
  strings,
  machineEmbeddedComputersTableContent,
} from './machine-embedded-computers-content'
import Button from '../button'

import styles from '../../styles/modules/table.module.scss'
import machineEmbeddedComputersStyles from '../../styles/modules/machine-embedded-computers/machine-embedded-computers.module.scss'
import { Link } from 'react-router-dom'

interface Props {
  machineEmbeddedComputers: MachineEmbeddedComputer[]
  deleteMachineEmbeddedComputer: (machineEmbeddedComputerId: number) => void
}

const deleteMachineEmbeddedComputeSentence = () =>
  `
  SDカードを削除します。
  操作を確定する場合はテキストフィールドに「削除」と入力してください。

  ※注意
  この操作は取り消すことができません。
  SDカードを削除することでお客様に不利益を及ぼす可能性があります。
  影響範囲を正しく理解した上で実行してください。
  `

const MachineEmbeddedComputersTable: React.FC<Props> = (props: Props) => {
  const handleOnClickDeleteMachineEmbeddedComputer = (
    machineEmbeddedComputerId: number
  ) => {
    const machineEmbeddedComputerSentence = deleteMachineEmbeddedComputeSentence()
    const input = window.prompt(machineEmbeddedComputerSentence)
    if (input !== null) {
      if (input === '削除') {
        // 削除が入力されたら動く処理
        props.deleteMachineEmbeddedComputer(machineEmbeddedComputerId)
      } else {
        // 削除が以外が入力されたら動く処理
        handleOnClickDeleteMachineEmbeddedComputer(machineEmbeddedComputerId)
      }
    }
  }

  return (
    <>
      <h3 className={styles.title}>
        {strings.machineEmbeddedComputersTableTitle}
      </h3>
      <div
        className={classnames({
          [styles.wrapper]: true,
          [styles.zebra]: true,
          [styles.stocker]: true,
        })}
      >
        <table className={styles.container}>
          <tbody>
            <tr className={styles.row}>
              {machineEmbeddedComputersTableContent.map(item => (
                <th key={item} className={styles.cell}>
                  {item}
                </th>
              ))}
            </tr>
            {props.machineEmbeddedComputers
              .sort((a, b) => {
                if (a.id < b.id) {
                  return -1
                } else {
                  return 1
                }
              })
              .map(machineEmbeddedComputer => (
                <tr className={styles.row} key={machineEmbeddedComputer.id}>
                  <td className={styles.cell}>{machineEmbeddedComputer.id}</td>
                  <td className={styles.cell}>
                    {machineEmbeddedComputer.sdCardID}
                  </td>
                  <td className={styles.cell}>
                    {machineEmbeddedComputer.nodeID
                      ? machineEmbeddedComputer.nodeID
                      : strings.noData}
                  </td>
                  <td className={styles.cell}>
                    {machineEmbeddedComputer.machineID
                      ? machineEmbeddedComputer.machineID
                      : strings.noData}
                  </td>
                  <td className={styles.cell}>
                    {machineEmbeddedComputer.stationID ? (
                      <Link
                        to={{
                          pathname: `/stations/${machineEmbeddedComputer.stationID}`,
                        }}
                      >
                        {machineEmbeddedComputer.stationID}
                      </Link>
                    ) : (
                      strings.noData
                    )}
                  </td>
                  <td className={styles.cell}>
                    {machineEmbeddedComputer.stationName
                      ? machineEmbeddedComputer.stationName
                      : strings.noData}
                  </td>
                  <td className={styles.cell}>
                    <Button
                      title="削除"
                      onClick={() => {
                        handleOnClickDeleteMachineEmbeddedComputer(
                          machineEmbeddedComputer.id
                        )
                      }}
                      className={
                        machineEmbeddedComputersStyles.machineEmbeddedComputerDeleteButton
                      }
                    />
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </>
  )
}

export default MachineEmbeddedComputersTable
