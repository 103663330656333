import React from 'react'
import classnames from 'classnames'

import machineErrorsTableStyles from '../../styles/modules/machine-errors/machine-errors-table.module.scss'
import styles from '../../styles/modules/table.module.scss'
import { machineErrorsTableContent } from './machine-errors-content'
import MachineErrorsTableItem from './machine-errors-table-item'
import { MachineError } from '../../../core/machine-errors'

interface Props {
  machineErrors: MachineError[]
}

const MachineErrorsTable: React.FC<Props> = props => {
  return (
    <div
      className={classnames({
        [styles.wrapper]: true,
        [styles.zebra]: true,
        [styles.stocker]: true,
      })}
    >
      <table
        className={classnames(styles.container, machineErrorsTableStyles.table)}
      >
        <tbody>
          <tr className={styles.row}>
            {machineErrorsTableContent.map(item => (
              <th key={item} className={styles.cell}>
                {item}
              </th>
            ))}
          </tr>
          {props.machineErrors
            .sort((a, b) => {
              if (a.id > b.id) {
                return -1
              } else {
                return 1
              }
            })
            .map(item => (
              <MachineErrorsTableItem machineError={item} key={item.id} />
            ))}
        </tbody>
      </table>
    </div>
  )
}

export default MachineErrorsTable
