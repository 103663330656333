import React, { useCallback } from 'react'
import classnames from 'classnames'

import { Item } from '../../../core/items'
import { itemTableContent } from './items-content'
import { ReactSortable } from 'react-sortablejs'
import { Link } from 'react-router-dom'
import styles from '../../styles/modules/table.module.scss'
import itemTableStyles from '../../styles/modules/items/item-table.module.scss'

interface Props {
  items: Item[]
  changeItemsIndex: (ids: number[]) => void
  setItems: (items: Item[]) => void
}

const ItemTable: React.FC<Props> = props => {
  // handleOnSort関数で、changeItemsIndexのみを依存配列に追加するためにpropsを分解
  const { setItems } = props

  // 画面のちらつきを防ぐためにuseCallbackを使用
  const handleOnSort = useCallback(
    (items: Item[]) => {
      setItems(items)
    },
    [setItems]
  )

  return (
    <div
      className={classnames({
        [styles.wrapper]: true,
        [styles.zebra]: true,
        [styles.stocker]: true,
      })}
    >
      <table className={styles.container}>
        <thead>
          <tr className={itemTableStyles.row}>
            {itemTableContent.map((item, index) => (
              <th key={index} className={styles.cell}>
                {item}
              </th>
            ))}
          </tr>
        </thead>
        <ReactSortable
          tag="tbody"
          list={props.items}
          setList={handleOnSort}
          handle=".handle"
          onSort={() =>
            props.changeItemsIndex(props.items.map(item => item.id))
          }
        >
          {props.items.map((item, index) => (
            <tr className={styles.row} key={item.id}>
              <td className={`${itemTableStyles.cell} handle`}>
                <div className={itemTableStyles.orderIcon}>
                  <span />
                  <span />
                  <span />
                </div>
              </td>
              <td className={styles.cell}>{item.id}</td>
              <td className={styles.cell}>{index + 1}</td>
              <td className={styles.cell}>
                <Link to={`/items/${item.id}`}>{item.name}</Link>
              </td>
              <td className={itemTableStyles.imgCell}>
                <img
                  alt="item"
                  src={item.imageURL}
                  className={itemTableStyles.image}
                />
              </td>
            </tr>
          ))}
        </ReactSortable>
      </table>
    </div>
  )
}

export default ItemTable
