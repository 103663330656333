
import React from 'react'

import Button from '../button'
import Input from '../input'
import styles from '../../styles/modules/modal/modal.module.scss'
import { strings } from './modal-content'
import { Config } from '../../../core/toast'

interface Props {
  isLoading: boolean
  createStation: (
    name: string,
    extra: string,
    fullAddress: string,
    openingHours: string,
    latitude: number,
    longitude: number
  ) => void
  show: (config: Config) => void
}

interface State {
  readonly name: string
  readonly extra: string
  readonly fullAddress: string
  readonly openingHours: string
  readonly latitude: string
  readonly longitude: string
}

export class CreateStationForm extends React.PureComponent<Props, State> {
  readonly state = {
    name: '',
    extra: '',
    fullAddress: '',
    openingHours: '',
    latitude: '',
    longitude: '',
  }

  handleOnClickSubmit = () => {
    const latitude = parseFloat(this.state.latitude)
    const longitude = parseFloat(this.state.longitude)
    const { name, extra, fullAddress, openingHours } = this.state

    if (
      !name || !extra || !fullAddress || !openingHours ||
      isNaN(latitude) ||
      isNaN(longitude)
    ) {
      const config: Config = {
        type: 'error',
        title: strings.toastError.title,
        body: strings.toastError.body,
      }
      this.props.show(config)
      return
    }
    this.props.createStation(
      name,
      extra,
      fullAddress,
      openingHours,
      latitude,
      longitude
    )
  }

  render() {
    const { isLoading } = this.props
    return (
      <main className={styles.main}>
        <Input
          label={strings.station.name}
          type={'text'}
          onChange={event => {
            this.setState({ name: event.target.value })
          }}
          value={this.state.name}
        />
        <Input
          label={strings.station.extra}
          type={'text'}
          onChange={event => {
            this.setState({ extra: event.target.value })
          }}
          value={this.state.extra}
        />
        <Input
          label={strings.station.fullAddress}
          type={'text'}
          onChange={event => {
            this.setState({ fullAddress: event.target.value })
          }}
          value={this.state.fullAddress}
        />
        <Input
          label={strings.station.openingHours}
          type={'text'}
          onChange={event => {
            this.setState({ openingHours: event.target.value })
          }}
          value={this.state.openingHours}
        />
        <Input
          label={strings.station.latitude}
          type={'text'}
          onChange={event => {
            this.setState({ latitude: event.target.value })
          }}
          value={this.state.latitude}
        />
        <Input
          label={strings.station.longitude}
          type={'text'}
          onChange={event => {
            this.setState({ longitude: event.target.value })
          }}
          value={this.state.longitude}
        />
        <Button
          title={strings.soracomSIM.createButton}
          onClick={this.handleOnClickSubmit}
          className={styles.report}
          isLoading={isLoading}
          loadingClassName={styles.loadingReport}
        />
      </main>
    )
  }
}
