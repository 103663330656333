import React from 'react'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { match } from 'react-router-dom'
import DatePicker from 'react-datepicker'

import Layout from '../layouts/layout'
import Wrapper from '../layouts/wrapper'
import RootState from '../../core/root-state'
import Button from '../components/button'
import EmergenciesTable from '../components/each-station/emergencies-table'
import { strings } from '../components/each-station/each-station-content'
import ItemTable from '../components/each-station/item-table'
import LockerBoxTable from '../components/each-station/locker-box-table'
import StationDetailsForm from '../components/each-station/station-details-form'
import StationDetailsTable from '../components/each-station/station-details-table'
import TimeSlotTable from '../components/each-station/time-slot-table'
import UpcomingOrdersTable from '../components/each-station/upcoming-orders-table'
import { LockerBox, LockerBoxComment, Machine } from '../../core/machines'
import { modalActions } from '../../core/modal'
import {
  stationsActions,
  Station,
  Emergency,
  StationImageType,
  StationImage,
  TimeSlot,
} from '../../core/stations'
import {
  TimeSlotPreset,
  timeSlotPresetsActions,
} from '../../core/time-slot-presets'
import styles from '../styles/modules/each-station/each-station.module.scss'
import 'react-datepicker/dist/react-datepicker.css'
import '../styles/modules/react-datepicker.scss'
import DayOfWeekTimeSlotPreset from '../components/each-station/day-of-week-time-slot-preset'
import OrderLimitationTable from '../components/each-station/order-limitation-table'

interface Props {
  match: match<any>
  station: Station
  stationImage: StationImage
  timeSlots: TimeSlot[]
  timeSlotPresets: TimeSlotPreset[]
  fetchStation: (stationId: number) => void
  fetchStationEvery: (stationId: number) => void
  fetchStationEveryFinished: () => void
  showCreateEmergency: (station: Station) => void
  resolveEmergency: (stationId: number, emergencyId: number) => void
  fillIce: (stationId: number) => void
  showCreateLockerBoxComment: (
    stationId: number,
    lockerBoxId: number,
    lockerBoxIndex: number
  ) => void
  showEditLockerBoxComment: (
    stationID: number,
    lockerBoxID: number,
    lockerBoxIndex: number,
    lockerBoxComment: LockerBoxComment
  ) => void
  openAllLockerBoxes: (stationId: number) => void
  openTimeSlot: (stationId: number, timeSlotId: number) => void
  closeTimeSlot: (stationId: number, timeSlotId: number) => void
  openAllTimeSlots: (stationId: number) => void
  closeAllTimeSlots: (stationId: number) => void
  setStationItemAvailable: (itemId: number, stationId: number) => void
  setStationItemAvailableTemporarily: (
    itemId: number,
    stationId: number
  ) => void
  setStationItemUnavailable: (itemId: number, stationId: number) => void
  setStationItemUnavailableTemporarily: (
    itemId: number,
    stationId: number
  ) => void
  setStation: (stationId: number, station: Station) => void
  uploadStationImage: (
    stationId: number,
    stationImageType: StationImageType,
    file: File
  ) => void
  setStationLockerBoxAvailable: (stationID: number, lockerBoxID: number) => void
  setStationLockerBoxUnavailable: (
    stationID: number,
    lockerBoxID: number
  ) => void
  clearLockerBox: (stationId: number, lockerBox: LockerBox) => void
  openLockerBox: (stationID: number, lockerBoxID: number) => void
  fetchTimeSlotPresets: (stationId: number) => void
  showEditTimeSlotPresets: (
    timeSlots: TimeSlot[],
    timeSlotPresets: TimeSlotPreset[]
  ) => void
}

interface State {
  readonly isEditing: boolean
  readonly stationEditing: Station
  readonly selectedDate: Date
}

class EachStationScreen extends React.Component<Props, State> {
  constructor(props) {
    super(props)
    this.state = {
      isEditing: false,
      stationEditing: null,
      selectedDate: null,
    }
  }

  componentDidMount = () => {
    this.props.fetchStation(this.props.match.params.id)
    this.props.fetchStationEvery(this.props.match.params.id)
    this.props.fetchTimeSlotPresets(this.props.match.params.id)
  }

  componentDidUpdate = (prevProps: Props) => {
    const { stationImage } = this.props
    if (stationImage) {
      if (prevProps.stationImage !== stationImage) {
        switch (stationImage.stationImageType) {
          case StationImageType.StationImage:
            this.setState({
              stationEditing: {
                ...this.state.stationEditing,
                stationImageURL: stationImage.url,
                stationImageFileName: stationImage.fileName,
              },
            })
            break
          case StationImageType.MapImage:
            this.setState({
              stationEditing: {
                ...this.state.stationEditing,
                mapImageURL: stationImage.url,
                mapImageFileName: stationImage.fileName,
              },
            })
            break
          default:
        }
      }
    }
  }

  componentWillUnmount = () => {
    this.props.fetchStationEveryFinished()
  }

  handleOnClickResolveEmergency = (item: Emergency) => {
    const response = window.confirm(strings.resolveAlert)
    if (response) {
      this.props.resolveEmergency(this.props.station.id, item.id)
    }
  }

  handleOnClickCreateEmergency = () => {
    this.props.showCreateEmergency(this.props.station)
  }

  handleOnClickFillIce = (station: Station) => {
    const response = window.confirm(strings.fillIceAlert)
    if (response) {
      this.props.fillIce(station.id)
    }
  }

  handleOnClickOpenAllLockerBoxes = () => {
    const response = window.confirm(strings.openAllLockerBoxesButtonAlert)
    if (response) {
      this.props.openAllLockerBoxes(this.props.station.id)
    }
  }

  handleOnClickOpenAllTimeSlots = () => {
    const response = window.confirm(strings.allTimeSlotOpenAlert)
    if (response) {
      this.props.openAllTimeSlots(this.props.station.id)
    }
  }

  handleOnClickCloseAllTimeSlots = () => {
    const response = window.confirm(strings.allTimeSlotCloseAlert)
    if (response) {
      this.props.closeAllTimeSlots(this.props.station.id)
    }
  }

  isSavable = (): boolean => {
    const {
      name,
      extra,
      fullAddress,
      openingHours,
      latitude,
      longitude,
    } = this.state.stationEditing
    if (
      name === '' ||
      extra === '' ||
      fullAddress === '' ||
      openingHours === '' ||
      latitude === null ||
      longitude === null
    ) {
      return false
    }
    return true
  }

  handleOnClickEdit = (station: Station) => {
    this.setState({ isEditing: true, stationEditing: station })
  }

  handleOnClickQuit = () => {
    this.setState({ isEditing: false, stationEditing: null })
  }

  handleOnClickSave = (stationId: number) => {
    this.props.setStation(stationId, this.state.stationEditing)
    this.setState({ isEditing: false })
  }

  handleOnChangeStationDetails = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const name = event.target.name
    const value = event.target.value

    if (name === 'latitude' || name === 'longitude') {
      const re = /^[0-9]+\.?[0-9]*$/
      if (value === '') {
        this.setState({
          stationEditing: {
            ...this.state.stationEditing,
            [name]: null,
          },
        })
      }
      if (re.test(value)) {
        this.setState({
          stationEditing: {
            ...this.state.stationEditing,
            [name]: Number(value),
          },
        })
      }
    } else if (name === 'isClosed') {
      this.setState({
        stationEditing: {
          ...this.state.stationEditing,
          [name]: !!Number(value),
        },
      })
    } else {
      this.setState({
        stationEditing: {
          ...this.state.stationEditing,
          [name]: value,
        },
      })
    }
  }

  onChangeDate = (date: Date, name: string) => {
    this.setState({
      stationEditing: {
        ...this.state.stationEditing,
        [name]: date,
      },
    })
  }

  fetchTimeSlotPresetsBySelectedDate = (
    selectedDate: Date
  ): TimeSlotPreset[] => {
    const initTimeSlotPresets: TimeSlotPreset[] = []
    const timeSlotPresets = this.props.timeSlotPresets.filter(
      timeSlotPreset =>
        new Date(timeSlotPreset.selectedDate).getTime() ===
        selectedDate.getTime()
    )

    if (timeSlotPresets.length !== 0) {
      return timeSlotPresets
    }

    for (let i = 1; i <= this.props.timeSlots.length; i++) {
      initTimeSlotPresets.push({
        stationID: this.props.station.id,
        timeSlotID: i,
        isClosed: false,
        dayOfWeek: 'NA',
        selectedDate,
      })
    }

    return initTimeSlotPresets
  }

  isLimitingOrder = (machine: Machine): boolean => {
    // カップ不足や氷不足の条件もここに追加する
    return (
      machine.unavailableBeanTypeIndexes.length > 0 ||
      machine.isHotCupShortage ||
      machine.isIceCupShortage ||
      machine.isIceShortage
    )
  }

  render() {
    const id = this.props.match.params.id
    const { station, timeSlots, timeSlotPresets } = this.props
    const { isEditing, stationEditing, selectedDate } = this.state
    const now = new Date()

    return (
      <Layout location={'stations'}>
        <Wrapper title={`ID:${id} ${station ? station.name : ''}`}>
          {station && (
            <>
              <Button
                title={strings.createEmergencyButton}
                onClick={() => this.handleOnClickCreateEmergency()}
                className={styles.emergencyButton}
              />
              {station.alertedEmergencies.length > 0 && (
                <>
                  <h3 className={styles.title}>{strings.emergencies}</h3>
                  <EmergenciesTable
                    station={station}
                    onClickItem={item =>
                      this.handleOnClickResolveEmergency(item)
                    }
                  />
                </>
              )}
              {station.machine && this.isLimitingOrder(station.machine) && (
                <>
                  <h3 className={styles.title}>
                    {strings.orderLimitations.title}
                  </h3>
                  <OrderLimitationTable
                    machine={station.machine}
                    station={station}
                    onClickFillIce={station =>
                      this.handleOnClickFillIce(station)
                    }
                  />
                </>
              )}
              <h3 className={styles.title}>{strings.upcomingOrders}</h3>
              <UpcomingOrdersTable station={station} />
              <h3 className={styles.title}>{strings.lockerBox}</h3>
              <LockerBoxTable
                station={station}
                setStationLockerBoxAvailable={
                  this.props.setStationLockerBoxAvailable
                }
                setStationLockerBoxUnavailable={
                  this.props.setStationLockerBoxUnavailable
                }
                showEditLockerBoxComment={this.props.showEditLockerBoxComment}
                showCreateLockerBoxComment={
                  this.props.showCreateLockerBoxComment
                }
                clearLockerBox={this.props.clearLockerBox}
                openLockerBox={this.props.openLockerBox}
              />

              {/* 曜日指定のプリセット */}
              <h3 className={styles.title}>{strings.usualTimeSlotPreset}</h3>
              <DayOfWeekTimeSlotPreset
                timeSlots={timeSlots}
                timeSlotPresets={timeSlotPresets}
                showEditTimeSlotPresets={this.props.showEditTimeSlotPresets}
              />

              {/* 日付指定のプリセット */}
              <h3 className={styles.title}>{strings.selectedTimeSlotPreset}</h3>
              <div className={styles.description}>
                ・日付指定のプリセットが存在する場合、曜日指定のプリセットより優先して反映されます
                <br />
                ・日付指定のプリセットのリストは、未来の日付が表示されます
                <br />
                ・新規の日付指定のプリセットを作成する場合、カレンダーから日付を入力して、プリセットを作成してください
              </div>
              {[...new Set(timeSlotPresets.map(v => v.selectedDate))]
                .filter(v => v)
                .filter(
                  v =>
                    new Date(v) >
                    new Date(now.getFullYear(), now.getMonth(), now.getDate())
                )
                .sort()
                .map(v => (
                  <Button
                    key={v.toString()}
                    title={new Date(v).toLocaleDateString()}
                    onClick={() => {
                      this.props.showEditTimeSlotPresets(
                        timeSlots,
                        this.fetchTimeSlotPresetsBySelectedDate(new Date(v))
                      )
                    }}
                    className={styles.timeSlotButton}
                  />
                ))}
              <div className={styles.selectedDateWrapper}>
                <DatePicker
                  className={styles.stationDateForm}
                  onChange={date => {
                    this.setState({
                      selectedDate: new Date(
                        date.getFullYear(),
                        date.getMonth(),
                        date.getDate()
                      ),
                    })
                  }}
                  selected={selectedDate}
                  dateFormat={'yyyy/MM/dd'}
                  minDate={
                    new Date(
                      now.getFullYear(),
                      now.getMonth(),
                      now.getDate() + 1
                    )
                  }
                />
                <Button
                  title={strings.editTimeSlotPresetsSelectedDate}
                  onClick={() => {
                    this.props.showEditTimeSlotPresets(
                      timeSlots,
                      this.fetchTimeSlotPresetsBySelectedDate(selectedDate)
                    )
                  }}
                  disabled={selectedDate === null}
                  className={styles.selectedDateButton}
                />
              </div>
              <h3 className={styles.title}>{strings.realTimeSlot}</h3>
              <div className={styles.description}>
                ・当日の受取時間スロットは、以下の項目を変更してください
                <br />
                ・受取時間スロットを翌日に継続したい場合は、翌日分の日付指定のプリセットを作成するか、翌日に再度、以下の項目を変更してください
              </div>
              <Button
                title={strings.openAllTimeSlotButton}
                onClick={() => this.handleOnClickOpenAllTimeSlots()}
                className={styles.timeSlotButton}
              />
              <Button
                title={strings.closeAllTimeSlotButton}
                onClick={() => this.handleOnClickCloseAllTimeSlots()}
                className={styles.timeSlotButton}
              />
              <TimeSlotTable
                station={station}
                openTimeSlot={this.props.openTimeSlot}
                closeTimeSlot={this.props.closeTimeSlot}
              />
              <h3 className={styles.title}>{strings.item}</h3>
              <div className={styles.description}>
                {strings.itemDetails}
                <br />
                ・一時販売停止にしたい場合は、取扱状態をONのまま購入可否状態をOFFにしてください。
                <br />
                ・指定のコーヒーをモバイル上で非表示にする場合は、取扱状態をOFFにしてください。(購入可否ボタンは非表示になります。)
              </div>
              <h3 className={styles.title}>{strings.itemIce}</h3>
              <ItemTable
                station={station}
                items={station.items.filter(item => item.cupType === 2)}
                setStationItemAvailable={this.props.setStationItemAvailable}
                setStationItemAvailableTemporarily={
                  this.props.setStationItemAvailableTemporarily
                }
                setStationItemUnavailable={this.props.setStationItemUnavailable}
                setStationItemUnavailableTemporarily={
                  this.props.setStationItemUnavailableTemporarily
                }
              />
              <h3 className={styles.title}>{strings.itemHot}</h3>
              <ItemTable
                station={station}
                items={station.items.filter(item => item.cupType === 1)}
                setStationItemAvailable={this.props.setStationItemAvailable}
                setStationItemAvailableTemporarily={
                  this.props.setStationItemAvailableTemporarily
                }
                setStationItemUnavailable={this.props.setStationItemUnavailable}
                setStationItemUnavailableTemporarily={
                  this.props.setStationItemUnavailableTemporarily
                }
              />
              <h3 className={styles.title}>{strings.stationDetails}</h3>
              {isEditing ? (
                <>
                  <Button
                    className={styles.stationEditButton}
                    title={strings.quit}
                    onClick={() => this.handleOnClickQuit()}
                  />
                  <Button
                    className={styles.stationEditButton}
                    title={strings.save}
                    disabled={!this.isSavable()}
                    onClick={() => this.handleOnClickSave(id)}
                  />
                  <br />
                  <StationDetailsForm
                    station={stationEditing}
                    onChange={event => this.handleOnChangeStationDetails(event)}
                    uploadStationImage={this.props.uploadStationImage}
                    onChangeDate={this.onChangeDate}
                  />
                </>
              ) : (
                <>
                  <Button
                    className={styles.stationEditButton}
                    title={strings.edit}
                    onClick={() => this.handleOnClickEdit(station)}
                  />
                  <br />
                  <StationDetailsTable station={station} />
                </>
              )}
            </>
          )}
        </Wrapper>
      </Layout>
    )
  }
}

const mapStateToProps = (state: RootState) => ({
  station: state.stations.station,
  stationImage: state.stations.stationImage,
  timeSlots: state.timeSlotPresets.timeSlots,
  timeSlotPresets: state.timeSlotPresets.timeSlotPresets,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchStation: (stationId: number) =>
    dispatch(stationsActions.fetchStation(stationId)),
  fetchStationEvery: (stationId: number) =>
    dispatch(stationsActions.fetchStationEvery(stationId)),
  fetchStationEveryFinished: () =>
    dispatch(stationsActions.fetchStationEveryFinished()),
  showCreateEmergency: (station: Station) =>
    dispatch(modalActions.showCreateEmergency(station)),
  openAllLockerBoxes: (stationId: number) =>
    dispatch(stationsActions.openAllLockerBoxes(stationId)),
  resolveEmergency: (stationId: number, emergencyId: number) =>
    dispatch(stationsActions.resolveEmergency(stationId, emergencyId)),
  fillIce: (stationId: number) => dispatch(stationsActions.fillIce(stationId)),
  showCreateLockerBoxComment: (
    stationId: number,
    lockerBoxId: number,
    lockerBoxIndex: number
  ) =>
    dispatch(
      modalActions.showCreateLockerBoxComment(
        stationId,
        lockerBoxId,
        lockerBoxIndex
      )
    ),
  showEditLockerBoxComment: (
    stationID: number,
    lockerBoxID: number,
    lockerBoxIndex: number,
    lockerBoxComment: LockerBoxComment
  ) =>
    dispatch(
      modalActions.showEditLockerBoxComment(
        stationID,
        lockerBoxID,
        lockerBoxIndex,
        lockerBoxComment
      )
    ),
  openTimeSlot: (stationId: number, timeSlotId: number) =>
    dispatch(stationsActions.openTimeSlot(stationId, timeSlotId)),
  closeTimeSlot: (stationId: number, timeSlotId: number) =>
    dispatch(stationsActions.closeTimeSlot(stationId, timeSlotId)),
  openAllTimeSlots: (stationId: number) =>
    dispatch(stationsActions.openAllTimeSlots(stationId)),
  closeAllTimeSlots: (stationId: number) =>
    dispatch(stationsActions.closeAllTimeSlots(stationId)),
  setStationItemAvailable: (itemId: number, stationId: number) =>
    dispatch(stationsActions.setStationItemAvailable(itemId, stationId)),
  setStationItemAvailableTemporarily: (itemId: number, stationId: number) =>
    dispatch(
      stationsActions.setStationItemAvailableTemporarily(itemId, stationId)
    ),
  setStationItemUnavailable: (itemId: number, stationId: number) =>
    dispatch(stationsActions.setStationItemUnavailable(itemId, stationId)),
  setStationItemUnavailableTemporarily: (itemId: number, stationId: number) =>
    dispatch(
      stationsActions.setStationItemUnavailableTemporarily(itemId, stationId)
    ),
  setStation: (stationId: number, station: Station) =>
    dispatch(stationsActions.setStation(stationId, station)),
  uploadStationImage: (
    stationId: number,
    stationImageType: StationImageType,
    file: File
  ) =>
    dispatch(
      stationsActions.uploadStationImage(stationId, stationImageType, file)
    ),
  setStationLockerBoxAvailable: (stationID: number, lockerBoxID: number) =>
    dispatch(
      stationsActions.setStationLockerBoxAvailable(stationID, lockerBoxID)
    ),
  setStationLockerBoxUnavailable: (stationID: number, lockerBoxID: number) =>
    dispatch(
      stationsActions.setStationLockerBoxUnavailable(stationID, lockerBoxID)
    ),
  clearLockerBox: (stationId: number, lockerBox: LockerBox) =>
    dispatch(stationsActions.clearLockerBox(stationId, lockerBox)),
  openLockerBox: (stationID: number, lockerBoxID: number) =>
    dispatch(stationsActions.openLockerBox(stationID, lockerBoxID)),
  fetchTimeSlotPresets: (stationId: number) =>
    dispatch(timeSlotPresetsActions.fetchTimeSlotPresets(stationId)),
  showEditTimeSlotPresets: (
    timeSlots: TimeSlot[],
    timeSlotPresets: TimeSlotPreset[]
  ) =>
    dispatch(modalActions.showEditTimeSlotPresets(timeSlots, timeSlotPresets)),
})

export default connect(mapStateToProps, mapDispatchToProps)(EachStationScreen)
