export const strings = {
  title: 'アプリお知らせ',
  stationAlertsTableTitle: 'お知らせ履歴',
  showStationAlertButton: '詳細',
  createStationAlertButton: 'お知らせ作成'
}

export const stationAlertsTableContent = [
  'ステーション名',
  'タイトル',
  '作成日',
  '削除',
  ''
]
