import { ActionType } from 'typesafe-actions'
import { machinesActionType, machinesActions } from './actions'
import { Machine, MachineRelationHistory } from './machines'

export interface MachinesState {
  readonly error: Error
  readonly isLoading: boolean
  readonly machines: Machine[]
  readonly machineRelationHistories: MachineRelationHistory[]
}

export const initialState: MachinesState = {
  error: null,
  isLoading: false,
  machines: [],
  machineRelationHistories: [],
}

export const machinesReducer = (
  state: MachinesState = initialState,
  action: ActionType<typeof machinesActions>
) => {
  switch (action.type) {
    case machinesActionType.FETCH_MACHINES:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case machinesActionType.FETCH_MACHINES_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        error: null,
        machines: action.payload.machines,
      }
    case machinesActionType.CREATE_MACHINE:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case machinesActionType.UPDATE_MACHINE:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case machinesActionType.FETCH_MACHINE_RELATION_HISTRIES:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case machinesActionType.FETCH_MACHINE_RELATION_HISTRIES_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        error: null,
        machineRelationHistories: action.payload.machineRelationHistories,
      }
    case machinesActionType.UPDATE_MACHINE_RELATION:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case machinesActionType.CLEAR_MACHINE_RELATION:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case machinesActionType.THROW_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      }
    default:
      return state
  }
}
