import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import RootState from '../../core/root-state'
import { kiosksActions } from '../../core/kiosks'
import { strings } from '../components/kiosks/kiosks-content'
import styles from '../styles/modules/kiosks/kiosks.module.scss'
import KiosksTable from '../components/kiosks/kiosks-table'
import Layout from '../layouts/layout'
import Wrapper from '../layouts/wrapper'
import { modalActions } from '../../core/modal'
import Button from '../components/button'

const KiosksScreen = () => {
  const dispatch = useDispatch()
  const kiosks = useSelector((state: RootState) => state.kiosks.kiosks)

  const handleOnClickOpenModal = () => {
    dispatch(modalActions.showCreateKiosk())
  }

  useEffect(() => {
    const fetchKiosks = () => {
      dispatch(kiosksActions.fetchKiosks())
    }

    fetchKiosks()
  }, [dispatch])

  return (
    <Layout location="kiosks">
      <Wrapper title={strings.title}>
        <Button
          title={strings.createKioskButton}
          onClick={handleOnClickOpenModal}
          className={styles.kioskCreateButton}
        />
        <KiosksTable
          kiosks={kiosks}
        />
      </Wrapper>
    </Layout>
  )
}

export default KiosksScreen
