import React from 'react'
import DatePicker from 'react-datepicker'

import Button from '../button'
import Input from '../input'
import styles from '../../styles/modules/modal/modal.module.scss'
import { strings } from './modal-content'
import { Config } from '../../../core/toast'
import 'react-datepicker/dist/react-datepicker.css'
import '../../styles/modules/react-datepicker.scss'

interface Props {
  isLoading: boolean
  createMachine: (
    serial: string,
    manufacturedDate: Date,
    type: string,
    version: string,
    factoryName: string
  ) => void
  show: (config: Config) => void
}

interface State {
  readonly serial: string
  manufacturedDate: Date
  type: string
  version: string
  factoryName: string
}

export class CreateMachineForm extends React.PureComponent<Props, State> {
  readonly state = {
    serial: '',
    manufacturedDate: new Date(),
    type: '',
    version: '',
    factoryName: '',
  }

  handleOnClickSubmit = () => {
    const { serial, manufacturedDate, type, version, factoryName } = this.state

    if (!serial || !version) {
      const config: Config = {
        type: 'error',
        title: strings.toastError.title,
        body: strings.toastError.body,
      }
      this.props.show(config)
      return
    }
    this.props.createMachine(
      serial,
      manufacturedDate,
      type,
      version,
      factoryName
    )
  }

  render() {
    const { isLoading } = this.props
    return (
      <main className={styles.main}>
        <Input
          label={strings.machine.serial}
          type={'text'}
          onChange={event => {
            this.setState({ serial: event.target.value })
          }}
          value={this.state.serial}
        />
        <div className={styles.toPickerWrapper}>
          {strings.machine.manufacturedDate}
        </div>
        <DatePicker
          className={styles.manufacturedForm}
          onChange={e => this.setState({ manufacturedDate: e })}
          selected={this.state.manufacturedDate}
          dateFormat={'yyyy/MM/dd'}
        />
        <Input
          label={strings.machine.type}
          type={'text'}
          onChange={event => {
            this.setState({ type: event.target.value })
          }}
          value={this.state.type}
        />
        <Input
          label={strings.machine.version}
          type={'text'}
          onChange={event => {
            this.setState({ version: event.target.value })
          }}
          value={this.state.version}
        />
        <Input
          label={strings.machine.factoryName}
          type={'text'}
          onChange={event => {
            this.setState({ factoryName: event.target.value })
          }}
          value={this.state.factoryName}
        />
        <Button
          title={strings.machine.createButton}
          onClick={this.handleOnClickSubmit}
          className={styles.report}
          isLoading={isLoading}
          loadingClassName={styles.loadingReport}
        />
      </main>
    )
  }
}
