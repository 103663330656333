import React, { useState } from 'react'
import classnames from 'classnames'
import { ChromePicker } from 'react-color';

import { Item } from '../../../core/items'
import { itemInfoTableContent } from './each-item-content'
import styles from '../../styles/modules/table.module.scss'
import formStyles from '../../styles/modules/each-item/each-item-forms.module.scss'

interface Props {
  item: Item
  maxIndex: number
  onChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
  ) => void
  onChangeColor: (color: string) => void
}

const ItemInfoForm: React.FC<Props> = props => {
  const [selectedColor, setSelectedColor] = useState(props.item.color)
  const handleOnChangeColor = (color) => {
    setSelectedColor(color.hex)
    props.onChangeColor(color.hex)
  }

  return (
    <div
      className={classnames({
        [styles.wrapper]: true,
        [styles.stocker]: true,
      })}
    >
      <table className={styles.container}>
        <tbody>
          <tr className={styles.inputRowStatic}>
            <td className={styles.inputCell}>{itemInfoTableContent.price}</td>
            <td className={styles.inputCell}>
              <input
                className={formStyles.input}
                value={props.item.price || ''}
                onChange={props.onChange}
                name="price"
              />
            </td>
          </tr>
          <tr className={styles.inputRowStatic}>
            <td className={styles.inputCell}>
              {itemInfoTableContent.disposalSeconds}
            </td>
            <td className={styles.inputCell}>
              <input
                className={formStyles.input}
                value={props.item.disposalSeconds || ''}
                onChange={props.onChange}
                name="disposalSeconds"
              />
            </td>
          </tr>
          <tr className={styles.inputRowStatic}>
            <td className={styles.inputCell}>{itemInfoTableContent.cupType}</td>
            <td className={styles.inputCell}>
              <input
                className={formStyles.input}
                value={props.item.cupType}
                onChange={props.onChange}
                disabled={true}
                name="cupType"
              />
            </td>
          </tr>
          <tr className={styles.inputRowStatic}>
            <td className={styles.inputCell}>{itemInfoTableContent.color}</td>
            <td className={styles.inputCell}>
              <ChromePicker
                color={selectedColor}
                onChange={handleOnChangeColor}
                disableAlpha={true}
              />
            </td>
          </tr>
          <tr className={styles.inputRowStatic}>
            <td className={styles.inputCell}>{itemInfoTableContent.index}</td>
            <td className={styles.inputCell}>
              <input
                className={formStyles.input}
                value={props.item.index}
                onChange={props.onChange}
                disabled={true}
                name="index"
              />
            </td>
          </tr>
          <tr className={styles.inputRowStatic}>
            <td className={styles.inputCell}>
              {itemInfoTableContent.description}
            </td>
            <td className={styles.inputCell}>
              <input
                className={formStyles.input}
                value={props.item.description}
                onChange={props.onChange}
                name="description"
              />
            </td>
          </tr>
          <tr className={styles.inputRowStatic}>
            <td className={styles.inputCell}>{itemInfoTableContent.story}</td>
            <td className={styles.itemInputCell}>
              <textarea
                className={formStyles.textInput}
                value={props.item.story}
                onChange={props.onChange}
                name="story"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default ItemInfoForm
