import { action } from 'typesafe-actions'

import { SignageBanner } from './signage-banners'

export enum signageBannersActionType {
  FETCH_SIGNAGE_BANNER = 'FETCH_SIGNAGE_BANNER',
  FETCH_SIGNAGE_BANNER_SUCCEEDED = 'FETCH_SIGNAGE_BANNER_SUCCEEDED',
  CREATE_SIGNAGE_BANNER = 'CREATE_SIGNAGE_BANNER',
  DELETE_SIGNAGE_BANNER = 'DELETE_SIGNAGE_BANNER',
  DELETE_SIGNAGE_BANNER_SUCCEEDED = 'DELETE_SIGNAGE_BANNER_SUCCEEDED',
  SORT_SIGNAGE_BANNER = 'SORT_SIGNAGE_BANNER',
  UPDATE_SIGNAGE_BANNER = 'UPDATE_SIGNAGE_BANNER',
  THROW_ERROR = 'THROW_ERROR',
}

export const signageBannersActions = {
  fetchSignageBanners: () =>
    action(signageBannersActionType.FETCH_SIGNAGE_BANNER),
  fetchSignageBannersSucceeded: (signageBanners: SignageBanner[]) =>
    action(signageBannersActionType.FETCH_SIGNAGE_BANNER_SUCCEEDED, {
      signageBanners,
    }),
  createSignageBanners: (
    file: File,
    title: string,
    extension: string,
    mimeType: string,
    duration: number,
    base64File: string,
    adCaption: string
  ) =>
    action(signageBannersActionType.CREATE_SIGNAGE_BANNER, {
      file,
      title,
      extension,
      mimeType,
      duration,
      base64File,
      adCaption,
    }),
  deleteSignageBanner: (id: number) =>
    action(signageBannersActionType.DELETE_SIGNAGE_BANNER, { id }),
  updateSignageBanners: (id: number, duration: number, isShow: boolean) =>
    action(signageBannersActionType.UPDATE_SIGNAGE_BANNER, {
      id,
      duration,
      isShow,
    }),
  sortSignageBanners: (signageBannerSequenceIds: number[]) =>
    action(signageBannersActionType.SORT_SIGNAGE_BANNER, {
      signageBannerSequenceIds,
    }),
  throwError: (error: Error) =>
    action(signageBannersActionType.THROW_ERROR, { error }),
}
