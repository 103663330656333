import { ActionType } from 'typesafe-actions'

import { itemsActionType, itemsActions } from './actions'
import { Item, ItemImage } from './items'

export interface ItemsState {
  readonly error: Error
  readonly isLoading: boolean
  readonly items: Item[]
  readonly hotItems: Item[]
  readonly iceItems: Item[]
  readonly itemImage: ItemImage
  readonly active: 'items' | 'item' | 'none'
}

export const initialState: ItemsState = {
  error: null,
  isLoading: false,
  items: [],
  hotItems: [],
  iceItems: [],
  itemImage: null,
  active: 'none',
}

export const itemsReducer = (
  state: ItemsState = initialState,
  action: ActionType<typeof itemsActions>
) => {
  switch (action.type) {
    case itemsActionType.FETCH_ITEMS:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case itemsActionType.FETCH_ITEMS_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        error: null,
        items: action.payload.items,
        hotItems: action.payload.hotItems,
        iceItems: action.payload.iceItems,
      }
    case itemsActionType.FETCH_ITEMS_EVERY:
      return {
        ...state,
        error: null,
        active: 'items',
      }
    case itemsActionType.FETCH_ITEMS_EVERY_FINISHED:
      return {
        ...state,
        error: null,
        active: 'none',
      }
    case itemsActionType.SET_ITEM:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case itemsActionType.SET_ITEM_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        error: null,
      }
    case itemsActionType.UPLOAD_ITEM_IMAGE:
      return {
        ...state,
        itemImage: null,
        isLoading: true,
        error: null,
      }
    case itemsActionType.SET_ITEM_IMAGE_URL:
      return {
        ...state,
        itemImage: {
          fileName: action.payload.fileName,
          url: action.payload.url,
          itemImageType: action.payload.itemImageType
        },
        error: null,
      }
    case itemsActionType.CREATE_ITEM:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case itemsActionType.CREATE_ITEM_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        error: null,
      }
    case itemsActionType.CHANGE_ITEMS_INDEX:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case itemsActionType.SET_HOT_ITEMS:
      return {
        ...state,
        hotItems: action.payload.hotItems,
      }
    case itemsActionType.SET_ICE_ITEMS:
      return {
        ...state,
        iceItems: action.payload.iceItems,
      }
    case itemsActionType.THROW_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      }
    default:
      return state
  }
}
