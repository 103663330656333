import React from 'react'
import styles from '../../styles/modules/promotion-campaigns/promotion-campaign-query.module.scss'
import { strings } from './promotion-campaigns-content'
import { CampaignStatus } from '../../../core/campaigns'

interface Props {
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void
  value: string
  title: string
}

const QueryInput: React.FC<Props> = props => {
  const statusList = Object.values(CampaignStatus)

  return (
    <div className={styles.container}>
      <span className={styles.title}>{props.title}</span>
      <div className={styles.statusWrapper}>
        <select
          className={styles.input}
          value={props.value}
          onChange={event => props.onChange(event)}
        >
          <option value={''}>{strings.queryNoStatus}</option>
          {statusList.map(status => (
            <option key={status}>{status}</option>
          ))}
        </select>
      </div>
    </div>
  )
}

export default QueryInput
