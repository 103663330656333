import React from 'react'

import { strings } from './users-content'
import styles from '../../styles/modules/users/users.module.scss'
import 'react-datepicker/dist/react-datepicker.css'
import '../../styles/modules/react-datepicker.scss'

const UserNotFound: React.FC = () => (
  <div className={styles.textContainer}>
    <p>{strings.userNotFound}</p>
  </div>
)

export default UserNotFound
