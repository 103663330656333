import { action } from 'typesafe-actions'

import { Campaign } from '../campaigns'
import { Station, TimeSlot } from '../stations'
import { StationAlert } from '../station-alerts'
import { LockerBoxComment } from '../machines'
import { SoracomSIM } from '../soracom-sims'
import { Machine } from '../machines'
import { TimeSlotPreset } from '../time-slot-presets'
import { PromotionImage } from '../promotion-images'
import { Kiosk } from '../kiosks'
import { Item } from '../items'
import { StationWithItemRanks } from '../station-with-item-ranks'
import { KioskItemPrice } from '../kiosk-item-prices'

export enum modalActionType {
  SHOW_CREATE_STATION = 'SHOW_CREATE_STATION',
  SHOW_CREATE_EMERGENCY = 'MODAL_SHOW_CREATE_EMERGENCY',
  SHOW_CREATE_PROMOTION_CAMPAIGN = 'SHOW_CREATE_PROMOTION_CAMPAIGN',
  SHOW_EDIT_PROMOTION_CAMPAIGN_STATUS = 'SHOW_EDIT_PROMOTION_CAMPAIGN_STATUS',
  SHOW_EDIT_PROMOTION_CAMPAIGN = 'SHOW_EDIT_PROMOTION_CAMPAIGN',
  SHOW_EDIT_STARTER_CAMPAIGN = 'SHOW_EDIT_STARTER_CAMPAIGN',
  SHOW_EDIT_STAMP_CAMPAIGN = 'SHOW_EDIT_STAMP_CAMPAIGN',
  SHOW_EDIT_ORDER_CAMPAIGN = 'SHOW_EDIT_ORDER_CAMPAIGN',
  SHOW_EDIT_LOCKER_BOX_COMMENT = 'SHOW_EDIT_LOCKER_BOX_COMMENT',
  SHOW_CREATE_LOCKER_BOX_COMMENT = 'SHOW_CREATE_LOCKER_BOX_COMMENT',
  SHOW_CREATE_PROMOTION_IMAGE = 'SHOW_CREATE_PROMOTION_IMAGE',
  SHOW_EDIT_PROMOTION_IMAGE = 'SHOW_EDIT_PROMOTION_IMAGE',
  SHOW_CREATE_GIVE_POINTS = 'SHOW_CREATE_GIVE_POINTS',
  SHOW_CREATE_EXPIRE_POINTS = 'SHOW_CREATE_EXPIRE_POINTS',
  SHOW_CREATE_STATION_ALERT = 'SHOW_CREATE_STATION_ALERT',
  SHOW_EDIT_STATION_ALERT = 'SHOW_EDIT_STATION_ALERT',
  SHOW_CREATE_SORACOM_SIM = 'SHOW_CREATE_SORACOM_SIM',
  SHOW_EDIT_SORACOM_SIM = 'SHOW_EDIT_SORACOM_SIM',
  SHOW_CREATE_MACHINE = 'SHOW_CREATE_MACHINE',
  SHOW_EDIT_MACHINE = 'SHOW_EDIT_MACHINE',
  SHOW_EDIT_MACHINE_RELATION = 'SHOW_EDIT_MACHINE_RELATION',
  SHOW_EDIT_KIOSK_RELATION = 'SHOW_EDIT_KIOSK_RELATION',
  SHOW_EDIT_TIME_SLOT_PRESETS = 'SHOW_EDIT_TIME_SLOT_PRESETS',
  SHOW_CREATE_SIGNAGE_BANNER = 'SHOW_CREATE_SIGNAGE_BANNER',
  SHOW_EDIT_SIGNAGE_BANNER_DURATION = 'SHOW_EDIT_SIGNAGE_BANNER_DURATION',
  SHOW_EDIT_STATION_WITH_ITEM_RANKS = 'SHOW_EDIT_STATION_WITH_ITEM_RANKS',
  SHOW_CREATE_KIOSK = 'SHOW_CREATE_KIOSK',
  SHOW_EDIT_KIOSK_ITEM_PRICE = 'SHOW_EDIT_KIOSK_ITEM_PRICE',
  SHOW_CREATE_POINT_ITEM = 'SHOW_CREATE_POINT_ITEM',
  SHOW_IMAGE = 'SHOW_IMAGE',
  HIDE = 'MODAL_HIDE',
}

export const modalActions = {
  showCreateStation: () => action(modalActionType.SHOW_CREATE_STATION),
  showCreateEmergency: (station: Station) =>
    action(modalActionType.SHOW_CREATE_EMERGENCY, { station }),
  showCreatePromotionCampaign: (stations: Station[]) =>
    action(modalActionType.SHOW_CREATE_PROMOTION_CAMPAIGN, { stations }),
  showEditPromotionCampaignStatus: (promotionCampaign: Campaign) =>
    action(modalActionType.SHOW_EDIT_PROMOTION_CAMPAIGN_STATUS, {
      promotionCampaign,
    }),
  showEditPromotionCampaign: (promotionCampaign: Campaign) => {
    return action(modalActionType.SHOW_EDIT_PROMOTION_CAMPAIGN, {
      promotionCampaign,
    })
  },
  showEditStarterCampaign: (starterCampaign: Campaign) => {
    return action(modalActionType.SHOW_EDIT_STARTER_CAMPAIGN, {
      starterCampaign,
    })
  },
  showEditStampCampaign: (stampCampaign: Campaign) => {
    return action(modalActionType.SHOW_EDIT_STAMP_CAMPAIGN, {
      stampCampaign,
    })
  },
  showEditOrderCampaign: (orderCampaign: Campaign) => {
    return action(modalActionType.SHOW_EDIT_ORDER_CAMPAIGN, {
      orderCampaign,
    })
  },
  showCreatePromotionImage: () =>
    action(modalActionType.SHOW_CREATE_PROMOTION_IMAGE),
  showEditPromotionImage: (promotionImage: PromotionImage) =>
    action(modalActionType.SHOW_EDIT_PROMOTION_IMAGE, { promotionImage }),
  showCreateSignageBanner: () =>
    action(modalActionType.SHOW_CREATE_SIGNAGE_BANNER),
  showEditSignageBannerDuration: (
    id: number,
    duration: number,
    isShow: boolean
  ) =>
    action(modalActionType.SHOW_EDIT_SIGNAGE_BANNER_DURATION, {
      id,
      duration,
      isShow,
    }),
  showCreateGivePoints: (userId: number) =>
    action(modalActionType.SHOW_CREATE_GIVE_POINTS, { userId }),
  showCreateExpirePoints: (userId: number) =>
    action(modalActionType.SHOW_CREATE_EXPIRE_POINTS, { userId }),
  showCreateStationAlert: (stations: Station[]) =>
    action(modalActionType.SHOW_CREATE_STATION_ALERT, { stations }),
  showEditStationAlert: (stations: Station[], stationAlert: StationAlert) =>
    action(modalActionType.SHOW_EDIT_STATION_ALERT, { stations, stationAlert }),
  showEditLockerBoxComment: (
    stationID: number,
    lockerBoxID: number,
    lockerBoxIndex: number,
    lockerBoxComment: LockerBoxComment
  ) =>
    action(modalActionType.SHOW_EDIT_LOCKER_BOX_COMMENT, {
      stationID,
      lockerBoxID,
      lockerBoxIndex,
      lockerBoxComment,
    }),
  showCreateLockerBoxComment: (
    stationID: number,
    lockerBoxID: number,
    lockerBoxIndex: number
  ) =>
    action(modalActionType.SHOW_CREATE_LOCKER_BOX_COMMENT, {
      stationID,
      lockerBoxID,
      lockerBoxIndex,
    }),
  showCreateSoracomSIM: () => action(modalActionType.SHOW_CREATE_SORACOM_SIM),
  showEditSoracomSIM: (soracomSIM: SoracomSIM) =>
    action(modalActionType.SHOW_EDIT_SORACOM_SIM, { soracomSIM }),
  showCreateMachine: () => action(modalActionType.SHOW_CREATE_MACHINE),
  showEditMachine: (machine: Machine) =>
    action(modalActionType.SHOW_EDIT_MACHINE, { machine }),
  showEditMachineRelation: (
    stations: Station[],
    soracomSIMs: SoracomSIM[],
    machines: Machine[],
    machine: Machine
  ) =>
    action(modalActionType.SHOW_EDIT_MACHINE_RELATION, {
      stations,
      soracomSIMs,
      machines,
      machine,
    }),
  showEditKioskRelation: (
    stations: Station[],
    soracomSIMs: SoracomSIM[],
    kiosks: Kiosk[],
    kiosk: Kiosk
  ) =>
    action(modalActionType.SHOW_EDIT_KIOSK_RELATION, {
      stations,
      soracomSIMs,
      kiosks,
      kiosk,
    }),
  showEditTimeSlotPresets: (
    timeSlots: TimeSlot[],
    timeSlotPresets: TimeSlotPreset[]
  ) =>
    action(modalActionType.SHOW_EDIT_TIME_SLOT_PRESETS, {
      timeSlots,
      timeSlotPresets,
    }),
  showEditStationWithItemRank: (
    stationWithItemRanks: StationWithItemRanks,
    items: Item[]
  ) =>
    action(modalActionType.SHOW_EDIT_STATION_WITH_ITEM_RANKS, {
      stationWithItemRanks,
      items,
    }),
  showCreateKiosk: () => action(modalActionType.SHOW_CREATE_KIOSK),
  showEditKioskItemPrice: (kioskItemPrice: KioskItemPrice) =>
    action(modalActionType.SHOW_EDIT_KIOSK_ITEM_PRICE, { kioskItemPrice }),
  showCreatePointItem: () => action(modalActionType.SHOW_CREATE_POINT_ITEM),
  showImage: (imageURL: string, alt: string) =>
    action(modalActionType.SHOW_IMAGE, { imageURL, alt }),
  hide: () => action(modalActionType.HIDE),
}
