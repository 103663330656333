import { put, call, takeLatest, fork, delay, select } from 'redux-saga/effects'
import axios from 'axios'

import RootState from '../root-state'
import { signageBannersActionType, signageBannersActions } from './actions'
import { auth0Actions } from '../auth0'
import { toastActions } from '../toast'
import { modalActions } from '../modal'
import {
  getSignageBanners,
  newSignageBanner,
  deleteSignageBanner,
  presignSignageBannerUrl,
  uploadSignageBanner,
  sortSignageBannerSequences,
  putSignageBannerSequence,
} from '../api'

export function* fetchSignageBanners() {
  try {
    yield put(auth0Actions.checkSession())
    const state: RootState = yield select()
    const token = state.auth0.auth.idToken
    const response = yield call(getSignageBanners, token)
    yield put(
      signageBannersActions.fetchSignageBannersSucceeded(response.signageBanner)
    )
  } catch (error) {
    yield put(signageBannersActions.throwError(error))
  }
}

export function* createSignageBanners(
  action: ReturnType<typeof signageBannersActions.createSignageBanners>
) {
  try {
    yield put(auth0Actions.checkSession())
    const state: RootState = yield select()
    const token = state.auth0.auth.idToken
    const presignResponse = yield call(
      presignSignageBannerUrl,
      token,
      action.payload.extension
    )
    yield delay(100)
    const { url, fileName } = presignResponse.result
    yield call(axios.put, url, action.payload.file, {
      headers: {
        'Content-Type': action.payload.file.type,
      },
    })
    yield call(
      newSignageBanner,
      token,
      fileName,
      action.payload.title,
      action.payload.mimeType,
      action.payload.duration,
      action.payload.adCaption
    )
    yield delay(100)
    yield call(
      uploadSignageBanner,
      token,
      action.payload.base64File,
      action.payload.mimeType,
      fileName
    )
    yield put(modalActions.hide())
    yield put(signageBannersActions.fetchSignageBanners())
    yield put(
      toastActions.show({
        title: '成功！',
        body: 'バナーを作成しました',
      })
    )
    yield delay(100)
  } catch (error) {
    yield put(signageBannersActions.throwError(error))
    yield put(signageBannersActions.fetchSignageBanners())
    yield put(
      toastActions.show({
        type: 'error',
        title: '失敗...',
        // TODO: エラーコードによってメッセージを変える
        body: error.response.data.message,
      })
    )
    yield put(modalActions.hide())
  }
}

export function* arrangeSignageBanners(
  action: ReturnType<typeof signageBannersActions.sortSignageBanners>
) {
  try {
    yield put(auth0Actions.checkSession())
    const state: RootState = yield select()
    const token = state.auth0.auth.idToken
    yield call(
      sortSignageBannerSequences,
      token,
      action.payload.signageBannerSequenceIds
    )
  } catch (error) {
    yield put(signageBannersActions.throwError(error))
  }
}

export function* updateSignageBanner(
  action: ReturnType<typeof signageBannersActions.updateSignageBanners>
) {
  try {
    yield put(auth0Actions.checkSession())
    const state: RootState = yield select()
    const token = state.auth0.auth.idToken
    yield call(
      putSignageBannerSequence,
      token,
      action.payload.id,
      action.payload.duration,
      action.payload.isShow
    )
    yield delay(100)
    yield put(signageBannersActions.fetchSignageBanners())
    yield put(
      toastActions.show({
        title: '成功！',
        body: 'バナーを更新しました',
      })
    )
    yield put(modalActions.hide())
  } catch (error) {
    yield put(signageBannersActions.throwError(error))
    yield put(
      toastActions.show({
        type: 'error',
        title: '失敗...',
        body: '更新に失敗しました',
      })
    )
  }
}

export function* deleteSignageBanners(
  action: ReturnType<typeof signageBannersActions.deleteSignageBanner>
) {
  try {
    yield put(auth0Actions.checkSession())
    const state: RootState = yield select()
    const token = state.auth0.auth.idToken

    yield call(deleteSignageBanner, token, action.payload.id)
    yield delay(100)
    yield put(signageBannersActions.fetchSignageBanners())
    yield put(
      toastActions.show({
        title: '成功！',
        body: 'バナーを削除しました',
      })
    )
    yield put(modalActions.hide())
  } catch (error) {
    yield put(signageBannersActions.throwError(error))
    yield put(
      toastActions.show({
        type: 'error',
        title: '失敗...',
        body: '削除に失敗しました',
      })
    )
  }
}

export function* watchFetchSignageBanners() {
  yield takeLatest(
    signageBannersActionType.FETCH_SIGNAGE_BANNER,
    fetchSignageBanners
  )
}

export function* watchCreateSignageBanners() {
  yield takeLatest(
    signageBannersActionType.CREATE_SIGNAGE_BANNER,
    createSignageBanners
  )
}

export function* watchDeleteCreateSignageBanners() {
  yield takeLatest(
    signageBannersActionType.DELETE_SIGNAGE_BANNER,
    deleteSignageBanners
  )
}

export function* watchUpdateSignageBanners() {
  yield takeLatest(
    signageBannersActionType.UPDATE_SIGNAGE_BANNER,
    updateSignageBanner
  )
}

export function* watchArrangeSignageBanners() {
  yield takeLatest(
    signageBannersActionType.SORT_SIGNAGE_BANNER,
    arrangeSignageBanners
  )
}

export const signageBannersSagas = [
  fork(watchFetchSignageBanners),
  fork(watchCreateSignageBanners),
  fork(watchDeleteCreateSignageBanners),
  fork(watchArrangeSignageBanners),
  fork(watchUpdateSignageBanners),
]
