import { History } from 'history'
import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'

import { stationsReducer } from './stations'
import { ordersReducer } from './orders'
import { itemsReducer } from './items'
import { campaignsReducer } from './campaigns'
import { emergenciesReducer } from './emergencies'
import { machineErrorsReducer } from './machine-errors/reducer'
import { toastReducer } from './toast'
import { modalReducer } from './modal'
import { auth0Reducer } from './auth0'
import { promotionImagesReducer } from './promotion-images'
import { signageBannersReducer } from './signage-banners'
import { pointsReducer } from './points'
import { usersReducer } from './users'
import { stationAlertsReducer } from './station-alerts'
import { subscriptionReducer } from './subscription'
import { soracomSIMsReducer } from './soracom-sims'
import { machineEmbeddedComputersReducer } from './machine-embedded-computers'
import { machinesReducer } from './machines'
import { timeSlotPresetsReducer } from './time-slot-presets'
import { salesDataReducer } from './sales-data'
import { cameraStreamingViewerCredentialsReducer } from './camera-streaming-viewer'
import { kiosksReducer } from './kiosks'
import { kioskItemPricesReducer } from './kiosk-item-prices'
import { stationWithItemRanksReducer } from './station-with-item-ranks'
import { pointItemsReducer } from './point-items'
import { pointItemSupportLogsReducer } from './point-item-support-logs'
import { pointItemOrdersReducer } from './point-item-orders'

const createRootReducer = (history: History) =>
  combineReducers({
    router: connectRouter(history),
    stations: stationsReducer,
    items: itemsReducer,
    stationWithItemRanks: stationWithItemRanksReducer,
    orders: ordersReducer,
    campaigns: campaignsReducer,
    emergencies: emergenciesReducer,
    machineErrors: machineErrorsReducer,
    promotionImages: promotionImagesReducer,
    signageBanners: signageBannersReducer,
    pointTransactions: pointsReducer,
    stationAlerts: stationAlertsReducer,
    toast: toastReducer,
    modal: modalReducer,
    auth0: auth0Reducer,
    users: usersReducer,
    subscription: subscriptionReducer,
    soracomSIMs: soracomSIMsReducer,
    machineEmbeddedComputers: machineEmbeddedComputersReducer,
    machines: machinesReducer,
    timeSlotPresets: timeSlotPresetsReducer,
    salesData: salesDataReducer,
    cameraStreamingViewerCredentials: cameraStreamingViewerCredentialsReducer,
    kiosks: kiosksReducer,
    kioskItemPrices: kioskItemPricesReducer,
    pointItems: pointItemsReducer,
    pointItemSupportLogs: pointItemSupportLogsReducer,
    pointItemOrders: pointItemOrdersReducer,
  })

export default createRootReducer
