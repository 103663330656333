export const strings = {
  manufacturingInfo: 'コーヒー抽出設定',
  itemInfo: 'アイテム情報',
  tastes: '味の特徴',
  features: 'その他の特徴',
  matchItem: 'root C MATCHの特徴量',
  images: '画像',
  edit: '編集',
  save: '保存',
  quit: 'やめる',
  add: '追加',
  delete: '削除',
}

export const itemInfoTableContent = {
  price: '価格',
  disposalSeconds: 'ストック時間(秒)',
  cupType: 'カップタイプ',
  color: 'カラーコード',
  index: 'インデックス',
  description: 'ディスクリプション',
  story: 'コーヒーストーリー',
}

export const manufacturingInfoTableContent = {
  beanAmount: '豆排出時間(gではない)',
  beanTypeIndex: '豆レーン番号',
  machineButtonIndex: 'コーヒーマシンボタン番号',
}

export const matchItemInfoTableContent = {
  bitterness: '苦味 (bitterness)',
  fragrance: '香り (fragrance)',
  acidity: '酸味 (acidity)',
  sweetness: '甘み (sweetness)',
  richness: 'コク (richness)',
  uniqueness: '個性 (uniqueness)',
}

export const imageTitles = {
  image: 'アイテム詳細画像',
  thumbnailImage: 'メニュー/注文履歴画像',
}
