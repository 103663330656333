import React from 'react'
import DatePicker from 'react-datepicker'

import { strings } from './orders-content'
import styles from '../../styles/modules/orders/orders-query.module.scss'
import 'react-datepicker/dist/react-datepicker.css'
import '../../styles/modules/react-datepicker.scss'

interface Props {
  onChangeFrom: (date: Date) => void
  onChangeTo: (date: Date) => void
  from: Date
  to: Date
}

const QueryDate: React.FC<Props> = props => (
  <div className={styles.container}>
    <span className={styles.title}>{strings.queryDateTitle}</span>
    <div className={styles.box}>
      <div className={styles.fromPickerWrapper}>
        <DatePicker
          onChange={date => props.onChangeFrom(date)}
          selected={props.from}
          dateFormat={'yyyy/MM/dd'}
        />
      </div>
      <div className={styles.toPickerWrapper}>
        <DatePicker
          onChange={date => props.onChangeTo(date)}
          selected={props.to}
          dateFormat={'yyyy/MM/dd'}
        />
      </div>
    </div>
  </div>
)

export default QueryDate
