import React from 'react'
import classnames from 'classnames'
import moment from 'moment'

import { Station, CoffeeLog } from '../../../core/stations'
import { LockerBox, LockerBoxComment } from '../../../core/machines'
import { stockerContent, lockerBoxContent } from './each-station-content'
import styles from '../../styles/modules/table.module.scss'
import lockerBoxTableStyles from '../../styles/modules/each-station/locker-box-table.module.scss'
import Button from '../button'

interface Props {
  station: Station
  setStationLockerBoxAvailable: (stationID: number, lockerBoxID: number) => void
  setStationLockerBoxUnavailable: (
    stationID: number,
    lockerBoxID: number
  ) => void
  clearLockerBox: (stationId: number, lockerBox: LockerBox) => void
  showEditLockerBoxComment: (
    stationID: number,
    lockerBoxID: number,
    lockerBoxIndex: number,
    lockerBoxComment: LockerBoxComment
  ) => void
  showCreateLockerBoxComment: (
    stationID: number,
    lockerBoxID: number,
    lockerBoxIndex: number
  ) => void
  openLockerBox: (tationID: number, lockerBoxID: number) => void
}

const formatCoffeeLogStatus = (coffeeLog: CoffeeLog) => {
  switch (coffeeLog.status) {
    case 'open':
      return '調理開始済'
    case 'closed':
      if (!coffeeLog.orderId) {
        return 'オーダー待'
      }
      return coffeeLog.servedAt ? '取出待' : 'ドア解錠待'
  }
}

const createConfirmClearLockerBoxSentence = (lockerBox: LockerBox) =>
  `
${lockerBox.index}番のロッカーをクリアします。
操作を確定する場合はテキストフィールドに「${lockerBoxContent.clearConfirmWord}」と入力してください。

※注意
この操作は取り消すことができません。
ロッカーをクリアすることでお客様に不利益を及ぼす可能性があります。
影響範囲を正しく理解した上で実行してください。
`

const LockerBoxTable: React.FC<Props> = props => {
  const machine = props.station.machine

  const handleClickConfirmLockerBox = (lockerBox: LockerBox) => {
    const lockerBoxWillBeUnavailableSentence = `ID:${lockerBox.index}のロッカーを利用停止にしますか？`
    const lockerBoxWillBeAvailableSentence = `ID:${lockerBox.index}のロッカーを利用再開しますか？`
    const response = window.confirm(
      lockerBox.isDisable
        ? lockerBoxWillBeAvailableSentence
        : lockerBoxWillBeUnavailableSentence
    )
    if (response) {
      lockerBox.isDisable
        ? props.setStationLockerBoxAvailable(props.station.id, lockerBox.id)
        : props.setStationLockerBoxUnavailable(props.station.id, lockerBox.id)
    }
  }

  const handleClickOpenLockerBox = (lockerBox: LockerBox) => {
    if (window.confirm(`ID:${lockerBox.index}のロッカーを解錠しますか？`)) {
      props.openLockerBox(props.station.id, lockerBox.id)
    } else {
      return
    }
  }

  const handleOnClickClearLockerBox = (lockerBox: LockerBox) => {
    const confirmClearLockerBoxSentence = createConfirmClearLockerBoxSentence(
      lockerBox
    )
    const input = window.prompt(confirmClearLockerBoxSentence)
    if (input !== null) {
      if (input === lockerBoxContent.clearConfirmWord) {
        props.clearLockerBox(props.station.id, lockerBox)
      } else {
        handleOnClickClearLockerBox(lockerBox)
      }
    }
  }

  return (
    <div
      className={classnames({
        [styles.wrapper]: true,
        [styles.zebra]: true,
        [styles.stocker]: true,
      })}
    >
      <table className={styles.container}>
        <tbody>
          <tr className={styles.row}>
            {stockerContent.map(item => (
              <th key={item} className={styles.cell}>
                {item}
              </th>
            ))}
          </tr>
          {machine ? (
            props.station.machine.lockerBoxes
              .sort(lockerBox => lockerBox.index)
              .map(lockerBox => (
                <tr
                  className={styles.row}
                  key={lockerBox.id}
                  id={
                    lockerBox.order != null &&
                    lockerBox.order.isEmergencyRelated
                      ? styles.isUnderEmergency
                      : null
                  }
                >
                  <td className={styles.cell}>{lockerBox.index}</td>
                  {/*
                  <td className={styles.cell}>{lockerBox.axisX}</td>
                  <td className={styles.cell}>{lockerBox.axisY}</td>
                */}
                  <td className={styles.cell}>
                    {lockerBox.order
                      ? formatCoffeeLogStatus(lockerBox.order.coffeeLog)
                      : '-'}
                  </td>
                  <td className={styles.cell}>{lockerBox.doorOpenStatus}</td>
                  <td className={styles.cell}>
                    <Button
                      title={lockerBoxContent.openButton}
                      onClick={() => {
                        handleClickOpenLockerBox(lockerBox)
                      }}
                      className={lockerBoxTableStyles.button}
                      disabled={
                        lockerBox.doorOpenStatus === 'open' ||
                        lockerBox.doorOpenStatus === 'shoud_open'
                      }
                    />
                  </td>
                  <td className={styles.cell}>
                    {lockerBox.order &&
                    lockerBox.order.coffeeLog &&
                    lockerBox.order.coffeeLog.orderId
                      ? lockerBox.order.coffeeLog.orderId
                      : '-'}
                  </td>
                  <td className={styles.cell}>
                    {lockerBox.order && lockerBox.order.hashid
                      ? lockerBox.order.hashid
                      : '-'}
                  </td>
                  <td className={styles.cell}>
                    {lockerBox.order && lockerBox.order.coffeeLog
                      ? lockerBox.order.coffeeLog.item.name
                      : '-'}
                  </td>
                  <td className={styles.cell}>
                    {lockerBox.order && lockerBox.order.coffeeLog
                      ? moment(
                          lockerBox.order.coffeeLog.makingStartedAt
                        ).format('MM/DD HH:mm:ss')
                      : '-'}
                  </td>
                  <td className={styles.cell}>
                    {lockerBox.order && lockerBox.order.coffeeLog
                      ? moment(lockerBox.order.coffeeLog.willDisposedAt).format(
                          'MM/DD HH:mm:ss'
                        )
                      : '-'}
                  </td>
                  <td className={styles.cell}>
                    {lockerBox.isFull
                      ? lockerBoxContent.full
                      : lockerBoxContent.notFull}
                  </td>
                  <td className={styles.cell}>
                    {lockerBox.isDisable
                      ? lockerBoxContent.unavailableNow
                      : lockerBoxContent.availableNow}
                  </td>
                  <td className={styles.cell}>
                    <Button
                      title={
                        lockerBox.isDisable
                          ? lockerBoxContent.makeAvailable
                          : lockerBoxContent.makeUnavailable
                      }
                      onClick={() => {
                        handleClickConfirmLockerBox(lockerBox)
                      }}
                      className={lockerBoxTableStyles.button}
                      disabled={
                        lockerBox.order && lockerBox.order.coffeeLog
                          ? true
                          : false
                      }
                    />
                  </td>
                  <td className={styles.cell}>
                    <Button
                      title={lockerBoxContent.clearButton}
                      onClick={() => {
                        handleOnClickClearLockerBox(lockerBox)
                      }}
                      className={lockerBoxTableStyles.accentButton}
                    />
                  </td>
                  <td className={styles.cell}>
                    {lockerBox.lockerBoxComment ? (
                      <Button
                        title={lockerBoxContent.changeCommentButton}
                        onClick={() => {
                          props.showEditLockerBoxComment(
                            props.station.id,
                            lockerBox.id,
                            lockerBox.index,
                            lockerBox.lockerBoxComment
                          )
                        }}
                        className={lockerBoxTableStyles.accentButton}
                      />
                    ) : (
                      // TODO 次回のプルリクで備考登録のモーダルを追加予定。その際disable解除する
                      <Button
                        title={lockerBoxContent.addCommentButton}
                        onClick={() => {
                          props.showCreateLockerBoxComment(
                            props.station.id,
                            lockerBox.id,
                            lockerBox.index
                          )
                        }}
                        className={lockerBoxTableStyles.button}
                      />
                    )}
                  </td>
                </tr>
              ))
          ) : (
            <></>
          )}
        </tbody>
      </table>
    </div>
  )
}

export default LockerBoxTable
