import React from 'react'
import DatePicker from 'react-datepicker'

import { Station, StationImageType } from '../../../core/stations'
import { powers } from '../../../core/machines'
import styles from '../../styles/modules/each-station/station-details-table.module.scss'
import formStyle from "../../styles/modules/each-station/each-station-forms.module.scss";
import { stationDetailsTableContent, strings } from "./each-station-content";
import FileInput from '../file-input'
import 'react-datepicker/dist/react-datepicker.css'
import '../../styles/modules/react-datepicker.scss'

interface Props {
  station: Station
  onChange: (
    event: React.ChangeEvent<HTMLInputElement|HTMLTextAreaElement|HTMLSelectElement>
  ) => void
  uploadStationImage: (
    id: number,
    stationImageType: StationImageType,
    file: File
  ) => void
  onChangeDate: (
    date: Date,
    name: string
  ) => void
}

const StationDetailsForm: React.FC<Props> = props => {

  const machine = props.station.machine

  return (
    <div className={styles.wrapper}>
      <table className={styles.container}>
        <tbody>
          <tr className={styles.row}>
            <td className={styles.cell}>{stationDetailsTableContent.id}</td>
            <td className={styles.inputCell} colSpan={2}>
              <input
                className={formStyle.input}
                value={props.station.id || ''}
                onChange={props.onChange}
                disabled
                name='id'
              />
            </td>
          </tr>
          <tr className={styles.row}>
            <td className={styles.cell}>{stationDetailsTableContent.name}</td>
            <td className={styles.inputCell} colSpan={2}>
              <input
                className={formStyle.input}
                value={props.station.name || ''}
                onChange={props.onChange}
                name='name'
              />
            </td>
          </tr>
          <tr className={styles.row}>
            <td rowSpan={2} className={styles.cell}>
              {stationDetailsTableContent.soracomSIM.title}
            </td>
            <td className={styles.cell}>{stationDetailsTableContent.soracomSIM.id}</td>
            <td className={styles.inputCell}>
              <input
                  className={formStyle.input}
                  value={machine && machine.soracomSIM ? machine.soracomSIM.id : strings.noData}
                  onChange={props.onChange}
                  disabled
                  name='soracomSimID'
                />
            </td>
          </tr>
          <tr className={styles.row}>
            <td className={styles.cell}>{stationDetailsTableContent.soracomSIM.imsi}</td>
            <td className={styles.inputCell}>
              <input
                  className={formStyle.input}
                  value={machine && machine.soracomSIM ? machine.soracomSIM.imsi : strings.noData}
                  onChange={props.onChange}
                  disabled
                  name='IMSI'
                />
            </td>
          </tr>
          <tr className={styles.row}>
            <td className={styles.cell}>{stationDetailsTableContent.machine.serial}</td>
            <td className={styles.inputCell} colSpan={2}>
            <input
                className={formStyle.input}
                value={machine ? machine.serial : strings.noData}
                onChange={props.onChange}
                disabled
                name='serial'
              />
            </td>
          </tr>
          <tr className={styles.row}>
            <td className={styles.cell}>{stationDetailsTableContent.machine.power}</td>
            <td className={styles.inputCell} colSpan={2}>
            <select
                className={formStyle.selectInput}
                value={machine ? machine.power : strings.noData}
                onChange={props.onChange}
                disabled
                name='power'
              >
                {Object.entries(powers).map(([key, value])=>(
                  <option value={value} key={key}>{value}</option>
                ))}
              </select>
            </td>
          </tr>
          <tr className={styles.row}>
            <td className={styles.cell}>{stationDetailsTableContent.extra}</td>
            <td className={styles.inputCell} colSpan={2} >
            <textarea
                className={formStyle.textInput}
                value={props.station.extra || ''}
                onChange={props.onChange}
                name='extra'
              />
            </td>
          </tr>
          <tr className={styles.row}>
            <td className={styles.cell}>{stationDetailsTableContent.fullAddress}</td>
            <td className={styles.inputCell} colSpan={2}>
            <input
                className={formStyle.input}
                value={props.station.fullAddress || ''}
                onChange={props.onChange}
                name='fullAddress'
              />
            </td>
          </tr>
          <tr className={styles.row}>
            <td className={styles.cell}>{stationDetailsTableContent.openingHours}</td>
            <td className={styles.inputCell} colSpan={2}>
            <input
                className={formStyle.input}
                value={props.station.openingHours || ''}
                onChange={props.onChange}
                name='openingHours'
              />
            </td>
          </tr>
          <tr className={styles.row}>
            <td rowSpan={2} className={styles.cell}>
              {stationDetailsTableContent.location.title}
            </td>
            <td className={styles.cell}>{stationDetailsTableContent.location.latitude}</td>
            <td className={styles.inputCell}>
            <input
                className={formStyle.input}
                value={props.station.latitude || ''}
                onChange={props.onChange}
                name='latitude'
              />
            </td>
          </tr>
          <tr className={styles.row}>
            <td className={styles.cell}>{stationDetailsTableContent.location.longitude}</td>
            <td className={styles.inputCell}>
            <input
                className={formStyle.input}
                value={props.station.longitude || ''}
                onChange={props.onChange}
                name='longitude'
              />
            </td>
          </tr>
          <tr className={styles.row}>
            <td className={styles.cell}>{stationDetailsTableContent.isClosed}</td>
            <td className={styles.inputCell} colSpan={2}>
            <select
                className={formStyle.selectInput}
                value={props.station.isClosed ? 1 : 0}
                onChange={props.onChange}
                name='isClosed'
              >
                <option value={0}>開店(アプリに表示)</option>
                <option value={1}>閉店(アプリで非表示)</option>
              </select>
            </td>
          </tr>
          <tr className={styles.row}>
            <td rowSpan={2} className={styles.cell}>
              {stationDetailsTableContent.image.title}
            </td>
            <td className={styles.cell}>{stationDetailsTableContent.image.stationImage}</td>
            <td className={styles.inputCell}>
              <FileInput
                type='file'
                name='stationImage'
                id='stationImage'
                onChange={(event) => {
                  props.uploadStationImage(
                    props.station.id,
                    StationImageType.StationImage,
                    event.target.files.item(0)
                  )
                }}
              />
            </td>
          </tr>
          <tr className={styles.row}>
            <td className={styles.cell}>{stationDetailsTableContent.image.mapImage}</td>
            <td className={styles.inputCell}>
            <FileInput
                type='file'
                name='mapImage'
                id='mapImage'
                onChange={(event) => {
                  props.uploadStationImage(
                    props.station.id,
                    StationImageType.MapImage,
                    event.target.files.item(0)
                  )
                }}
              />
            </td>
          </tr>
          <tr className={styles.row}>
            <td className={styles.cell}>{stationDetailsTableContent.placedDate}</td>
            <td className={styles.inputCell} colSpan={2}>
              <DatePicker
                className={styles.stationDateForm}
                onChange={(date)=>props.onChangeDate(date, 'placedDate')}
                selected={props.station.placedDate ? new Date(props.station.placedDate) : null}
                dateFormat={'yyyy/MM/dd'}
              />
            </td>
          </tr>
          <tr className={styles.row}>
            <td className={styles.cell}>{stationDetailsTableContent.shippedDate}</td>
            <td className={styles.inputCell} colSpan={2}>
              <DatePicker
                className={styles.stationDateForm}
                onChange={(date)=>props.onChangeDate(date, 'shippedDate')}
                selected={props.station.shippedDate ? new Date(props.station.shippedDate) : null}
                dateFormat={'yyyy/MM/dd'}
              />
            </td>
          </tr>
          <tr className={styles.row}>
            <td className={styles.cell}>{stationDetailsTableContent.permittedDate}</td>
            <td className={styles.inputCell} colSpan={2}>
              <DatePicker
                className={styles.stationDateForm}
                onChange={(date)=>props.onChangeDate(date, 'permittedDate')}
                selected={props.station.permittedDate ? new Date(props.station.permittedDate) : null}
                dateFormat={'yyyy/MM/dd'}
              />
            </td>
          </tr>
          <tr className={styles.row}>
            <td className={styles.cell}>{stationDetailsTableContent.startOperationDate}</td>
            <td className={styles.inputCell} colSpan={2}>
              <DatePicker
                className={styles.stationDateForm}
                onChange={(date)=>props.onChangeDate(date, 'startOperationDate')}
                selected={props.station.startOperationDate ? new Date(props.station.startOperationDate) : null}
                dateFormat={'yyyy/MM/dd'}
              />
            </td>
          </tr>
          <tr className={styles.row}>
            <td className={styles.cell}>{stationDetailsTableContent.lastOperationDate}</td>
            <td className={styles.inputCell} colSpan={2}>
              <DatePicker
                className={styles.stationDateForm}
                onChange={(date)=>props.onChangeDate(date, 'lastOperationDate')}
                selected={props.station.lastOperationDate ? new Date(props.station.lastOperationDate) : null}
                dateFormat={'yyyy/MM/dd'}
              />
            </td>
          </tr>
          <tr className={styles.row}>
            <td className={styles.cell}>{stationDetailsTableContent.removedDate}</td>
            <td className={styles.inputCell} colSpan={2}>
              <DatePicker
                className={styles.stationDateForm}
                onChange={(date)=>props.onChangeDate(date, 'removedDate')}
                selected={props.station.removedDate ? new Date(props.station.removedDate) : null}
                dateFormat={'yyyy/MM/dd'}
              />
            </td>
          </tr>
          <tr className={styles.row}>
            <td className={styles.cell}>{stationDetailsTableContent.orderEnablingHours}</td>
            <td className={styles.inputCell} colSpan={2}>
              <input
                className={formStyle.input}
                value={props.station.orderEnablingHours || ''}
                onChange={props.onChange}
                name='orderEnablingHours'
              />
            </td>
          </tr>
          <tr className={styles.row}>
            <td className={styles.cell}>{stationDetailsTableContent.operationHours}</td>
            <td className={styles.inputCell} colSpan={2}>
              <input
                  className={formStyle.input}
                  value={props.station.operationHours || ''}
                  onChange={props.onChange}
                  name='operationHours'
              />
            </td>
          </tr>
          <tr className={styles.row}>
            <td className={styles.cell}>{stationDetailsTableContent.operationDays}</td>
            <td className={styles.inputCell} colSpan={2}>
              <input
                  className={formStyle.input}
                  value={props.station.operationDays || ''}
                  onChange={props.onChange}
                  name='operationDays'
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default StationDetailsForm
