import { ActionType } from 'typesafe-actions'

import { usersActionType, usersActions } from './actions'
import { User, UserInfo } from './users'

export interface UsersState {
  readonly error: Error
  readonly isLoading: boolean
  readonly user: User
  readonly users: UserInfo[]
}

export const initialState: UsersState = {
  error: null,
  isLoading: false,
  user: null,
  users: [],
}

export const usersReducer = (
  state: UsersState = initialState,
  action: ActionType<typeof usersActions>
) => {
  switch (action.type) {
    case usersActionType.FETCH_USER_BY_ID:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case usersActionType.FETCH_USER_BY_ID_SUCCEEDED:
      return {
        ...state,
        user: action.payload.user,
        isLoading: false,
        error: null,
      }
    case usersActionType.FETCH_USER_BY_EMAIL:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case usersActionType.FETCH_USER_SUCCEEDED:
      return {
        ...state,
        users: action.payload.users,
        isLoading: false,
        error: null,
      }
    case usersActionType.RESET_USER:
      return {
        ...state,
        user: null,
        users: [],
      }
    case usersActionType.DELETE_USER:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case usersActionType.THROW_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
        user: null,
      }
    default:
      return state
  }
}
