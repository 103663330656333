import React from 'react'

import { Item } from '../../../core/items'
import { imageTitles } from './each-item-content'
import styles from '../../styles/modules/each-item/item-image-items.module.scss'

interface Props {
  item: Item
}

const ItemImageItems: React.FC<Props> = props => (
  <div className={styles.container}>
    <div className={styles.itemContainer}>
      <h4>{imageTitles.image}</h4>
      <img
        alt="item"
        src={props.item.imageURL}
        className={styles.image}
      />
    </div>
    <div className={styles.itemContainer}>
      <h4>{imageTitles.thumbnailImage}</h4>
      <img
        alt="item"
        src={props.item.thumbnailImageURL}
        className={styles.image}
      />
    </div>
  </div>
)

export default ItemImageItems
