import React from 'react'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'

import { stationsActions } from '../../core/stations'
import { modalActions } from '../../core/modal'
import RootState from '../../core/root-state'
import Layout from '../layouts/layout'
import Wrapper from '../layouts/wrapper'
import StationItem from '../components/stations/station-item'
import Button from '../components/button'
import { Station } from '../../core/stations'
import { strings } from '../components/stations/stations-content'
import styles from '../styles/modules/stations/stations.module.scss'

interface Props {
  stations: Station[]
  isLoading: boolean
  fetchStations: () => void
  fetchStationsEvery: () => void
  fetchStationsEveryFinished: () => void
  showCreateStation: () => void
  bootMachine: (stationId: number) => void
  shutMachine: (stationId: number) => void
}

interface State {
  readonly pushed?: 'power'
  readonly pushedId?: number
}

class StationsScreen extends React.Component<Props, State> {
  readonly state = {
    pushed: null,
    pushedId: null,
  }

  componentDidMount = () => {
    this.props.fetchStations()
    this.props.fetchStationsEvery()
  }

  componentWillUnmount = () => {
    this.props.fetchStationsEveryFinished()
  }

  handleOnClickPower = (station: Station) => {
    const response = window.confirm(
      station.machine.power === 'on'
        ? strings.cautionStationStop
        : strings.cautionStationStart
    )
    if (response) {
      this.setState({
        pushed: 'power',
        pushedId: station.id,
      })

      if (
        station.machine.power === 'on' ||
        station.machine.power === 'should_be_on'
      ) {
        this.props.shutMachine(station.id)
      } else {
        this.props.bootMachine(station.id)
      }
    }
  }

  handleOnClickChangeStatus = (station: Station) => {
    const response = window.confirm(
      station.machine.status === 'station_stop'
        ? strings.cautionStationStart
        : strings.cautionStationStop
    )
    if (response) {
      this.setState({
        pushed: 'power',
        pushedId: station.id,
      })

      if (station.machine.status === 'station_stop') {
        this.props.bootMachine(station.id)
      } else {
        this.props.shutMachine(station.id)
      }
    }
  }

  render() {
    return (
      <Layout location={'stations'}>
        <Wrapper title={strings.title}>
          <br />
          <div>
            稼働中：新規オーダー受付可/調理可能/サイネージ「オーダー受付中」
          </div>
          <div>
            停止中：新規オーダー受付不可/調理停止/サイネージ「営業時間外」
          </div>
          <div className={styles.wrapper}>
            <Button
              title={strings.createStationButton}
              onClick={() => {
                this.props.showCreateStation()
              }}
              className={styles.stationCreateButton}
            />
            <div className={styles.stationContainer}>
              <h3>開店</h3>
              <div className={styles.stationGroup}>
                {this.props.stations
                  .filter(station => station.machine && !station.isClosed)
                  .map(station => (
                    <StationItem
                      station={station}
                      key={station.id}
                      onClickPower={() => this.handleOnClickPower(station)}
                      powerIsLoading={
                        this.props.isLoading &&
                        this.state.pushed === 'power' &&
                        this.state.pushedId === station.id
                      }
                      onClickChangeStatus={() =>
                        this.handleOnClickChangeStatus(station)
                      }
                      changeStatusIsLoading={
                        this.props.isLoading &&
                        this.state.pushed === 'power' &&
                        this.state.pushedId === station.id
                      }
                    />
                  ))}
              </div>
            </div>
            <div className={styles.closedStationContainer}>
              <h3>閉店/マシン未紐付</h3>
              <div className={styles.closedStationGroup}>
                {this.props.stations
                  .filter(station => !station.machine || station.isClosed)
                  .map(station => (
                    <StationItem
                      station={station}
                      key={station.id}
                      onClickPower={() => this.handleOnClickPower(station)}
                      powerIsLoading={
                        this.props.isLoading &&
                        this.state.pushed === 'power' &&
                        this.state.pushedId === station.id
                      }
                      onClickChangeStatus={() =>
                        this.handleOnClickPower(station)
                      }
                      changeStatusIsLoading={
                        this.props.isLoading &&
                        this.state.pushed === 'power' &&
                        this.state.pushedId === station.id
                      }
                    />
                  ))}
              </div>
            </div>
          </div>
        </Wrapper>
      </Layout>
    )
  }
}

const mapStateToProps = (state: RootState) => ({
  stations: state.stations.stations,
  isLoading: state.stations.isLoading,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchStations: () => dispatch(stationsActions.fetchStations()),
  fetchStationsEvery: () => dispatch(stationsActions.fetchStationsEvery()),
  fetchStationsEveryFinished: () =>
    dispatch(stationsActions.fetchStationsEveryFinished()),
  showCreateStation: () => dispatch(modalActions.showCreateStation()),
  bootMachine: (stationId: number) =>
    dispatch(stationsActions.bootMachine(stationId)),
  shutMachine: (stationId: number) =>
    dispatch(stationsActions.shutMachine(stationId)),
})

export default connect(mapStateToProps, mapDispatchToProps)(StationsScreen)
