export const strings = {
  title: 'グッズ',
  createPointItemButton: 'グッズ追加',
}
export const pointItemsTableContent = [
  '',
  'ID',
  '表示順序',
  'グッズ名',
  '画像',
  '要配送',
  '引換ポイント',
  '公開',
  '在庫',
]
