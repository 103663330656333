import React, { useState } from 'react'
import { Campaign } from '../../../core/campaigns'
import { Config } from '../../../core/toast'

import Button from '../button'
import styles from '../../styles/modules/modal/modal.module.scss'
import { strings } from './modal-content'
import Input from '../input'

interface Props {
  promotionCampaign: Campaign
  isLoading: boolean
  show: (config: Config) => void
  setPromotionCampaign: (campaignID: number, campaign: Campaign) => void
}

export const EditPromotionCampaignForm: React.FC<Props> = (props: Props) => {
  const [campaign, setCampaign] = useState<Campaign>(props.promotionCampaign)

  const handleOnClickSubmit = () => {
    if (!campaign.name) {
      const config: Config = {
        type: 'error',
        title: strings.toastError.title,
        body: strings.toastError.body,
      }
      props.show(config)
      return
    }

    props.setPromotionCampaign(campaign.id, campaign)
  }

  return (
    <main className={styles.main}>
      <Input
        label={strings.campaign.name}
        type={'text'}
        onChange={event => {
          setCampaign({ ...campaign, name: event.target.value })
        }}
        value={campaign.name}
      />
      <div className={styles.form}>
        <label className={styles.label}>{strings.campaign.memoContent}</label>
        <textarea
          className={styles.textarea}
          onChange={event =>
            setCampaign({ ...campaign, memo: event.target.value })
          }
          value={campaign.memo}
        />
      </div>
      <Button
        title={strings.lockerBoxComment.updateButton}
        onClick={() => handleOnClickSubmit()}
        className={styles.report}
        isLoading={props.isLoading}
        loadingClassName={styles.loadingReport}
      />
    </main>
  )
}
