import React from 'react'
import classnames from 'classnames'

import { strings } from './stations-content'
import styles from '../../styles/modules/stations/on-off.module.scss'

interface Props {
  status: 'on' | 'off' | 'should_be_on' | 'should_be_off'
}

const formatStatus = (status: 'on' | 'off' | 'should_be_on' | 'should_be_off') => {
  switch (status) {
    case 'on':
      return strings.status.on
    case 'off':
      return strings.status.off
    case 'should_be_on':
      return strings.status.shouldBeOn
    case 'should_be_off':
      return strings.status.shouldBeOff
  }
}

const OnOff: React.FC<Props> = props => (
  <div className={styles.container}>
    <span
      className={classnames({
        [styles.sign]: true,
        [styles.off]: props.status !== 'on',
      })}
    />
    <span className={styles.statement}>
      {formatStatus(props.status)}
    </span>
  </div>
)

export default OnOff
