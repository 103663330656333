import React from 'react'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import classnames from 'classnames'

import { toastActions } from '../../core/toast'
import RootState from '../../core/root-state'
import styles from '../styles/modules/toast.module.scss'

interface Props {
  isVisible: boolean
  type: 'success' | 'error'
  title: string
  body: string
  hide: () => void
}

class Toast extends React.PureComponent<Props, {}> {
  render() {
    return (
      <div
        className={classnames({
          [styles.container]: true,
          [styles.success]: this.props.type === 'success',
          [styles.error]: this.props.type === 'error',
          [styles.visible]: this.props.isVisible,
        })}
      >
        <div onClick={() => this.props.hide()} className={styles.button}>
          <span className={styles.close} />
        </div>
        <div className={styles.info}>
          <span className={styles.title}>{this.props.title}</span>
          <span className={styles.body}>{this.props.body}</span>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state: RootState) => ({
  isVisible: state.toast.isVisible,
  type: state.toast.type,
  title: state.toast.title,
  body: state.toast.body,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  hide: () => dispatch(toastActions.hide()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Toast)
