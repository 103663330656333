import { ActionType } from 'typesafe-actions'
import { machineErrorsActions, machineErrorsActionType } from './actions'
import { MachineError } from './machine-error'

export interface MachineErrorsState {
  readonly error: Error
  readonly isLoading: boolean
  readonly machineErrors: MachineError[]
}

export const initialState: MachineErrorsState = {
  error: null,
  isLoading: false,
  machineErrors: [],
}

export const machineErrorsReducer = (
  state: MachineErrorsState = initialState,
  action: ActionType<typeof machineErrorsActions>
) => {
  switch (action.type) {
    case machineErrorsActionType.FETCH_MACHINE_ERRORS:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case machineErrorsActionType.FETCH_MACHINE_ERRORS_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        error: null,
        machineErrors: action.payload.machineErrors,
      }
    case machineErrorsActionType.THROW_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      }
    default:
      return state
  }
}
