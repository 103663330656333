import { put, takeLatest, fork, call } from 'redux-saga/effects'
import Auth0Lock from 'auth0-lock'
import { Auth0UserProfile, Auth0Error } from 'auth0-js'
import { push } from 'connected-react-router'

import { auth0ActionType, auth0Actions } from './actions'
import { getRole } from '../api'

interface Auth0Info {
  user: Auth0UserProfile
  auth: AuthResult
}

const lock = new Auth0Lock(
  process.env.REACT_APP_AUTH0_CLIENT_ID,
  process.env.REACT_APP_AUTH0_DOMAIN,
  {
    avatar: null,
    auth: {
      responseType: 'token id_token',
      redirectUrl: `${process.env.REACT_APP_BASE_URL}/callback`,
    },
  }
)

export function* login() {
  try {
    lock.show()
  } catch (error) {
    yield put(auth0Actions.throwError(error))
  }
}

export function* checkAuthenticated() {
  const observeInfo = () =>
    new Promise<Auth0Info>((resolve, reject) => {
      lock.on('authenticated', (auth: AuthResult) => {
        lock.getUserInfo(
          auth.accessToken,
          (error: Auth0Error, user: Auth0UserProfile) => {
            if (!error) {
              resolve({ user, auth })
            } else {
              reject(error)
            }
          }
        )
      })

      lock.on('unrecoverable_error', error => {
        reject(error)
      })
    })
  try {
    const { user, auth } = yield call(observeInfo)
    const response = yield call(getRole, auth.idToken)
    const role = response.user.role
    yield put(auth0Actions.authSucceeded(user, auth, role))
    yield put(push('/'))
  } catch (error) {
    yield put(auth0Actions.logout())
    yield put(auth0Actions.throwError(error))
  }
}

export function* checkSession() {
  const refreshInfo = () =>
    new Promise<AuthResult>((resolve, reject) => {
      lock.checkSession({}, (error: Auth0Error, auth: AuthResult) => {
        if (!error) {
          resolve(auth)
        } else {
          reject(error)
        }
      })
    })
  try {
    const auth = yield call(refreshInfo)
    const response = yield call(getRole, auth.idToken)
    const role = response.user.role
    yield put(auth0Actions.checkSessionSucceeded(auth, role))
  } catch (error) {
    yield put(auth0Actions.logout())
    yield put(auth0Actions.throwError(error))
  }
}

export function* logout() {
  try {
    lock.logout({
      clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
      returnTo: process.env.REACT_APP_BASE_URL,
    })
    yield put(push('/'))
  } catch (error) {
    yield put(auth0Actions.throwError(error))
  }
}

export function* watchLogin() {
  yield takeLatest(auth0ActionType.LOGIN, login)
}

export function* watchCheckAuthenticated() {
  yield takeLatest(auth0ActionType.CHECK_AUTHENTICATED, checkAuthenticated)
}

export function* watchCheckSession() {
  yield takeLatest(auth0ActionType.CHECK_SESSION, checkSession)
}

export function* watchLogout() {
  yield takeLatest(auth0ActionType.LOGOUT, logout)
}

export const auth0Sagas = [
  fork(watchLogin),
  fork(watchCheckAuthenticated),
  fork(watchCheckSession),
  fork(watchLogout),
]
