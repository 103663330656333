export const strings = {
  title: 'ステーション',
  createStationButton: '新規作成',
  status: {
    on: 'ステーション稼働中',
    off: 'ステーション停止中',
    shouldBeOn: 'ステーション稼働準備中',
    shouldBeOff: 'ステーション停止準備中',
  },
  runButton: {
    on: '停止する',
    off: '稼働する',
  },
  changeStatusButton: {
    start: '稼働する',
    stop: '停止する',
  },
  stationStatus: {
    open: 'いつでも注文を用意できる状態です',
    making: 'コーヒーを用意しています',
    suspend: '一時停止しています',
  },
  emergencyStatus: {
    occur: 'エマージェンシー発生中',
  },
  orderLimitation: {
    occur: '材料不足のため注文制限中',
  },
  trashReportButton: '清掃を報告する',
  detailButton: 'ステーションの詳細を見る',
  cautionStationStart: '本当に稼働しますか？',
  cautionStationStop: '本当に停止しますか？',
  cautionReport: '本当に清掃を報告しますか？',
}
