export const strings = {
  labels: {
    name: '商品名',
    id: '商品ID',
    price: '価格（円）',
    stock: '想定ストック数',
    disposal: 'ストック時間（秒）',
    cup: 'カップタイプ',
  },
  button: '決定',
  closeShowCaution: '確認を終了しますか？',
  closeEditCaution: '編集を終了しますか？',
  toastError: {
    type: 'error',
    title: 'エラー...',
    body: '入力情報を確認してください',
  },
  emergency: {
    message: 'メッセージ',
    button: '確定',
    placeholder: 'メッセージを入力してください',
    openLockerBoxe: 'ロッカー解錠可否',
    availableOpenLockerBox: 'ロッカー解錠可',
    unavailableOpenLockerBox: 'ロッカー解錠不可',
  },
  campaign: {
    code: 'コード',
    name: 'クーポン名',
    discountAmount: '割引額',
    entryCountLimit: '適用回数上限',
    availableCount: '使用可能回数',
    status: 'ステータス',
    expiresAt: '失効予定日',
    duration: '有効期間',
    eligibleStations: '対象ステーション',
    unsetExpiration: '無期限',
    setExpiration: '失効予定日を設定する',
    setEligibleStations: '対象ステーションを設定する',
    updateButton: '更新する',
    memoContent: 'メモの内容',
  },
  stationItem: {
    stockButton: '想定ストック数を変更',
    isAvailable: '購入',
    availableButton: '購入可能に変更',
    unavailableButton: '購入不可に変更',
  },
  promotionImage: {
    title: 'タイトル',
    description: '説明',
    image: '画像',
    index: 'インデックス',
    linkUrl: 'リンク',
    createButton: '作成する',
    updateButton: '更新する',
  },
  signageBanner: {
    image: `画像 / 動画(※mp4形式 H264コーデック推奨)`,
    title: 'タイトル',
    button: '作成する',
    caption: '広告文',
    errorBody:
      '無効なファイル形式です。画像またはビデオファイルを選択してください',
    note:
      '※ 1MB以上のファイルをアップロードする場合は、まずバナーを登録し、プロダクト開発部ソフトウェアグループへご連絡ください。',
    invalidVideoDuration:
      '動画の長さが不正です。別のビデオファイルを選択してください',
  },
  signageBannerSequence: {
    duration: `表示時間(秒)`,
    button: '更新する',
  },
  point: {
    userId: 'ユーザーID(編集不可)',
    givePoint: '付与ポイント数',
    expirePoint: '失効ポイント数',
    description: 'メッセージ',
    defaultGiveDescription: 'root C サービスが付与',
    defaultExpireDescription: 'root C サービスが失効',
    giveButton: '付与する',
    expireButton: '失効する',
    expirePointsMessage: '本当にポイントを失効しますか？',
  },
  stationAlert: {
    station: '対象ステーション',
    title: '表題',
    body: '内容',
    stationUnset: '未選択',
    createButton: '登録する',
    updateButton: '更新する',
    deleteButton: '削除する',
    restoreButton: '削除を取り消す',
  },
  lockerBoxComment: {
    comment: '備考',
    createButton: '登録する',
    updateButton: '更新する',
    deleteButton: '削除する',
  },
  station: {
    name: 'ステーション名',
    extra: '備考',
    fullAddress: '住所',
    openingHours: '営業時間',
    latitude: '緯度',
    longitude: '経度',
  },
  soracomSIM: {
    imsi: 'SIM IMSI（半角数字15桁）',
    serial: 'SIM 製造番号',
    createButton: '作成する',
    updateButton: '更新する',
  },
  machine: {
    serial: 'マシンシリアル',
    createButton: '作成する',
    updateButton: '更新する',
    manufacturedDate: '製造年月日',
    type: '型式',
    version: 'バージョン',
    factoryName: '製造工場',
  },
  machineRelation: {
    machineId: 'マシン ID',
    machineSerial: 'マシンシリアル',
    station: 'ステーション',
    soracomSIM: 'SIM',
    unset: '未選択',
    editButton: '更新する',
  },
  kioskRelation: {
    kioskId: 'KIOSK ID',
    kioskSerial: 'シリアル',
    station: 'ステーション',
    soracomSIM: 'SIM',
    unset: '未選択',
    editButton: '更新する',
  },
  user: {
    deletedAt: '退会日',
    deleteButton: '退会する',
  },
  timeSlotPreset: {
    timeSlotTableContent: ['From', 'To', '枠状況'],
    updateButton: '更新する',
  },
  stationWithItemRanks: {
    itemRanks: ['1位のアイテム', '2位のアイテム', '3位のアイテム'],
    createButton: '設定する',
  },
  kiosk: {
    serial: '製造番号',
    createButton: '登録する',
  },
  kioskItemPrice: {
    itemName: 'アイテム名',
    price: '価格',
    updateButton: '更新する',
  },
  createCampaignAlert: `
割引額が150円未満です。本当に発行してよろしいですか？

※
このクーポンは注文特典クーポンより優先して自動適用されます。
割引額が150円未満のクーポンを発行するとお客様に不利益が及ぶ可能性があるため、システムを理解した上で発行してください。
`,
  pointItem: {
    button: '作成する',
    name: 'グッズ名',
    image: '画像',
    isDeliveryRequired: '要配送',
    pointsPrice: '引換ポイント数',
    stock: '在庫数',
    isAvailable: '公開',
    isInfiniteStock: '在庫無限',
    errorBody: '無効なファイル形式です。画像ファイルを選択してください',
  },
}
