import { action } from 'typesafe-actions'

import { Emergency } from './emergencies' 

export enum emergenciesActionType {
    FETCH_EMERGENCIES = 'FETCH_EMERGENCIES',
    FETCH_EMERGENCIES_SUCCEEDED = 'FETCH_EMERGENCIES_SUCCEEDED',
    THROW_ERROR = 'THROW_ERROR',
}

export const emergenciesActions = {
  fetchEmergencies:() => action(emergenciesActionType.FETCH_EMERGENCIES),
  fetchEmergenciesSucceeded: (emergencies: [Emergency]) => action(emergenciesActionType.FETCH_EMERGENCIES_SUCCEEDED, { emergencies }),
  throwError: (error: Error) => action(emergenciesActionType.THROW_ERROR, { error }),
}