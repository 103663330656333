import { put, call, takeLatest, fork, select, delay } from 'redux-saga/effects'
import RootState from '../root-state'
import { timeSlotPresetsActionType, timeSlotPresetsActions } from './actions'
import { getTimeSlotPresets, putTimeSlotPresets } from '../api'
import { auth0Actions } from '../auth0'
import { toastActions } from '../toast'
import { modalActions } from '../modal'

export function* fetchTimeSlotPresets(
  action: ReturnType<typeof timeSlotPresetsActions.fetchTimeSlotPresets>
) {
  try {
    yield put(auth0Actions.checkSession())
    const state: RootState = yield select()
    const token = state.auth0.auth.idToken
    const response = yield call(getTimeSlotPresets, token, action.payload.stationId)
    yield put(timeSlotPresetsActions.fetchTimeSlotPresetsSucceeded(response.timeSlots, response.timeSlotPresets))
  } catch (error) {
    yield put(timeSlotPresetsActions.throwError(error))
  }
}

export function* updateTimeSlotPresets(
  action: ReturnType<typeof timeSlotPresetsActions.updateTimeSlotPresets>
){
  try {
    const state: RootState = yield select()
    const token = state.auth0.auth.idToken
    yield call(
      putTimeSlotPresets,
      token,
      action.payload.stationId,
      action.payload.timeSlotPresets,
    )
    yield delay(100)
    yield put(timeSlotPresetsActions.fetchTimeSlotPresets(action.payload.stationId))
    yield put(
      toastActions.show({
        title: '成功！',
        body: 'TimeSlotPresetsを更新しました',
      })
    )
    yield put(modalActions.hide())
  }catch(error){
    yield put(timeSlotPresetsActions.throwError(error))
    yield put(
      toastActions.show({
        type: 'error',
        title: '失敗...',
        body: 'TimeSlotPresetsの更新に失敗しました',
      })
    )
  }
}

export function* watchFetchTimeSlotPresets() {
  yield takeLatest(timeSlotPresetsActionType.FETCH_TIME_SLOT_PRESETS, fetchTimeSlotPresets)
}

export function* watchUpdateTimeSlotPresets() {
  yield takeLatest(timeSlotPresetsActionType.UPDATE_TIME_SLOT_PRESETS, updateTimeSlotPresets)
}

export const timeSlotPresetsSagas = [
  fork(watchFetchTimeSlotPresets),
  fork(watchUpdateTimeSlotPresets),
]
