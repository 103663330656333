import { put, call, takeLatest, fork, select } from 'redux-saga/effects'

import RootState from '../root-state'
import {
  cameraStreamingViewerCredentialsActionType,
  cameraStreamingViewerCredentialsActions,
} from './actions'
import { auth0Actions } from '../auth0'
import { getCameraStreamingViewerCredentials } from '../api'

export function* fetchCameraStreamingViewerCredentials() {
  try {
    yield put(auth0Actions.checkSession())
    const state: RootState = yield select()
    const token = state.auth0.auth.idToken
    const response = yield call(getCameraStreamingViewerCredentials, token)
    yield put(
      cameraStreamingViewerCredentialsActions.fetchCameraStreamingViewerCredentialsSucceeded(
        response.cameraStreamingViewerCredentials
      )
    )
  } catch (error) {
    yield put(cameraStreamingViewerCredentialsActions.throwError(error))
  }
}

export function* watchFetchCameraStreamingViewerCredentials() {
  yield takeLatest(
    cameraStreamingViewerCredentialsActionType.FETCH_CAMERA_STREAMING_VIEWER_CREDENTIALS,
    fetchCameraStreamingViewerCredentials
  )
}

export const cameraStreamingViewerCredentialsSagas = [
  fork(watchFetchCameraStreamingViewerCredentials),
]
