import { put, call, takeLatest, fork, select, delay } from 'redux-saga/effects'
import RootState from '../root-state'
import { usersActionType, usersActions } from './actions'
import { getUserById, getUserByEmail, deleteUser } from '../api'
import { modalActions } from '../modal'
import { toastActions } from '../toast'

export function* fetchUserById(
  action: ReturnType<typeof usersActions.fetchUserById>
) {
  try {
    const state: RootState = yield select()
    const token = state.auth0.auth.idToken
    const response = yield call(getUserById, token, action.payload.id)
    yield put(usersActions.fetchUserByIdSucceeded(response))
  } catch (error) {
    yield put(usersActions.throwError(error))
  }
}

export function* fetchUserByEmail(
  action: ReturnType<typeof usersActions.fetchUserByEmail>
) {
  try {
    const state: RootState = yield select()
    const token = state.auth0.auth.idToken
    const response = yield call(getUserByEmail, token, action.payload.email)
    yield put(usersActions.fetchUserSucceeded(response.users))
  } catch (error) {
    yield put(usersActions.throwError(error))
  }
}

export function* destroyUser(
  action: ReturnType<typeof usersActions.deleteUser>
) {
  try {
    const state: RootState = yield select()
    const token = state.auth0.auth.idToken
    yield call(
      deleteUser,
      token,
      action.payload.userId,
    )
    yield delay(100)
    yield put(usersActions.fetchUserById(action.payload.userId))
    yield put(
      toastActions.show({
        title: '成功！',
        body: 'ユーザーの退会処理に成功しました',
      })
    )
    yield put(modalActions.hide())
  }catch(error){
    yield put(usersActions.throwError(error))
    yield put(
      toastActions.show({
        type: 'error',
        title: '失敗...',
        body: 'ユーザーの退会処理に失敗しました',
      })
    )
  }
}

export function* watchFetchUser() {
  yield takeLatest(usersActionType.FETCH_USER_BY_ID, fetchUserById)
}

export function* watchFetchUserByEmail() {
  yield takeLatest(usersActionType.FETCH_USER_BY_EMAIL, fetchUserByEmail)
}

export function* watchDeleteUser() {
  yield takeLatest(usersActionType.DELETE_USER, destroyUser)
}

export const usersSagas = [fork(watchFetchUser), fork(watchFetchUserByEmail), fork(watchDeleteUser)]
