import React from 'react'
import moment from 'moment'

import { Station } from '../../../core/stations'
import styles from '../../styles/modules/each-station/station-details-table.module.scss'
import { stationDetailsTableContent, strings } from "./each-station-content";

interface Props {
  station: Station
}

const StationDetailsTable: React.FC<Props> = props => {

  const machine = props.station.machine

  return(
    <div className={styles.wrapper}>
      <table className={styles.container}>
        <tbody>
          <tr className={styles.row}>
            <td className={styles.cell}>{stationDetailsTableContent.id}</td>
            <td className={styles.cell} colSpan={2}>
              {props.station.id}
            </td>
          </tr>
          <tr className={styles.row}>
            <td className={styles.cell}>{stationDetailsTableContent.name}</td>
            <td className={styles.cell} colSpan={2}>
              {props.station.name}
            </td>
          </tr>
          <tr className={styles.row}>
            <td rowSpan={2} className={styles.cell}>
              {stationDetailsTableContent.soracomSIM.title}
            </td>
            <td className={styles.cell}>{stationDetailsTableContent.soracomSIM.id}</td>
            <td className={styles.cell}>{machine && machine.soracomSIM ? machine.soracomSIM.id : strings.noData}</td>
          </tr>
          <tr className={styles.row}>
            <td className={styles.cell}>{stationDetailsTableContent.soracomSIM.imsi}</td>
            <td className={styles.cell}>{machine && machine.soracomSIM ? machine.soracomSIM.imsi : strings.noData}</td>
          </tr>
          <tr className={styles.row}>
            <td className={styles.cell}>{stationDetailsTableContent.machine.serial}</td>
            <td className={styles.cell} colSpan={2}>
              {machine ? machine.serial : strings.noData}
            </td>
          </tr>
          <tr className={styles.row}>
            <td className={styles.cell}>{stationDetailsTableContent.machine.power}</td>
            <td className={styles.cell} colSpan={2}>
              {machine ? machine.power : strings.noData}
            </td>
          </tr>
          <tr className={styles.row}>
            <td className={styles.cell}>{stationDetailsTableContent.extra}</td>
            <td className={styles.textCell} colSpan={2} >
                {props.station.extra}
            </td>
          </tr>
          <tr className={styles.row}>
            <td className={styles.cell}>{stationDetailsTableContent.fullAddress}</td>
            <td className={styles.cell} colSpan={2}>
              {props.station.fullAddress}
            </td>
          </tr>
          <tr className={styles.row}>
            <td className={styles.cell}>{stationDetailsTableContent.openingHours}</td>
            <td className={styles.cell} colSpan={2}>
              {props.station.openingHours}
            </td>
          </tr>
          <tr className={styles.row}>
            <td rowSpan={2} className={styles.cell}>
              {stationDetailsTableContent.location.title}
            </td>
            <td className={styles.cell}>{stationDetailsTableContent.location.latitude}</td>
            <td className={styles.cell}>{props.station.latitude}</td>
          </tr>
          <tr className={styles.row}>
            <td className={styles.cell}>{stationDetailsTableContent.location.longitude}</td>
            <td className={styles.cell}>{props.station.longitude}</td>
          </tr>
          <tr className={styles.row}>
            <td className={styles.cell}>{stationDetailsTableContent.isClosed}</td>
            <td className={styles.cell} colSpan={2}>
              {props.station.isClosed ? '閉店(アプリで非表示)' : '開店(アプリに表示)'}
            </td>
          </tr>
          <tr className={styles.row}>
            <td rowSpan={2} className={styles.cell}>
              {stationDetailsTableContent.image.title}
            </td>
            <td className={styles.cell}>{stationDetailsTableContent.image.stationImage}</td>
            <td className={styles.cell}>
              <img alt="ステーション画像" src={props.station.stationImageURL} className={styles.image}/>
            </td>
          </tr>
          <tr className={styles.row}>
            <td className={styles.cell}>{stationDetailsTableContent.image.mapImage}</td>
            <td className={styles.cell}>
              <img alt="地図画像" src={props.station.mapImageURL} className={styles.image}/>
            </td>
          </tr>
          <tr className={styles.row}>
            <td className={styles.cell}>{stationDetailsTableContent.placedDate}</td>
            <td className={styles.cell} colSpan={2}>
              {props.station.placedDate ? moment(props.station.placedDate).format('YYYY/MM/DD') : null}
            </td>
          </tr>
          <tr className={styles.row}>
            <td className={styles.cell}>{stationDetailsTableContent.shippedDate}</td>
            <td className={styles.cell} colSpan={2}>
              {props.station.shippedDate ? moment(props.station.shippedDate).format('YYYY/MM/DD') : null}
            </td>
          </tr>
          <tr className={styles.row}>
            <td className={styles.cell}>{stationDetailsTableContent.permittedDate}</td>
            <td className={styles.cell} colSpan={2}>
              {props.station.permittedDate ? moment(props.station.permittedDate).format('YYYY/MM/DD') : null}
            </td>
          </tr>
          <tr className={styles.row}>
            <td className={styles.cell}>{stationDetailsTableContent.startOperationDate}</td>
            <td className={styles.cell} colSpan={2}>
              {props.station.startOperationDate ? moment(props.station.startOperationDate).format('YYYY/MM/DD') : null}
            </td>
          </tr>
          <tr className={styles.row}>
            <td className={styles.cell}>{stationDetailsTableContent.lastOperationDate}</td>
            <td className={styles.cell} colSpan={2}>
              {props.station.lastOperationDate ? moment(props.station.lastOperationDate).format('YYYY/MM/DD') : null}
            </td>
          </tr>
          <tr className={styles.row}>
            <td className={styles.cell}>{stationDetailsTableContent.removedDate}</td>
            <td className={styles.cell} colSpan={2}>
              {props.station.removedDate ? moment(props.station.removedDate).format('YYYY/MM/DD') : null}
            </td>
          </tr>
          <tr className={styles.row}>
            <td className={styles.cell}>{stationDetailsTableContent.orderEnablingHours}</td>
            <td className={styles.cell} colSpan={2}>
              {props.station.orderEnablingHours}
            </td>
          </tr>
          <tr className={styles.row}>
            <td className={styles.cell}>{stationDetailsTableContent.operationHours}</td>
            <td className={styles.cell} colSpan={2}>
              {props.station.operationHours}
            </td>
          </tr>
          <tr className={styles.row}>
            <td className={styles.cell}>{stationDetailsTableContent.operationDays}</td>
            <td className={styles.cell} colSpan={2}>
              {props.station.operationDays}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default StationDetailsTable
