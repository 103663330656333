export const strings = {
  title: 'KIOSK価格',
  openingKioskItemPricesTableTitle: '開店',
  closingKioskItemPricesTableTitle: '閉店/マシン未紐付',
  showItemPricesButton: '価格一覧を確認',
}

export const kioskItemPricesTableContent = [
  'ステーションID',
  'ステーション名',
  '',
]
