import { put, call, takeLatest, fork, delay, select } from 'redux-saga/effects'

import RootState from '../root-state'
import { stationsActionType, stationsActions } from './actions'
import { auth0Actions } from '../auth0'
import { toastActions } from '../toast'
import { modalActions } from '../modal'
import {
  getStations,
  getStation,
  postStation,
  startMachine,
  stopMachine,
  solveEmergency,
  reportEmergency,
  fillIce,
  changeStationItemAvailable,
  changeStationItemUnavailable,
  openAllLockerBoxes,
  closeTimeSlot,
  openTimeSlot,
  updateTimeSlots,
  presignStationImageUrl,
  changeStation,
  changeStationLockerBoxAvailable,
  changeStationLockerBoxUnavailable,
  deleteCoffeeLogOfLockerBox,
  lockerBoxOpener,
  newLockerBoxComment,
  changeLockerBoxComment,
  deleteLockerBoxComment,
  changeStationItemUnavailableTemporarily,
} from '../api'

import { TimeSlot } from './stations'
import axios from 'axios'

export function* fetchStations() {
  try {
    yield put(auth0Actions.checkSession())
    const state: RootState = yield select()
    const token = state.auth0.auth.idToken
    const response = yield call(getStations, token)
    yield put(stationsActions.fetchStationsSucceeded(response.stations))
  } catch (error) {
    yield put(stationsActions.throwError(error))
  }
}

export function* fetchStationsEvery() {
  try {
    while (true) {
      const state: RootState = yield select()
      const active = state.stations.active
      if (active !== 'stations') {
        return
      }
      yield put(stationsActions.fetchStations())
      yield delay(5000)
    }
  } catch (error) {
    yield put(stationsActions.throwError(error))
  }
}

export function* fetchStation(
  action: ReturnType<typeof stationsActions.fetchStation>
) {
  try {
    yield put(auth0Actions.checkSession())
    const state: RootState = yield select()
    const token = state.auth0.auth.idToken
    const response = yield call(getStation, token, action.payload.stationId)
    yield put(stationsActions.fetchStationSucceeded(response.station))
  } catch (error) {
    yield put(stationsActions.throwError(error))
  }
}

export function* fetchStationEvery(
  action: ReturnType<typeof stationsActions.fetchStationEvery>
) {
  try {
    while (true) {
      const state: RootState = yield select()
      const active = state.stations.active
      if (active !== 'station') {
        return
      }
      yield put(stationsActions.fetchStation(action.payload.stationId))
      yield delay(5000)
    }
  } catch (error) {
    yield put(stationsActions.throwError(error))
  }
}

export function* createStation(
  action: ReturnType<typeof stationsActions.createStation>
) {
  try {
    const state: RootState = yield select()
    const token = state.auth0.auth.idToken
    yield call(
      postStation,
      token,
      action.payload.name,
      action.payload.extra,
      action.payload.fullAddress,
      action.payload.openingHours,
      action.payload.latitude,
      action.payload.longitude
    )
    yield delay(100)
    yield put(stationsActions.fetchStations())
    yield put(
      toastActions.show({
        title: '成功！',
        body: 'ステーションを新規作成しました',
      })
    )
    yield put(modalActions.hide())
  } catch (error) {
    yield put(stationsActions.throwError(error))
    yield put(
      toastActions.show({
        type: 'error',
        title: '失敗...',
        body: 'ステーションの新規作成に失敗しました',
      })
    )
  }
}

export function* bootMachine(
  action: ReturnType<typeof stationsActions.bootMachine>
) {
  try {
    yield put(auth0Actions.checkSession())
    const state: RootState = yield select()
    const token = state.auth0.auth.idToken
    yield call(startMachine, token, action.payload.stationId)
    yield delay(100)
    yield put(stationsActions.fetchStations())
    yield put(
      toastActions.show({ title: '成功！', body: '営業を開始しました' })
    )
  } catch (error) {
    yield put(stationsActions.throwError(error))
    yield put(
      toastActions.show({
        type: 'error',
        title: '失敗...',
        body: '営業の開始に失敗しました',
      })
    )
  }
}

export function* shutMachine(
  action: ReturnType<typeof stationsActions.shutMachine>
) {
  try {
    yield put(auth0Actions.checkSession())
    const state: RootState = yield select()
    const token = state.auth0.auth.idToken
    yield call(stopMachine, token, action.payload.stationId)
    yield delay(100)
    yield put(stationsActions.fetchStations())
    yield put(
      toastActions.show({ title: '成功！', body: '営業を終了しました' })
    )
  } catch (error) {
    yield put(stationsActions.throwError(error))
    yield put(
      toastActions.show({
        type: 'error',
        title: '失敗...',
        body: '営業の終了に失敗しました',
      })
    )
  }
}

export function* resolveEmergency(
  action: ReturnType<typeof stationsActions.resolveEmergency>
) {
  try {
    yield put(auth0Actions.checkSession())
    const state: RootState = yield select()
    const token = state.auth0.auth.idToken
    yield call(
      solveEmergency,
      token,
      action.payload.stationId,
      action.payload.emergencyId
    )
    yield put(stationsActions.fetchStation(action.payload.stationId))
    yield put(
      toastActions.show({
        title: '成功！',
        body: 'エマージェンシーを解決済みにしました',
      })
    )
  } catch (error) {
    yield put(stationsActions.throwError(error))
    yield put(
      toastActions.show({
        type: 'error',
        title: '失敗...',
        body: '情報の更新に失敗しました',
      })
    )
  }
}

export function* createEmergency(
  action: ReturnType<typeof stationsActions.createEmergency>
) {
  try {
    yield put(auth0Actions.checkSession())
    const state: RootState = yield select()
    const token = state.auth0.auth.idToken
    yield call(
      reportEmergency,
      token,
      action.payload.stationId,
      action.payload.message,
      action.payload.canOpenLockerBox
    )
    yield delay(100)
    yield put(stationsActions.fetchStation(action.payload.stationId))
    yield put(
      toastActions.show({
        title: '成功！',
        body: 'エマージェンシーを登録しました',
      })
    )
    yield put(modalActions.hide())
  } catch (error) {
    yield put(stationsActions.throwError(error))
    yield put(
      toastActions.show({
        type: 'error',
        title: '失敗...',
        body: 'エマージェンシーの登録に失敗しました',
      })
    )
  }
}

export function* setIceFill(
  action: ReturnType<typeof stationsActions.fillIce>
) {
  try {
    yield put(auth0Actions.checkSession())
    const state: RootState = yield select()
    const token = state.auth0.auth.idToken
    yield call(fillIce, token, action.payload.stationId)
    yield delay(100)
    yield put(stationsActions.fetchStation(action.payload.stationId))
    yield put(
      toastActions.show({
        title: '成功！',
        body: '氷の補充を報告しました',
      })
    )
  } catch (error) {
    yield put(stationsActions.throwError(error))
    yield put(
      toastActions.show({
        type: 'error',
        title: '失敗...',
        body: '氷の補充報告に失敗しました',
      })
    )
  }
}

export function* setStationItemAvailable(
  action: ReturnType<typeof stationsActions.setStationItemAvailable>
) {
  try {
    yield put(auth0Actions.checkSession())
    const state: RootState = yield select()
    const token = state.auth0.auth.idToken
    yield call(
      changeStationItemAvailable,
      token,
      action.payload.stationId,
      action.payload.itemId
    )
    yield delay(100)
    yield put(stationsActions.fetchStation(action.payload.stationId))
    yield put(
      toastActions.show({
        title: '成功！',
        body: 'アイテムを取扱可能にしました',
      })
    )
    yield put(modalActions.hide())
  } catch (error) {
    yield put(stationsActions.throwError(error))
    yield put(
      toastActions.show({
        type: 'error',
        title: '失敗...',
        body: '更新に失敗しました',
      })
    )
  }
}

export function* setStationItemAvailableTemporarily(
  action: ReturnType<typeof stationsActions.setStationItemAvailableTemporarily>
) {
  try {
    yield put(auth0Actions.checkSession())
    const state: RootState = yield select()
    const token = state.auth0.auth.idToken
    yield call(
      changeStationItemAvailable,
      token,
      action.payload.stationId,
      action.payload.itemId
    )
    yield delay(100)
    yield put(stationsActions.fetchStation(action.payload.stationId))
    yield put(
      toastActions.show({
        title: '成功！',
        body: 'アイテムを購入可能にしました',
      })
    )
    yield put(modalActions.hide())
  } catch (error) {
    yield put(stationsActions.throwError(error))
    yield put(
      toastActions.show({
        type: 'error',
        title: '失敗...',
        body: '更新に失敗しました',
      })
    )
  }
}

export function* setStationItemUnavailable(
  action: ReturnType<typeof stationsActions.setStationItemUnavailable>
) {
  try {
    yield put(auth0Actions.checkSession())
    const state: RootState = yield select()
    const token = state.auth0.auth.idToken
    yield call(
      changeStationItemUnavailable,
      token,
      action.payload.stationId,
      action.payload.itemId
    )
    yield delay(100)
    yield put(stationsActions.fetchStation(action.payload.stationId))
    yield put(
      toastActions.show({
        title: '成功！',
        body: 'アイテムを取扱不可にしました',
      })
    )
    yield put(modalActions.hide())
  } catch (error) {
    yield put(stationsActions.throwError(error))
    yield put(
      toastActions.show({
        type: 'error',
        title: '失敗...',
        body: '更新に失敗しました',
      })
    )
  }
}

export function* setStationItemUnavailableTemporarily(
  action: ReturnType<
    typeof stationsActions.setStationItemUnavailableTemporarily
  >
) {
  try {
    yield put(auth0Actions.checkSession())
    const state: RootState = yield select()
    const token = state.auth0.auth.idToken
    yield call(
      changeStationItemUnavailableTemporarily,
      token,
      action.payload.stationId,
      action.payload.itemId
    )
    yield delay(100)
    yield put(stationsActions.fetchStation(action.payload.stationId))
    yield put(
      toastActions.show({
        title: '成功！',
        body: 'アイテムを購入不可にしました',
      })
    )
    yield put(modalActions.hide())
  } catch (error) {
    yield put(stationsActions.throwError(error))
    yield put(
      toastActions.show({
        type: 'error',
        title: '失敗...',
        body: '更新に失敗しました',
      })
    )
  }
}

export function* openLockerBoxes(
  action: ReturnType<typeof stationsActions.openAllLockerBoxes>
) {
  try {
    yield put(auth0Actions.checkSession())
    const state: RootState = yield select()
    const token = state.auth0.auth.idToken
    yield call(openAllLockerBoxes, token, action.payload.stationId)
    yield delay(100)
    yield put(stationsActions.fetchStation(action.payload.stationId))
    yield put(
      toastActions.show({
        title: '成功！',
        body: 'ドアを開きました',
      })
    )
    yield put(modalActions.hide())
  } catch (error) {
    yield put(stationsActions.throwError(error))
    yield put(
      toastActions.show({
        type: 'error',
        title: '失敗...',
        body: '更新に失敗しました',
      })
    )
  }
}

export function* setTimeSlotOpen(
  action: ReturnType<typeof stationsActions.openTimeSlot>
) {
  try {
    yield put(auth0Actions.checkSession())
    const state: RootState = yield select()
    const token = state.auth0.auth.idToken
    yield call(
      openTimeSlot,
      token,
      action.payload.stationId,
      action.payload.timeSlotId
    )
    yield delay(100)
    yield put(stationsActions.fetchStation(action.payload.stationId))
    yield put(
      toastActions.show({
        title: '成功！',
        body: 'オーダー停止を解除しました',
      })
    )
    yield put(modalActions.hide())
  } catch (error) {
    yield put(stationsActions.throwError(error))
    yield put(
      toastActions.show({
        type: 'error',
        title: '失敗...',
        body: '更新に失敗しました',
      })
    )
  }
}

export function* setTimeSlotClose(
  action: ReturnType<typeof stationsActions.closeTimeSlot>
) {
  try {
    yield put(auth0Actions.checkSession())
    const state: RootState = yield select()
    const token = state.auth0.auth.idToken
    yield call(
      closeTimeSlot,
      token,
      action.payload.stationId,
      action.payload.timeSlotId
    )
    yield delay(100)
    yield put(stationsActions.fetchStation(action.payload.stationId))
    yield put(
      toastActions.show({
        title: '成功！',
        body: 'オーダー停止に設定完了！',
      })
    )
    yield put(modalActions.hide())
  } catch (error) {
    yield put(stationsActions.throwError(error))
    yield put(
      toastActions.show({
        type: 'error',
        title: '失敗...',
        body: '更新に失敗しました',
      })
    )
  }
}

export function* setAllTimeSlotsOpen(
  action: ReturnType<typeof stationsActions.openAllTimeSlots>
) {
  try {
    yield put(auth0Actions.checkSession())
    const state: RootState = yield select()
    const token = state.auth0.auth.idToken
    const currentTimeSlots = state.stations.stations.find(
      station => station.id === action.payload.stationId
    ).timeSlots

    const timeSlots: TimeSlot[] = currentTimeSlots.map(
      timeSlot => ({ id: timeSlot.id, isClosed: false } as TimeSlot)
    )

    yield call(updateTimeSlots, token, action.payload.stationId, timeSlots)

    yield delay(100)
    yield put(stationsActions.fetchStation(action.payload.stationId))
    yield put(
      toastActions.show({
        title: '成功！',
        body: 'すべてのメンテナンスを解除しました',
      })
    )
    yield put(modalActions.hide())
  } catch (error) {
    yield put(stationsActions.throwError(error))
    yield put(
      toastActions.show({
        type: 'error',
        title: '失敗...',
        body: '更新に失敗しました',
      })
    )
  }
}

export function* setAllTimeSlotsClose(
  action: ReturnType<typeof stationsActions.closeAllTimeSlots>
) {
  try {
    yield put(auth0Actions.checkSession())
    const state: RootState = yield select()
    const token = state.auth0.auth.idToken
    const currentTimeSlots = state.stations.stations.find(
      station => station.id === action.payload.stationId
    ).timeSlots
    const timeSlots: TimeSlot[] = currentTimeSlots.map(
      timeSlot => ({ id: timeSlot.id, isClosed: true } as TimeSlot)
    )
    yield call(updateTimeSlots, token, action.payload.stationId, timeSlots)
    yield delay(100)
    yield put(stationsActions.fetchStation(action.payload.stationId))
    yield put(
      toastActions.show({
        title: '成功！',
        body: 'すべてのオーダー停止スロットしました',
      })
    )
    yield put(modalActions.hide())
  } catch (error) {
    yield put(stationsActions.throwError(error))
    yield put(
      toastActions.show({
        type: 'error',
        title: '失敗...',
        body: '更新に失敗しました',
      })
    )
  }
}

export function* setStation(
  action: ReturnType<typeof stationsActions.setStation>
) {
  try {
    yield put(auth0Actions.checkSession())
    const state: RootState = yield select()
    const token = state.auth0.auth.idToken
    yield call(
      changeStation,
      token,
      action.payload.stationId,
      action.payload.station
    )
    yield delay(100)
    yield put(
      toastActions.show({
        title: '成功!',
        body: 'ステーション情報を更新しました',
      })
    )
    yield put(stationsActions.fetchStation(action.payload.stationId))
    yield put(stationsActions.setStationSucceeded())
  } catch (error) {
    yield put(stationsActions.throwError(error))
    yield put(
      toastActions.show({
        type: 'error',
        title: '失敗...',
        body: '情報の更新に失敗しました',
      })
    )
  }
}

export function* uploadStationImage(
  action: ReturnType<typeof stationsActions.uploadStationImage>
) {
  try {
    yield put(auth0Actions.checkSession())
    const state: RootState = yield select()
    const token = state.auth0.auth.idToken
    const presignResponse = yield call(
      presignStationImageUrl,
      token,
      action.payload.stationId,
      action.payload.stationImageType
    )
    const { fileName, url } = presignResponse.result
    yield call(axios.put, url, action.payload.file, {
      headers: {
        'Content-Type': action.payload.file.type,
      },
    })
    yield put(
      stationsActions.setImageUrl(
        fileName,
        url,
        action.payload.stationImageType
      )
    )
    yield delay(100)
  } catch (error) {
    yield put(stationsActions.throwError(error))
    yield put(
      toastActions.show({
        type: 'error',
        title: '失敗...',
        body: '画像のアップロードに失敗しました',
      })
    )
  }
}

export function* setStationLockerBoxAvailable(
  action: ReturnType<typeof stationsActions.setStationLockerBoxAvailable>
) {
  try {
    yield put(auth0Actions.checkSession())
    const state: RootState = yield select()
    const token = state.auth0.auth.idToken
    yield call(
      changeStationLockerBoxAvailable,
      token,
      action.payload.stationId,
      action.payload.lockerBoxId
    )
    yield delay(100)
    yield put(stationsActions.fetchStation(action.payload.stationId))
    yield put(
      toastActions.show({
        title: '成功！',
        body: 'ロッカーの使用を再開しました',
      })
    )
  } catch (error) {
    yield put(stationsActions.throwError(error))
    yield put(
      toastActions.show({
        type: 'error',
        title: '失敗...',
        body: error.response.data.message,
      })
    )
  }
}

export function* setStationLockerBoxUnavailable(
  action: ReturnType<typeof stationsActions.setStationLockerBoxUnavailable>
) {
  try {
    yield put(auth0Actions.checkSession())
    const state: RootState = yield select()
    const token = state.auth0.auth.idToken
    yield call(
      changeStationLockerBoxUnavailable,
      token,
      action.payload.stationId,
      action.payload.lockerBoxId
    )
    yield delay(100)
    yield put(stationsActions.fetchStation(action.payload.stationId))
    yield put(
      toastActions.show({
        title: '成功！',
        body: 'ロッカーを使用停止にしました',
      })
    )
  } catch (error) {
    yield put(stationsActions.throwError(error))
    yield put(
      toastActions.show({
        type: 'error',
        title: '失敗...',
        body: error.response.data.message,
      })
    )
  }
}

export function* openLockerBox(
  action: ReturnType<typeof stationsActions.openLockerBox>
) {
  try {
    yield put(auth0Actions.checkSession())
    const state: RootState = yield select()
    const token = state.auth0.auth.idToken
    yield call(
      lockerBoxOpener,
      token,
      action.payload.stationId,
      action.payload.lockerBoxId
    )
    yield delay(100)
    yield put(stationsActions.fetchStation(action.payload.stationId))
    yield put(
      toastActions.show({
        title: '成功！',
        body: 'ロッカーを解錠しました',
      })
    )
  } catch (error) {
    yield put(stationsActions.throwError(error))
    yield put(
      toastActions.show({
        type: 'error',
        title: '失敗...',
        body: error.response.data.message,
      })
    )
  }
}

export function* clearLockerBox(
  action: ReturnType<typeof stationsActions.clearLockerBox>
) {
  try {
    yield put(auth0Actions.checkSession())
    const state: RootState = yield select()
    const token = state.auth0.auth.idToken
    yield call(
      deleteCoffeeLogOfLockerBox,
      token,
      action.payload.stationId,
      action.payload.lockerBox
    )
    yield delay(100)
    yield put(stationsActions.fetchStation(action.payload.stationId))
    yield put(
      toastActions.show({
        title: '成功!',
        body: 'ロッカーをクリアしました',
      })
    )
  } catch (error) {
    yield put(stationsActions.throwError(error))
    yield put(
      toastActions.show({
        type: 'error',
        title: '失敗...',
        body: error.response.data.message,
      })
    )
  }
}
export function* createLockerBoxComment(
  action: ReturnType<typeof stationsActions.createLockerBoxComment>
) {
  try {
    yield put(auth0Actions.checkSession())
    const state: RootState = yield select()
    const token = state.auth0.auth.idToken
    yield call(
      newLockerBoxComment,
      token,
      action.payload.stationId,
      action.payload.lockerBoxId,
      action.payload.comment
    )
    yield delay(100)
    yield put(stationsActions.fetchStation(action.payload.stationId))
    yield put(
      toastActions.show({
        title: '成功!',
        body: 'ロッカーの備考を登録しました！',
      })
    )
    yield put(modalActions.hide())
  } catch (error) {
    yield put(stationsActions.throwError(error))
    yield put(
      toastActions.show({
        type: 'error',
        title: '失敗...',
        body: error.response.data.message,
      })
    )
  }
}

export function* setLockerBoxComment(
  action: ReturnType<typeof stationsActions.setLockerBoxComment>
) {
  try {
    yield put(auth0Actions.checkSession())
    const state: RootState = yield select()
    const token = state.auth0.auth.idToken
    yield call(
      changeLockerBoxComment,
      token,
      action.payload.stationId,
      action.payload.lockerBoxId,
      action.payload.lockerBoxCommentId,
      action.payload.comment
    )
    yield delay(100)
    yield put(stationsActions.fetchStation(action.payload.stationId))
    yield put(
      toastActions.show({
        title: '成功!',
        body: 'ロッカーの備考を更新しました！',
      })
    )
    yield put(modalActions.hide())
  } catch (error) {
    yield put(stationsActions.throwError(error))
    yield put(
      toastActions.show({
        type: 'error',
        title: '失敗...',
        body: error.response.data.message,
      })
    )
  }
}

export function* removeLockerBoxComment(
  action: ReturnType<typeof stationsActions.removeLockerBoxComment>
) {
  try {
    yield put(auth0Actions.checkSession())
    const state: RootState = yield select()
    const token = state.auth0.auth.idToken
    yield call(
      deleteLockerBoxComment,
      token,
      action.payload.stationId,
      action.payload.lockerBoxId,
      action.payload.lockerBoxCommentId
    )
    yield delay(100)
    yield put(stationsActions.fetchStation(action.payload.stationId))
    yield put(
      toastActions.show({
        title: '成功!',
        body: 'ロッカーの備考を削除しました！',
      })
    )
    yield put(modalActions.hide())
  } catch (error) {
    yield put(stationsActions.throwError(error))
    yield put(
      toastActions.show({
        type: 'error',
        title: '失敗...',
        body: error.response.data.message,
      })
    )
  }
}

export function* watchFetchStations() {
  yield takeLatest(stationsActionType.FETCH_STATIONS, fetchStations)
}

export function* watchFetchStationsEvery() {
  yield takeLatest(stationsActionType.FETCH_STATIONS_EVERY, fetchStationsEvery)
}

export function* watchFetchStation() {
  yield takeLatest(stationsActionType.FETCH_STATION, fetchStation)
}

export function* watchFetchStationEvery() {
  yield takeLatest(stationsActionType.FETCH_STATION_EVERY, fetchStationEvery)
}

export function* watchCreateStation() {
  yield takeLatest(stationsActionType.CREATE_STATION, createStation)
}

export function* watchBootMachine() {
  yield takeLatest(stationsActionType.BOOT_MACHINE, bootMachine)
}

export function* watchShutMachine() {
  yield takeLatest(stationsActionType.SHUT_MACHINE, shutMachine)
}

export function* watchResolveEmergency() {
  yield takeLatest(stationsActionType.RESOLVE_EMERGENCY, resolveEmergency)
}

export function* watchCreateEmergency() {
  yield takeLatest(stationsActionType.CREATE_EMERGENCY, createEmergency)
}

export function* watchFillIce() {
  yield takeLatest(stationsActionType.FILL_ICE, setIceFill)
}

export function* watchSetStationItemAvailable() {
  yield takeLatest(
    stationsActionType.SET_STATION_ITEM_AVAILABLE,
    setStationItemAvailable
  )
}

export function* watchSetStationItemAvailableTemporarily() {
  yield takeLatest(
    stationsActionType.SET_STATION_ITEM_AVAILABLE_TEMPORARILY,
    setStationItemAvailableTemporarily
  )
}

export function* watchSetStationItemUnavailable() {
  yield takeLatest(
    stationsActionType.SET_STATION_ITEM_UNAVAILABLE,
    setStationItemUnavailable
  )
}

export function* watchSetStationItemUnavailableTemporarily() {
  yield takeLatest(
    stationsActionType.SET_STATION_ITEM_UNAVAILABLE_TEMPORARILY,
    setStationItemUnavailableTemporarily
  )
}

export function* watchOpenAllLockerBoxes() {
  yield takeLatest(stationsActionType.OPEN_ALL_LOCKER_BOXES, openLockerBoxes)
}

export function* watchOpenTimeSlot() {
  yield takeLatest(stationsActionType.OPEN_TIME_SLOT, setTimeSlotOpen)
}

export function* watchCloseTimeSlot() {
  yield takeLatest(stationsActionType.CLOSE_TIME_SLOT, setTimeSlotClose)
}

export function* watchOpenAllTimeSlots() {
  yield takeLatest(stationsActionType.OPEN_ALL_TIME_SLOTS, setAllTimeSlotsOpen)
}

export function* watchCloseAllTimeSlots() {
  yield takeLatest(
    stationsActionType.CLOSE_ALL_TIME_SLOTS,
    setAllTimeSlotsClose
  )
}

export function* watchSetStation() {
  yield takeLatest(stationsActionType.SET_STATION, setStation)
}

export function* watchUploadStationImage() {
  yield takeLatest(stationsActionType.UPLOAD_STATION_IMAGE, uploadStationImage)
}

export function* watchSetStationLockerBoxAvailable() {
  yield takeLatest(
    stationsActionType.SET_STATION_LOCKER_BOX_AVAILABLE,
    setStationLockerBoxAvailable
  )
}

export function* watchSetStationLockerBoxUnavailable() {
  yield takeLatest(
    stationsActionType.SET_STATION_LOCKER_BOX_UNAVAILABLE,
    setStationLockerBoxUnavailable
  )
}

export function* watchOpenLockerBox() {
  yield takeLatest(stationsActionType.OPEN_LOCKER_BOX, openLockerBox)
}

export function* watchClearLockerBox() {
  yield takeLatest(stationsActionType.CLEAR_LOCKER_BOX, clearLockerBox)
}

export function* watchCreateLockerBoxComment() {
  yield takeLatest(
    stationsActionType.CREATE_LOCKER_BOX_COMMENT,
    createLockerBoxComment
  )
}

export function* watchSetLockerBoxComment() {
  yield takeLatest(
    stationsActionType.SET_LOCKER_BOX_COMMENT,
    setLockerBoxComment
  )
}

export function* watchRemoveLockerBoxComment() {
  yield takeLatest(
    stationsActionType.REMOVE_LOCKER_BOX_COMMENT,
    removeLockerBoxComment
  )
}

export const stationsSagas = [
  fork(watchFetchStations),
  fork(watchFetchStationsEvery),
  fork(watchFetchStation),
  fork(watchFetchStationEvery),
  fork(watchCreateStation),
  fork(watchBootMachine),
  fork(watchShutMachine),
  fork(watchResolveEmergency),
  fork(watchCreateEmergency),
  fork(watchFillIce),
  fork(watchSetStationItemAvailable),
  fork(watchSetStationItemAvailableTemporarily),
  fork(watchSetStationItemUnavailable),
  fork(watchSetStationItemUnavailableTemporarily),
  fork(watchOpenAllLockerBoxes),
  fork(watchOpenTimeSlot),
  fork(watchCloseTimeSlot),
  fork(watchOpenAllTimeSlots),
  fork(watchCloseAllTimeSlots),
  fork(watchSetStation),
  fork(watchUploadStationImage),
  fork(watchSetStationLockerBoxAvailable),
  fork(watchSetStationLockerBoxUnavailable),
  fork(watchClearLockerBox),
  fork(watchOpenLockerBox),
  fork(watchCreateLockerBoxComment),
  fork(watchSetLockerBoxComment),
  fork(watchRemoveLockerBoxComment),
]
