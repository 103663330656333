import React from 'react'
import classnames from 'classnames'

import { Item } from '../../../core/items'
import { matchItemInfoTableContent } from './each-item-content'
import styles from '../../styles/modules/table.module.scss'

interface Props {
  item: Item
}

const MatchItemInfoTable: React.FC<Props> = props => (
  <div
    className={classnames({
      [styles.wrapper]: true,
      [styles.stocker]: true,
    })}
  >
    <table className={styles.container}>
      <tbody>
        <tr className={styles.rowStatic}>
          <td className={styles.cell}>
            {matchItemInfoTableContent.bitterness}
          </td>
          <td className={styles.cell}>{props.item.matchItemParam.bitterness}</td>
        </tr>
        <tr className={styles.rowStatic}>
          <td className={styles.cell}>
            {matchItemInfoTableContent.fragrance}
          </td>
          <td className={styles.cell}>{props.item.matchItemParam.fragrance}</td>
        </tr>
        <tr className={styles.rowStatic}>
          <td className={styles.cell}>
            {matchItemInfoTableContent.acidity}
          </td>
          <td className={styles.cell}>{props.item.matchItemParam.acidity}</td>
        </tr>
        <tr className={styles.rowStatic}>
          <td className={styles.cell}>
            {matchItemInfoTableContent.sweetness}
          </td>
          <td className={styles.cell}>{props.item.matchItemParam.sweetness}</td>
        </tr>
        <tr className={styles.rowStatic}>
          <td className={styles.cell}>
            {matchItemInfoTableContent.richness}
          </td>
          <td className={styles.cell}>{props.item.matchItemParam.richness}</td>
        </tr>
        <tr className={styles.rowStatic}>
          <td className={styles.cell}>
            {matchItemInfoTableContent.uniqueness}
          </td>
          <td className={styles.cell}>{props.item.matchItemParam.uniqueness}</td>
        </tr>
      </tbody>
    </table>
  </div>
)

export default MatchItemInfoTable
