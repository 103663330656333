import { createBrowserHistory } from 'history'
import { applyMiddleware, createStore, compose } from 'redux'
import createMiddleware from 'redux-saga'
import { routerMiddleware } from 'connected-react-router'
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import immutableTransform from 'redux-persist-transform-immutable'

import createRootReducer from './root-reducer'
import rootSaga from './root-saga'

const persistConfig = {
  transform: [immutableTransform()],
  key: 'root-v2',
  storage,
  whitelist: ['stations', 'auth0'],
}

export const history = createBrowserHistory()

export default function configureStore() {
  const sagaMiddleware = createMiddleware()
  const rootReducer = createRootReducer(history)
  const persistedReducer = persistReducer(persistConfig, rootReducer)

  const composeEnhancers =
    (typeof window !== 'undefined' &&
      (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
    compose

  const store = createStore(
    persistedReducer,
    composeEnhancers(applyMiddleware(routerMiddleware(history), sagaMiddleware))
  )

  sagaMiddleware.run(rootSaga)
  const persistor = persistStore(store)

  return { store, persistor }
}
