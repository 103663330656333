import { put, call, takeLatest, fork, delay, select } from 'redux-saga/effects'
import axios from 'axios'

import RootState from '../root-state'
import { itemsActionType, itemsActions } from './actions'
import { auth0Actions } from '../auth0'
import { toastActions } from '../toast'
import { modalActions } from '../modal'
import { getItems, changeItem, presignItemImageUrl, newItem, updateItemsIndex } from '../api'

export function* fetchItems() {
  try {
    const state: RootState = yield select()
    const token = state.auth0.auth.idToken
    const response = yield call(getItems, token)
    yield put(itemsActions.fetchItemsSucceeded(response.items, response.hotItems, response.iceItems))
  } catch (error) {
    yield put(itemsActions.throwError(error))
  }
}

export function* fetchItemsEvery() {
  try {
    while (true) {
      const state: RootState = yield select()
      const active = state.items.active
      if (active !== 'items') {
        return
      }
      yield put(itemsActions.fetchItems())
      yield delay(5000)
    }
  } catch (error) {
    yield put(itemsActions.throwError(error))
  }
}

export function* uploadItemImage(
  action: ReturnType<typeof itemsActions.uploadItemImage>
) {
  try {
    yield put(auth0Actions.checkSession())
    const state: RootState = yield select()
    const token = state.auth0.auth.idToken
    const presignResponse = yield call(
      presignItemImageUrl,
      token,
      action.payload.itemId,
      action.payload.itemImageType
    )
    const { fileName, url } = presignResponse.result
    yield call(axios.put, url, action.payload.file, {
      headers: {
        'Content-Type': action.payload.file.type,
      },
    })
    yield put(itemsActions.setImageUrl(fileName, url, action.payload.itemImageType))
    yield delay(100)
  } catch (error) {
    yield put(itemsActions.throwError(error))
    yield put(
      toastActions.show({
        type: 'error',
        title: '失敗...',
        body: '画像のアップロードに失敗しました',
      })
    )
  }
}

export function* setItem(action: ReturnType<typeof itemsActions.setItem>) {
  try {
    yield put(auth0Actions.checkSession())
    const state: RootState = yield select()
    const token = state.auth0.auth.idToken
    yield call(changeItem, token, action.payload.itemId, action.payload.item)
    yield delay(100)
    yield put(itemsActions.fetchItems())
    yield put(
      toastActions.show({ title: '成功！', body: '商品の情報を更新しました' })
    )
    yield put(modalActions.hide())
  } catch (error) {
    yield put(itemsActions.throwError(error))
    yield put(
      toastActions.show({
        type: 'error',
        title: '失敗...',
        body: '情報の更新に失敗しました',
      })
    )
  }
}

export function* createItem(action: ReturnType<typeof itemsActions.createItem>) {
  try {
    yield put(auth0Actions.checkSession())
    const state: RootState = yield select()
    const token = state.auth0.auth.idToken
    yield call(newItem, token, action.payload.item)
    yield put(itemsActions.createItemSucceeded())
    yield put(
      toastActions.show({ title: '成功！', body: '商品の情報を保存しました' })
    )
  } catch (error) {
    yield put(itemsActions.throwError(error))
    yield put(
      toastActions.show({
        type: 'error',
        title: '失敗...',
        body: 'アイテムの追加に失敗しました',
      })
    )
  }
}

export function* changeItemsIndex(action: ReturnType<typeof itemsActions.changeItemsIndex>) {
  try {
    yield put(auth0Actions.checkSession())
    const state: RootState = yield select()
    const token = state.auth0.auth.idToken
    yield call(updateItemsIndex, token, action.payload.ids)
    yield delay(100)
    yield put(itemsActions.fetchItems())
  } catch (error) {
    yield put(itemsActions.throwError(error))
  }
}

export function* watchFetchItems() {
  yield takeLatest(itemsActionType.FETCH_ITEMS, fetchItems)
}

export function* watchFetchItemsEvery() {
  yield takeLatest(itemsActionType.FETCH_ITEMS_EVERY, fetchItemsEvery)
}

export function* watchSetItem() {
  yield takeLatest(itemsActionType.SET_ITEM, setItem)
}

export function* watchUploadItemImage() {
  yield takeLatest(itemsActionType.UPLOAD_ITEM_IMAGE, uploadItemImage)
}

export function* watchCreateItem() {
  yield takeLatest(itemsActionType.CREATE_ITEM, createItem)
}

export function* watchChangeItemsIndex() {
  yield takeLatest(itemsActionType.CHANGE_ITEMS_INDEX, changeItemsIndex)
}

export const itemsSagas = [
  fork(watchFetchItems),
  fork(watchFetchItemsEvery),
  fork(watchSetItem),
  fork(watchUploadItemImage),
  fork(watchCreateItem),
  fork(watchChangeItemsIndex),
]
