import React from 'react'
import styles from '../../styles/modules/modal/modal.module.scss'

interface Props {
  imageUrl: string
  alt: string
}

export const ShowImageModal: React.FC<Props> = (props: Props) => {
  return (
    <main className={styles.main}>
      <img alt={props.alt} src={props.imageUrl} className={styles.image} />
    </main>
  )
}
