import React, { useEffect } from 'react'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import RootState from '../../core/root-state'
import Layout from '../layouts/layout'
import Wrapper from '../layouts/wrapper'
import {
  strings,
  monthlySalesDataContent,
  dailySalesDataContent,
} from '../components/sales-data/sales-data-content'
import SalesDataFrame from '../components/sales-data/sales-data-frame'
import { SalesData, salesDataActions } from '../../core/sales-data'

interface Props {
  monthlySalesData: SalesData
  fetchMonthlySalesData: () => void
  dailySalesData: SalesData
  fetchDailySalesData: () => void
}

const SalesDataScreen: React.FC<Props> = (props: Props) => {
  const {
    monthlySalesData,
    fetchMonthlySalesData,
    dailySalesData,
    fetchDailySalesData,
  } = props

  useEffect(() => {
    fetchMonthlySalesData()
  }, [fetchMonthlySalesData])

  useEffect(() => {
    fetchDailySalesData()
  }, [fetchDailySalesData])

  return (
    <Layout location="sales_data">
      <Wrapper title={strings.title}>
        <SalesDataFrame
          title={monthlySalesDataContent.title}
          salesData={monthlySalesData}
          height={monthlySalesDataContent.height}
        />
        <SalesDataFrame
          title={dailySalesDataContent.title}
          salesData={dailySalesData}
          height={dailySalesDataContent.height}
        />
      </Wrapper>
    </Layout>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    monthlySalesData: state.salesData.monthlySalesData,
    dailySalesData: state.salesData.dailySalesData,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchMonthlySalesData: () =>
    dispatch(salesDataActions.fetchMonthlySalesData()),
  fetchDailySalesData: () => dispatch(salesDataActions.fetchDailySalesData()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SalesDataScreen)
