import React from 'react'
import classnames from 'classnames'

import { Item } from '../../../core/items'
import { itemInfoTableContent } from './each-item-content'
import styles from '../../styles/modules/table.module.scss'

interface Props {
  item: Item
}

const ItemInfoTable: React.FC<Props> = props => (
  <div
    className={classnames({
      [styles.wrapper]: true,
      [styles.stocker]: true,
    })}
  >
    <table className={styles.container}>
      <tbody>
        <tr className={styles.rowStatic}>
          <td className={styles.cell}>{itemInfoTableContent.price}</td>
          <td className={styles.cell}>{props.item.price}</td>
        </tr>
        <tr className={styles.rowStatic}>
          <td className={styles.cell}>
            {itemInfoTableContent.disposalSeconds}
          </td>
          <td className={styles.cell}>{props.item.disposalSeconds}</td>
        </tr>
        <tr className={styles.rowStatic}>
          <td className={styles.cell}>{itemInfoTableContent.cupType}</td>
          <td className={styles.cell}>{props.item.cupType}</td>
        </tr>
        <tr className={styles.rowStatic}>
          <td className={styles.cell}>{itemInfoTableContent.color}</td>
          <td className={styles.cell}>{props.item.color}</td>
        </tr>
        <tr className={styles.rowStatic}>
          <td className={styles.cell}>{itemInfoTableContent.index}</td>
          <td className={styles.cell}>{props.item.index}</td>
        </tr>
        <tr className={styles.rowStatic}>
          <td className={styles.cell}>{itemInfoTableContent.description}</td>
          <td className={styles.cell}>{props.item.description}</td>
        </tr>
        <tr className={styles.rowStatic}>
          <td className={styles.cell}>{itemInfoTableContent.story}</td>
          <td className={styles.textCell}>{props.item.story}</td>
        </tr>
      </tbody>
    </table>
  </div>
)

export default ItemInfoTable
