import { ActionType } from 'typesafe-actions'
import { stationAlertActionType, stationAlertActions } from './actions'
import { StationAlert } from './station-alerts'


export interface StationAlertsState {
  readonly error: Error
  readonly isLoading: boolean
  readonly stationAlerts : StationAlert[]
  readonly stationAlert : StationAlert
}

export const initialState: StationAlertsState = {
  error: null,
  isLoading: false,
  stationAlerts: [],
  stationAlert: null
}

export const stationAlertsReducer = (
  state: StationAlertsState = initialState,
  action: ActionType<typeof stationAlertActions>
) => {
  switch (action.type) {
    case stationAlertActionType.FETCH_STATION_ALERTS:
      return {
        ...state,
        isLoading: true,
        error: null
      }
    case stationAlertActionType.FETCH_STATION_ALERTS_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        error: null,
        stationAlerts: action.payload.stationAlerts
      }
    case stationAlertActionType.CREATE_STATION_ALERT:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case stationAlertActionType.UPDATE_STATION_ALERT:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case stationAlertActionType.DELETE_STATION_ALERT:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case stationAlertActionType.RESTORE_STATION_ALERT:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case stationAlertActionType.THROW_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      }
      default:
      return state
    }
}