import { put, call, delay, takeLatest, fork, select } from 'redux-saga/effects'

import RootState from '../root-state'
import { campaignsActionType, campaignsActions } from './actions'
import { toastActions } from '../toast'
import { modalActions } from '../modal'
import {
  getCampaigns,
  newCampaign,
  changeCampaign,
  changeCampaignExpiresAt,
} from '../api'
import { CampaignType } from './campaigns'

export function* fetchPromotionCampaigns(
  action: ReturnType<typeof campaignsActions.fetchPromotionCampaigns>
) {
  try {
    const state: RootState = yield select()
    const token = state.auth0.auth.idToken
    const param = {
      ...action.payload.campaignParam,
      types: [CampaignType.Promotion],
    }
    const response = yield call(getCampaigns, token, param)
    yield put(
      campaignsActions.fetchPromotionCampaignsSucceeded(response.campaigns)
    )
  } catch (error) {
    yield put(campaignsActions.throwError(error))
  }
}

export function* fetchStarterCampaigns(
  action: ReturnType<typeof campaignsActions.fetchStarterCampaigns>
) {
  try {
    const state: RootState = yield select()
    const token = state.auth0.auth.idToken
    const param = {
      types: [CampaignType.Starter],
    }
    const response = yield call(getCampaigns, token, param)
    yield put(
      campaignsActions.fetchStarterCampaignsSucceeded(response.campaigns)
    )
  } catch (error) {
    yield put(campaignsActions.throwError(error))
  }
}

export function* fetchStampCampaigns(
  action: ReturnType<typeof campaignsActions.fetchStampCampaigns>
) {
  try {
    const state: RootState = yield select()
    const token = state.auth0.auth.idToken
    const param = {
      types: [CampaignType.Stamp],
    }
    const response = yield call(getCampaigns, token, param)
    yield put(campaignsActions.fetchStampCampaignsSucceeded(response.campaigns))
  } catch (error) {
    yield put(campaignsActions.throwError(error))
  }
}

export function* fetchOrderCampaigns(
  action: ReturnType<typeof campaignsActions.fetchOrderCampaigns>
) {
  try {
    const state: RootState = yield select()
    const token = state.auth0.auth.idToken
    const param = {
      types: [CampaignType.Order],
    }
    const response = yield call(getCampaigns, token, param)
    yield put(campaignsActions.fetchOrderCampaignsSucceeded(response.campaigns))
  } catch (error) {
    yield put(campaignsActions.throwError(error))
  }
}

export function* createPromotionCampaign(
  action: ReturnType<typeof campaignsActions.createPromotionCampaign>
) {
  try {
    const state: RootState = yield select()
    const token = state.auth0.auth.idToken
    yield call(
      newCampaign,
      token,
      action.payload.code,
      action.payload.name,
      action.payload.discountAmount,
      action.payload.memo,
      action.payload.availableCount,
      action.payload.entryCountLimit,
      action.payload.expiresAt,
      action.payload.eligibleStationIds
    )
    yield delay(100)
    yield put(campaignsActions.fetchPromotionCampaigns(null))
    yield put(
      toastActions.show({
        title: '成功！',
        body: 'クーポンを登録しました',
      })
    )
    yield put(modalActions.hide())
  } catch (error) {
    yield put(campaignsActions.throwError(error))
    yield put(
      toastActions.show({
        type: 'error',
        title: '失敗...',
        body: 'クーポンの登録に失敗しました',
      })
    )
  }
}

export function* setPromotionCampaign(
  action: ReturnType<typeof campaignsActions.setPromotionCampaign>
) {
  try {
    const state: RootState = yield select()
    const token = state.auth0.auth.idToken
    yield call(
      changeCampaign,
      token,
      action.payload.campaignId,
      action.payload.campaign
    )
    yield delay(100)
    yield put(campaignsActions.fetchPromotionCampaigns(null))
    yield put(
      toastActions.show({ title: '成功！', body: 'クーポンを更新しました' })
    )
    yield put(modalActions.hide())
  } catch (error) {
    yield put(campaignsActions.throwError(error))
    yield put(
      toastActions.show({
        type: 'error',
        title: '失敗...',
        body: 'クーポンの更新に失敗しました',
      })
    )
  }
}

export function* setPromotionCampaignExpiresAt(
  action: ReturnType<typeof campaignsActions.setPromotionCampaignExpiresAt>
) {
  try {
    const state: RootState = yield select()
    const token = state.auth0.auth.idToken
    yield call(changeCampaignExpiresAt, token, action.payload.campaignId)
    yield delay(100)
    yield put(campaignsActions.fetchPromotionCampaigns(null))
    yield put(
      toastActions.show({ title: '成功！', body: 'クーポンを更新しました' })
    )
    yield put(modalActions.hide())
  } catch (error) {
    yield put(campaignsActions.throwError(error))
    yield put(
      toastActions.show({
        type: 'error',
        title: '失敗...',
        body: 'クーポンの更新に失敗しました',
      })
    )
  }
}

export function* setStarterCampaign(
  action: ReturnType<typeof campaignsActions.setStarterCampaign>
) {
  try {
    const state: RootState = yield select()
    const token = state.auth0.auth.idToken
    yield call(
      changeCampaign,
      token,
      action.payload.campaignId,
      action.payload.campaign
    )
    yield delay(100)
    yield put(campaignsActions.fetchStarterCampaigns())
    yield put(
      toastActions.show({ title: '成功！', body: 'クーポンを作成しました' })
    )
    yield put(modalActions.hide())
  } catch (error) {
    yield put(campaignsActions.throwError(error))
    yield put(
      toastActions.show({
        type: 'error',
        title: '失敗...',
        body: 'クーポンの更新に失敗しました',
      })
    )
  }
}

export function* setStampCampaign(
  action: ReturnType<typeof campaignsActions.setStampCampaign>
) {
  try {
    const state: RootState = yield select()
    const token = state.auth0.auth.idToken
    yield call(
      changeCampaign,
      token,
      action.payload.campaignId,
      action.payload.campaign
    )
    yield delay(100)
    yield put(campaignsActions.fetchStampCampaigns())
    yield put(
      toastActions.show({ title: '成功！', body: 'クーポンを作成しました' })
    )
    yield put(modalActions.hide())
  } catch (error) {
    yield put(campaignsActions.throwError(error))
    yield put(
      toastActions.show({
        type: 'error',
        title: '失敗...',
        body: 'クーポンの更新に失敗しました',
      })
    )
  }
}

export function* setOrderCampaign(
  action: ReturnType<typeof campaignsActions.setOrderCampaign>
) {
  try {
    const state: RootState = yield select()
    const token = state.auth0.auth.idToken
    yield call(
      changeCampaign,
      token,
      action.payload.campaignId,
      action.payload.campaign
    )
    yield delay(100)
    yield put(campaignsActions.fetchOrderCampaigns())
    yield put(
      toastActions.show({ title: '成功！', body: 'クーポンを作成しました' })
    )
    yield put(modalActions.hide())
  } catch (error) {
    yield put(campaignsActions.throwError(error))
    yield put(
      toastActions.show({
        type: 'error',
        title: '失敗...',
        body: 'クーポンの更新に失敗しました',
      })
    )
  }
}

export function* watchFetchPromotionCampaigns() {
  yield takeLatest(
    campaignsActionType.FETCH_PROMOTION_CAMPAIGNS,
    fetchPromotionCampaigns
  )
}

export function* watchFetchStarterCampaigns() {
  yield takeLatest(
    campaignsActionType.FETCH_STARTER_CAMPAIGNS,
    fetchStarterCampaigns
  )
}

export function* watchFetchStampCampaigns() {
  yield takeLatest(
    campaignsActionType.FETCH_STAMP_CAMPAIGNS,
    fetchStampCampaigns
  )
}

export function* watchFetchOrderCampaigns() {
  yield takeLatest(
    campaignsActionType.FETCH_ORDER_CAMPAIGNS,
    fetchOrderCampaigns
  )
}

export function* watchCreatePromotionCampaign() {
  yield takeLatest(
    campaignsActionType.CREATE_PROMOTION_CAMPAIGN,
    createPromotionCampaign
  )
}

export function* watchSetPromotionCampaign() {
  yield takeLatest(
    campaignsActionType.SET_PROMOTION_CAMPAIGN,
    setPromotionCampaign
  )
}

export function* watchSetPromotionCampaignExpiresAt() {
  yield takeLatest(
    campaignsActionType.SET_PROMOTION_CAMPAIGN_EXPIRES_AT,
    setPromotionCampaignExpiresAt
  )
}

export function* watchSetStarterCampaign() {
  yield takeLatest(campaignsActionType.SET_STARTER_CAMPAIGN, setStarterCampaign)
}

export function* watchSetStampCampaign() {
  yield takeLatest(campaignsActionType.SET_STAMP_CAMPAIGN, setStampCampaign)
}

export function* watchSetOrderCampaign() {
  yield takeLatest(campaignsActionType.SET_ORDER_CAMPAIGN, setOrderCampaign)
}

export const campaignsSagas = [
  fork(watchFetchPromotionCampaigns),
  fork(watchFetchStarterCampaigns),
  fork(watchFetchStampCampaigns),
  fork(watchFetchOrderCampaigns),
  fork(watchCreatePromotionCampaign),
  fork(watchSetPromotionCampaign),
  fork(watchSetPromotionCampaignExpiresAt),
  fork(watchSetStarterCampaign),
  fork(watchSetStampCampaign),
  fork(watchSetOrderCampaign),
]
