import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import Layout from '../layouts/layout'
import Wrapper from '../layouts/wrapper'
import StarterCampaignsTable from '../components/auto-entry-campaigns/starter-campaigns-table'
import { Campaign, campaignsActions } from '../../core/campaigns'
import { strings } from '../components/auto-entry-campaigns/auto-entry-campaign-content'
import styles from '../styles/modules/auto-entry-campaigns/auto-entry-campaigns-screen.module.scss'
import StampCampaignsTable from '../components/auto-entry-campaigns/stamp-campaigns-table'
import OrderCampaignsTable from '../components/auto-entry-campaigns/order-campaigns-table'
import RootState from '../../core/root-state'
import { modalActions } from '../../core/modal'

interface Props {
  isLoading: boolean
  starterCampaigns: Campaign[]
  stampCampaigns: Campaign[]
  orderCampaigns: Campaign[]
  fetchStarterCampaigns: () => void
  fetchStampCampaigns: () => void
  fetchOrderCampaigns: () => void
  showSetStarterCampaign: (starterCampaign: Campaign) => void
  showSetStampCampaign: (stampCampaign: Campaign) => void
  showSetOrderCampaign: (orderCampaign: Campaign) => void
}

const AutoEntryCampaignScreen: React.FC<Props> = ({
  starterCampaigns,
  stampCampaigns,
  orderCampaigns,
  fetchStarterCampaigns,
  fetchStampCampaigns,
  fetchOrderCampaigns,
  showSetStarterCampaign,
  showSetStampCampaign,
  showSetOrderCampaign,
}) => {
  useEffect(() => {
    fetchStarterCampaigns()
  }, [fetchStarterCampaigns])

  useEffect(() => {
    fetchStampCampaigns()
  }, [fetchStampCampaigns])

  useEffect(() => {
    fetchOrderCampaigns()
  }, [fetchOrderCampaigns])

  return (
    <Layout location="auto_entry_campaigns">
      <Wrapper title={strings.screenTitle}>
        <h3 className={styles.title}>{strings.starterCampaignsTitle}</h3>
        <StarterCampaignsTable
          starterCampaigns={starterCampaigns}
          onClickSetStarterCampaign={(starterCampaign: Campaign) =>
            showSetStarterCampaign(starterCampaign)
          }
        />

        <h3 className={styles.title}>{strings.stampCampaignsTitle}</h3>
        <StampCampaignsTable
          stampCampaigns={stampCampaigns}
          onClickSetStampCampaign={(stampCampaign: Campaign) =>
            showSetStampCampaign(stampCampaign)
          }
        />

        <h3 className={styles.title}>{strings.orderCampaignsTitle}</h3>
        <OrderCampaignsTable
          orderCampaigns={orderCampaigns}
          onClickSetOrderCampaign={(orderCampaign: Campaign) =>
            showSetOrderCampaign(orderCampaign)
          }
        />
      </Wrapper>
    </Layout>
  )
}

const mapStateToProps = (state: RootState) => ({
  isLoading: state.campaigns.isLoading,
  starterCampaigns: state.campaigns.starterCampaigns,
  stampCampaigns: state.campaigns.stampCampaigns,
  orderCampaigns: state.campaigns.orderCampaigns,
})

const mapDispatchToProps = (dispatch: any) => ({
  fetchStarterCampaigns: () =>
    dispatch(campaignsActions.fetchStarterCampaigns()),
  fetchStampCampaigns: () => dispatch(campaignsActions.fetchStampCampaigns()),
  fetchOrderCampaigns: () => dispatch(campaignsActions.fetchOrderCampaigns()),
  showSetStarterCampaign: (starterCampaign: Campaign) =>
    dispatch(modalActions.showEditStarterCampaign(starterCampaign)),
  showSetStampCampaign: (stampCampaign: Campaign) =>
    dispatch(modalActions.showEditStampCampaign(stampCampaign)),
  showSetOrderCampaign: (orderCampaign: Campaign) =>
    dispatch(modalActions.showEditOrderCampaign(orderCampaign)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AutoEntryCampaignScreen)
