import React from 'react'
import { Dispatch } from 'redux'
import { connect } from "react-redux";

import RootState from '../../core/root-state'
import { StationAlert, stationAlertActions } from '../../core/station-alerts';
import { Station, stationsActions } from '../../core/stations';
import Layout from '../layouts/layout'
import Wrapper from '../layouts/wrapper'
import { strings } from "../components/station-alerts/station-alerts-content";
import StationAlertsTable from "../components/station-alerts/station-alerts-table"
import styles from '../styles/modules/station-alerts/station-alerts.module.scss'
import Button from '../components/button'
import { modalActions } from '../../core/modal';


interface Props {
  isLoading: boolean
  stationAlerts: StationAlert[]
  stationAlert: StationAlert
  stations: Station[]
  fetchStationAlerts: () => void
  fetchStations: () =>void
  showCreateStationAlert: (stations: Station[]) => void
  showEditStationAlert: (stations: Station[], stationAlert: StationAlert) => void
}

class StationAlertsScreen extends React.Component<Props> {

  componentDidMount = () => {
    this.props.fetchStationAlerts()
    this.props.fetchStations()
  }

  handleOnClickStationAlert = () => {
    this.props.showCreateStationAlert(this.props.stations)
  }


  render() {
    return (
      <Layout location='station_alerts'>
        <Wrapper title={strings.title}>
          <Button
            title={strings.createStationAlertButton}
            onClick={() => this.props.showCreateStationAlert(this.props.stations)}
            className={styles.stationAlertCreateButton}
          />
          <StationAlertsTable stationAlerts={this.props.stationAlerts} stations={this.props.stations} showEditStationAlert={this.props.showEditStationAlert}/>
        </Wrapper>
      </Layout>
    )
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    isLoading: state.stationAlerts.isLoading,
    stationAlerts: state.stationAlerts.stationAlerts,
    stations: state.stations.stations,
    stationAlert: state.stationAlerts.stationAlert
  }
}


const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchStationAlerts: () => dispatch(stationAlertActions.fetchStationAlerts()),
  fetchStations: () => dispatch(stationsActions.fetchStations()),
  showCreateStationAlert: (stations: Station[]) => dispatch(modalActions.showCreateStationAlert(stations)),
  showEditStationAlert: (stations: Station[], stationAlert: StationAlert) => dispatch(modalActions.showEditStationAlert(stations, stationAlert))
})



export default connect(mapStateToProps,mapDispatchToProps) (StationAlertsScreen)