import { call, delay, fork, put, select, takeLatest } from 'redux-saga/effects'
import * as api from '../api'
import { pointItemOrdersActionType, pointItemOrdersActions } from './actions'
import RootState from '../root-state'
import { toastActions } from '../toast'
import { auth0Actions } from '../auth0'

export function* fetchPointItemOrders() {
  try {
    yield put(auth0Actions.checkSession())
    const state: RootState = yield select()
    const token = state.auth0.auth.idToken
    const response = yield call(api.getPointItemOrders, token)
    yield put(
      pointItemOrdersActions.fetchPointItemOrdersSucceeded(
        response.pointItemOrders
      )
    )
  } catch (error) {
    yield put(pointItemOrdersActions.throwError(error))
  }
}

export function* completePointItemOrder(
  action: ReturnType<typeof pointItemOrdersActions.completePointItemOrder>
) {
  try {
    yield put(auth0Actions.checkSession())
    const state: RootState = yield select()
    const token = state.auth0.auth.idToken
    yield call(api.completePointItemOrder, token, action.payload.id)
    yield delay(100)
    yield put(pointItemOrdersActions.fetchPointItemOrders())
    yield put(
      toastActions.show({
        title: '成功！',
        body: '発送完了しました',
      })
    )
  } catch (error) {
    yield put(pointItemOrdersActions.throwError(error))
    yield put(
      toastActions.show({
        type: 'error',
        title: '失敗...',
        body: '発送完了に失敗しました',
      })
    )
  }
}

export function* cancelPointItemOrder(
  action: ReturnType<typeof pointItemOrdersActions.cancelPointItemOrder>
) {
  try {
    yield put(auth0Actions.checkSession())
    const state: RootState = yield select()
    const token = state.auth0.auth.idToken
    yield call(api.cancelPointItemOrder, token, action.payload.id)
    yield delay(100)
    yield put(pointItemOrdersActions.fetchPointItemOrders())
    yield put(
      toastActions.show({
        title: '成功！',
        body: 'キャンセルしました',
      })
    )
  } catch (error) {
    yield put(pointItemOrdersActions.throwError(error))
    yield put(
      toastActions.show({
        type: 'error',
        title: '失敗...',
        body: 'キャンセルに失敗しました',
      })
    )
  }
}

export function* returnPointItemOrder(
  action: ReturnType<typeof pointItemOrdersActions.returnPointItemOrder>
) {
  try {
    yield put(auth0Actions.checkSession())
    const state: RootState = yield select()
    const token = state.auth0.auth.idToken
    yield call(api.returnPointItemOrder, token, action.payload.id)
    yield delay(100)
    yield put(pointItemOrdersActions.fetchPointItemOrders())
    yield put(
      toastActions.show({
        title: '成功！',
        body: '返品しました',
      })
    )
  } catch (error) {
    yield put(pointItemOrdersActions.throwError(error))
    yield put(
      toastActions.show({
        type: 'error',
        title: '失敗...',
        body: '発送完了に失敗しました',
      })
    )
  }
}

export function* watchFetchPointItemOrders() {
  yield takeLatest(
    pointItemOrdersActionType.FETCH_POINT_ITEM_ORDERS,
    fetchPointItemOrders
  )
}

export function* watchCompletePointItemOrders() {
  yield takeLatest(
    pointItemOrdersActionType.COMPLETE_POINT_ITEM_ORDER,
    completePointItemOrder
  )
}

export function* watchCancelPointItemOrders() {
  yield takeLatest(
    pointItemOrdersActionType.CANCEL_POINT_ITEM_ORDER,
    cancelPointItemOrder
  )
}

export function* watchReturnPointItemOrders() {
  yield takeLatest(
    pointItemOrdersActionType.RETURN_POINT_ITEM_ORDER,
    returnPointItemOrder
  )
}

export const pointItemOrdersSagas = [
  fork(watchFetchPointItemOrders),
  fork(watchCompletePointItemOrders),
  fork(watchCancelPointItemOrders),
  fork(watchReturnPointItemOrders),
]
