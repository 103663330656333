import React from 'react'
import ReactSVG from 'react-svg'
import classnames from 'classnames'

import loading from '../assets/animations/loading.svg'
import loadingBlue from '../assets/animations/loading_blue.svg'
import styles from '../styles/modules/button.module.scss'

interface Props {
  title: string
  isLoading?: boolean
  onClick: (event?: React.MouseEvent<HTMLElement>) => void
  className?: string
  loadingClassName?: string
  isGray?: boolean
  disabled?: boolean
}

const Button: React.FC<Props> = props => (
  <button
    onClick={props.onClick}
    disabled={props.disabled}
    className={classnames({
      [styles.container]: true,
      [styles.grayContainer]: props.isGray,
      [props.className]: props.className,
    })}
  >
    <span>{props.title}</span>
    <ReactSVG
      src={props.isGray ? loadingBlue : loading}
      beforeInjection={svg => {
        svg.classList.add(styles.loading)
        svg.classList.add(props.loadingClassName)
      }}
      className={classnames({
        [styles.loadingWrapper]: true,
        [styles.grayLoadingWrapper]: props.isGray,
        [styles.visible]: props.isLoading,
      })}
    />
  </button>
)

export default Button
