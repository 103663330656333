import { ActionType } from 'typesafe-actions'
import { pointItemsActionType, pointItemsActions } from './actions'
import { PointItem } from './point-items'

export interface PointItemsState {
  readonly pointItems: PointItem[]
  readonly error: Error
  readonly isLoading: boolean
}

export const initialState: PointItemsState = {
  pointItems: [],
  error: null,
  isLoading: false,
}

export const pointItemsReducer = (
  state: PointItemsState = initialState,
  action: ActionType<typeof pointItemsActions>
) => {
  switch (action.type) {
    case pointItemsActionType.FETCH_POINT_ITEMS:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case pointItemsActionType.FETCH_POINT_ITEMS_SUCCEEDED:
      return {
        ...state,
        pointItems: action.payload.pointItems,
        isLoading: false,
        error: null,
      }
    case pointItemsActionType.CREATE_POINT_ITEM:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case pointItemsActionType.UPDATE_POINT_ITEM:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case pointItemsActionType.SORT_POINT_ITEMS:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case pointItemsActionType.SET_POINT_ITEMS:
      return {
        ...state,
        pointItems: action.payload.pointItems,
      }
    case pointItemsActionType.THROW_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      }
    default:
      return state
  }
}
