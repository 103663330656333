import { action } from 'typesafe-actions'
import { CameraStreamingViewerCredentials } from './camera-streaming-viewer-credentials'

export enum cameraStreamingViewerCredentialsActionType {
  FETCH_CAMERA_STREAMING_VIEWER_CREDENTIALS = 'FETCH_CAMERA_STREAMING_VIEWER_CREDENTIALS',
  FETCH_CAMERA_STREAMING_VIEWER_CREDENTIALS_SUCCEEDED = 'FETCH_CAMERA_STREAMING_VIEWER_CREDENTIALS_SUCCEEDED',
  THROW_ERROR = 'THROW_ERROR',
}

export const cameraStreamingViewerCredentialsActions = {
  fetchCameraStreamingViewerCredentials: () =>
    action(
      cameraStreamingViewerCredentialsActionType.FETCH_CAMERA_STREAMING_VIEWER_CREDENTIALS
    ),
  fetchCameraStreamingViewerCredentialsSucceeded: (
    cameraStreamingViewerCredentials: CameraStreamingViewerCredentials
  ) =>
    action(
      cameraStreamingViewerCredentialsActionType.FETCH_CAMERA_STREAMING_VIEWER_CREDENTIALS_SUCCEEDED,
      { cameraStreamingViewerCredentials }
    ),
  throwError: (error: Error) =>
    action(cameraStreamingViewerCredentialsActionType.THROW_ERROR, { error }),
}
