import { action } from 'typesafe-actions'
import { PointItemOrder } from './point-item-orders'

export enum pointItemOrdersActionType {
  FETCH_POINT_ITEM_ORDERS = 'FETCH_POINT_ITEM_ORDERS',
  FETCH_POINT_ITEM_ORDERS_SUCCEEDED = 'FETCH_POINT_ITEM_ORDERS_SUCCEEDED',
  COMPLETE_POINT_ITEM_ORDER = 'COMPLETE_POINT_ITEM_ORDER',
  CANCEL_POINT_ITEM_ORDER = 'CANCEL_POINT_ITEM_ORDER',
  RETURN_POINT_ITEM_ORDER = 'RETURN_POINT_ITEM_ORDER',
  THROW_ERROR = 'THROW_ERROR',
}

export const pointItemOrdersActions = {
  fetchPointItemOrders: () =>
    action(pointItemOrdersActionType.FETCH_POINT_ITEM_ORDERS),
  fetchPointItemOrdersSucceeded: (pointItemOrders: PointItemOrder[]) =>
    action(pointItemOrdersActionType.FETCH_POINT_ITEM_ORDERS_SUCCEEDED, {
      pointItemOrders,
    }),
  completePointItemOrder: (id: number) =>
    action(pointItemOrdersActionType.COMPLETE_POINT_ITEM_ORDER, { id }),
  cancelPointItemOrder: (id: number) =>
    action(pointItemOrdersActionType.CANCEL_POINT_ITEM_ORDER, { id }),
  returnPointItemOrder: (id: number) =>
    action(pointItemOrdersActionType.RETURN_POINT_ITEM_ORDER, { id }),
  throwError: (error: Error) =>
    action(pointItemOrdersActionType.THROW_ERROR, { error }),
}
