export const strings = {
  title: 'エマージェンシー履歴',
  availableOpenLockerBox: 'ロッカー解錠可',
  unavailableOpenLockerBox: 'ロッカー解錠不可',
}

export const emergenciesTableContent = [
  '',
  'ID',
  'ステーションID',
  'マシンID',
  '発生起因',
  'メッセージ',
  'スタックトレース',
  'ロッカー解錠可否',
  '発生日時',
  '解決日時',
  'NUCバージョン',
]
