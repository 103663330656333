export const strings = {
  title: 'KIOSK価格',
  hotKioskItemPricesTableTitle: 'HOT',
  iceKioskItemPricesTableTitle: 'ICE',
  editKioskItemPriceButton: '価格を変更する',
}

export const kioskItemPricesTableContent = [
  'アイテムID',
  'アイテム名',
  'KIOSK価格',
  '',
]
