import React from 'react'
import classnames from 'classnames'

import styles from '../styles/modules/file-input.module.scss'

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  id?: string
  label?: string
  className?: string
}

interface State {
  readonly message: string
}

export default class FileInput extends React.PureComponent<Props, State> {
  readonly state = {
    message: 'ファイルをアップロード',
  }

  handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { onChange } = this.props
    const item = event.target.files.item(0)
    if (item) {
      this.setState({ message: item.name })
    }
    if (onChange) {
      onChange(event)
    }
  }

  render() {
    const { id, label, className, ...rest } = this.props
    return (
      <div className={classnames({ [styles.form]: true, [className]: true })}>
        {label && <label className={styles.label}>{label}</label>}
        <label htmlFor={id || "file-input"} className={styles.pseudoLabel}>
          {this.state.message}
        </label>
        <input
          id={id || 'file-input'}
          {...rest}
          onChange={this.handleOnChange}
          className={styles.input}
        />
      </div>
    )
  }
}
