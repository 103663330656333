import React from 'react'

import { Item, ItemImageType, ItemImage } from '../../../core/items'
import { imageTitles } from './each-item-content'
import styles from '../../styles/modules/each-item/item-image-items.module.scss'
import FileInput from '../file-input'

interface Props {
  item: Item
  itemImage: ItemImage
  id: number
  uploadItemImage: (
    id: number,
    itemImageType: ItemImageType,
    file: File
  ) => void
}

interface State {
  readonly file?: File
}

class ItemImageForms extends React.PureComponent<Props, State> {
  readonly state = {
    file: null,
  }
  render() {
    return (
      <div className={styles.container}>
        <div className={styles.itemContainer}>
          <h4>{imageTitles.image}</h4>
          <FileInput
            type={'file'}
            name={'image'}
            id={'image'}
            onChange={event => {
              this.props.uploadItemImage(
                this.props.id,
                ItemImageType.Image,
                event.target.files.item(0)
              )
            }}
          />
        </div>
        <div className={styles.itemContainer}>
          <h4>{imageTitles.thumbnailImage}</h4>
          <FileInput
            type={'file'}
            name={'thumbNailmage'}
            id={'thumbnailImage'}
            onChange={event => {
              this.props.uploadItemImage(
                this.props.id,
                ItemImageType.ThumbnailImage,
                event.target.files.item(0)
              )
            }}
          />
        </div>
      </div>
    )
  }
}

export default ItemImageForms
