import { action } from 'typesafe-actions'

import { Kiosk } from './kiosks'

export enum kiosksActionType {
  FETCH_KIOSKS = 'FETCH_KIOSKS',
  FETCH_KIOSKS_SUCCEEDED = 'FETCH_KIOSKS_SUCCEEDED',
  CREATE_KIOSK = 'CREATE_KIOSK',
  UPDATE_KIOSK_RELATION = 'UPDATE_KIOSK_RELATION',
  CLEAR_KIOSK_RELATION = 'CLEAR_KIOSK_RELATION',
  THROW_ERROR = 'THROW_ERROR',
}

export const kiosksActions = {
  fetchKiosks: () => action(kiosksActionType.FETCH_KIOSKS),
  fetchKiosksSucceeded: (kiosks: Kiosk[]) =>
    action(kiosksActionType.FETCH_KIOSKS_SUCCEEDED, { kiosks }),
  createKiosk: (serial: string) =>
    action(kiosksActionType.CREATE_KIOSK, { serial }),
  updateKioskRelation: (
    kioskId: number,
    stationId: number,
    soracomSIMId: number
  ) =>
    action(kiosksActionType.UPDATE_KIOSK_RELATION, {
      kioskId,
      stationId,
      soracomSIMId,
    }),
  throwError: (error: Error) => action(kiosksActionType.THROW_ERROR, { error }),
  clearKioskRelation: (kioskId: number) =>
    action(kiosksActionType.CLEAR_KIOSK_RELATION, { kioskId }),
}
