import { action } from 'typesafe-actions'

import { PromotionImage } from './promotion-images'

export enum promotionImagesActionType {
  FETCH_PROMOTION_IMAGE = 'FETCH_PROMOTION_IMAGE',
  FETCH_PROMOTION_IMAGE_SUCCEEDED = 'FETCH_PROMOTION_IMAGE_SUCCEEDED',
  CREATE_PROMOTION_IMAGE = 'CREATE_PROMOTION_IMAGE',
  UPDATE_PROMOTION_IMAGE = 'UPDATE_PROMOTION_IMAGE',
  DELETE_PROMOTION_IMAGE = 'DELETE_PROMOTION_IMAGE',
  UPDATE_PROMOTION_IMAGES_INDEX = 'UPDATE_PROMOTION_IMAGES_INDEX',
  SET_PROMOTION_IMAGES = 'SET_PROMOTION_IMAGES',
  THROW_ERROR = 'THROW_ERROR',
}


export const promotionImagesActions = {
  fetchPromotionImages: () => action(promotionImagesActionType.FETCH_PROMOTION_IMAGE),
  fetchPromotionImagesSucceeded: (promotionImages: PromotionImage[]) =>
    action(promotionImagesActionType.FETCH_PROMOTION_IMAGE_SUCCEEDED, { promotionImages }),
  createPromotionImage: (file: File, linkUrl: string, title: string, description: string) =>
    action(promotionImagesActionType.CREATE_PROMOTION_IMAGE, { file, linkUrl, title, description }),
  updatePromotionImage: (id: number, file: File, linkUrl: string, title: string, description: string) =>
    action(promotionImagesActionType.UPDATE_PROMOTION_IMAGE, { id, file, linkUrl, title, description }),
  deletePromotionImage: (id: number) =>
    action(promotionImagesActionType.DELETE_PROMOTION_IMAGE, { id, }),
  updatePromotionImageIndex: (ids: number[]) =>
    action(promotionImagesActionType.UPDATE_PROMOTION_IMAGES_INDEX, { ids }),
  setPromotionImages: (promotionImages: PromotionImage[]) =>
    action(promotionImagesActionType.SET_PROMOTION_IMAGES, { promotionImages }),
  throwError: (error: Error) =>
    action(promotionImagesActionType.THROW_ERROR, { error }),
}
