import React from 'react'

import { SoracomSIM } from '../../../core/soracom-sims'
import { strings } from './soracom-sims-content'
import SoracomSIMsTable from "./soracom-sims-table"

import Button from '../button'
import styles from '../../styles/modules/soracom-sims/soracom-sims.module.scss'

interface Props {
  soracomSIMs: SoracomSIM[]
  showCreateSoracomSIM: () => void
  showEditSoracomSIM: (soracomSIM: SoracomSIM) => void
}

const SoracomSIMIndex: React.FC<Props> = (props: Props) => {
  return (
    <>
      <Button
        title={strings.createSoracomSIMButton}
        onClick={() => props.showCreateSoracomSIM()}
        className={styles.soracomSIMCreateButton}
      />
      <SoracomSIMsTable
        soracomSIMs={props.soracomSIMs}
        showEditSoracomSIM={props.showEditSoracomSIM}
      />
    </>
  )
}

export default SoracomSIMIndex
