import { SignalingClient, Role } from 'amazon-kinesis-video-streams-webrtc'
import { KinesisVideo, KinesisVideoSignalingChannels } from 'aws-sdk'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { CameraStreamingViewerCredentials } from '../../../core/camera-streaming-viewer'
import arrowIcon from '../../assets/images/icon-toggle-arrow-down.png'
import styles from '../../styles/modules/camera-streaming-viewer/camera-streaming-viewer-main.module.scss'

interface Props {
  channelName: string
  channelARN: string
  cameraStreamingViewerCredentials: CameraStreamingViewerCredentials
}

const globalKVS: any = {}

const StreamingViewer: React.FC<Props> = (props: Props) => {
  const kinesisVideoClientRef = useRef(
    new KinesisVideo(props.cameraStreamingViewerCredentials)
  )
  const clientIdRef = useRef('viewer-' + generateUuid())
  const [masterStream, setMasterStream] = useState<MediaStream | null>(null)
  const [paused, setPaused] = useState(false)
  const [clientIsLoaded, setClientIsLoaded] = useState(false)
  const [cameraList, setCameraList] = useState<any[]>([])

  const videoRef = useRef<HTMLVideoElement>(null)

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.srcObject = masterStream
    }
  }, [masterStream])

  useEffect(() => {
    if (videoRef.current && paused) {
      videoRef.current.pause()
    }
  }, [paused])

  useEffect(() => {
    if (clientIsLoaded) {
      startViewer()
    }
  }, [clientIsLoaded])

  const prepareNewPeer = useCallback((iceServers, signalingClient) => {
    const peerConnection = new RTCPeerConnection({ iceServers })
    peerConnection.addEventListener('icecandidate', ({ candidate }) => {
      if (candidate) {
        signalingClient.sendIceCandidate(candidate)
      } else {
        // No more ICE candidates will be generated
      }
    })

    // As remote tracks are received, add them to the remote view
    peerConnection.addEventListener('track', event => {
      playVideo(event.streams[0])
    })

    function stopViewer() {
      stopVideo()

      if (globalKVS.signalingClient) {
        globalKVS.signalingClient.close()
      }

      if (globalKVS.peerConnection) {
        globalKVS.peerConnection.close()
        globalKVS.peerConnection = null
      }

      if (globalKVS.localStream) {
        globalKVS.localStream.getTracks().forEach(track => track.stop())
        globalKVS.localStream = null
      }
    }

    // handle disconnect
    peerConnection.addEventListener('iceconnectionstatechange', event => {
      if (peerConnection.iceConnectionState === 'failed') {
        stopVideo()
        stopViewer()
      }
    })

    const dataChannel = peerConnection.createDataChannel('kvsDataChannel')
    peerConnection.ondatachannel = event => {
      event.channel.onmessage = messageEvent => {
        setCameraList(JSON.parse(messageEvent.data).cameras)
      }
    }

    globalKVS.dataChannel = dataChannel

    return peerConnection
  }, [])

  const setupKVS = useCallback(async () => {
    const clientId = clientIdRef.current
    const kinesisVideoClient = kinesisVideoClientRef.current
    const getSignalingChannelEndpointResponse = await kinesisVideoClient
      .getSignalingChannelEndpoint({
        ChannelARN: props.channelARN,
        SingleMasterChannelEndpointConfiguration: {
          Protocols: ['WSS', 'HTTPS'],
          Role: Role.VIEWER,
        },
      })
      .promise()
    const endpointsByProtocol = getSignalingChannelEndpointResponse.ResourceEndpointList.reduce(
      (endpoints, endpoint) => {
        endpoints[endpoint.Protocol] = endpoint.ResourceEndpoint
        return endpoints
      },
      {}
    )

    globalKVS.getSignalingChannelEndpointResponse = getSignalingChannelEndpointResponse
    globalKVS.endpointsByProtocol = endpointsByProtocol
    const kinesisVideoSignalingChannelsClient = new KinesisVideoSignalingChannels(
      {
        ...props.cameraStreamingViewerCredentials,
        endpoint: endpointsByProtocol.ResourceEndpoint,
      }
    )
    globalKVS.kinesisVideoSignalingChannelsClient = kinesisVideoSignalingChannelsClient

    const getIceServerConfigResponse = await kinesisVideoSignalingChannelsClient
      .getIceServerConfig({
        ChannelARN: props.channelARN,
      })
      .promise()
    const iceServers: any = [
      {
        urls: [
          `stun:stun.kinesisvideo.${props.cameraStreamingViewerCredentials.region}.amazonaws.com:443`,
        ],
      },
    ]
    getIceServerConfigResponse.IceServerList?.forEach(iceServer =>
      iceServers.push({
        urls: iceServer.Uris,
        username: iceServer.Username,
        credential: iceServer.Password,
      })
    )
    globalKVS.iceServers = iceServers

    const protocolKey = 'WSS'
    const protocolEndpoint = endpointsByProtocol[protocolKey]

    const signalingClient = new SignalingClient({
      channelARN: props.channelARN,
      channelEndpoint: protocolEndpoint,
      clientId,
      role: Role.VIEWER,
      region: props.cameraStreamingViewerCredentials.region,
      credentials: {
        accessKeyId: props.cameraStreamingViewerCredentials.accessKeyId,
        secretAccessKey: props.cameraStreamingViewerCredentials.secretAccessKey,
      },
    })
    globalKVS.signalingClient = signalingClient

    // const peerConnection = new RTCPeerConnection({
    //   iceServers: iceServers,
    // })

    // globalKVS.peerConnection = peerConnection

    // Once the signaling channel connection is open, connect to the webcam and create an offer to send to the master
    globalKVS.signalingClient.on('open', async () => {
      // --- prepare new peer ---
      if (!globalKVS.peerConnection) {
        globalKVS.peerConnection = prepareNewPeer(
          globalKVS.iceServers,
          globalKVS.signalingClient
        )
      } else {
        console.warn('WARN: ALREADY peer exist')
      }

      if (globalKVS.peerConnection.addTransceiver) {
        globalKVS.peerConnection.addTransceiver('video', {
          direction: 'recvonly',
        })
        globalKVS.peerConnection.addTransceiver('audio', {
          direction: 'recvonly',
        })
      }

      // Create an SDP offer and send it to the master
      const offer = await globalKVS.peerConnection.createOffer({
        offerToReceiveAudio: true,
        offerToReceiveVideo: true,
      })
      await globalKVS.peerConnection.setLocalDescription(offer)
      signalingClient.sendSdpOffer(globalKVS.peerConnection.localDescription)
    })

    // When the SDP answer is received back from the master, add it to the peer connection.
    globalKVS.signalingClient.on('sdpAnswer', async answer => {
      await globalKVS.peerConnection.setRemoteDescription(answer)
    })

    // When an ICE candidate is received from the master, add it to the peer connection.
    globalKVS.signalingClient.on('iceCandidate', candidate => {
      globalKVS.peerConnection.addIceCandidate(candidate)
    })

    globalKVS.signalingClient.on('close', () => {
      // Handle client closures
      console.warn('signalingClient close')
    })

    globalKVS.signalingClient.on('error', error => {
      // Handle client errors
      console.error('signalingClient error', error)
    })

    setClientIsLoaded(true)
  }, [props.channelARN, props.cameraStreamingViewerCredentials, prepareNewPeer])

  async function playVideo(stream) {
    setMasterStream(stream)
    setPaused(false)
  }

  function stopVideo() {
    setPaused(true)
  }

  function generateUuid() {
    // refer
    //   https://qiita.com/psn/items/d7ac5bdb5b5633bae165
    //   https://github.com/GoogleChrome/chrome-platform-analytics/blob/master/src/internal/identifier.js
    // const FORMAT: string = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx";
    const chars = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.split('')
    for (let i = 0, len = chars.length; i < len; i++) {
      switch (chars[i]) {
        case 'x':
          chars[i] = Math.floor(Math.random() * 16).toString(16)
          break
        case 'y':
          chars[i] = (Math.floor(Math.random() * 4) + 8).toString(16)
          break
      }
    }
    return chars.join('')
  }

  function startViewer() {
    globalKVS.signalingClient.open()
  }

  useEffect(() => {
    setupKVS()
  }, [setupKVS])

  return (
    <div className={styles.container}>
      <h1>{props.channelName}</h1>
      <video
        className={styles.video}
        ref={videoRef}
        autoPlay
        playsInline
        muted
      />
      <label className={styles.label}>
        <select
          className={styles.select}
          onChange={event => {
            globalKVS.dataChannel.send(
              JSON.stringify({ cameraId: event.target.value })
            )
          }}
        >
          {cameraList?.map((camera, index) => (
            <option key={index} value={camera.deviceId}>
              {camera.label}
            </option>
          ))}
        </select>
        <img
          src={arrowIcon}
          alt="Arrow Down Icon"
          className={styles.arrowIcon}
        />
      </label>
    </div>
  )
}

export default StreamingViewer
