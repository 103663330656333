import React from 'react'
import classnames from 'classnames'
import { Station } from '../../../core/stations'
import { SoracomSIM } from '../../../core/soracom-sims'
import styles from '../../styles/modules/table.module.scss'
import { strings, kioskRelationsTableContent } from './kiosk-relations-content'
import kioskRelationsTableStyles from '../../styles/modules/kiosk-relations/kiosk-relations-table.module.scss'
import Button from '../button'
import { Kiosk } from '../../../core/kiosks'

interface Props {
  stations: Station[]
  soracomSIMs: SoracomSIM[]
  kiosks: Kiosk[]
  showEditKioskRelation: (
    stations: Station[],
    soracomSIMs: SoracomSIM[],
    kiosks: Kiosk[],
    kiosk: Kiosk
  ) => void
  clearKioskRelation: (kioskId: number) => void
}

const createConfirmClearKioskRelationSentence = (kiosk: Kiosk) =>
  `
KIOSK ID ${kiosk.id}の紐付けを解除します。
操作を確定する場合はテキストフィールドに「解除」と入力してください。

※注意
この操作は取り消すことができません。
紐付けを解除することでお客様に不利益を及ぼす可能性があります。
影響範囲を正しく理解した上で実行してください。
`

const KioskRelationsTable: React.FC<Props> = (props: Props) => {
  const handleOnClickEdit = (kioskId: number) => {
    const kiosk = props.kiosks.find(i => i.id === Number(kioskId))
    props.showEditKioskRelation(
      props.stations,
      props.soracomSIMs,
      props.kiosks,
      kiosk
    )
  }

  const printStationName = (stationID: number | null) => {
    if (stationID == null) {
      return strings.noData
    }
    return props.stations.find(station => station.id === stationID).name
  }

  const printSoracomSimID = (kioskID: number) => {
    const targetSoracomSIM = props.soracomSIMs.find(
      soracomSIM => soracomSIM.kioskID === kioskID
    )
    return targetSoracomSIM ? targetSoracomSIM.id : strings.noData
  }

  const handleOnClickClearKioskRelation = (kiosk: Kiosk) => {
    const confirmClearKioskRelationSentence = createConfirmClearKioskRelationSentence(
      kiosk
    )
    const input = window.prompt(confirmClearKioskRelationSentence)
    if (input !== null) {
      if (input === '解除') {
        props.clearKioskRelation(kiosk.id)
      } else {
        handleOnClickClearKioskRelation(kiosk)
      }
    }
  }

  return (
    <div
      className={classnames({
        [styles.wrapper]: true,
        [styles.zebra]: true,
        [styles.stocker]: true,
      })}
    >
      <table
        className={classnames(
          styles.container,
          kioskRelationsTableStyles.table
        )}
      >
        <tbody>
          <tr className={styles.row}>
            {kioskRelationsTableContent.map(item => (
              <th key={item} className={styles.cell}>
                {item}
              </th>
            ))}
          </tr>
          {props.kiosks
            .sort((a, b) => {
              if (a.id < b.id) {
                return -1
              } else {
                return 1
              }
            })
            .map(kiosk => (
              <tr className={styles.row} key={kiosk.id}>
                <td className={styles.cell}>{kiosk.id}</td>
                <td className={styles.cell}>{kiosk.serial}</td>
                <td className={styles.cell}>
                  {printStationName(kiosk.stationID ? kiosk.stationID : null)}
                </td>
                <td className={styles.cell}>{printSoracomSimID(kiosk.id)}</td>
                <td className={styles.cell}>
                  <Button
                    title={strings.editKioskRelationButton}
                    onClick={() => {
                      handleOnClickEdit(kiosk.id)
                    }}
                    className={kioskRelationsTableStyles.container}
                  />
                </td>
                <td className={styles.cell}>
                  <Button
                    title={strings.deleteKioskRelationButton}
                    onClick={() => {
                      handleOnClickClearKioskRelation(kiosk)
                    }}
                    className={classnames(
                      kioskRelationsTableStyles.container,
                      kioskRelationsTableStyles.delete
                    )}
                  />
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  )
}

export default KioskRelationsTable
