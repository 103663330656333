import React from 'react'
import classnames from 'classnames'

import { PointTransaction } from '../../../core/points'
import { strings, pointsTableContent } from './points-content'
import styles from '../../styles/modules/table.module.scss'
import PointsTableItem from './points-table-item'

interface Props {
  pointTransactions: PointTransaction[]
}

const PointsTable: React.FC<Props> = (props: Props) => {
  return (
    <>
      <h3 className={styles.title}>{strings.pointsTableTitle}</h3>
      <div
        className={classnames({
          [styles.wrapper]: true,
          [styles.stocker]: true,
        })}
      >
        <table className={styles.container}>
          <tbody>
            <tr className={styles.rowStatic}>
              {pointsTableContent.map(item => (
                <th key={item} className={styles.cell}>
                  {item}
                </th>
              ))}
            </tr>
            {props.pointTransactions
              .sort((a, b) => {
                if (a.id > b.id) {
                  return -1
                } else {
                  return 1
                }
              })
              .map(pointTransaction => (
                <PointsTableItem pointTransaction={pointTransaction} key={pointTransaction.id}/>
              ))
            }
          </tbody>
        </table>
      </div>
    </>
  )
}

export default PointsTable
