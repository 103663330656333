import { action } from 'typesafe-actions'

export interface Config {
  type?: 'success' | 'error'
  title?: string
  body?: string
  duration?: number
}

export const defaultConfig: Config = {
  type: 'success',
  title: '',
  body: '',
  duration: 2000,
}

export enum toastActionType {
  SHOW = 'TOAST_SHOW',
  HIDE = 'TOAST_HIDE',
}

export const toastActions = {
  show: (options?: Config) =>
    action(toastActionType.SHOW, { ...defaultConfig, ...options }),
  hide: () => action(toastActionType.HIDE),
}
