import { action } from 'typesafe-actions'
import {
  StationWithItemRanks,
  StationWithItemRanksParam,
  UpdateStationWithItemRanksBody,
} from './station-with-item-ranks'

export enum stationWithItemRanksActionType {
  FETCH_STATION_WITH_ITEM_RANKS = 'FETCH_STATION_WITH_ITEM_RANKS',
  FETCH_STATION_WITH_ITEM_RANKS_SUCCEEDED = 'FETCH_STATION_WITH_ITEM_RANKS_SUCCEEDED',
  UPDATE_STATION_WITH_ITEM_RANKS = 'UPDATE_STATION_WITH_ITEM_RANKS',
  THROW_ERROR = 'THROW_ERROR',
}

export const stationWithItemRanksActions = {
  fetchStationWithItemRanks: (param: StationWithItemRanksParam) =>
    action(stationWithItemRanksActionType.FETCH_STATION_WITH_ITEM_RANKS, {
      param,
    }),
  fetchStationWithItemRanksSucceeded: (
    stationWithItemRanks: StationWithItemRanks[]
  ) =>
    action(
      stationWithItemRanksActionType.FETCH_STATION_WITH_ITEM_RANKS_SUCCEEDED,
      {
        stationWithItemRanks,
      }
    ),
  updateStationWithItemRanks: (body: UpdateStationWithItemRanksBody) =>
    action(stationWithItemRanksActionType.UPDATE_STATION_WITH_ITEM_RANKS, {
      body,
    }),
  throwError: (error: Error) =>
    action(stationWithItemRanksActionType.THROW_ERROR, { error }),
}
