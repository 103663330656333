import React, { useState } from 'react'

import Input from '../input'
import styles from '../../styles/modules/modal/modal.module.scss'
import { strings } from './modal-content'
import { Campaign, CampaignDurationUnit } from '../../../core/campaigns'
import DurationSelect from '../duration-select'
import Button from '../button'
import { Config } from '../../../core/toast'

interface Props {
  campaignId: number
  orderCampaign: Campaign
  isLoading: boolean
  setOrderCampaign: (campaignId: number, campaign: Campaign) => void
  show: (config: Config) => void
}

const EditOrderCampaignForm: React.FC<Props> = ({
  campaignId,
  orderCampaign,
  isLoading,
  setOrderCampaign,
  show,
}) => {
  const [formData, setFormData] = useState<Campaign>({
    ...orderCampaign,
  })

  const handleOnClickSubmit = () => {
    const { discountAmount, duration } = formData
    if (discountAmount <= 0 || duration <= 0) {
      const config: Config = {
        type: 'error',
        title: strings.toastError.title,
        body: strings.toastError.body,
      }
      show(config)
      return
    }
    setOrderCampaign(campaignId, formData)
  }

  return (
    <main className={styles.main}>
      <Input
        label={strings.campaign.discountAmount}
        type={'number'}
        onChange={event => {
          setFormData({
            ...formData,
            discountAmount: parseInt(event.target.value, 10) || 0,
          })
        }}
        value={formData.discountAmount ? formData.discountAmount : ''}
      />
      <DurationSelect
        label={strings.campaign.duration}
        durationValue={formData.duration ? formData.duration : ''}
        durationUnitValue={formData.durationUnit}
        onChangeDuration={event => {
          setFormData({
            ...formData,
            duration: parseInt(event.target.value, 10) || 0,
          })
        }}
        onChangeDurationUnit={event => {
          setFormData({
            ...formData,
            durationUnit: event.target.value as CampaignDurationUnit,
          })
        }}
      />
      <Button
        title={strings.campaign.updateButton}
        onClick={() => handleOnClickSubmit()}
        className={styles.decide}
        isLoading={isLoading}
      />
    </main>
  )
}

export default EditOrderCampaignForm
