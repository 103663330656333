import React from 'react'

import { SidebarHeaderContent } from './sidebar-content'
import styles from '../../styles/modules/sidebar/sidebar-header.module.scss'

interface Props {
  content: SidebarHeaderContent
}

const SidebarHeader: React.FC<Props> = props => (
  <header className={styles.container}>
    <h3 className={styles.company}>{props.content.company}</h3>
    <h4 className={styles.service}>{props.content.service}</h4>
  </header>
)

export default SidebarHeader
