import { ActionType } from 'typesafe-actions'

import { campaignsActionType, campaignsActions } from './actions'
import { Campaign } from './campaigns'

export interface CampaignsState {
  readonly error: Error
  readonly isLoading: boolean
  readonly promotionCampaigns: Campaign[]
  readonly starterCampaigns: Campaign[]
  readonly stampCampaigns: Campaign[]
  readonly orderCampaigns: Campaign[]
  readonly active: 'campaigns' | 'campaign' | 'none'
}

export const initialState: CampaignsState = {
  error: null,
  isLoading: false,
  promotionCampaigns: [],
  starterCampaigns: [],
  stampCampaigns: [],
  orderCampaigns: [],
  active: 'none',
}

export const campaignsReducer = (
  state: CampaignsState = initialState,
  action: ActionType<typeof campaignsActions>
) => {
  switch (action.type) {
    case campaignsActionType.FETCH_PROMOTION_CAMPAIGNS:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case campaignsActionType.FETCH_PROMOTION_CAMPAIGNS_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        error: null,
        promotionCampaigns: action.payload.promotionCampaigns,
      }
    case campaignsActionType.FETCH_STARTER_CAMPAIGNS:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case campaignsActionType.FETCH_STARTER_CAMPAIGNS_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        error: null,
        starterCampaigns: action.payload.starterCampaigns,
      }
    case campaignsActionType.FETCH_STAMP_CAMPAIGNS:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case campaignsActionType.FETCH_STAMP_CAMPAIGNS_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        error: null,
        stampCampaigns: action.payload.stampCampaigns,
      }
    case campaignsActionType.FETCH_ORDER_CAMPAIGNS:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case campaignsActionType.FETCH_ORDER_CAMPAIGNS_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        error: null,
        orderCampaigns: action.payload.orderCampaigns,
      }
    case campaignsActionType.CREATE_PROMOTION_CAMPAIGN:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case campaignsActionType.SET_PROMOTION_CAMPAIGN:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case campaignsActionType.SET_PROMOTION_CAMPAIGN_EXPIRES_AT:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case campaignsActionType.SET_STARTER_CAMPAIGN:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case campaignsActionType.SET_STAMP_CAMPAIGN:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case campaignsActionType.SET_ORDER_CAMPAIGN:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case campaignsActionType.THROW_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      }
    default:
      return state
  }
}
