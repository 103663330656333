import { ActionType } from 'typesafe-actions'
import { pointItemOrdersActionType, pointItemOrdersActions } from './actions'
import { PointItemOrder } from './point-item-orders'

export interface PointItemOrdersState {
  readonly pointItemOrders: PointItemOrder[]
  readonly error: Error
  readonly isLoading: boolean
}

export const initialState: PointItemOrdersState = {
  pointItemOrders: [],
  error: null,
  isLoading: false,
}

export const pointItemOrdersReducer = (
  state: PointItemOrdersState = initialState,
  action: ActionType<typeof pointItemOrdersActions>
) => {
  switch (action.type) {
    case pointItemOrdersActionType.FETCH_POINT_ITEM_ORDERS:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case pointItemOrdersActionType.FETCH_POINT_ITEM_ORDERS_SUCCEEDED:
      return {
        ...state,
        pointItemOrders: action.payload.pointItemOrders,
        isLoading: false,
        error: null,
      }
    case pointItemOrdersActionType.COMPLETE_POINT_ITEM_ORDER:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case pointItemOrdersActionType.CANCEL_POINT_ITEM_ORDER:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case pointItemOrdersActionType.RETURN_POINT_ITEM_ORDER:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case pointItemOrdersActionType.THROW_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      }
    default:
      return state
  }
}
