import React from 'react'
import classnames from 'classnames'

import { SoracomSIM } from '../../../core/soracom-sims'
import { strings, soracomSIMsTableContent } from './soracom-sims-content'
import Button from '../button'

import styles from '../../styles/modules/table.module.scss'
import soracomSIMsStyles from '../../styles/modules/soracom-sims/soracom-sims.module.scss'

interface Props {
  soracomSIMs: SoracomSIM[]
  showEditSoracomSIM: (soracomSIM: SoracomSIM) => void
}

const SoracomSIMsTable: React.FC<Props> = (props: Props) => {
  const handleOnClickEdit = (soracomSIMId: number) => {
    const soracomSIM = props.soracomSIMs.find(i => i.id === Number(soracomSIMId))
    props.showEditSoracomSIM(soracomSIM)
  }

  return (
    <>
      <h3 className={styles.title}>{strings.soracomSIMsTableTitle}</h3>
      <div
        className={classnames({
          [styles.wrapper]: true,
          [styles.zebra]: true,
          [styles.stocker]: true
        })}
      >
        <table className={styles.container}>
          <tbody>
            <tr className={styles.row} >
              {soracomSIMsTableContent.map(item => (
                <th key={item} className={styles.cell}>
                  {item}
                </th>
              ))}
            </tr>
            {props.soracomSIMs
              .sort((a, b) => {
                if (a.id < b.id) {
                  return -1
                } else {
                  return 1
                }
              })
              .map(soracomSIM => (
            <tr className={styles.row} key={soracomSIM.id}>
              <td className={styles.cell}>{soracomSIM.id}</td>
              <td className={styles.cell}>{soracomSIM.imsi}</td>
              <td className={styles.cell}>{soracomSIM.serial}</td>
              <td className={styles.cell}>{soracomSIM.machineID}</td>
              <td className={styles.cell}>
                <Button
                    title={strings.editSoracomSIMButton}
                    onClick={() => { handleOnClickEdit(soracomSIM.id) }}
                    className={soracomSIMsStyles.soracomSIMEditButton}
                  />
              </td>
            </tr>
          ))}
          </tbody>
        </table>
      </div>
    </>
  )
}

export default SoracomSIMsTable
