export const strings = {
  title: 'グッズ発送管理',
}

export const pointItemSupportLogsTableContent = [
  'ID',
  'グッズID',
  'グッズ名',
  'ユーザーID',
  '発送状態',
  '発送状態切替',
  '発送完了日時',
  '郵便番号',
  '都道府県',
  '市町村',
  '配送先住所',
  '宛名',
  '電話番号',
  'キャンセル',
]
