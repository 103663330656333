import React, { useState } from 'react'
import DatePicker from 'react-datepicker'

import Button from '../button'
import Input from '../input'
import styles from '../../styles/modules/modal/modal.module.scss'
import { strings } from './modal-content'
import { Config } from '../../../core/toast'
import { Machine } from '../../../core/machines'
import 'react-datepicker/dist/react-datepicker.css'
import '../../styles/modules/react-datepicker.scss'

interface Props {
  isLoading: boolean
  machine: Machine
  setMachine: (
    machineId: number,
    serial: string,
    manufacturedDate: Date,
    type: string,
    version: string,
    factoryName: string,
    shouldBeLatest: boolean
  ) => void
  show: (config: Config) => void
}

export const EditMachineForm: React.FC<Props> = (props: Props) => {
  const [serial, setSerial] = useState<string>(props.machine.serial)
  const [manufacturedDate, setManufacturedDate] = useState<Date>(
    props.machine.manufacturedDate
      ? new Date(props.machine.manufacturedDate)
      : null
  )
  const [type, setType] = useState<string>(props.machine.type)
  const [version, setVersion] = useState<string>(props.machine.version)
  const [factoryName, setFactoryName] = useState<string>(
    props.machine.factoryName
  )

  const handleOnClickSubmit = () => {
    if (!serial || !version) {
      const config: Config = {
        type: 'error',
        title: strings.toastError.title,
        body: strings.toastError.body,
      }
      props.show(config)
      return
    }
    props.setMachine(
      props.machine.id,
      serial,
      manufacturedDate,
      type,
      version,
      factoryName,
      props.machine.shouldBeLatest
    )
  }

  return (
    <main className={styles.main}>
      <Input
        label={strings.machine.serial}
        type={'text'}
        onChange={event => {
          setSerial(event.target.value)
        }}
        value={serial}
      />
      <div className={styles.toPickerWrapper}>
        {strings.machine.manufacturedDate}
      </div>
      <DatePicker
        className={styles.manufacturedForm}
        onChange={event => {
          setManufacturedDate(event)
        }}
        selected={manufacturedDate ? new Date(manufacturedDate) : null}
        dateFormat={'yyyy/MM/dd'}
      />
      <Input
        label={strings.machine.type}
        type={'text'}
        onChange={event => {
          setType(event.target.value)
        }}
        value={type || ''}
      />
      <Input
        label={strings.machine.version}
        type={'text'}
        onChange={event => {
          setVersion(event.target.value)
        }}
        value={version || ''}
      />
      <Input
        label={strings.machine.factoryName}
        type={'text'}
        onChange={event => {
          setFactoryName(event.target.value)
        }}
        value={factoryName || ''}
      />
      <Button
        title={strings.machine.updateButton}
        onClick={handleOnClickSubmit}
        className={styles.report}
        isLoading={props.isLoading}
        loadingClassName={styles.loadingReport}
      />
    </main>
  )
}
