import { ActionType } from 'typesafe-actions'

import {
  stationWithItemRanksActionType,
  stationWithItemRanksActions,
} from './actions'
import { StationWithItemRanks } from './station-with-item-ranks'

export interface StationWithItemRanksState {
  readonly error: Error
  readonly isLoading: boolean
  readonly stationWithItemRanks: StationWithItemRanks[]
  readonly active: 'stationWithItemRanks' | 'none'
}

export const initialState: StationWithItemRanksState = {
  error: null,
  isLoading: false,
  stationWithItemRanks: [],
  active: 'none',
}

export const stationWithItemRanksReducer = (
  state: StationWithItemRanksState = initialState,
  action: ActionType<typeof stationWithItemRanksActions>
) => {
  switch (action.type) {
    case stationWithItemRanksActionType.FETCH_STATION_WITH_ITEM_RANKS:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case stationWithItemRanksActionType.FETCH_STATION_WITH_ITEM_RANKS_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        error: null,
        stationWithItemRanks: action.payload.stationWithItemRanks,
      }
    case stationWithItemRanksActionType.UPDATE_STATION_WITH_ITEM_RANKS:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case stationWithItemRanksActionType.THROW_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      }
    default:
      return state
  }
}
