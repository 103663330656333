export const strings = {
  title: 'マシン紐付け',
  machineRelationHistoryTitle: 'マシン紐付け履歴',
  editMachineRelationButton: '編集',
  deleteMachineRelationButton: '解除',
  noData: '-----',
  queryButton: '絞り込む',
  queryPlaceHolder: 'すべて',
  queryStationTitle: 'ステーションで絞り込む',
  queryMachineTitle: 'マシンで絞り込む',
  querySimTitle: 'SIM IDで絞り込む',
}

export const machineRelationsTableContent = [
  'マシン ID',
  'マシンシリアル',
  'ステーション名',
  'SIM ID',
  '編集',
  '解除',
]

export const machineRelationHistoriesTableContent = [
  'マシン ID',
  'マシンシリアル',
  'ステーション名',
  'SIM ID',
  'SIM 製造番号',
  '紐付け開始日',
  '紐付け解除日',
]
