import React from 'react'
import moment from 'moment'
import classnames from 'classnames'
import { Link } from 'react-router-dom'

import { strings } from './users-content'
import { User } from '../../../core/users'
import { userInfoTableContent } from './users-content'
import styles from '../../styles/modules/table.module.scss'
import headerStyles from '../../styles/modules/users/users.module.scss'
import Button from '../button'

interface Props {
  user: User
  deleteUser: (userId: number) => void
}

const deleteUserSentence = (userId: number) =>
`
ユーザーID: ${userId} の退会処理を実行します。よろしいですか？
操作を確定する場合はテキストフィールドに「${strings.deleteConfirmWord}」と入力してください。

※注意
この操作は取り消すことができません。
`

const UserInfoTable: React.FC<Props> = props => {

  const handleOnClickDelete = (user: User) => {
    const confirmDeleteUserSentence = deleteUserSentence(user.user.id)
    const input = window.prompt(confirmDeleteUserSentence)
    if (input !== null) {
      if (input === strings.deleteConfirmWord) {
        props.deleteUser(user.user.id)
      } else {
        handleOnClickDelete(user)
      }
    }
  }

  return (
    <>
      <h3 className={styles.title}>{strings.userTableTitle}</h3>
      <Button
        title={props.user.user.deletedAt ? strings.disabledDeleteUseButton : strings.deleteUserButton}
        disabled={!!props.user.user.deletedAt}
        onClick={() => handleOnClickDelete(props.user)}
        className={headerStyles.deleteUserButton}
      />
      <div
        className={classnames({
          [styles.wrapper]: true,
          [styles.stocker]: true,
        })}
      >
        <table className={styles.container}>
          <tbody>
            <tr className={styles.rowStatic}>
              <td className={styles.cell}>{userInfoTableContent.id}</td>
              <td className={styles.cell}>{props.user.user.id}</td>
            </tr>
            <tr className={styles.rowStatic}>
              <td className={styles.cell}>{userInfoTableContent.name}</td>
              <td className={styles.cell}>{props.user.user.name}</td>
            </tr>
            <tr className={styles.rowStatic}>
              <td className={styles.cell}>{userInfoTableContent.FirebaseUID}</td>
              <td className={styles.cell}>
                {props.user.user.firebaseUID}
              </td>
            </tr>
            <tr className={styles.rowStatic}>
              <td className={styles.cell}>{userInfoTableContent.email}</td>
              <td className={styles.cell}>
                {props.user.firebaseUser ? props.user.firebaseUser.email : null}
              </td>
            </tr>
            <tr className={styles.rowStatic}>
              <td className={styles.cell}>{userInfoTableContent.creation}</td>
              <td className={styles.cell}>
                {moment(props.user.user.registeredAt).format('YYYY/MM/DD/ HH:mm:ss')}
              </td>
            </tr>
            <tr className={styles.rowStatic}>
              <td className={styles.cell}>{userInfoTableContent.lastLogIn}</td>
              <td className={styles.cell}>
                {props.user.firebaseUser
                  ? moment(props.user.firebaseUser.lastLogInTimestamp).format(
                      'YYYY/MM/DD/ HH:mm:ss'
                    )
                  : null}
              </td>
            </tr>
            <tr className={styles.rowStatic}>
              <td className={styles.cell}>{userInfoTableContent.payJpID}</td>
              <td className={styles.cell}>
                {props.user.user.hasCard ? (
                  <Link
                    to={{
                      pathname: `https://pay.jp/d/customers/${props.user.user.id}`,
                    }}
                    target="_blank"
                  >
                    {props.user.user.id}
                  </Link>
                ) : null}
              </td>
            </tr>
            <tr className={styles.rowStatic}>
              <td className={styles.cell}>{userInfoTableContent.deleted}</td>
              <td className={styles.cell}>
                {props.user.user.deletedAt
                  ? moment(props.user.user.deletedAt).format(
                      'YYYY/MM/DD/ HH:mm:ss'
                    )
                  : null}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  )
}

export default UserInfoTable
