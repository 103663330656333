import { put, call, takeLatest, fork, select } from 'redux-saga/effects'
import RootState from '../root-state'

import { salesDataActionType, salesDataActions } from './actions'
import { getDailySalesData, getMonthlySalesData } from '../api'

export function* fetchMonthlySalesData(
  action: ReturnType<typeof salesDataActions.fetchMonthlySalesData>
) {
  try {
    const state: RootState = yield select()
    const token = state.auth0.auth.idToken
    const response = yield call(getMonthlySalesData, token)
    yield put(
      salesDataActions.fetchMonthlySalesDataSucceeded(response.salesData)
    )
  } catch (error) {
    yield put(salesDataActions.throwError(error))
  }
}

export function* fetchDailySalesData(
  action: ReturnType<typeof salesDataActions.fetchDailySalesData>
) {
  try {
    const state: RootState = yield select()
    const token = state.auth0.auth.idToken
    const response = yield call(getDailySalesData, token)
    yield put(salesDataActions.fetchDailySalesDataSucceeded(response.salesData))
  } catch (error) {
    yield put(salesDataActions.throwError(error))
  }
}

export function* watchFetchMonthlySalesData() {
  yield takeLatest(
    salesDataActionType.FETCH_MONTHLY_SALES_DATA,
    fetchMonthlySalesData
  )
}

export function* watchFetchDailySalesData() {
  yield takeLatest(
    salesDataActionType.FETCH_DAILY_SALES_DATA,
    fetchDailySalesData
  )
}

export const salesDataSagas = [
  fork(watchFetchMonthlySalesData),
  fork(watchFetchDailySalesData),
]
