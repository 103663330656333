import { put, call, takeLatest, fork, select, delay } from 'redux-saga/effects'

import RootState from '../root-state'
import { kioskItemPricesActionType, kioskItemPricesActions } from './actions'
import { getKioskItemPrices, putKioskItemPrice } from '../api'
import { toastActions } from '../toast'
import { modalActions } from '../modal'

export function* fetchKioskItemPrices(
  action: ReturnType<typeof kioskItemPricesActions.fetchKioskItemPrices>
) {
  try {
    const state: RootState = yield select()
    const token = state.auth0.auth.idToken
    const response = yield call(
      getKioskItemPrices,
      token,
      action.payload.stationId
    )
    yield put(
      kioskItemPricesActions.fetchKioskItemPricesSucceeded(
        response.hotItems,
        response.iceItems
      )
    )
  } catch (error) {
    yield put(kioskItemPricesActions.throwError(error))
  }
}

export function* updateKioskItemPrice(
  action: ReturnType<typeof kioskItemPricesActions.updateKioskItemPrice>
) {
  try {
    const state: RootState = yield select()
    const token = state.auth0.auth.idToken
    yield call(
      putKioskItemPrice,
      token,
      action.payload.kioskItemPrice.id,
      action.payload.price
    )
    yield delay(100)
    yield put(
      kioskItemPricesActions.fetchKioskItemPrices(
        action.payload.kioskItemPrice.stationID
      )
    )
    yield put(
      toastActions.show({
        title: '成功！',
        body: 'KIOSK商品価格を更新しました',
      })
    )
    yield put(modalActions.hide())
  } catch (error) {
    yield put(kioskItemPricesActions.throwError(error))
    yield put(
      toastActions.show({
        title: '失敗！',
        body: 'KIOSK商品価格の更新に失敗しました',
      })
    )
  }
}

export function* watchFetchKioskItemPrices() {
  yield takeLatest(
    kioskItemPricesActionType.FETCH_KIOSK_ITEM_PRICES,
    fetchKioskItemPrices
  )
}

export function* watchUpdateKioskItemPrice() {
  yield takeLatest(
    kioskItemPricesActionType.UPDATE_KIOSK_ITEM_PRICE,
    updateKioskItemPrice
  )
}

export const kioskItemPricesSagas = [
  fork(watchFetchKioskItemPrices),
  fork(watchUpdateKioskItemPrice),
]
