export const userInfoTableContent = {
  id: 'ユーザーID',
  name: '名前',
  FirebaseUID: 'FirebaseUID',
  email: 'メールアドレス',
  creation: 'アカウント作成日時',
  lastLogIn: '最終ログイン日時',
  payJpID: 'PAY.JP ID',
  deleted: 'アカウント削除日時',
}

export const subscriptionTableContent = {
  id: 'サブスクリプションID',
  subscribedAt : 'サブスクリプション加入日時',
  cancelledAt : 'サブスクリプション解約日時',
  planName: 'プラン',
}

export const strings = {
  title: 'ユーザー',
  queryIdTitle: 'ユーザーIDで絞り込む',
  queryEmailTitle: 'メールアドレスで絞りこむ',
  queryFirebaseUid: 'FirebaseUIDで絞りこむ',
  queryButton: '絞り込む',
  userTableTitle: 'ユーザー情報',
  subscriptionTableTitle: 'サブスクリプション情報',
  userOrderTitle: 'オーダー一覧',
  userNotFound: 'ユーザーが見つかりません',
  activeSubscriptionNotFound: '有効なサブスクリプションはありません',
  linkToSubscriptionsButton: 'ユーザのサブスクリプション履歴を見る',
  deleteUserButton: '退会',
  disabledDeleteUseButton: '退会済み',
  deleteConfirmWord: '退会',
}
