import React from 'react'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { Route, Switch, Redirect } from 'react-router'
import { ConnectedRouter } from 'connected-react-router'

import configureStore, { history } from './core/store'
import Auth from './view/layouts/auth'
import AuthRoute from './view/layouts/auth-route'
import Toast from './view/components/toast'
import Modal from './view/components/modal/modal'
import OrdersScreen from './view/screen/orders-screen'

import UsersScreen from './view/screen/users-screen'
import StationsScreen from './view/screen/stations-screen'
import EachStationScreen from './view/screen/each-station-screen'
import ItemsScreen from './view/screen/items-screen'
import EachItemScreen from './view/screen/each-item-screen'
import NewItemScreen from './view/screen/new-item-screen'
import StationWithItemRankScreen from './view/screen/station-with-item-ranks-screen'
import PromotionCampaignsScreen from './view/screen/promotion-campaigns-screen'
import AutoEntryCampaignScreen from './view/screen/auto-entry-campaigns-screen'
import EmergenciesScreen from './view/screen/emergencies-screen'
import PromotionImagesScreen from './view/screen/promotion-images-screen'
import PointsScreen from './view/screen/points-screen'
import StationAlertsScreen from './view/screen/station-alerts-screen'
import LoginScreen from './view/screen/login-screen'
import CallbackScreen from './view/screen/callback-screen'
import NotFoundScreen from './view/screen/404-screen'
import MachinesScreen from './view/screen/machines-screen'
import SubscriptionsScreen from './view/screen/subscriptions-screen'
import EachSubscriptionScreen from './view/screen/each-subscription-screen'
import MachineRelationsScreen from './view/screen/machine-relations-screen'
import KioskRelationsScreen from './view/screen/kiosk-relations-screen'
import { roleType } from './core/auth0'
import SoracomSimsScreen from './view/screen/soracom-sims-screen'
import MachineEmbeddedComputersScreen from './view/screen/machine-embedded-computers-screen'
import MachineErrorsScreen from './view/screen/machine-errors-screen'
import SignageBannerScreen from './view/screen/signage-banner-screen'
import SalesDataScreen from './view/screen/sales-data-screen'
import CameraStreamingViewerScreen from './view/screen/camera-streaming-viewer-screen'
import VncLinkScreen from './view/screen/vnc-link-screen'
import KiosksScreen from './view/screen/kiosks-screen'
import KioskItemPricesScreen from './view/screen/kiosk-item-prices-screen'
import EachKioskItemPricesScreen from './view/screen/each-kiosk-item-prices-screen'
import PointItemsScreen from './view/screen/point-items-screen'
import PointItemSupportLogsScreen from './view/screen/point-item-support-logs-screen'
import PointItemOrdersScreen from './view/screen/point-item-orders-screen'
const { store, persistor } = configureStore()

const RootStack = () => (
  <ConnectedRouter history={history}>
    <div>
      <Switch>
        <Route exact path="/login" render={() => <LoginScreen />} />
        <Route exact path="/callback" render={() => <CallbackScreen />} />

        <Auth>
          <Switch>
            <Route
              exact
              path="/"
              render={() => <Redirect to={'/sales_data'} />}
            />
            <Route exact path="/orders" render={() => <OrdersScreen />} />
            <Route
              exact
              path="/users/:id?"
              render={props => (
                <UsersScreen
                  match={props.match}
                  history={props.history}
                  location={props.location}
                />
              )}
            />
            <AuthRoute
              exact
              path="/sales_data"
              render={() => <SalesDataScreen />}
              allowedRoles={[roleType.ADMIN]}
            />
            <AuthRoute
              exact
              path="/stations"
              render={() => <StationsScreen />}
              allowedRoles={[roleType.ADMIN, roleType.OPERATOR]}
            />
            <AuthRoute
              exact
              path="/stations/:id"
              render={({ match }) => <EachStationScreen match={match} />}
              allowedRoles={[roleType.ADMIN, roleType.OPERATOR]}
            />
            <AuthRoute
              exact
              path="/subscriptions"
              render={() => <SubscriptionsScreen />}
              allowedRoles={[roleType.ADMIN, roleType.OPERATOR]}
            />
            <AuthRoute
              exact
              path="/subscriptions/:id"
              render={({ match }) => <EachSubscriptionScreen match={match} />}
              allowedRoles={[roleType.ADMIN, roleType.OPERATOR]}
            />
            <AuthRoute
              exact
              path="/machines"
              render={() => <MachinesScreen />}
              allowedRoles={[roleType.ADMIN, roleType.OPERATOR]}
            />
            <AuthRoute
              exact
              path="/kiosks"
              render={() => <KiosksScreen />}
              allowedRoles={[roleType.ADMIN, roleType.OPERATOR]}
            />
            <AuthRoute
              exact
              path="/soracom_sims"
              render={() => <SoracomSimsScreen />}
              allowedRoles={[roleType.ADMIN, roleType.OPERATOR]}
            />
            <AuthRoute
              exact
              path="/machine_embedded_computers"
              render={() => <MachineEmbeddedComputersScreen />}
              allowedRoles={[roleType.ADMIN, roleType.OPERATOR]}
            />
            <AuthRoute
              exact
              path="/machine_relations"
              render={() => <MachineRelationsScreen />}
              allowedRoles={[roleType.ADMIN, roleType.OPERATOR]}
            />
            <AuthRoute
              exact
              path="/kiosk_relations"
              render={() => <KioskRelationsScreen />}
              allowedRoles={[roleType.ADMIN, roleType.OPERATOR]}
            />
            <AuthRoute
              exact
              path="/items"
              render={() => <ItemsScreen />}
              allowedRoles={[roleType.ADMIN]}
            />
            <AuthRoute
              exact
              path="/items/new"
              render={() => <NewItemScreen />}
              allowedRoles={[roleType.ADMIN]}
            />
            <AuthRoute
              exact
              path="/station_with_item_ranks"
              render={() => <StationWithItemRankScreen />}
              allowedRoles={[roleType.ADMIN]}
            />
            <AuthRoute
              exact
              path="/kiosk_item_prices"
              render={() => <KioskItemPricesScreen />}
              allowedRoles={[roleType.ADMIN]}
            />
            <AuthRoute
              exact
              path="/kiosk_item_prices/:station_id?"
              render={props => (
                <EachKioskItemPricesScreen
                  location={props.location}
                  match={props.match}
                />
              )}
              allowedRoles={[roleType.ADMIN]}
            />
            <AuthRoute
              exact
              path="/items/:id"
              render={({ match }) => <EachItemScreen match={match} />}
              allowedRoles={[roleType.ADMIN]}
            />
            <AuthRoute
              exact
              path="/point_items"
              render={() => <PointItemsScreen />}
              allowedRoles={[roleType.ADMIN]}
            />
            <AuthRoute
              exact
              path="/point_item_support_logs"
              render={() => <PointItemSupportLogsScreen />}
              allowedRoles={[roleType.ADMIN]}
            />
            <AuthRoute
              exact
              path="/point_item_orders"
              render={() => <PointItemOrdersScreen />}
              allowedRoles={[roleType.ADMIN]}
            />
            <AuthRoute
              exact
              path="/promotion_campaigns"
              render={() => <PromotionCampaignsScreen />}
              allowedRoles={[roleType.ADMIN]}
            />
            <AuthRoute
              exact
              path="/auto_entry_campaigns"
              render={() => <AutoEntryCampaignScreen />}
              allowedRoles={[roleType.ADMIN]}
            />
            <AuthRoute
              exact
              path="/emergencies"
              render={() => <EmergenciesScreen />}
              allowedRoles={[roleType.ADMIN, roleType.OPERATOR]}
            />
            <AuthRoute
              exact
              path="/machine_errors"
              render={() => <MachineErrorsScreen />}
              allowedRoles={[roleType.ADMIN, roleType.OPERATOR]}
            />
            <AuthRoute
              exact
              path="/promotion_images"
              render={() => <PromotionImagesScreen />}
              allowedRoles={[roleType.ADMIN]}
            />
            <AuthRoute
              exact
              path="/points"
              render={() => <PointsScreen />}
              allowedRoles={[roleType.ADMIN]}
            />
            <AuthRoute
              exact
              path="/station_alerts"
              render={() => <StationAlertsScreen />}
              allowedRoles={[roleType.ADMIN]}
            />
            <AuthRoute
              exact
              path="/signage_banner"
              render={() => <SignageBannerScreen />}
              allowedRoles={[roleType.ADMIN]}
            />
            <AuthRoute
              exact
              path="/streaming_viewer"
              render={() => <CameraStreamingViewerScreen />}
              allowedRoles={[roleType.ADMIN]}
            />
            <AuthRoute
              exact
              path="/vnc_link"
              render={() => <VncLinkScreen />}
              allowedRoles={[roleType.ADMIN]}
            />
            <Route render={() => <NotFoundScreen />} />
          </Switch>
        </Auth>
      </Switch>
    </div>
  </ConnectedRouter>
)

export default class App extends React.Component {
  render() {
    return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Toast />
          <Modal />
          <RootStack />
        </PersistGate>
      </Provider>
    )
  }
}
