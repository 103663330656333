import { CoffeeLog } from '../stations'

export interface Order {
  id: number
  hashid: string
  item: {
    id: number
    name: string
  }
  station: {
    id: number
    name: string
  }
  machine: {
    id: number
    serial: string
  }
  place: {
    id: number
    name: string
  }
  userID: number
  kioskID: number
  status: string
  paidAmount: number
  pointDiscountAmount: number
  campaignDiscountAmount: number
  oneMoreCoffeeDiscountAmount: number
  orderedAt: string
  timeSlotFrom: string
  coffeeLog?: CoffeeLog
  paymentMethod: OrderPaymentMethod
  isEmergencyRelated?: boolean
  provisionStatus: OrderProvisionStatus
  kioskPurchaserImageURL: string
}

export type OrderPaymentMethod =
  | OrderPaymentMethodDefaultCard
  | OrderPaymentMethodApplePay
  | OrderPaymentMethodSubscription
  | OrderPaymentMethodPoint
  | OrderPaymentMethodLinePay
  | OrderPaymentMethodPayPay
  | OrderPaymentMethodAuPay
  | OrderPaymentMethodSalo01Credit
  | OrderPaymentMethodSalo01QR
  | OrderPaymentMethodSalo01EMoney
type OrderPaymentMethodDefaultCard = 'default_card'
type OrderPaymentMethodApplePay = 'apple_pay'
type OrderPaymentMethodSubscription = 'subscription'
type OrderPaymentMethodPoint = 'point'
type OrderPaymentMethodLinePay = 'line_pay'
type OrderPaymentMethodPayPay = 'paypay'
type OrderPaymentMethodAuPay = 'au_pay'
type OrderPaymentMethodSalo01Credit = 'salo01_credit'
type OrderPaymentMethodSalo01QR = 'salo01_qr'
type OrderPaymentMethodSalo01EMoney = 'salo01_e_money'

// enumでOrderProvisionStatusをexportする
export enum OrderProvisionStatus {
  willStartMaking = '調理開始待',
  disposedAutomatically = 'オート廃棄済',
  isMaking = '調理中',
  providedSuccessfully = '提供完了',
  willBePickedUp = '取出待',
  doorWillOpen = 'ドア解錠待',
  willDispose = 'オート廃棄予定',
}

export interface OrdersParam {
  count: number
  user_id?: number
  email?: string
  station_id?: number
  provision_status?: OrderProvisionStatus
  date_from: string
  date_to: string
}
