import { ActionType } from 'typesafe-actions'

import { emergenciesActionType, emergenciesActions } from './actions'
import { Emergency } from './emergencies'

export interface EmergenciesState {
  readonly error: Error
  readonly isLoading: boolean
  readonly emergencies: Emergency[]
  readonly emergency: Emergency
}

export const initialState: EmergenciesState = {
  error: null,
  isLoading: false,
  emergencies: [],
  emergency: null,
}

export const emergenciesReducer = (
  state: EmergenciesState = initialState,
  action: ActionType<typeof emergenciesActions>
) => {
  switch (action.type) {
    case emergenciesActionType.FETCH_EMERGENCIES:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case emergenciesActionType.FETCH_EMERGENCIES_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        error: null,
        emergencies: action.payload.emergencies,
      }
     case emergenciesActionType.THROW_ERROR:
        return {
          ...state,
          isLoading: false,
          error: action.payload.error,
        }
    default:
      return state
  }
}
