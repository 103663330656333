import React, { ReactNode } from 'react'
import ReactTooltip from 'react-tooltip'

interface TooltipProps {
  id: string
  triggerNode: ReactNode
  contentNode: ReactNode
  place?: 'top' | 'right' | 'bottom' | 'left'
  type?: 'dark' | 'success' | 'warning' | 'error' | 'info' | 'light'
  effect?: 'float' | 'solid'
}

const Tooltip: React.FC<TooltipProps> = ({
  id,
  triggerNode,
  contentNode,
  place = 'top',
  type = 'dark',
  effect = 'float',
}) => (
  <>
    <div data-tip data-for={id}>
      {triggerNode}
    </div>
    <ReactTooltip id={id} place={place} type={type} effect={effect}>
      {contentNode}
    </ReactTooltip>
  </>
)

export default Tooltip
