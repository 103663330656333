import React from 'react'

import { TimeSlot } from '../../../core/stations'
import { strings } from './each-station-content'
import styles from '../../styles/modules/each-station/day-of-week-time-slot-preset.module.scss'
import Button from '../button'
import { TimeSlotPreset } from '../../../core/time-slot-presets'

interface Props {
  timeSlots: TimeSlot[]
  timeSlotPresets: TimeSlotPreset[]
  showEditTimeSlotPresets: (
    timeSlots: TimeSlot[],
    timeSlotPresets: TimeSlotPreset[]
  ) => void
}

const DayOfWeekTimeSlotPreset: React.FC<Props> = ({
  timeSlots,
  timeSlotPresets,
  showEditTimeSlotPresets,
}: Props) => {
  return (
    <>
      <div className={styles.description}>
        ・当日のスロットは、24時を超えると、その曜日に対応したプリセットから上書きされます
        <br />
        ・祝日は、日付指定のプリセットを編集してください
      </div>
      <Button
        title={strings.editTimeSlotPresetsMonday}
        onClick={() =>
          showEditTimeSlotPresets(
            timeSlots,
            timeSlotPresets.filter(
              timeSlotPreset => timeSlotPreset.dayOfWeek === 'monday'
            )
          )
        }
        className={styles.timeSlotButton}
      />
      <Button
        title={strings.editTimeSlotPresetsTuesday}
        onClick={() =>
          showEditTimeSlotPresets(
            timeSlots,
            timeSlotPresets.filter(
              timeSlotPreset => timeSlotPreset.dayOfWeek === 'tuesday'
            )
          )
        }
        className={styles.timeSlotButton}
      />
      <Button
        title={strings.editTimeSlotPresetsWednesday}
        onClick={() =>
          showEditTimeSlotPresets(
            timeSlots,
            timeSlotPresets.filter(
              timeSlotPreset => timeSlotPreset.dayOfWeek === 'wednesday'
            )
          )
        }
        className={styles.timeSlotButton}
      />
      <Button
        title={strings.editTimeSlotPresetsThursday}
        onClick={() =>
          showEditTimeSlotPresets(
            timeSlots,
            timeSlotPresets.filter(
              timeSlotPreset => timeSlotPreset.dayOfWeek === 'thursday'
            )
          )
        }
        className={styles.timeSlotButton}
      />
      <Button
        title={strings.editTimeSlotPresetsFriday}
        onClick={() =>
          showEditTimeSlotPresets(
            timeSlots,
            timeSlotPresets.filter(
              timeSlotPreset => timeSlotPreset.dayOfWeek === 'friday'
            )
          )
        }
        className={styles.timeSlotButton}
      />
      <Button
        title={strings.editTimeSlotPresetsSaturday}
        onClick={() =>
          showEditTimeSlotPresets(
            timeSlots,
            timeSlotPresets.filter(
              timeSlotPreset => timeSlotPreset.dayOfWeek === 'saturday'
            )
          )
        }
        className={styles.timeSlotButton}
      />
      <Button
        title={strings.editTimeSlotPresetsSunday}
        onClick={() =>
          showEditTimeSlotPresets(
            timeSlots,
            timeSlotPresets.filter(
              timeSlotPreset => timeSlotPreset.dayOfWeek === 'sunday'
            )
          )
        }
        className={styles.timeSlotButton}
      />
    </>
  )
}

export default DayOfWeekTimeSlotPreset
