import { ActionType } from 'typesafe-actions'
import {
  pointItemSupportLogsActionType,
  pointItemSupportLogsActions,
} from './actions'
import { PointItemSupportLog } from './point-item-support-logs'

export interface PointItemSupportLogsState {
  readonly pointItemSupportLogs: PointItemSupportLog[]
  readonly error: Error
  readonly isLoading: boolean
}

export const initialState: PointItemSupportLogsState = {
  pointItemSupportLogs: [],
  error: null,
  isLoading: false,
}

export const pointItemSupportLogsReducer = (
  state: PointItemSupportLogsState = initialState,
  action: ActionType<typeof pointItemSupportLogsActions>
) => {
  switch (action.type) {
    case pointItemSupportLogsActionType.FETCH_POINT_ITEM_SUPPORT_LOGS:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case pointItemSupportLogsActionType.FETCH_POINT_ITEM_SUPPORT_LOGS_SUCCEEDED:
      return {
        ...state,
        pointItemSupportLogs: action.payload.pointItemSupportLogs,
        isLoading: false,
        error: null,
      }
    case pointItemSupportLogsActionType.THROW_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      }
    default:
      return state
  }
}
