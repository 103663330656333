import React from 'react'
import Layout from '../layouts/layout'
import Wrapper from '../layouts/wrapper'
import { strings } from '../components/kiosk-item-prices/kiosk-item-prices-content'
import KioskItemPricesTable from '../components/kiosk-item-prices/kiosk-item-prices-table'

const KioskItemPricesScreen: React.FC = () => {
  return (
    <Layout location="kiosk_item_prices">
      <Wrapper title={strings.title}>
        <KioskItemPricesTable />
      </Wrapper>
    </Layout>
  )
}

export default KioskItemPricesScreen
