import { put, call, takeLatest, fork, select, delay } from 'redux-saga/effects'
import RootState from '../root-state'

import { soracomSIMsActionType, soracomSIMsActions } from './actions'
import { getSoracomSIMs, postSoracomSIM, putSoracomSIM } from '../api'

import { toastActions } from '../toast'
import { modalActions } from '../modal'

export function* fetchSoracomSIMs(
  action: ReturnType<typeof soracomSIMsActions.fetchSoracomSIMs>
){
  try {
    const state: RootState = yield select()
    const token = state.auth0.auth.idToken
    const response = yield call(getSoracomSIMs, token)
    yield put(soracomSIMsActions.fetchSoracomSIMsSucceeded(response.soracomSIMs))
  } catch (error) {
    yield put(soracomSIMsActions.throwError(error))
  }
}

export function* createSoracomSIM(
  action: ReturnType<typeof soracomSIMsActions.createSoracomSIM>
){
  try {
    const state: RootState = yield select()
    const token = state.auth0.auth.idToken
    yield call(
      postSoracomSIM,
      token,
      action.payload.imsi,
      action.payload.serial
    )
    yield delay(100)
    yield put(soracomSIMsActions.fetchSoracomSIMs())
    yield put(
      toastActions.show({
        title: '成功！',
        body: 'soracomSIMを新規作成しました',
      })
    )
    yield put(modalActions.hide())
  }catch(error){
    yield put(soracomSIMsActions.throwError(error))
    yield put(
      toastActions.show({
        type: 'error',
        title: '失敗...',
        body: 'soracomSIMの新規作成に失敗しました',
      })
    )
  }
}

export function* updateSoracomSIM(
  action: ReturnType<typeof soracomSIMsActions.updateSoracomSIM>
){
  try {
    const state: RootState = yield select()
    const token = state.auth0.auth.idToken
    yield call(
      putSoracomSIM,
      token,
      action.payload.soracomSIMId,
      action.payload.imsi,
      action.payload.serial
    )
    yield delay(100)
    yield put(soracomSIMsActions.fetchSoracomSIMs())
    yield put(
      toastActions.show({
        title: '成功！',
        body: 'soracomSIMを更新しました',
      })
    )
    yield put(modalActions.hide())
  }catch(error){
    yield put(soracomSIMsActions.throwError(error))
    yield put(
      toastActions.show({
        type: 'error',
        title: '失敗...',
        body: 'soracomSIMの更新に失敗しました',
      })
    )
  }
}

export function* watchFetchSoracomSIMs() {
  yield takeLatest(soracomSIMsActionType.FETCH_SORACOM_SIMS, fetchSoracomSIMs)
}

export function* watchCreateSoracomSIM() {
  yield takeLatest(soracomSIMsActionType.CREATE_SORACOM_SIM, createSoracomSIM)
}

export function* watchUpdateSoracomSIM() {
  yield takeLatest(soracomSIMsActionType.UPDATE_SORACOM_SIM, updateSoracomSIM)
}

export const soracomSIMsSagas = [
  fork(watchFetchSoracomSIMs),
  fork(watchCreateSoracomSIM),
  fork(watchUpdateSoracomSIM),
]
