import React from 'react'

import styles from '../styles/modules/wrapper.module.scss'

interface Props {
  title: string
  children: React.ReactNode
}

const Wrapper: React.FC<Props> = props => (
  <div className={styles.container}>
    <h2 className={styles.title}>{props.title}</h2>
    <div>{props.children}</div>
  </div>
)

export default Wrapper
