import React from 'react'
import classnames from 'classnames'

import {
  starterCampaignsTableContent,
  getDurationToDisplay,
  strings,
} from './auto-entry-campaign-content'
import { Campaign } from '../../../core/campaigns'
import styles from '../../styles/modules/table.module.scss'
import Button from '../button'

interface Props {
  starterCampaigns: Campaign[]
  onClickSetStarterCampaign: (starterCampaign: Campaign) => void
}

const StarterCampaignsTable: React.FC<Props> = props => (
  <div
    className={classnames({
      [styles.wrapper]: true,
      [styles.zebra]: true,
      [styles.stocker]: true,
    })}
  >
    <table className={classnames(styles.container)}>
      <tbody>
        <tr className={styles.row}>
          {starterCampaignsTableContent.map(item => (
            <th key={item} className={styles.cell}>
              {item}
            </th>
          ))}
        </tr>
        {props.starterCampaigns
          // 営業中のステーションと紐づいているクーポンのみ表示
          .filter(item => item.ownerStation && !item.ownerStation.isClosed)
          // ステーションID順でソート
          .sort((a, b) => {
            if (!a.ownerStation) {
              return 1
            } else if (!b.ownerStation) {
              return -1
            } else {
              return a.ownerStation.id > b.ownerStation.id ? 1 : -1
            }
          })
          .map(item => (
            <tr className={styles.row} key={item.id}>
              <td className={styles.cell}>{item.id}</td>
              <td className={styles.cell}>
                {item.ownerStation
                  ? `ID:${item.ownerStation?.id} ${item.ownerStation?.name}`
                  : ''}
              </td>
              <td className={styles.cell}>{item.code}</td>
              <td className={styles.cell}>{item.name}</td>
              <td className={styles.cell}>{item.discountAmount}</td>
              <td className={styles.cell}>{item.availableCount}</td>
              <td className={styles.cell}>
                {getDurationToDisplay(item.duration, item.durationUnit)}
              </td>
              <td className={styles.buttonCell}>
                <Button
                  title={strings.setAutoEntryCampaignButton}
                  onClick={() => {
                    props.onClickSetStarterCampaign(item)
                  }}
                />
              </td>
            </tr>
          ))}
      </tbody>
    </table>
  </div>
)

export default StarterCampaignsTable
