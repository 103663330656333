import { all } from 'redux-saga/effects'

import { stationsSagas } from './stations'
import { ordersSagas } from './orders'
import { itemsSagas } from './items'
import { stationWithItemRanksSagas } from './station-with-item-ranks'
import { campaignsSagas } from './campaigns'
import { emergenciesSagas } from './emergencies'
import { machineErrorsSagas } from './machine-errors/sagas'
import { toastSagas } from './toast'
import { auth0Sagas } from './auth0'
import { promotionImagesSagas } from './promotion-images'
import { signageBannersSagas } from './signage-banners'
import { pointsSagas } from './points'
import { stationAlertsSagas } from './station-alerts'
import { usersSagas } from './users'
import { subscriptionSagas } from './subscription'
import { soracomSIMsSagas } from './soracom-sims'
import { machineEmbeddedComputersSagas } from './machine-embedded-computers'
import { machinesSagas } from './machines'
import { timeSlotPresetsSagas } from './time-slot-presets'
import { salesDataSagas } from './sales-data'
import { cameraStreamingViewerCredentialsSagas } from './camera-streaming-viewer'
import { kiosksSagas } from './kiosks/sagas'
import { kioskItemPricesSagas } from './kiosk-item-prices'
import { pointItemsSagas } from './point-items'
import { pointItemSupportLogsSagas } from './point-item-support-logs'
import { pointItemOrdersSagas } from './point-item-orders'

export default function* rootSaga() {
  yield all([
    ...stationsSagas,
    ...ordersSagas,
    ...campaignsSagas,
    ...emergenciesSagas,
    ...machineErrorsSagas,
    ...itemsSagas,
    ...stationWithItemRanksSagas,
    ...toastSagas,
    ...auth0Sagas,
    ...promotionImagesSagas,
    ...signageBannersSagas,
    ...pointsSagas,
    ...stationAlertsSagas,
    ...usersSagas,
    ...subscriptionSagas,
    ...soracomSIMsSagas,
    ...machineEmbeddedComputersSagas,
    ...machinesSagas,
    ...timeSlotPresetsSagas,
    ...salesDataSagas,
    ...cameraStreamingViewerCredentialsSagas,
    ...kiosksSagas,
    ...kioskItemPricesSagas,
    ...pointItemsSagas,
    ...pointItemSupportLogsSagas,
    ...pointItemOrdersSagas,
  ])
}
