export const strings = {
  soracomSIMsTableTitle: 'SIM一覧',
  createSoracomSIMButton: '新規作成',
  editSoracomSIMButton: '編集'
}

export const soracomSIMsTableContent = [
  'ID',
  'SIM IMSI',
  'SIM 製造番号',
  'マシンID',
  ''
]
