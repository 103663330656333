import React from 'react'
import classnames from 'classnames'
import { strings } from '../machines/machines-content'
import styles from '../../styles/modules/stations/on-off.module.scss'
import {
  activeMachineStatuses,
  MachineStatus as MachineStatusType,
} from '../../../core/machines'

interface Props {
  status: MachineStatusType
}

const formatStatus = (status: MachineStatusType) => {
  switch (status) {
    case 'active':
      return strings.machineStatus.active
    case 'config_mode':
      return strings.machineStatus.configMode
    case 'fs_mode':
      return strings.machineStatus.fsMode
    case 'operation_mode':
      return strings.machineStatus.operationMode
    case 'critical_stop':
      return strings.machineStatus.criticalStop
    case 'station_stop':
      return strings.machineStatus.stationStop
    case 'communication_loss':
      return strings.machineStatus.connectionLost
    default:
      return ''
  }
}

const MachineStatus: React.FC<Props> = props => (
  <div className={styles.container}>
    <span
      className={classnames({
        [styles.sign]: true,
        [styles.off]: !activeMachineStatuses.includes(props.status),
      })}
    />
    <span className={styles.statement}>{formatStatus(props.status)}</span>
  </div>
)

export default MachineStatus
