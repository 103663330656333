import React, { useState } from 'react'
import { KioskItemPrice } from '../../../core/kiosk-item-prices'
import { Config } from '../../../core/toast'
import styles from '../../styles/modules/modal/modal.module.scss'
import { strings } from './modal-content'
import Input from '../input'
import Button from '../button'

interface Props {
  isLoading: boolean
  kioskItemPrice: KioskItemPrice
  setKioskItemPrice: (kioskItemPrice: KioskItemPrice, price: number) => void
  show: (config: Config) => void
}

const EditKioskItemPriceForm: React.FC<Props> = (props: Props) => {
  const [price, setPrice] = useState<number>(props.kioskItemPrice.price)
  const handleOnClickSubmit = () => {
    if (price <= 0) {
      const config: Config = {
        type: 'error',
        title: 'Error',
        body: 'Price is required',
      }
      props.show(config)
      return
    }
    props.setKioskItemPrice(props.kioskItemPrice, price)
  }

  return (
    <main className={styles.main}>
      <div className={styles.form}>
        <label className={styles.label}>
          {strings.kioskItemPrice.itemName}
        </label>
        <div className={styles.contentText}>
          {props.kioskItemPrice.itemName}
        </div>
      </div>
      <Input
        label={strings.kioskItemPrice.price}
        type={'number'}
        onChange={event => {
          setPrice(parseInt(event.target.value, 10) || 0)
        }}
        value={price || ''}
      />
      <Button
        title={strings.kioskItemPrice.updateButton}
        onClick={() => handleOnClickSubmit()}
        className={styles.report}
        isLoading={props.isLoading}
        loadingClassName={styles.loadingReport}
      />
    </main>
  )
}

export default EditKioskItemPriceForm
