import React, { useState } from 'react'
import moment from 'moment'
import styles from '../../styles/modules/modal/modal.module.scss'
import { strings } from './modal-content'
import { Config } from '../../../core/toast'
import Button from '../button'
import { FormSeparator } from './form-separator'
import { LockerBoxComment } from '../../../core/machines'



interface Props {
  isLoading: boolean
  stationId: number
  lockerBoxId: number
  lockerBoxIndex: number
  lockerBoxComment: LockerBoxComment
  setLockerBoxComment: (stationId: number, lockerBoxId: number, lockerBoxCommentId: number, comment: string) => void
  removeLockerBoxComment: (stationId: number, lockerBoxId: number, lockerBoxCommentId: number) => void
  show: (config: Config) => void
}

export const EditLockerBoxCommentForm: React.FC<Props> = (props: Props) => {
  const lockerLabel = `番号：${props.lockerBoxIndex}のロッカー備考`
  const occurredDateSentence = `作成日：${moment(props.lockerBoxComment.occurredAt).format('MM/DD HH:mm:ss')}`
  const changedDateSentence = props.lockerBoxComment.changedAt ? `変更日：${moment(props.lockerBoxComment.changedAt).format('MM/DD HH:mm:ss')}` : "変更日：------"

  const [comment, setComment] = useState<string>(props.lockerBoxComment.comment)

  const handleOnClickSubmit = () => {

    if (!comment) {
      const config: Config = {
        type: 'error',
        title: strings.toastError.title,
        body: strings.toastError.body
      }
      props.show(config)
      return
    }
    props.setLockerBoxComment(props.stationId, props.lockerBoxId, props.lockerBoxComment.id, comment)
  }

  const handleOnClickDelete = () => {
    props.removeLockerBoxComment(props.stationId, props.lockerBoxId, props.lockerBoxComment.id)
  }



  return (
    <main className={styles.main}>
      <label className={styles.label}>{lockerLabel}</label>
      <div className={styles.form}>
        <label className={styles.label}>{strings.stationAlert.body}</label>
        <textarea
          className={styles.textarea}
          onChange={event => {setComment(event.target.value)}}
          value={comment}
        />
      </div>
      <label className={styles.label}>{occurredDateSentence}</label>
      <label className={styles.label}>{changedDateSentence}</label>
      <Button
        title={strings.lockerBoxComment.updateButton}
        onClick={() => handleOnClickSubmit()}
        className={styles.report}
        isLoading={props.isLoading}
        loadingClassName={styles.loadingReport}
      />
      <FormSeparator/>
      <Button
        title={strings.lockerBoxComment.deleteButton}
        onClick={() => handleOnClickDelete()}
        className={styles.report}
        isLoading={props.isLoading}
        loadingClassName={styles.loadingReport}
      />

    </main>
  )
}
