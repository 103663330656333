import { Item } from '../items'
import { Machine, LockerBox } from '../machines'
import { Order } from '../orders'

export interface Station {
  id: number
  name: string
  machine?: Machine
  extra: string
  fullAddress: string
  stationImageURL: string
  stationImageFileName: string
  mapImageURL: string
  mapImageFileName: string
  openingHours: string
  latitude: number
  longitude: number
  isClosed: boolean
  alertedEmergencies: Emergency[]
  items: StationItem[]
  timeSlots: TimeSlot[]
  orders: Order[]
  placedDate: Date
  shippedDate: Date
  permittedDate: Date
  startOperationDate: Date
  lastOperationDate: Date
  removedDate: Date
  orderEnablingHours: string
  operationHours: string
  operationDays: string
}

export interface Emergency {
  id: number
  stationID: number
  group: 'admin' | 'server' | 'hardware'
  message: string
  reportedAt: string
  canOpenLockerBox: boolean
}

export interface CoffeeLog {
  id: number
  makingStartedAt: string
  makingDoneAt?: string
  lockerBox: LockerBox
  item: Item
  willDisposedAt: string
  servedAt?: string
  doorClosedAt?: string
  status: 'open' | 'closed'
  orderId: number
  disposedAt?: string
}
export interface StationItem {
  id: number
  name: string
  cupType: number
  status: 'available' | 'unavailable' | 'unavailable_temporarily'
}

export interface TimeSlot {
  id: number
  from: string
  to: string
  isClosed?: boolean
}

export enum StationImageType {
  StationImage = 'station_image',
  MapImage = 'map_image',
}

export interface StationImage {
  fileName?: string
  url?: string
  stationImageType?: StationImageType
}
