import React, { useState } from 'react'
import Button from '../button'
import Input from '../input'
import styles from '../../styles/modules/modal/modal.module.scss'
import { strings } from './modal-content'
import { Config } from '../../../core/toast'

interface Props {
  isLoading: boolean
  createKiosk: (serial: string) => void
  show: (config: Config) => void
}

const CreateKioskForm: React.FC<Props> = ({ isLoading, createKiosk, show }) => {
  const [serial, setSerial] = useState('')
  const handleOnClickSubmit = () => {
    let isInvalid = false
    if (!serial) {
      isInvalid = true
    }
    if (isInvalid) {
      const config: Config = {
        type: 'error',
        title: strings.toastError.title,
        body: strings.toastError.body,
      }
      show(config)
      return
    }
    createKiosk(serial)
  }

  return (
    <main className={styles.main}>
      <Input
        label={strings.kiosk.serial}
        type={'text'}
        onChange={e => {
          setSerial(e.target.value)
        }}
        value={serial}
      />
      <Button
        title={strings.kiosk.createButton}
        onClick={() => handleOnClickSubmit()}
        isLoading={isLoading}
        className={styles.decide}
      />
    </main>
  )
}

export default CreateKioskForm
