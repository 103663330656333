import { action } from 'typesafe-actions'
import { PointItemSupportLog } from './point-item-support-logs'

export enum pointItemSupportLogsActionType {
  FETCH_POINT_ITEM_SUPPORT_LOGS = 'FETCH_POINT_ITEM_SUPPORT_LOGS',
  FETCH_POINT_ITEM_SUPPORT_LOGS_SUCCEEDED = 'FETCH_POINT_ITEM_SUPPORT_LOGS_SUCCEEDED',
  THROW_ERROR = 'THROW_ERROR',
}

export const pointItemSupportLogsActions = {
  fetchPointItemSupportLogs: () =>
    action(pointItemSupportLogsActionType.FETCH_POINT_ITEM_SUPPORT_LOGS),
  fetchPointItemSupportLogsSucceeded: (
    pointItemSupportLogs: PointItemSupportLog[]
  ) =>
    action(
      pointItemSupportLogsActionType.FETCH_POINT_ITEM_SUPPORT_LOGS_SUCCEEDED,
      { pointItemSupportLogs }
    ),
  throwError: (error: Error) =>
    action(pointItemSupportLogsActionType.THROW_ERROR, { error }),
}
