import { ActionType } from 'typesafe-actions'

import { ordersActionType, ordersActions } from './actions'
import { Order } from './orders'

export interface OrdersState {
  readonly error: Error
  readonly isLoading: boolean
  readonly orders: Order[]
  readonly active: boolean
}

export const initialState: OrdersState = {
  error: null,
  isLoading: false,
  orders: [],
  active: false,
}

export const ordersReducer = (
  state: OrdersState = initialState,
  action: ActionType<typeof ordersActions>
) => {
  switch (action.type) {
    case ordersActionType.FETCH_ORDERS:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case ordersActionType.FETCH_ORDERS_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        error: null,
        orders: action.payload.data,
      }
    case ordersActionType.FETCH_ORDERS_EVERY:
      return {
        ...state,
        error: null,
        active: true,
      }
    case ordersActionType.FETCH_ORDERS_EVERY_FINISHED:
      return {
        ...state,
        error: null,
        active: false,
      }
    case ordersActionType.RESET_ORDERS:
      return {
        ...state,
        orders: [],
        error: null,
      }
    case ordersActionType.THROW_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      }
    default:
      return state
  }
}
