import React from 'react'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { Redirect } from 'react-router'

import RootState from '../../core/root-state'
import { auth0Actions } from '../../core/auth0'
import Layout from '../layouts/layout'
import Button from '../components/button'
import { strings } from '../components/login/login-content'
import styles from '../styles/modules/login/login.module.scss'

interface Props {
  isAuthenticated: boolean
  login: () => void
}

class LoginScreen extends React.Component<Props, {}> {
  handleOnClickLogin = () => {
    this.props.login()
  }

  render() {
    if (this.props.isAuthenticated) {
      return <Redirect to={'/'} />
    } else {
      return (
        <Layout isGuest={true}>
          <div className={styles.container}>
            <div className={styles.wrapper}>
              <h4 className={styles.title}>{strings.title}</h4>
              <span className={styles.description}>{strings.description}</span>
              <Button
                onClick={() => this.handleOnClickLogin()}
                title={strings.title}
                className={styles.button}
              />
            </div>
          </div>
        </Layout>
      )
    }
  }
}

const mapStateToProps = (state: RootState) => ({
  isAuthenticated: state.auth0.isAuthenticated,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  login: () => dispatch(auth0Actions.login()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginScreen)
