import React from 'react'
import Layout from '../layouts/layout'
import Wrapper from '../layouts/wrapper'
import { strings } from '../components/each-kiosk-item-prices/each-kiosk-item-prices-content'
import EachKioskItemPricesTable from '../components/each-kiosk-item-prices/each-kiosk-item-prices-table'
import { Location } from 'history'
import { match } from 'react-router-dom'
import styles from '../styles/modules/each-kiosk-item-prices/each-kiosk-item-prices.module.scss'

interface Props {
  location: Location
  match: match<any>
}

const EachKioskItemPricesScreen: React.FC<Props> = props => {
  const params = new URLSearchParams(props.location.search)
  const stationName = params.get('station_name')
  const stationId = Number(props.match.params.station_id)

  return (
    <Layout location="kiosk_item_prices">
      <Wrapper title={strings.title}>
        <h3 className={styles.title}>{stationName}</h3>
        <EachKioskItemPricesTable
          stationId={stationId}
        />
      </Wrapper>
    </Layout>
  )
}

export default EachKioskItemPricesScreen
