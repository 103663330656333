export const strings = {
  title: 'KIOSK紐付け',
  editKioskRelationButton: '編集',
  deleteKioskRelationButton: '解除',
  noData: '-----',
}

export const kioskRelationsTableContent = [
  'KIOSK ID',
  'シリアル',
  'ステーション名',
  'SIM ID',
  '編集',
  '解除',
]
