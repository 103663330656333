import React, { useState } from 'react'

import styles from '../../styles/modules/modal/modal.module.scss'
import { strings } from './modal-content'
import { Campaign, CampaignDurationUnit } from '../../../core/campaigns'
import DurationSelect from '../duration-select'
import Button from '../button'
import { Config } from '../../../core/toast'

interface Props {
  campaignId: number
  stampCampaign: Campaign
  isLoading: boolean
  setStampCampaign: (campaignId: number, campaign: Campaign) => void
  show: (config: Config) => void
}

const EditStampCampaignForm: React.FC<Props> = ({
  campaignId,
  stampCampaign,
  isLoading,
  setStampCampaign,
  show,
}) => {
  const [formData, setFormData] = useState<Campaign>({
    ...stampCampaign,
  })

  const handleOnClickSubmit = () => {
    if (formData.duration <= 0) {
      const config: Config = {
        type: 'error',
        title: strings.toastError.title,
        body: strings.toastError.body,
      }
      show(config)
      return
    }
    setStampCampaign(campaignId, formData)
  }

  return (
    <main className={styles.main}>
      <DurationSelect
        label={strings.campaign.duration}
        durationValue={formData.duration ? formData.duration : ''}
        durationUnitValue={formData.durationUnit}
        onChangeDuration={event => {
          setFormData({
            ...formData,
            duration: parseInt(event.target.value, 10) || 0,
          })
        }}
        onChangeDurationUnit={event => {
          setFormData({
            ...formData,
            durationUnit: event.target.value as CampaignDurationUnit,
          })
        }}
      />
      <Button
        title={strings.campaign.updateButton}
        onClick={() => handleOnClickSubmit()}
        className={styles.decide}
        isLoading={isLoading}
      />
    </main>
  )
}

export default EditStampCampaignForm
