import React from 'react'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'

import RootState from '../../core/root-state'
import Layout from '../layouts/layout'
import Wrapper from '../layouts/wrapper'

import { modalActions } from '../../core/modal'

import MachinesIndex from '../components/machines/machines-index'
import { Machine, machinesActions } from '../../core/machines'

interface Props {
  machines: Machine[]
  isLoading: boolean
  fetchMachines: () => void
  showCreateMachine: () => void
  updateMachine: (
    machineId: number,
    serial: string,
    manufacturedDate: Date,
    type: string,
    version: string,
    factoryName: string,
    shouldBeLatest: boolean
  ) => void
  showEditMachine: (machine: Machine) => void
}

class MachinesScreen extends React.Component<Props> {
  componentDidMount = () => {
    this.props.fetchMachines()
  }

  render() {
    return (
      <Layout location="machines">
        <Wrapper title="マシン">
          <MachinesIndex
            machines={this.props.machines}
            showCreateMachine={this.props.showCreateMachine}
            updateMachine={this.props.updateMachine}
            showEditMachine={this.props.showEditMachine}
          />
        </Wrapper>
      </Layout>
    )
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    machines: state.machines.machines,
    isLoading: state.machines.isLoading,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchMachines: () => dispatch(machinesActions.fetchMachines()),
  showCreateMachine: () => dispatch(modalActions.showCreateMachine()),
  updateMachine: (
    machineId: number,
    serial: string,
    manufacturedDate: Date,
    type: string,
    version: string,
    factoryName: string,
    shouldBeLatest: boolean
  ) =>
    dispatch(
      machinesActions.updateMachine(
        machineId,
        serial,
        manufacturedDate,
        type,
        version,
        factoryName,
        shouldBeLatest
      )
    ),
  showEditMachine: (machine: Machine) =>
    dispatch(modalActions.showEditMachine(machine)),
})

export default connect(mapStateToProps, mapDispatchToProps)(MachinesScreen)
