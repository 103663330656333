import React, { useState } from 'react'
import Button from '../button'
import styles from '../../styles/modules/modal/modal.module.scss'
import { strings } from './modal-content'
import { Config } from '../../../core/toast'
import { Station } from '../../../core/stations'
import { StationAlert } from '../../../core/station-alerts'
import { FormSeparator } from './form-separator'



interface Props {
  isLoading: boolean
  stations: Station[]
  stationAlert: StationAlert

  show: (config: Config) => void
  setStationAlert: (stationAlertID: number, stationID: number, title: string, body: string) => void
  deleteStationAlert: (stationAlertID: number) => void
  restoreStationAlert: (stationAlertID: number) => void
}

export const EditStationAlertForm: React.FC<Props> = (props: Props) => {
  const [stationId, setStationId] = useState<number>(props.stationAlert.stationID)
  const [title, setTitle] = useState<string>(props.stationAlert.title)
  const [body, setBody] = useState<string>(props.stationAlert.body)

  const handleOnClickSubmit = () => {

    if (!stationId || !title || !body) {
      const config: Config = {
        type: 'error',
        title: strings.toastError.title,
        body: strings.toastError.body
      }
      props.show(config)
      return
    }
    props.setStationAlert(props.stationAlert.id, stationId, title, body)
  }

  const handleOnClickDelete = () => {
    props.stationAlert.isDeleted? props.restoreStationAlert(props.stationAlert.id) :props.deleteStationAlert(props.stationAlert.id)
  }


  return (
    <main className={styles.main}>
      <label className={styles.label}>{strings.stationAlert.station}</label>
      <div>
        <select
          className={styles.input}
          value={stationId}
          onChange={event => setStationId(Number(event.target.value))}
        >
          {stationId ? <></> : <option value={null}>{strings.stationAlert.stationUnset}</option> }
          {props.stations.map(station => (
            <option value={station.id} key={station.id}>
              {`ID: ${station.id} ${station.name}`}
            </option>
          ))}
        </select>
      </div>
      <div className={styles.form}>
        <label className={styles.label}>{strings.stationAlert.title}</label>
        <input
          type="text"
          value={title}
          className={styles.input}
          onChange={event => {
            setTitle(event.target.value)
          }}
          />
      </div>
      <div className={styles.form}>
        <label className={styles.label}>{strings.stationAlert.body}</label>
        <textarea
          className={styles.textarea}
          value={body}
          onChange={event => {
            setBody(event.target.value)}
          }
        />
      </div>
      <Button
        title={strings.stationAlert.updateButton}
        onClick={() => handleOnClickSubmit()}
        className={styles.report}
        isLoading={props.isLoading}
        loadingClassName={styles.loadingReport}
      />
      <FormSeparator/>
      <Button
        title={props.stationAlert.isDeleted? strings.stationAlert.restoreButton :strings.stationAlert.deleteButton}
        onClick={() => handleOnClickDelete()}
        className={styles.report}
        isLoading={props.isLoading}
        loadingClassName={styles.loadingReport}
      />
    </main>
  )
}
