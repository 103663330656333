import { put, call, takeLatest, fork, select } from 'redux-saga/effects'

import RootState from '../root-state'
import { emergenciesActionType, emergenciesActions } from './actions'
import { auth0Actions } from '../auth0'

import { getEmergencies } from '../api'

export function* fetchEmergencies() {
  try {
    yield put(auth0Actions.checkSession())
    const state: RootState = yield select()
    const token = state.auth0.auth.idToken
    const response = yield call(getEmergencies, token)
    yield put(
      emergenciesActions.fetchEmergenciesSucceeded(response.emergencies)
    )
  } catch (error) {
    yield put(emergenciesActions.throwError(error))
  }
}

export function* watchFetchEmergencies() {
  yield takeLatest(emergenciesActionType.FETCH_EMERGENCIES, fetchEmergencies)
}

export const emergenciesSagas = [fork(watchFetchEmergencies)]
