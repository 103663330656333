import React from 'react'
import classnames from 'classnames'

import styles from '../../styles/modules/table.module.scss'
import { orderLimitationTableContent, strings } from './each-station-content'
import { Machine } from '../../../core/machines'
import Button from '../button'
import { Station } from '../../../core/stations'
import orderLimitationTableStyles from '../../styles/modules/each-station/order-limitation-table.module.scss'

interface Props {
  machine: Machine
  station: Station
  onClickFillIce: (station: Station) => void
}

const OrderLimitationTable: React.FC<Props> = props => {
  return (
    <div
      className={classnames({
        [styles.wrapper]: true,
        [styles.zebra]: true,
        [styles.stocker]: true,
      })}
    >
      <table className={styles.container}>
        <tbody>
          <tr className={styles.row}>
            {orderLimitationTableContent.map(item => (
              <th key={item} className={styles.cell}>
                {item}
              </th>
            ))}
          </tr>
          {/* 豆不足の情報 */}
          {props.machine.unavailableBeanTypeIndexes.length > 0 && (
            <tr className={styles.row}>
              <td className={styles.cell}>
                {strings.orderLimitations.lackOfBeans}
              </td>
              <td
                className={styles.cell}
              >{`豆レーン番号${props.machine.unavailableBeanTypeIndexes}の豆が不足しています`}</td>
            </tr>
          )}
          {/* ホットカップ不足の情報 */}
          {props.machine.isHotCupShortage && (
            <tr className={styles.row}>
              <td className={styles.cell}>
                {strings.orderLimitations.lackOfCups}
              </td>
              <td className={styles.cell}>ホットカップが不足しています</td>
            </tr>
          )}
          {/* アイスカップ不足の情報 */}
          {props.machine.isIceCupShortage && (
            <tr className={styles.row}>
              <td className={styles.cell}>
                {strings.orderLimitations.lackOfCups}
              </td>
              <td className={styles.cell}>アイスカップが不足しています</td>
            </tr>
          )}
          {/* 氷不足の情報 */}
          {props.machine.isIceShortage && (
            <tr className={styles.row}>
              <td className={styles.cell}>
                {strings.orderLimitations.lackOfIce}
              </td>
              <td className={styles.cell}>氷が不足しています</td>
              <td className={styles.cell}>
                <Button
                  title={strings.fillIceButton}
                  onClick={() => props.onClickFillIce(props.station)}
                  className={orderLimitationTableStyles.container}
                />
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  )
}

export default OrderLimitationTable
