import React, { useState, useEffect } from 'react'
import { Dispatch } from 'redux'
import { connect } from "react-redux";
import SubscriptionsTable from '../components/subscriptions/subscriptions-table';
import RootState from '../../core/root-state'
import { Subscription, SubscriptionParam, subscriptionActions } from '../../core/subscription';
import Layout from '../layouts/layout'
import Wrapper from '../layouts/wrapper'
import QueryId from '../components/points/query-id'
import Button from '../components/button';
import styles from '../styles/modules//points/points.module.scss'
import { strings } from "../components/subscriptions/subscriptions-content";

interface Props {
  isLoading: boolean
  subscriptions: Subscription[]
  fetchSubscriptions: (subscriptionParam: SubscriptionParam) => void
}

const SubscriptionsScreen: React.FC<Props> = (props: Props) => {

  const queryUserId = Number(window.location.search.replace("?user_id=", ""))
  const [userId, setUserId] = useState<number>(queryUserId)
  const fetchSubscriptions = props.fetchSubscriptions

  useEffect(() => {
    const params: SubscriptionParam = {user_id: queryUserId}
    fetchSubscriptions(params)
  }, [fetchSubscriptions, queryUserId])

  const handleOnChangeId = (event: React.ChangeEvent<HTMLInputElement>) => {
    const re = /^[0-9\b]+$/
    if (event.target.value === '') {
      setUserId(null)
    }
    if (re.test(event.target.value)) {
      setUserId(parseInt(event.target.value, 10))
    }
  }

  const handleOnClickQueryId = () => {
    const subscriptionParam: SubscriptionParam = {user_id: userId}
    props.fetchSubscriptions(subscriptionParam)
  }

  return (
    <Layout location='subscriptions'>
      <Wrapper title={strings.title}>
        <div className={styles.queryContainer}>
          <QueryId id={userId} onChange={event => handleOnChangeId(event)}/>
          <Button
            title={strings.queryButton}
            onClick={handleOnClickQueryId}
            className={styles.queryButton}
          />
        </div>
        <SubscriptionsTable
          subscriptions={props.subscriptions}
        />
      </Wrapper>
    </Layout>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    isLoading: state.subscription.isLoading,
    subscriptions: state.subscription.subscriptions
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchSubscriptions: (subscriptionParam: SubscriptionParam) =>
  dispatch(subscriptionActions.fetchSubscriptions(subscriptionParam)),
})

export default connect(mapStateToProps,mapDispatchToProps) (SubscriptionsScreen)
