import React from 'react'
import { Dispatch } from 'redux'
import { connect } from "react-redux";

import RootState from '../../core/root-state'
import Layout from '../layouts/layout'
import Wrapper from '../layouts/wrapper'

import MachineEmbeddedComputersIndex from '../components/machine-embedded-computers/machine-embedded-computers-index'
import { MachineEmbeddedComputer, machineEmbeddedComputersActions } from '../../core/machine-embedded-computers';

interface Props {
  machineEmbeddedComputers: MachineEmbeddedComputer[]
  isLoading: boolean
  fetchMachineEmbeddedComputers: () => void
  deleteMachineEmbeddedComputer: (machineEmbeddedComputerId: number) => void
}

class MachineEmbeddedComputersScreen extends React.Component<Props> {
  componentDidMount = () => {
    this.props.fetchMachineEmbeddedComputers()
  }

  render() {
    return (
      <Layout location='machine_embedded_computers'>
        <Wrapper title='SDカード'>
          <MachineEmbeddedComputersIndex
            machineEmbeddedComputers={this.props.machineEmbeddedComputers}
            deleteMachineEmbeddedComputer={this.props.deleteMachineEmbeddedComputer}
          />
        </Wrapper>
      </Layout>
    )
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    machineEmbeddedComputers: state.machineEmbeddedComputers.machineEmbeddedComputers,
    isLoading: state.machineEmbeddedComputers.isLoading,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchMachineEmbeddedComputers: () => dispatch(machineEmbeddedComputersActions.fetchMachineEmbeddedComputers()),
  deleteMachineEmbeddedComputer: (machineEmbeddedComputerId: number) => dispatch(machineEmbeddedComputersActions.deleteMachineEmbeddedComputer(machineEmbeddedComputerId)),
})

export default connect(mapStateToProps,mapDispatchToProps) (MachineEmbeddedComputersScreen)
