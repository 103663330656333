import { put, call, takeLatest, fork, select, delay } from 'redux-saga/effects'
import RootState from '../root-state'
import { pointActions, pointActionType } from './actions'
import { getPointTransactions, givePointsToUser } from '../api'
import { toastActions } from '../toast'
import { modalActions } from '../modal'

export function* fetchPointTransactions(
  action: ReturnType<typeof pointActions.fetchPointTransactions>
) {
  try {
    const state: RootState = yield select()
    const token = state.auth0.auth.idToken
    const response = yield call(getPointTransactions, token, action.payload.id)
    yield put(
      pointActions.fetchPointTransactionsByUserIdSucceeded(
        response.user,
        response.amount,
        response.pointTransactions
      )
    )
  } catch (error) {
    yield put(pointActions.throwError(error))
  }
}

export function* createPoints(
  action: ReturnType<typeof pointActions.createPoints>
) {
  try {
    const state: RootState = yield select()
    const token = state.auth0.auth.idToken
    yield call(
      givePointsToUser,
      token,
      action.payload.userId,
      action.payload.point,
      action.payload.description,
      action.payload.calculationType
    )
    yield delay(100)
    yield put(pointActions.fetchPointTransactions(action.payload.userId))
    if (action.payload.calculationType === 'give') {
      yield put(
        toastActions.show({
          title: '成功！',
          body: `ポイントを付与しました`,
        })
      )
    } else if (action.payload.calculationType === 'expire') {
      yield put(
        toastActions.show({
          title: '成功！',
          body: `ポイントを失効しました`,
        })
      )
    }
    yield put(modalActions.hide())
  } catch (error) {
    yield put(pointActions.createPointsFailed(error))
    yield put(
      toastActions.show({
        type: 'error',
        title: '失敗...',
        body: 'ポイントの更新に失敗しました',
      })
    )
  }
}

export function* watchPointTransactions() {
  yield takeLatest(
    pointActionType.FETCH_POINT_TRANSACTIONS,
    fetchPointTransactions
  )
}

export function* watchCreatePoints() {
  yield takeLatest(pointActionType.CREATE_POINTS, createPoints)
}

export const pointsSagas = [
  fork(watchPointTransactions),
  fork(watchCreatePoints),
]
