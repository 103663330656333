import { action } from 'typesafe-actions'

import { Order, OrdersParam } from './orders'

export enum ordersActionType {
  FETCH_ORDERS = 'FETCH_ORDERS',
  FETCH_ORDERS_SUCCEEDED = 'FETCH_ORDERS_SUCCEEDED',
  FETCH_ORDERS_EVERY = 'FETCH_ORDERS_EVERY',
  FETCH_ORDERS_EVERY_FINISHED = 'FETCH_ORDERS_EVERY_FINISHED',
  RESET_ORDERS = 'RESET_ORDERS',
  CANCEL_ORDER = 'CANCEL_ORDER',
  THROW_ERROR = 'THROW_ERROR',
}

export const ordersActions = {
  fetchOrders: (param: OrdersParam) =>
    action(ordersActionType.FETCH_ORDERS, { param }),
  fetchOrdersSucceeded: (data: Order[]) =>
    action(ordersActionType.FETCH_ORDERS_SUCCEEDED, { data }),
  fetchOrdersEvery: (param: OrdersParam) =>
    action(ordersActionType.FETCH_ORDERS_EVERY, { param }),
  fetchOrdersEveryFinished: () =>
    action(ordersActionType.FETCH_ORDERS_EVERY_FINISHED),
  cancelOrder: (id: number) => action(ordersActionType.CANCEL_ORDER, { id }),
  resetOrders: () => action(ordersActionType.RESET_ORDERS),
  throwError: (error: Error) => action(ordersActionType.THROW_ERROR, { error }),
}
