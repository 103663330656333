import { action } from 'typesafe-actions'
import { User, UserInfo } from './users'

export enum usersActionType {
  FETCH_USER_BY_ID = 'FETCH_USER_BY_ID',
  FETCH_USER_BY_ID_SUCCEEDED = 'FETCH_USER_BY_ID_SUCCEEDED',
  FETCH_USER_BY_EMAIL = 'FETCH_USER_BY_EMAIL',
  FETCH_USER_SUCCEEDED = 'FETCH_USER_SUCCEEDED',
  RESET_USER = 'RESET_USER',
  DELETE_USER = 'DELETE_USER',
  THROW_ERROR = 'THROW_ERROR',
}

export const usersActions = {
  fetchUserById: (id: number) =>
    action(usersActionType.FETCH_USER_BY_ID, { id }),
  fetchUserByIdSucceeded: (user: User) =>
    action(usersActionType.FETCH_USER_BY_ID_SUCCEEDED, { user }),
  fetchUserByEmail: (email: string) =>
    action(usersActionType.FETCH_USER_BY_EMAIL, { email }),
  fetchUserSucceeded: (users: UserInfo[]) =>
    action(usersActionType.FETCH_USER_SUCCEEDED, { users }),
  resetUser: () => action(usersActionType.RESET_USER),
  deleteUser: (userId: number) =>
    action(usersActionType.DELETE_USER, { userId }),
  throwError: (error: Error) => action(usersActionType.THROW_ERROR, { error }),
}
