export const strings = {
  machinesTableTitle: 'マシン一覧',
  createMachineButton: '新規作成',
  editMachineButton: '編集',
  updateButton: 'アップデート',
  updateStopButton: 'アップデートを中止',
  machineStatus: {
    active: '営業中',
    configMode: '設定モード',
    fsMode: 'FSモード',
    operationMode: 'オペモード',
    criticalStop: '営業不能(非常停止ボタン押下)',
    stationStop: '営業停止中',
    connectionLost: '営業不能(通信途絶)',
  },
}

export const machinesTableContent = [
  'ID',
  'マシンシリアル',
  'マシンステータス',
  'power',
  'ステーションID',
  '製造年月日',
  '型式',
  'バージョン',
  '製造工場',
  'NUCバージョン',
  '更新ステータス',
  'アップデート',
  '更新受入時刻',
  '',
]
