import React from 'react'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'

import RootState from '../../core/root-state'
import Layout from '../layouts/layout'
import Wrapper from '../layouts/wrapper'
import { MachineError, machineErrorsActions } from '../../core/machine-errors'
import { strings } from '../components/machine-errors/machine-errors-content'
import styles from '../styles/modules/machine-errors/machine-errors.module.scss'
import MachineErrorsTable from '../components/machine-errors/machine-errors-item'

interface Props {
  machineErrors: MachineError[]
  isLoading: boolean
  fetchMachineErrors: () => void
}

class MachineErrorsScreen extends React.Component<Props, {}> {
  componentDidMount = () => {
    this.props.fetchMachineErrors()
  }

  render() {
    return (
      <Layout location={'machine_error'}>
        <Wrapper title={strings.title}>
          <div className={styles.container}>
            <MachineErrorsTable machineErrors={this.props.machineErrors} />
          </div>
        </Wrapper>
      </Layout>
    )
  }
}

const mapStateToProps = (state: RootState) => ({
  machineErrors: state.machineErrors.machineErrors,
  isLoading: state.machineErrors.isLoading,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchMachineErrors: () => dispatch(machineErrorsActions.fetchMachineErrors()),
})

export default connect(mapStateToProps, mapDispatchToProps)(MachineErrorsScreen)
