import React from 'react'
import classnames from 'classnames'

import { Item, ItemCategory, ItemCupType } from '../../../core/items'
import { itemInfoTableContent } from './new-item-content'
import styles from '../../styles/modules/table.module.scss'
import formStyles from '../../styles/modules/each-item/each-item-forms.module.scss'

interface Props {
  item: Item
  onChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
  ) => void
}

const cupTypes: Array<{value: ItemCupType, label: string}> = [{ value: 1, label: 'ホット' }, { value: 2, label: 'アイス' }]
const category: Array<{value: ItemCategory, label: string}> = [
  {value: 'hot', label: 'ホット'},
  {value: 'ice', label: 'アイス'},
  {value: 'hot_latte', label: 'ホットラテ'},
  {value: 'ice_latte', label: 'アイスラテ'},
  {value: 'hot_milk', label: 'ホットミルク'},
  {value: 'ice_milk', label: 'アイスミルク'}
]

const ItemInfoForm: React.FC<Props> = props => {
  return (
    <div
      className={classnames({
        [styles.wrapper]: true,
        [styles.stocker]: true,
      })}
    >
      <table className={styles.container}>
        <tbody>
          <tr className={styles.inputRowStatic}>
            <td className={styles.inputCell}>{itemInfoTableContent.name}</td>
            <td className={styles.inputCell}>
              <input
                className={formStyles.input}
                value={props.item.name || ''}
                onChange={props.onChange}
                name="name"
              />
            </td>
          </tr>
          <tr className={styles.inputRowStatic}>
            <td className={styles.inputCell}>{itemInfoTableContent.price}</td>
            <td className={styles.inputCell}>
              <input
                className={formStyles.input}
                value={props.item.price || ''}
                onChange={props.onChange}
                name="price"
              />
            </td>
          </tr>
          <tr className={styles.inputRowStatic}>
            <td className={styles.inputCell}>
              {itemInfoTableContent.disposalSeconds}
            </td>
            <td className={styles.inputCell}>
              <input
                className={formStyles.input}
                value={props.item.disposalSeconds || ''}
                onChange={props.onChange}
                name="disposalSeconds"
              />
            </td>
          </tr>
          <tr className={styles.inputRowStatic}>
            <td className={styles.inputCell}>{itemInfoTableContent.category}</td>
            <td className={styles.inputCell}>
              <select
                className={formStyles.input}
                value={props.item.category || ''}
                onChange={props.onChange}
                name="category"
              >
                {category
                  .map((v => (
                    <option key={`cupType-` + v.value} value={v.value}>
                      {v.label}
                    </option>
                  )))}
              </select>
            </td>
          </tr>
          <tr className={styles.inputRowStatic}>
            <td className={styles.inputCell}>{itemInfoTableContent.cupType}</td>
            <td className={styles.inputCell}>
              <select
                className={formStyles.input}
                value={props.item.cupType || ''}
                onChange={props.onChange}
                name="cupType"
              >
                {cupTypes
                  .map((v => (
                    <option key={`cupType-` + v.value} value={v.value}>
                      {v.label}
                    </option>
                  )))}
              </select>
            </td>
          </tr>
          <tr className={styles.inputRowStatic}>
            <td className={styles.inputCell}>
              {itemInfoTableContent.description}
            </td>
            <td className={styles.inputCell}>
              <input
                className={formStyles.input}
                value={props.item.description}
                onChange={props.onChange}
                name="description"
              />
            </td>
          </tr>
          <tr className={styles.inputRowStatic}>
            <td className={styles.inputCell}>{itemInfoTableContent.story}</td>
            <td className={styles.itemInputCell}>
              <textarea
                className={formStyles.textInput}
                value={props.item.story}
                onChange={props.onChange}
                name="story"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default ItemInfoForm
