import { ActionType } from 'typesafe-actions'

import { signageBannersActionType, signageBannersActions } from './actions'
import { SignageBanner } from './signage-banners'

export interface SignageBannersState {
  readonly signageBanners: SignageBanner[]
  readonly error: Error
  readonly isLoading: boolean
}

export const initialState: SignageBannersState = {
  signageBanners: [],
  error: null,
  isLoading: false,
}

export const signageBannersReducer = (
  state: SignageBannersState = initialState,
  action: ActionType<typeof signageBannersActions>
) => {
  switch (action.type) {
    case signageBannersActionType.FETCH_SIGNAGE_BANNER:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case signageBannersActionType.FETCH_SIGNAGE_BANNER_SUCCEEDED:
      return {
        ...state,
        signageBanners: action.payload.signageBanners,
        isLoading: false,
        error: null,
      }
    case signageBannersActionType.CREATE_SIGNAGE_BANNER:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case signageBannersActionType.DELETE_SIGNAGE_BANNER:
      return {
        ...state,
        isLoading: false,
        error: null,
      }
    case signageBannersActionType.SORT_SIGNAGE_BANNER:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case signageBannersActionType.UPDATE_SIGNAGE_BANNER:
      return {
        ...state,
        isLoading: false,
        error: null,
      }

    case signageBannersActionType.THROW_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      }
    default:
      return state
  }
}
