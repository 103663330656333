import React from 'react'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import RootState from '../../core/root-state'
import Layout from '../layouts/layout'
import Wrapper from '../layouts/wrapper'
import styles from '../styles/modules/table.module.scss'
import wrapperStyles from '../styles/modules/wrapper.module.scss'

const VncLinkScreen: React.FC<{}> = () => {
  return (
    <Layout location={'vnc_link'}>
      <Wrapper title="VNC接続">
        <br />
        各マシンのラズパイにアクセスするためのApp Stream
        2.0へのリンクは以下です。
        <br />
        <br />
        <a
          href="https://appstream2.ap-northeast-1.aws.amazon.com/userpools#/signin?ref=3EvY0ueBFH"
          target="_blank"
          rel="noreferrer"
        >
          本番環境用
        </a>
        <br />
        <a
          href="https://appstream2.ap-northeast-1.aws.amazon.com/userpools#/signin?ref=JTZ5Y11cZS"
          target="_blank"
          rel="noreferrer"
        >
          dev環境用
        </a>
        <br />
        <br />
        App Stream
        2.0へのログインには、以下のユーザー名とパスワードを使用してください。（本番、dev共通です）
        <br />
        <br />
        <table className={styles.container}>
          <tbody>
            <tr className={styles.rowStatic}>
              <td>ユーザー名</td>
              <td>dev-soft@newinov.com</td>
            </tr>
            <tr className={styles.rowStatic}>
              <td>パスワード</td>
              <td>c0mAPU741kCEiFRqyDQX</td>
            </tr>
          </tbody>
        </table>
        <br />
        <h3 className={wrapperStyles.title}>使い方</h3>
        <br />
        App Stream
        2.0にログインした後、「Desktop」「GitBash」「VNCViewer」の3つのアプリが表示されます。
        <br />
        Desktopをクリックして、デスクトップのアイコンからGitBashを起動してください。（デスクトップの起動には2分程度かかります）
        <br />
        デスクトップ上でGitBashを起動したら、以下のコマンドを実行しラズパイのインスタンス情報を確認してください。
        <br />
        <br />
        <code>./ListInstanceInfo</code>
        <br />
        <br />
        次に、以下のコマンドを実行してラズパイのVNCポートを開いてください。
        <br />
        <br />
        <code>./OpenVNCPort mi-~~~~~</code>
        <br />
        <br />
        この状態で、デスクトップのアイコンからVNCViewerを起動してください。
        <br />
        VNCViewerを起動したら、localhostに対して接続を開始してください。
        <br />
        <br />
        <h3 className={wrapperStyles.title}>注意事項</h3>
        <br />
        <ul>
          短い時間に3人以上が接続開始すると、後から接続する人は接続できないことがあります。
          <br />
          その場合は、しばらく待ってから再度接続してください。
          <br />
        </ul>
        <br />
        <ul>
          App Stream
          2.0は秒単位での課金となります。（15分までは一律15分の料金がかかります。）
          <br />
          特に必要がない場合は接続した状態で放置しないようにしてください。
          <br />
          作業が終わったら必ず右上のアイコンからログアウトを行ってください。
        </ul>
      </Wrapper>
    </Layout>
  )
}

const mapStateToProps = (state: RootState) => ({})

const mapDispatchToProps = (dispatch: Dispatch) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(VncLinkScreen)
