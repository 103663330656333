import React from 'react'
import moment from 'moment'
import classnames from 'classnames'
import { Link } from 'react-router-dom'

import Button from '../button'
import { Order } from '../../../core/orders'
import { orderTableContent, strings } from './orders-content'
import styles from '../../styles/modules/table.module.scss'
import ordersTableStyles from '../../styles/modules/orders/orders-table.module.scss'

interface Props {
  orders: Order[]
  onClickCancelButton: (id: number) => void
  onClickKioskPurchaserImageButton: (imageUrl: string, alt: string) => void
}

const OrdersTable: React.FC<Props> = props => {
  return (
    <>
      <h3 className={styles.title}>{strings.ordersTableTitle}</h3>
      <div
        className={classnames({
          [styles.wrapper]: true,
          [styles.zebra]: true,
          [styles.stocker]: true,
        })}
      >
        <div>
          キャンセル:単発→返金/Limited→杯数カウントが戻る/Unlimited→特に無し
        </div>
        <br />
        <div>表示件数: {props.orders.length}件</div>
        <br />
        <table className={styles.container}>
          <tbody>
            <tr className={styles.row}>
              {orderTableContent.map(item => (
                <th key={item} className={styles.cell}>
                  {item}
                </th>
              ))}
            </tr>
            {props.orders &&
              props.orders.map(item => (
                <tr
                  key={item.id}
                  className={classnames({
                    [ordersTableStyles.row]: true,
                    [ordersTableStyles.hasTroubleRow]: item.isEmergencyRelated,
                  })}
                >
                  <td className={styles.cell}>
                    {moment(item.orderedAt).format('YYYY/MM/DD/ HH:mm:ss')}
                  </td>
                  <td className={styles.cell}>{item.id}</td>
                  <td className={styles.cell}>{item.hashid}</td>
                  <td className={styles.cell}>
                    {item.kioskID ? 'KIOSK' : 'Mobile'}
                  </td>
                  <td className={styles.cell}>
                    {item.coffeeLog == null
                      ? '未割当'
                      : item.coffeeLog.lockerBox.index}
                  </td>
                  <td className={styles.cell}>
                    {moment(item.timeSlotFrom).format('HH:mm')}
                  </td>
                  <td className={styles.cell}>
                    {item.coffeeLog?.makingStartedAt
                      ? moment(item.coffeeLog.makingStartedAt).format(
                          'HH:mm:ss'
                        )
                      : ''}
                  </td>
                  <td className={styles.cell}>
                    {item.coffeeLog?.makingDoneAt
                      ? moment(item.coffeeLog.makingDoneAt).format('HH:mm:ss')
                      : ''}
                  </td>
                  <td className={styles.cell}>
                    {item.coffeeLog?.servedAt
                      ? moment(item.coffeeLog.servedAt).format('HH:mm:ss')
                      : ''}
                  </td>
                  <td className={styles.cell}>
                    {item.coffeeLog?.doorClosedAt
                      ? moment(item.coffeeLog.doorClosedAt).format('HH:mm:ss')
                      : ''}
                  </td>
                  <td className={styles.cell}>{item.provisionStatus}</td>
                  <td className={styles.cell}>{item.status}</td>
                  <td className={styles.cell}>
                    {(() => {
                      if (
                        item.status === 'canceled' ||
                        item.status === 'pre_canceled'
                      ) {
                        return strings.canceled
                      }
                    })()}
                  </td>
                  <td className={styles.cell}>
                    {(() => {
                      if (item.isEmergencyRelated) {
                        return strings.hasTrouble
                      }
                    })()}
                  </td>
                  <td
                    className={styles.cell}
                  >{`ID: ${item.item.id} ${item.item.name}`}</td>
                  <td className={styles.cell}>
                    {item.coffeeLog ? item.coffeeLog.id : '未調理'}
                  </td>
                  <td className={styles.cell}>
                    <Link
                      to={{
                        pathname: `/users/${item.userID}`,
                      }}
                    >
                      {item.userID}
                    </Link>
                  </td>
                  <td className={styles.cell}>{item.kioskID}</td>
                  <td className={styles.cell}>
                    <Link
                      to={{
                        pathname: `/stations/${item.station.id}`,
                      }}
                    >
                      {`ID: ${item.station.id} ${item.station.name}`}
                    </Link>
                  </td>
                  <td className={styles.cell}>{item.paymentMethod}</td>
                  <td className={styles.cell}>{item.paidAmount}</td>
                  <td className={styles.cell}>{item.pointDiscountAmount}</td>
                  <td className={styles.cell}>{item.campaignDiscountAmount}</td>
                  <td className={styles.cell}>
                    {item.oneMoreCoffeeDiscountAmount}
                  </td>
                  <td className={styles.cell}>
                    {item.kioskPurchaserImageURL !== '' && (
                      <Button
                        title={strings.kioskPurchaseImage}
                        onClick={() => {
                          props.onClickKioskPurchaserImageButton(
                            item.kioskPurchaserImageURL,
                            'KIOSK購入者画像'
                          )
                        }}
                        className={ordersTableStyles.container}
                      />
                    )}
                  </td>
                  <td className={styles.cell}>
                    {item.status !== 'canceled' &&
                      item.status !== 'pre_canceled' && (
                        <Button
                          title={strings.cancel}
                          onClick={() => {
                            props.onClickCancelButton(item.id)
                          }}
                          className={ordersTableStyles.container}
                        />
                      )}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </>
  )
}

export default OrdersTable
