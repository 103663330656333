import {
  getPointItems,
  newPointItem,
  presignPointItemImageUrl,
  putPointItem,
  sortPointItems as sortPointItemsApi,
} from '../api'
import RootState from '../root-state'
import { put, call, takeLatest, fork, select, delay } from 'redux-saga/effects'
import { pointItemsActionType, pointItemsActions } from './actions'
import { auth0Actions } from '../auth0'
import axios from 'axios'
import { modalActions } from '../modal'
import { toastActions } from '../toast'

export function* fetchPointItems() {
  try {
    yield put(auth0Actions.checkSession())
    const state: RootState = yield select()
    const token = state.auth0.auth.idToken
    const response = yield call(getPointItems, token)
    yield put(pointItemsActions.fetchPointItemsSucceeded(response.pointItems))
  } catch (error) {
    yield put(pointItemsActions.throwError(error))
  }
}

export function* createPointItem(
  action: ReturnType<typeof pointItemsActions.createPointItem>
) {
  try {
    yield put(auth0Actions.checkSession())
    const state: RootState = yield select()
    const token = state.auth0.auth.idToken
    const presignResponse = yield call(presignPointItemImageUrl, token)
    yield delay(100) // ?
    const { url, fileName } = presignResponse.result
    yield call(axios.put, url, action.payload.imageFile, {
      headers: {
        'Content-Type': action.payload.imageFile.type,
      },
    })
    yield call(
      newPointItem,
      token,
      action.payload.name,
      fileName,
      action.payload.isDeliveryRequired,
      action.payload.pointsPrice,
      action.payload.stock,
      action.payload.isAvailable,
      action.payload.isInfiniteStock
    )
    yield put(modalActions.hide())
    yield delay(100) // ?
    yield put(pointItemsActions.fetchPointItems())
    yield put(
      toastActions.show({
        title: '成功！',
        body: 'グッズを追加しました',
      })
    )
    yield delay(100)
  } catch (error) {
    yield put(pointItemsActions.throwError(error))
    yield put(pointItemsActions.fetchPointItems())
    yield put(
      toastActions.show({
        type: 'error',
        body: error.response.data.message,
      })
    )
    yield put(toastActions.hide())
  }
}

export function* updatePointItem(
  action: ReturnType<typeof pointItemsActions.updatePointItem>
) {
  try {
    yield put(auth0Actions.checkSession())
    const state: RootState = yield select()
    const token = state.auth0.auth.idToken
    yield call(
      putPointItem,
      token,
      action.payload.id,
      action.payload.imageFileName,
      action.payload.name,
      action.payload.isDeliveryRequired,
      action.payload.pointsPrice,
      action.payload.stock,
      action.payload.isAvailable,
      action.payload.isInfiniteStock
    )
    yield put(pointItemsActions.fetchPointItems())
    yield put(
      toastActions.show({
        title: '成功！',
        body: 'グッズを更新しました',
      })
    )
  } catch (error) {
    yield put(pointItemsActions.throwError(error))
    yield put(pointItemsActions.fetchPointItems())
    yield put(
      toastActions.show({
        type: 'error',
        title: '失敗...',
        body: '更新に失敗しました',
      })
    )
  }
}

export function* sortPointItems(
  action: ReturnType<typeof pointItemsActions.sortPointItems>
) {
  try {
    yield put(auth0Actions.checkSession())
    const state: RootState = yield select()
    const token = state.auth0.auth.idToken
    const response = yield call(sortPointItemsApi, token, action.payload.ids)
    yield put(pointItemsActions.fetchPointItemsSucceeded(response.pointItems))
  } catch (error) {
    yield put(pointItemsActions.throwError(error))
  }
}

export function* watchFetchPointItems() {
  yield takeLatest(pointItemsActionType.FETCH_POINT_ITEMS, fetchPointItems)
}

export function* watchCreatePointItem() {
  yield takeLatest(pointItemsActionType.CREATE_POINT_ITEM, createPointItem)
}

export function* watchUpdatePointItem() {
  yield takeLatest(pointItemsActionType.UPDATE_POINT_ITEM, updatePointItem)
}

export function* watchSortPointItems() {
  yield takeLatest(pointItemsActionType.SORT_POINT_ITEMS, sortPointItems)
}

export const pointItemsSagas = [
  fork(watchFetchPointItems),
  fork(watchCreatePointItem),
  fork(watchUpdatePointItem),
  fork(watchSortPointItems),
]
