import { call, fork, put, select, takeLatest } from 'redux-saga/effects'
import { getPointItemSupportLogs } from '../api'
import {
  pointItemSupportLogsActionType,
  pointItemSupportLogsActions,
} from './actions'
import RootState from '../root-state'
import { auth0Actions } from '../auth0'

export function* fetchPointItemSupportLogs() {
  try {
    yield put(auth0Actions.checkSession())
    const state: RootState = yield select()
    const token = state.auth0.auth.idToken
    const response = yield call(getPointItemSupportLogs, token)
    yield put(
      pointItemSupportLogsActions.fetchPointItemSupportLogsSucceeded(
        response.pointItemSupportLogs
      )
    )
  } catch (error) {
    yield put(pointItemSupportLogsActions.throwError(error))
  }
}

export function* watchFetchPointItemSupportLogs() {
  yield takeLatest(
    pointItemSupportLogsActionType.FETCH_POINT_ITEM_SUPPORT_LOGS,
    fetchPointItemSupportLogs
  )
}

export const pointItemSupportLogsSagas = [fork(watchFetchPointItemSupportLogs)]
