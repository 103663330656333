import React from 'react'
import classnames from 'classnames'

import { Station } from '../../../core/stations'
import { timeSlotTableContent } from './each-station-content'
import timeSlotTableStyles from '../../styles/modules/each-station/time-slot-table.module.scss'
import styles from '../../styles/modules/table.module.scss'
import Switch from 'react-switch'

interface Props {
  station: Station
  openTimeSlot: (stationId: number, timeSlotId: number) => void
  closeTimeSlot: (stationId: number, timeSlotId: number) => void
}

const TimeSlotTable: React.FC<Props> = props => {
  const handleOnClick = (isClosed: boolean, timeSlotId: number) => {
    if (isClosed) {
      props.openTimeSlot(props.station.id, timeSlotId)
    } else {
      props.closeTimeSlot(props.station.id, timeSlotId)
    }
  }

  return (
    <div
      className={classnames({
        [styles.wrapper]: true,
        [styles.zebra]: true,
        [styles.stocker]: true,
      })}
    >
      <table className={styles.container}>
        <tbody>
          <tr className={styles.row}>
            {timeSlotTableContent.map(item => (
              <th key={item} className={styles.cell}>
                {item}
              </th>
            ))}
          </tr>
          {props.station.timeSlots.map(timeSlot => (
            <tr className={styles.row} key={timeSlot.id}>
              <td className={styles.cell}>{timeSlot.id}</td>
              <td className={styles.cell}>{timeSlot.from}</td>
              <td className={styles.cell}>{timeSlot.to}</td>
              <td className={styles.cell}>
                {timeSlot.isClosed ? '停止' : '-'}
              </td>
              <td className={styles.cell}>
                <div className={timeSlotTableStyles.switchContainer}>
                <Switch
                  onChange={() => {
                    handleOnClick(timeSlot.isClosed, timeSlot.id)
                  }}
                  checked={!timeSlot.isClosed}
                />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default TimeSlotTable
