export interface SoracomSIM {
  id: number
  machineID: number
  kioskID: number
  imsi: string
  serial: string
}

export const validation = {
  imsiRegex: /^[0-9]{15}$/,
  serialRegex: /^[0-9a-zA-Z]{2}[0-9]{13}$/,
}
