import React, { useEffect } from 'react'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'

import RootState from '../../core/root-state'
import { Station, stationsActions } from '../../core/stations'
import { SoracomSIM, soracomSIMsActions } from '../../core/soracom-sims'
import { modalActions } from '../../core/modal'
import Layout from '../layouts/layout'
import Wrapper from '../layouts/wrapper'
import { strings } from '../components/kiosk-relations/kiosk-relations-content'
import styles from '../styles/modules/kiosk-relations/kiosk-relations.module.scss'
import KioskRelationsTable from '../components/kiosk-relations/kiosk-relations-table'
import { Kiosk, kiosksActions } from '../../core/kiosks'

interface Props {
  stations: Station[]
  isLoadingStation: boolean
  fetchStations: () => void
  soracomSIMs: SoracomSIM[]
  isLoadingSoracomSIM: boolean
  fetchSoracomSIMs: () => void
  fetchKiosks: () => void
  kiosks: Kiosk[]
  isLoadingKiosk: boolean
  showEditKioskRelation: (
    stations: Station[],
    soracomSIMs: SoracomSIM[],
    kiosks: Kiosk[],
    kiosk: Kiosk
  ) => void
  clearKioskRelation: (kioskID: number) => void
}

const KioskRelationsScreen: React.FC<Props> = props => {
  const { fetchStations, fetchSoracomSIMs, fetchKiosks } = props

  useEffect(() => {
    fetchStations()
    fetchSoracomSIMs()
    fetchKiosks()
  }, [fetchStations, fetchSoracomSIMs, fetchKiosks])

  return (
    <Layout location="kiosk_relations">
      <Wrapper title={strings.title}>
        <div className={styles.container}>
          <KioskRelationsTable
            stations={props.stations}
            soracomSIMs={props.soracomSIMs}
            kiosks={props.kiosks}
            showEditKioskRelation={props.showEditKioskRelation}
            clearKioskRelation={props.clearKioskRelation}
          />
        </div>
      </Wrapper>
    </Layout>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    stations: state.stations.stations,
    isLoadingStation: state.stations.isLoading,
    soracomSIMs: state.soracomSIMs.soracomSIMs,
    isLoadingSoracomSIM: state.soracomSIMs.isLoading,
    kiosks: state.kiosks.kiosks,
    isLoadingKiosk: state.kiosks.isLoading,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchStations: () => dispatch(stationsActions.fetchStations()),
  fetchSoracomSIMs: () => dispatch(soracomSIMsActions.fetchSoracomSIMs()),
  fetchKiosks: () => dispatch(kiosksActions.fetchKiosks()),
  showEditKioskRelation: (
    stations: Station[],
    soracomSIMs: SoracomSIM[],
    kiosks: Kiosk[],
    kiosk: Kiosk
  ) =>
    dispatch(
      modalActions.showEditKioskRelation(stations, soracomSIMs, kiosks, kiosk)
    ),
  clearKioskRelation: (kioskID: number) =>
    dispatch(kiosksActions.clearKioskRelation(kioskID)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(KioskRelationsScreen)
