import React from 'react'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { History, Location } from 'history'
import { match } from 'react-router-dom'
import moment from 'moment'

import RootState from '../../core/root-state'
import { User, UserInfo, usersActions } from '../../core/users'
import { Subscription, subscriptionActions, SubscriptionParam } from '../../core/subscription'
import Layout from '../layouts/layout'
import Wrapper from '../layouts/wrapper'
import QueryId from '../components/users/query-id'
import QueryEmail from '../components/users/query-email'
import UserNotFound from '../components/users/user-not-found'
import Button from '../components/button'
import { strings } from '../components/users/users-content'
import styles from '../styles/modules/users/users.module.scss'
import UserInfoTable from '../components/users/user-info-table'
import SubscriptionTable from '../components/users/subscription-table'

interface Props {
  isLoading: boolean
  active: boolean
  user: User
  users: UserInfo[]
  subscriptions: Subscription[]
  match: match<any>
  location: Location
  history: History
  error: Error
  resetUser: () => void
  fetchUserById: (id: number) => void
  fetchUserByEmail: (email: string) => void
  fetchSubscriptions: (subscriptionParam: SubscriptionParam) => void
  resetSubscriptions: () => void
  deleteUser: (id: number) => void
}

interface State {
  readonly id: number
  readonly email: string
  readonly didSearchFinish: boolean
  readonly date_from: string
  readonly date_to: string
}

class UsersScreen extends React.Component<Props, State> {
  constructor(props) {
    super(props)
    const params = new URLSearchParams(this.props.location.search)
    const email = params.get('email')
    const id = Number(this.props.match.params.id)
    this.state = {
      id: id ? id : null,
      email: email ? email : null,
      didSearchFinish: false,
      date_from: '2010-01-01',
      date_to: moment(new Date()).format('YYYY-MM-DD'),
    }
    this.props.resetUser()
  }

  componentDidMount() {
    if (this.state.id) {
      this.props.fetchUserById(this.state.id)
      const subscriptionParam: SubscriptionParam = {count: 1,  user_id: this.state.id}
      this.props.fetchSubscriptions(subscriptionParam)
      this.setState({ didSearchFinish: true })
    } else if (this.state.email) {
      this.props.fetchUserByEmail(this.state.email)
      this.setState({ didSearchFinish: true })
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.user === null && this.props.user) {
      const subscriptionParam: SubscriptionParam = {count: 1,  user_id: this.props.user.user.id}
      this.props.fetchSubscriptions(subscriptionParam)
    }
    if (this.props.users.length > 0) {
      if (this.props.users !== prevProps.users) {
        this.props.fetchUserById(this.props.users[0].id)
      }
    }
  }

  componentWillUnmount() {
    this.props.resetUser()
    this.props.resetSubscriptions()
  }

  handleOnChangeId = (event: React.ChangeEvent<HTMLInputElement>) => {
    const re = /^[0-9\b]+$/
    if (event.target.value === '') {
      this.setState({ id: null })
    }
    if (re.test(event.target.value)) {
      this.setState({ id: parseInt(event.target.value, 10) })
    }
  }

  handleOnChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ email: event.target.value })
  }

  handleOnClickQueryId = () => {
    this.props.resetUser()
    this.props.resetSubscriptions()
    this.setState({
      email: null,
      didSearchFinish: true
    })
    this.props.history.replace(`/users/${this.state.id}`)
    this.props.fetchUserById(this.state.id)
  }

  handleOnClickQueryEmail = () => {
    this.props.resetUser()
    this.props.resetSubscriptions()
    this.setState({
      id: null,
      didSearchFinish: true
    })
    this.props.history.replace(`/users/?email=${this.state.email}`)
    this.props.fetchUserByEmail(this.state.email)
  }

  render() {
    const { isLoading, active, user, subscriptions } = this.props
    const { id, email, didSearchFinish } = this.state
    return (
      <Layout location={'users'}>
        <Wrapper title={strings.title}>
          <div className={styles.queryContainer}>
            <QueryId id={id} onChange={event => this.handleOnChangeId(event)} />
            <Button
              title={strings.queryButton}
              onClick={this.handleOnClickQueryId}
              className={styles.queryButton}
              isLoading={isLoading && !active}
            />
          </div>
          <div className={styles.queryContainer}>
            <QueryEmail
              email={email}
              onChange={event => this.handleOnChangeEmail(event)}
            />
            <Button
              title={strings.queryButton}
              onClick={this.handleOnClickQueryEmail}
              className={styles.queryButton}
              isLoading={isLoading && !active}
            />
          </div>
          {user && (
            <>
              <UserInfoTable user={user} deleteUser={this.props.deleteUser} />
              <SubscriptionTable subscription={subscriptions[0]} />
            </>
          )}
          {!user && !isLoading && didSearchFinish && <UserNotFound />}
        </Wrapper>
      </Layout>
    )
  }
}

const mapStateToProps = (state: RootState) => ({
  isLoading: state.users.isLoading,
  error: state.users.error,
  active: state.orders.active,
  user: state.users.user,
  users: state.users.users,
  subscriptions: state.subscription.subscriptions,
  orders: state.orders.orders,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  resetUser: () => dispatch(usersActions.resetUser()),
  fetchUserById: (id: number) => dispatch(usersActions.fetchUserById(id)),
  fetchUserByEmail: (email: string) =>
    dispatch(usersActions.fetchUserByEmail(email)),
  fetchSubscriptions: (subscriptionParam: SubscriptionParam) =>
    dispatch(subscriptionActions.fetchSubscriptions(subscriptionParam)),
  resetSubscriptions: () => dispatch(subscriptionActions.resetSubscriptions()),
  deleteUser: (id: number) => dispatch(usersActions.deleteUser(id)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UsersScreen)
