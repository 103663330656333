import { action } from 'typesafe-actions'
import { Auth0UserProfile } from 'auth0-js'

import { roleType } from './auth0'

export enum auth0ActionType {
  LOGIN = 'LOGIN',
  CHECK_AUTHENTICATED = 'CHECK_AUTHENTICATED',
  AUTH_SUCCEEDED = 'AUTH_SUCCEEDED',
  CHECK_SESSION = 'CHECK_SESSION',
  CHECK_SESSION_SUCCEEDED = 'CHECK_SESSION_SUCCEEDED',
  LOGOUT = 'LOGOUT',
  THROW_ERROR = 'THROW_ERROR',
}

export const auth0Actions = {
  login: () => action(auth0ActionType.LOGIN),
  checkAuthenticated: () => action(auth0ActionType.CHECK_AUTHENTICATED),
  checkSession: () => action(auth0ActionType.CHECK_SESSION),
  authSucceeded: (user: Auth0UserProfile, auth: AuthResult, role: roleType) =>
    action(auth0ActionType.AUTH_SUCCEEDED, { user, auth, role }),
  checkSessionSucceeded: (auth: AuthResult, role: roleType) =>
    action(auth0ActionType.CHECK_SESSION_SUCCEEDED, { auth, role }),
  logout: () => action(auth0ActionType.LOGOUT),
  throwError: (error: Error) => action(auth0ActionType.THROW_ERROR, { error }),
}
