import React from 'react'
import classnames from 'classnames'

import { strings, userInfoTableContent } from './points-content'
import styles from '../../styles/modules/table.module.scss'
import { UserInfo } from '../../../core/users'

interface Props {
  userInfo: UserInfo
  amount: number
}

const UserInfoTable: React.FC<Props> = props => (
    <>
      <h3 className={styles.title}>{strings.userInfoTableTitle}</h3>
      <div
      className={classnames({
        [styles.wrapper]: true,
        [styles.stocker]: true,
      })}
    >
      <table className={styles.container}>
        <tbody>
          <tr className={styles.rowStatic}>
            <td className={styles.cell}>{userInfoTableContent.id}</td>
            <td className={styles.cell}>{props.userInfo.id}</td>
          </tr>
          <tr className={styles.rowStatic}>
            <td className={styles.cell}>{userInfoTableContent.name}</td>
            <td className={styles.cell}>{props.userInfo.name}</td>
          </tr>
          <tr className={styles.rowStatic}>
            <td className={styles.cell}>{userInfoTableContent.FirebaseUID}</td>
            <td className={styles.cell}>{props.userInfo.firebaseUID}</td>
          </tr>
          <tr className={styles.rowStatic}>
            <td className={styles.cell}>{userInfoTableContent.amount}</td>
            <td className={styles.cell}>{props.amount}P</td>
          </tr>
        </tbody>
      </table>
    </div>
    </>
  )


export default UserInfoTable
