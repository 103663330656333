import { action } from "typesafe-actions";
import { MachineEmbeddedComputer } from "./machine-embedded-computers";

export enum machineEmbeddedComputersActionType {
  FETCH_MACHINE_EMBEDDED_COMPUTERS = 'FETCH_MACHINE_EMBEDDED_COMPUTERS',
  FETCH_MACHINE_EMBEDDED_COMPUTERS_SUCCEEDED = 'FETCH_MACHINE_EMBEDDED_COMPUTERS_SUCCEEDED',
  DELETE_MACHINE_EMBEDDED_COMPUTER = 'DELETE_MACHINE_EMBEDDED_COMPUTER',
  THROW_ERROR = 'THROW_ERROR',
}

export const machineEmbeddedComputersActions = {
  fetchMachineEmbeddedComputers: () =>
    action(machineEmbeddedComputersActionType.FETCH_MACHINE_EMBEDDED_COMPUTERS),
  fetchMachineEmbeddedComputersSucceeded: (machineEmbeddedComputers: MachineEmbeddedComputer[]) =>
    action(machineEmbeddedComputersActionType.FETCH_MACHINE_EMBEDDED_COMPUTERS_SUCCEEDED, {machineEmbeddedComputers}),
  deleteMachineEmbeddedComputer: (machineEmbeddedComputerId: number) =>
    action(machineEmbeddedComputersActionType.DELETE_MACHINE_EMBEDDED_COMPUTER, {machineEmbeddedComputerId}),
  throwError: (error: Error) =>
    action(machineEmbeddedComputersActionType.THROW_ERROR, { error }),
}
