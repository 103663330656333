import React, { useEffect, useState } from 'react'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'

import { signageBannersActions } from '../../core/signage-banners'
import { modalActions } from '../../core/modal'
import RootState from '../../core/root-state'
import Layout from '../layouts/layout'
import Wrapper from '../layouts/wrapper'
import { SignageBanner } from '../../core/signage-banners'
import { strings } from '../components/signage-banners/signage-banners-content'
import styles from '../styles/modules/signage-banners/signage-banner-items.module.scss'
import SignageBannerTale from '../components/signage-banners/signage-banner-table'

interface Props {
  signageBanners: [SignageBanner]
  isLoading: boolean
  fetchSignageBanners: () => void
  showCreateSignageBanner: () => void
  deleteSignageBanner: (id: number) => void
  sortSignageBanners: (ids: number[]) => void
  updateSignageBanners: (id: number, duration: number, isShow: boolean) => void
  showEditSignageBannerDuration: (
    id: number,
    duration: number,
    isShow: boolean
  ) => void
}

const SignageBannerScreen: React.FC<Props> = ({
  signageBanners,
  fetchSignageBanners,
  showCreateSignageBanner,
  deleteSignageBanner,
  sortSignageBanners,
  updateSignageBanners,
  showEditSignageBannerDuration,
}) => {
  const [items, setItems] = useState<SignageBanner[]>([])

  useEffect(() => {
    fetchSignageBanners()
  }, [fetchSignageBanners])

  const handleOnClickCreateSignageBanner = () => {
    showCreateSignageBanner()
  }

  useEffect(() => {
    setItems(signageBanners)
  }, [signageBanners])

  const handleOnClickDeleteSignageBanner = (id: number) => {
    const response = window.confirm('本当に削除しますか？')
    if (response) {
      deleteSignageBanner(id)
    }
  }

  const handleOnClickEditSignageBannerDuration = (
    id: number,
    duration: number,
    isShow: boolean
  ) => {
    showEditSignageBannerDuration(id, duration, isShow)
  }

  return (
    <Layout location={'signage_banner'}>
      <Wrapper title={strings.title}>
        <div className={styles.container}>
          <SignageBannerTale
            signageBanners={items}
            setSignageBanners={setItems}
            sortSignageBanners={sortSignageBanners}
            updateSignageBanners={updateSignageBanners}
            handleOnClickEditSignageBannerDuration={
              handleOnClickEditSignageBannerDuration
            }
            onClickCreateSignageBanner={handleOnClickCreateSignageBanner}
            onClickDeleteSignageBanner={handleOnClickDeleteSignageBanner}
            showEditSignageBannerDuration={showEditSignageBannerDuration}
          />
        </div>
      </Wrapper>
    </Layout>
  )
}

const mapStateToProps = (state: RootState) => ({
  signageBanners: state.signageBanners.signageBanners,
  isLoading: state.signageBanners.isLoading,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchSignageBanners: () =>
    dispatch(signageBannersActions.fetchSignageBanners()),
  showCreateSignageBanner: () =>
    dispatch(modalActions.showCreateSignageBanner()),
  deleteSignageBanner: (id: number) =>
    dispatch(signageBannersActions.deleteSignageBanner(id)),
  sortSignageBanners: (ids: number[]) =>
    dispatch(signageBannersActions.sortSignageBanners(ids)),
  updateSignageBanners: (id: number, duration: number, isShow: boolean) =>
    dispatch(signageBannersActions.updateSignageBanners(id, duration, isShow)),
  showEditSignageBannerDuration: (
    id: number,
    duration: number,
    isShow: boolean
  ) =>
    dispatch(modalActions.showEditSignageBannerDuration(id, duration, isShow)),
})

export default connect(mapStateToProps, mapDispatchToProps)(SignageBannerScreen)
