import React from 'react'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { match } from 'react-router-dom'

import RootState from '../../core/root-state'
import Layout from '../layouts/layout'
import Wrapper from '../layouts/wrapper'
import {
  Item,
  itemsActions,
  Feature,
  Taste,
  ItemImageType,
  ItemImage,
} from '../../core/items'
import ManufacturingInfoForm from '../components/each-item/manufacturing-info-form'
import ItemInfoTable from '../components/each-item/item-info-table'
import TastesTable from '../components/each-item/tastes-table'
import FeaturesTable from '../components/each-item/features-table'
import ItemImageItems from '../components/each-item/item-image-items'
import { strings } from '../components/each-item/each-item-content'
import styles from '../styles/modules/each-item/each-item.module.scss'
import ManufacturingInfoTable from '../components/each-item/manufacturing-info-table'
import ItemInfoForm from '../components/each-item/item-info-form'
import FeaturesForm from '../components/each-item/features-form'
import TastesForm from '../components/each-item/tastes-form'
import Button from '../components/button'
import ItemImageForms from '../components/each-item/item-image-forms'
import MatchItemInfoTable from '../components/each-item/match-item-info-table'
import MatchItemInfoForm from '../components/each-item/match-item-info-form'

interface Props {
  match: match<any>
  items: Item[]
  itemImage: ItemImage
  fetchItems: () => void
  setItem: (id: number, item: Item) => void
  uploadItemImage: (
    id: number,
    itemImageType: ItemImageType,
    file: File
  ) => void
}

interface State {
  readonly isEditing: boolean
  readonly itemEditing: Item
  readonly newFeature: Feature
  readonly newTaste: Taste
}

class EachItemScreen extends React.Component<Props, State> {
  constructor(props) {
    super(props)
    this.props.fetchItems()
    this.state = {
      isEditing: false,
      itemEditing: null,
      newFeature: { name: '', content: '' },
      newTaste: { name: '', value: null },
    }
  }

  componentDidMount = () => {
    this.props.fetchItems()
  }

  componentDidUpdate = (prevProps: Props) => {
    const { itemImage } = this.props
    if (itemImage) {
      if (prevProps.itemImage !== itemImage) {
        switch (itemImage.itemImageType) {
          case ItemImageType.Image:
            this.setState({
              itemEditing: {
                ...this.state.itemEditing,
                imageURL: itemImage.url,
                imageFileName: itemImage.fileName,
              },
            })
            break
          case ItemImageType.ThumbnailImage:
            this.setState({
              itemEditing: {
                ...this.state.itemEditing,
                thumbnailImageURL: itemImage.url,
                thumbnailImageFileName: itemImage.fileName,
              },
            })
            break
          default:
        }
      }
    }
  }

  isSavable = () => {
    const {
      beanAmount,
      beanTypeIndex,
      machineButtonIndex,
      name,
      price,
      disposalSeconds,
      description,
      story,
      tastes,
      features,
      color,
    } = this.state.itemEditing
    if (name === '' || description === '' || story === '' || color === '') {
      return false
    } else if (
      price === null ||
      price === 0 ||
      disposalSeconds === null ||
      disposalSeconds === 0 ||
      beanAmount === null ||
      beanAmount === 0 ||
      beanTypeIndex === null ||
      beanTypeIndex === 0 ||
      machineButtonIndex === null
    ) {
      return false
    } else if (tastes.length === 0 || features.length === 0) {
      return false
    } else {
      return true
    }
  }

  handleOnClickEdit = (item: Item) => {
    this.setState({ isEditing: true, itemEditing: item })
  }

  handleOnClickQuit = () => {
    this.setState({
      isEditing: false,
      itemEditing: null,
      newFeature: { name: '', content: '' },
      newTaste: { name: '', value: null },
    })
  }

  handleOnClickSave = (id: number) => {
    this.props.setItem(id, this.state.itemEditing)
    this.setState({
      isEditing: false,
      newFeature: { name: '', content: '' },
      newTaste: { name: '', value: null },
    })
  }

  handleOnChangeManufacturingInfo = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const name = event.target.name
    const value = event.target.value
    if (
      name === 'machineButtonIndex' ||
      name === 'beanTypeIndex' ||
      name === 'beanAmount'
    ) {
      const re = /^[0-9\b]+$/
      if (value === '') {
        this.setState({
          itemEditing: {
            ...this.state.itemEditing,
            [name]: null,
          },
        })
      }
      if (re.test(value)) {
        this.setState({
          itemEditing: {
            ...this.state.itemEditing,
            [name]: Number(value),
          },
        })
      }
    }
  }

  handleOnChangeItemInfo = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const name = event.target.name
    const value = event.target.value
    if (name === 'description' || name === 'story') {
      this.setState({
        itemEditing: {
          ...this.state.itemEditing,
          [name]: value,
        },
      })
    } else if (
      name === 'price' ||
      name === 'disposalSeconds' ||
      name === 'index'
    ) {
      const re = /^[0-9\b]+$/
      if (value === '') {
        this.setState({
          itemEditing: {
            ...this.state.itemEditing,
            [name]: null,
          },
        })
      }
      if (re.test(value)) {
        this.setState({
          itemEditing: {
            ...this.state.itemEditing,
            [name]: Number(value),
          },
        })
      }
    }
  }

  handleOnChangeColor = (color: string) => {
    this.setState({
      itemEditing: {
        ...this.state.itemEditing,
        color,
      },
    })
  }

  handleOnChangeTastes = (event: React.ChangeEvent<HTMLInputElement>) => {
    const re = /^[0-9\b]+$/
    const tastes = this.state.itemEditing.tastes.slice()
    const index = event.currentTarget.getAttribute('data-num')
    if (event.target.name === 'name') {
      tastes[index] = {
        name: event.target.value,
        value: tastes[index].value,
      }
    } else if (event.target.name === 'value') {
      if (event.target.value === '') {
        tastes[index] = {
          name: tastes[index].name,
          value: null,
        }
      } else if (re.test(event.target.value)) {
        tastes[index] = {
          name: tastes[index].name,
          value: Number(event.target.value),
        }
      }
    }
    this.setState({
      itemEditing: {
        ...this.state.itemEditing,
        tastes,
      },
    })
  }

  handleOnChangeNewTastes = (event: React.ChangeEvent<HTMLInputElement>) => {
    const re = /^[0-9\b]+$/
    if (event.target.name === 'name') {
      this.setState({
        newTaste: {
          name: event.target.value,
          value: this.state.newTaste.value,
        },
      })
    } else if (event.target.name === 'value') {
      if (event.target.value === '') {
        this.setState({
          newTaste: {
            name: this.state.newTaste.name,
            value: null,
          },
        })
      } else if (re.test(event.target.value)) {
        this.setState({
          newTaste: {
            name: this.state.newTaste.name,
            value: Number(event.target.value),
          },
        })
      }
    }
  }

  handleOnAddNewTaste = () => {
    const tastes = this.state.itemEditing.tastes.slice()
    tastes.push(this.state.newTaste)
    this.setState({
      itemEditing: { ...this.state.itemEditing, tastes },
      newTaste: { name: '', value: null },
    })
  }

  handleOnDeleteTaste = (event: React.MouseEvent<HTMLElement>) => {
    const index = Number(event.currentTarget.getAttribute('data-num'))
    const tastes = this.state.itemEditing.tastes.slice()
    tastes.splice(index, 1)
    this.setState({
      itemEditing: { ...this.state.itemEditing, tastes },
    })
  }

  handleOnChangeFeatures = (event: React.ChangeEvent<HTMLInputElement>) => {
    const features = this.state.itemEditing.features.slice()
    const index = event.currentTarget.getAttribute('data-num')
    if (event.target.name === 'name') {
      features[index] = {
        name: event.target.value,
        content: features[index].content,
      }
    } else if (event.target.name === 'content') {
      features[index] = {
        name: features[index].name,
        content: event.target.value,
      }
    }
    this.setState({
      itemEditing: {
        ...this.state.itemEditing,
        features,
      },
    })
  }

  handleOnChangeNewFeature = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.name === 'name') {
      this.setState({
        newFeature: {
          name: event.target.value,
          content: this.state.newFeature.content,
        },
      })
    } else if (event.target.name === 'content') {
      this.setState({
        newFeature: {
          name: this.state.newFeature.name,
          content: event.target.value,
        },
      })
    }
  }

  handleOnAddNewFeature = () => {
    const features = this.state.itemEditing.features.slice()
    features.push(this.state.newFeature)
    this.setState({
      itemEditing: { ...this.state.itemEditing, features },
      newFeature: { name: '', content: '' },
    })
  }

  handleOnDeleteFeature = (event: React.MouseEvent<HTMLLIElement>) => {
    const index = Number(event.currentTarget.getAttribute('data-num'))
    const features = this.state.itemEditing.features.slice()
    features.splice(index, 1)
    this.setState({
      itemEditing: { ...this.state.itemEditing, features },
    })
  }

  handleOnChangeMatchItemInfo = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const re = /^[0-9\b]+$/
    const name = event.target.name
    const value = event.target.value
    const matchItemParam = { ...this.state.itemEditing.matchItemParam }
    if (
      name === 'bitterness' ||
      name === 'fragrance' ||
      name === 'acidity' ||
      name === 'sweetness' ||
      name === 'richness' ||
      name === 'uniqueness'
    ) {
      if (value === '') {
        matchItemParam[name] = 0
        this.setState({
          itemEditing: {
            ...this.state.itemEditing,
            matchItemParam,
          },
        })
      }
      if (re.test(value)) {
        matchItemParam[name] = Number(value)
        this.setState({
          itemEditing: {
            ...this.state.itemEditing,
            matchItemParam,
          },
        })
      }
    }
  }

  render() {
    const id = this.props.match.params.id
    const item = this.props.items.find(i => i.id === Number(id))
    const { isEditing, itemEditing, newTaste, newFeature } = this.state
    const { itemImage, uploadItemImage } = this.props
    return (
      <Layout location={'items'}>
        <Wrapper title={`ID: ${id} ${item ? item.name : ''}`}>
          {item && (
            <>
              {isEditing ? (
                <>
                  <Button
                    className={styles.itemEditButton}
                    title={strings.quit}
                    onClick={() => this.handleOnClickQuit()}
                  />
                  <Button
                    className={styles.itemEditButton}
                    title={strings.save}
                    disabled={!this.isSavable()}
                    onClick={() => this.handleOnClickSave(id)}
                  />
                  <h3 className={styles.title}>{strings.manufacturingInfo}</h3>
                  <ManufacturingInfoForm
                    item={itemEditing}
                    onChange={event =>
                      this.handleOnChangeManufacturingInfo(event)
                    }
                  />
                  <h3 className={styles.title}>{strings.itemInfo}</h3>
                  <ItemInfoForm
                    item={itemEditing}
                    maxIndex={this.props.items.length}
                    onChange={event => this.handleOnChangeItemInfo(event)}
                    onChangeColor={color => this.handleOnChangeColor(color)}
                  />
                  <h3 className={styles.title}>{strings.tastes}</h3>
                  <TastesForm
                    item={itemEditing}
                    newTaste={newTaste}
                    onChangeTastes={event => this.handleOnChangeTastes(event)}
                    onChangeNewTaste={event =>
                      this.handleOnChangeNewTastes(event)
                    }
                    onAddNewTaste={() => this.handleOnAddNewTaste()}
                    onDeleteTaste={this.handleOnDeleteTaste}
                  />
                  <h3 className={styles.title}>{strings.features}</h3>
                  <FeaturesForm
                    item={itemEditing}
                    newFeature={newFeature}
                    onChangeFeatures={event =>
                      this.handleOnChangeFeatures(event)
                    }
                    onChangeNewFeature={event =>
                      this.handleOnChangeNewFeature(event)
                    }
                    onAddNewFeature={() => this.handleOnAddNewFeature()}
                    onDeleteFeature={this.handleOnDeleteFeature}
                  />
                  <h3 className={styles.title}>{strings.matchItem}</h3>
                  <MatchItemInfoForm
                    item={itemEditing}
                    onChange={event => this.handleOnChangeMatchItemInfo(event)}
                  />
                  <ItemImageForms
                    id={id}
                    item={item}
                    itemImage={itemImage}
                    uploadItemImage={uploadItemImage}
                  />
                </>
              ) : (
                <>
                  <Button
                    className={styles.itemEditButton}
                    title={strings.edit}
                    onClick={() => this.handleOnClickEdit(item)}
                  />
                  <h3 className={styles.title}>{strings.manufacturingInfo}</h3>
                  <ManufacturingInfoTable item={item} />
                  <h3 className={styles.title}>{strings.itemInfo}</h3>
                  <ItemInfoTable item={item} />
                  <h3 className={styles.title}>{strings.tastes}</h3>
                  <TastesTable item={item} />
                  <h3 className={styles.title}>{strings.features}</h3>
                  <FeaturesTable item={item} />
                  <h3 className={styles.title}>{strings.matchItem}</h3>
                  <MatchItemInfoTable item={item} />
                  <h3 className={styles.title}>{strings.images}</h3>
                  <ItemImageItems item={item} />
                </>
              )}
            </>
          )}
        </Wrapper>
      </Layout>
    )
  }
}

const mapStateToProps = (state: RootState) => ({
  items: state.items.items,
  itemImage: state.items.itemImage,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchItems: () => dispatch(itemsActions.fetchItems()),
  setItem: (id: number, item: Item) => dispatch(itemsActions.setItem(id, item)),
  uploadItemImage: (id: number, itemImageType: ItemImageType, file: File) =>
    dispatch(itemsActions.uploadItemImage(id, itemImageType, file)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EachItemScreen)
