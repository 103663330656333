import React from 'react'
import classnames from 'classnames'

import styles from '../styles/modules/input.module.scss'

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string
  className?: string
  children?: React.ReactNode
}

export default class Input extends React.PureComponent<Props, {}> {
  render() {
    const { label, className, ...rest } = this.props
    return (
      <div className={classnames({ [styles.form]: true, [className]: true })}>
        {label && <label className={styles.label}>{label}</label>}
        {this.props.children ? this.props.children : <input {...rest} className={styles.input} />}
      </div>
    )
  }
}
