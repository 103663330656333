import React from 'react'
import classnames from 'classnames'

import DatePicker from 'react-datepicker'
import styles from '../styles/modules/date-input.module.scss'

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  onChangeDate: (date: Date) => void
  date?: Date
  label?: string
  className?: string
  children?: React.ReactNode
}

export default class OptionalDateInput extends React.PureComponent<Props> {
  readonly state = {
    shouldSelectDate: false,
  }
  render() {
    const { onChangeDate, label, date, className } = this.props
    return (
      <>
        <div className={classnames({ [styles.form]: true, [className]: true })}>
          {label && <label className={styles.label}>{label}</label>}
          <div className={styles.pickerWrapper}>
            <DatePicker
              className={styles.input}
              onChange={e => onChangeDate(e)}
              selected={date}
              dateFormat={'yyyy/MM/dd'}
            />
          </div>
        </div>
      </>
    )
  }
}
