import React from 'react'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import classnames from 'classnames'

import RootState from '../../../core/root-state'
import {
  EditKioskRelationData,
  EditSignageBannerSequenceData,
  SHowImageData,
  modalActions,
} from '../../../core/modal'
import { Config, toastActions } from '../../../core/toast'
import { stationsActions } from '../../../core/stations'
import { Campaign, campaignsActions } from '../../../core/campaigns'
import { promotionImagesActions } from '../../../core/promotion-images'
import { signageBannersActions } from '../../../core/signage-banners'
import { pointActions } from '../../../core/points'
import { stationAlertActions } from '../../../core/station-alerts'
import { soracomSIMsActions } from '../../../core/soracom-sims'
import { machinesActions } from '../../../core/machines'
import {
  TimeSlotPreset,
  timeSlotPresetsActions,
} from '../../../core/time-slot-presets'
import { stationWithItemRanksActions } from '../../../core/station-with-item-ranks'
import {
  ModalStateType,
  CreateEmergencyData,
  CreatePromotionCampaignData,
  EditPromotionCampaignStatusData,
  EditPromotionCampaignData,
  EditStarterCampaignData,
  EditStampCampaignData,
  EditOrderCampaignData,
  CreateGivePointsData,
  CreateExpirePointsData,
  CreateStationAlertData,
  EditStationAlertData,
  EditLockerBoxCommentData,
  CreateLockerBoxCommentData,
  EditSoracomSIMData,
  EditMachineData,
  EditMachineRelationData,
  EditTimeSlotPresetsData,
  EditPromotionImageData,
  EditStationWithItemRanksData,
  EditKioskItemPriceData,
} from '../../../core/modal/index'
import { strings } from './modal-content'
import styles from '../../styles/modules/modal/modal.module.scss'
import { CreateEmergencyForm } from './create-emergency-form'
import { CreatePromotionCampaignForm } from './create-promotion-campaign-form'
import { EditPromotionCampaignStatusForm } from './edit-promotion-campaign-status-form'
import { EditPromotionCampaignForm } from './edit-promotion-campaign-form'
import { CreatePromotionImageForm } from './create-promotion-image-form'
import { EditPromotionImageForm } from './edit-promotion-image-form'
import { CreateSignageBannerForm } from './create-signage-banner-form'
import { CreateGivePointsForm } from './create-give-points-form'
import { CreateStationAlertForm } from './create-station-alert-form'
import { EditStationAlertForm } from './edit-station-alert-form'
import { EditLockerBoxCommentForm } from './edit-locker-box-comment-form'
import { CreateLockerBoxCommentForm } from './create-locker-box-comment-form'
import { CreateSoracomSIMForm } from './create-soracom-sim-form'
import { CreateStationForm } from './create-station-form'
import { EditSoracomSIMForm } from './edit-soracom-sim-form'
import { CreateMachineForm } from './create-machine-form'
import { EditMachineForm } from './edit-machine-form'
import { EditMachineRelationForm } from './edit-machine-relation-form'
import { EditTimeSlotPresetsForm } from './edit-time-slot-presets-form'
import { EditSignageBannerDurationForm } from './edit-signage-banner-duration-form'
import { UpdateStationWithItemRanksBody } from '../../../core/station-with-item-ranks'
import EditStarterCampaignForm from './edit-starter-campaign-form'
import EditOrderCampaignForm from './edit-order-campaign-form'
import EditStampCampaignForm from './edit-stamp-campaign-form'
import { EditKioskRelationForm } from './edit-kiosk-relation-form'
import { kiosksActions } from '../../../core/kiosks'
import EditStationWithItemRanksForm from './edit-station-with-item-ranks-form'
import CreateKioskForm from './create-kiosk-form'
import EditKioskItemPriceForm from './edit-kiosk-item-price-form'
import {
  KioskItemPrice,
  kioskItemPricesActions,
} from '../../../core/kiosk-item-prices'
import { CreateExpirePointsForm } from './create-expire-points-form'
import { ShowImageModal } from './image-modal'
import { CreatePointItemForm } from './create-point-item-form'
import { pointItemsActions } from '../../../core/point-items'

interface Props {
  createEmergencyData: CreateEmergencyData
  createPromotionCampaignData: CreatePromotionCampaignData
  editPromotionCampaignStatusData: EditPromotionCampaignStatusData
  editPromotionCampaignData: EditPromotionCampaignData
  editStarterCampaignData: EditStarterCampaignData
  editStampCampaignData: EditStampCampaignData
  editOrderCampaignData: EditOrderCampaignData
  createGivePointsData: CreateGivePointsData
  createExpirePointsData: CreateExpirePointsData
  createStationAlertData: CreateStationAlertData
  editStationAlertData: EditStationAlertData
  editLockerBoxCommentData: EditLockerBoxCommentData
  createLockerBoxCommentData: CreateLockerBoxCommentData
  editSoracomSIMData: EditSoracomSIMData
  editMachineData: EditMachineData
  editMachineRelationData: EditMachineRelationData
  editKioskRelationData: EditKioskRelationData
  editTimeSlotPresetsData: EditTimeSlotPresetsData
  editSignageBannerSequenceData: EditSignageBannerSequenceData
  editPromotionImageData: EditPromotionImageData
  editStationWithItemRanksData: EditStationWithItemRanksData
  editKioskItemPriceData: EditKioskItemPriceData
  showImageData: SHowImageData
  isLoading: boolean
  isVisible: boolean
  type: ModalStateType
  hide: () => void
  show: (config: Config) => void
  createEmergency: (
    stationId: number,
    message: string,
    canOpenLockerBox: boolean
  ) => void
  createPromotionCampaign: (
    code: string,
    name: string,
    discountAmount: number,
    memo: string,
    availableCount: number,
    entryCountLimit: number,
    expiresAt: string,
    eligibleStationIds?: number[]
  ) => void
  setPromotionCampaign: (campaignId: number, campaign: Campaign) => void
  setStarterCampaign: (campaignId: number, campaign: Campaign) => void
  setStampCampaign: (campaignId: number, campaign: Campaign) => void
  setOrderCampaign: (campaignId: number, campaign: Campaign) => void
  setStationItemAvailable: (itemId: number, stationId: number) => void
  setStationItemUnavailable: (itemId: number, stationId: number) => void
  createPromotionImage: (
    file: File,
    linkUrl: string,
    title: string,
    description: string
  ) => void
  setPromotionImages: (
    promotionImageId: number,
    file: File,
    linkUrl: string,
    title: string,
    description: string
  ) => void
  createSignageBanner: (
    file: File,
    title: string,
    extension: string,
    mimeType: string,
    duration: number,
    base64File: string,
    adCaption: string
  ) => void
  updateSignageBannerDuration: (
    id: number,
    duration: number,
    isShow: boolean
  ) => void
  createPoints: (
    userId: number,
    point: number,
    description: string,
    calculationType: 'give' | 'expire'
  ) => void
  createStationAlert: (stationId: number, title: string, body: string) => void
  setStationAlert: (
    stationAlertID: number,
    stationID: number,
    title: string,
    body: string
  ) => void
  deleteStationAlert: (stationAlertID: number) => void
  restoreStationAlert: (stationAlertID: number) => void
  createLockerBoxComment: (
    stationId: number,
    lockerBoxId: number,
    comment: string
  ) => void
  setLockerBoxComment: (
    stationId: number,
    lockerBoxId: number,
    lockerBoxCommentId: number,
    comment: string
  ) => void
  removeLockerBoxComment: (
    stationId: number,
    lockerBoxId: number,
    lockerBoxCommentId: number
  ) => void
  createStation: (
    name: string,
    extra: string,
    fullAddress: string,
    openingHours: string,
    latitude: number,
    longitude: number
  ) => void
  createSoracomSIM: (imsi: string, serial: string) => void
  setSoracomSIM: (soracomSIMId: number, imsi: string, serial: string) => void
  createMachine: (
    serial: string,
    manufacturedDate: Date,
    type: string,
    version: string,
    factoryName: string
  ) => void
  setMachine: (
    machineId: number,
    serial: string,
    manufacturedDate: Date,
    type: string,
    version: string,
    factoryName: string,
    shouldBeLatest: boolean
  ) => void
  setMachineRelation: (
    machineId: number,
    stationId: number,
    soracomSIMId: number
  ) => void
  setKioskRelation: (
    kioskId: number,
    stationId: number,
    soracomSIMId: number
  ) => void
  setTimeSlotPresets: (
    stationId: number,
    timeSlotPresets: TimeSlotPreset[]
  ) => void
  setStationWithItemRanks: (body: UpdateStationWithItemRanksBody) => void
  createKiosk: (serial: string) => void
  setKioskItemPrice: (kioskItemPrice: KioskItemPrice, price: number) => void
  createPointItem: (
    name: string,
    imageFile: File,
    isDeliveryRequired: boolean,
    pointsPrice: number,
    stock: number,
    isAvailable: boolean,
    isInfiniteStock: boolean
  ) => void
}

const Form = (props: Props) => {
  switch (props.type) {
    case ModalStateType.CreateEmergency:
      return (
        <CreateEmergencyForm
          station={props.createEmergencyData.station}
          isLoading={props.isLoading}
          createEmergency={props.createEmergency}
        />
      )
    case ModalStateType.CreatePromotionCampaign:
      return (
        <CreatePromotionCampaignForm
          stations={props.createPromotionCampaignData.stations}
          show={props.show}
          isLoading={props.isLoading}
          createPromotionCampaign={props.createPromotionCampaign}
        />
      )
    case ModalStateType.EditPromotionCampaignStatus:
      return (
        <EditPromotionCampaignStatusForm
          campaignId={
            props.editPromotionCampaignStatusData.promotionCampaign.id
          }
          promotionCampaign={
            props.editPromotionCampaignStatusData.promotionCampaign
          }
          isLoading={props.isLoading}
          setPromotionCampaign={props.setPromotionCampaign}
        />
      )
    case ModalStateType.EditPromotionCampaign:
      return (
        <EditPromotionCampaignForm
          promotionCampaign={props.editPromotionCampaignData.promotionCampaign}
          setPromotionCampaign={props.setPromotionCampaign}
          show={props.show}
          isLoading={props.isLoading}
        />
      )
    case ModalStateType.EditStarterCampaign:
      return (
        <EditStarterCampaignForm
          campaignId={props.editStarterCampaignData.starterCampaign.id}
          starterCampaign={props.editStarterCampaignData.starterCampaign}
          isLoading={props.isLoading}
          setStarterCampaign={props.setStarterCampaign}
          show={props.show}
        />
      )
    case ModalStateType.EditStampCampaign:
      return (
        <EditStampCampaignForm
          campaignId={props.editStampCampaignData.stampCampaign.id}
          stampCampaign={props.editStampCampaignData.stampCampaign}
          isLoading={props.isLoading}
          setStampCampaign={props.setStampCampaign}
          show={props.show}
        />
      )
    case ModalStateType.EditOrderCampaign:
      return (
        <EditOrderCampaignForm
          campaignId={props.editOrderCampaignData.orderCampaign.id}
          orderCampaign={props.editOrderCampaignData.orderCampaign}
          isLoading={props.isLoading}
          setOrderCampaign={props.setOrderCampaign}
          show={props.show}
        />
      )
    case ModalStateType.CreatePromotionImage:
      return (
        <CreatePromotionImageForm
          isLoading={props.isLoading}
          createPromotionImage={props.createPromotionImage}
          show={props.show}
        />
      )
    case ModalStateType.EditPromotionImage:
      return (
        <EditPromotionImageForm
          promotionImage={props.editPromotionImageData.promotionImage}
          setPromotionImages={props.setPromotionImages}
          show={props.show}
          isLoading={props.isLoading}
        />
      )
    case ModalStateType.CreateSignageBanner:
      return (
        <CreateSignageBannerForm
          isLoading={props.isLoading}
          createSignageBanner={props.createSignageBanner}
          show={props.show}
        />
      )
    case ModalStateType.EditSignageBannerSequence:
      return (
        <EditSignageBannerDurationForm
          isLoading={props.isLoading}
          editSignageBannerSequenceData={props.editSignageBannerSequenceData}
          updateSignageBannerDuration={props.updateSignageBannerDuration}
          show={props.show}
        />
      )
    case ModalStateType.CreateGivePoints:
      return (
        <CreateGivePointsForm
          userId={props.createGivePointsData.userId}
          isLoading={props.isLoading}
          show={props.show}
          createPoints={props.createPoints}
        />
      )
    case ModalStateType.CreateExpirePoints:
      return (
        <CreateExpirePointsForm
          userId={props.createExpirePointsData.userId}
          isLoading={props.isLoading}
          show={props.show}
          createPoints={props.createPoints}
        />
      )
    case ModalStateType.CreateStationAlert:
      return (
        <CreateStationAlertForm
          isLoading={props.isLoading}
          stations={props.createStationAlertData.stations}
          show={props.show}
          createStationAlert={props.createStationAlert}
        />
      )
    case ModalStateType.EditStationAlert:
      return (
        <EditStationAlertForm
          isLoading={props.isLoading}
          stations={props.editStationAlertData.stations}
          stationAlert={props.editStationAlertData.stationAlert}
          setStationAlert={props.setStationAlert}
          deleteStationAlert={props.deleteStationAlert}
          restoreStationAlert={props.restoreStationAlert}
          show={props.show}
        />
      )
    case ModalStateType.EditLockerBoxComment:
      return (
        <EditLockerBoxCommentForm
          isLoading={props.isLoading}
          stationId={props.editLockerBoxCommentData.stationId}
          lockerBoxId={props.editLockerBoxCommentData.lockerBoxId}
          lockerBoxIndex={props.editLockerBoxCommentData.lockerBoxIndex}
          lockerBoxComment={props.editLockerBoxCommentData.lockerBoxComment}
          setLockerBoxComment={props.setLockerBoxComment}
          removeLockerBoxComment={props.removeLockerBoxComment}
          show={props.show}
        />
      )
    case ModalStateType.CreateLockerBoxComment:
      return (
        <CreateLockerBoxCommentForm
          isLoading={props.isLoading}
          stationId={props.createLockerBoxCommentData.stationId}
          lockerBoxId={props.createLockerBoxCommentData.lockerBoxId}
          lockerBoxIndex={props.createLockerBoxCommentData.lockerBoxIndex}
          createLockerBoxComment={props.createLockerBoxComment}
          show={props.show}
        />
      )
    case ModalStateType.CreateStation:
      return (
        <CreateStationForm
          isLoading={props.isLoading}
          createStation={props.createStation}
          show={props.show}
        />
      )
    case ModalStateType.CreateSoracomSIM:
      return (
        <CreateSoracomSIMForm
          isLoading={props.isLoading}
          createSoracomSIM={props.createSoracomSIM}
          show={props.show}
        />
      )
    case ModalStateType.EditSoracomSIM:
      return (
        <EditSoracomSIMForm
          isLoading={props.isLoading}
          soracomSIM={props.editSoracomSIMData.soracomSIM}
          setSoracomSIM={props.setSoracomSIM}
          show={props.show}
        />
      )
    case ModalStateType.CreateMachine:
      return (
        <CreateMachineForm
          isLoading={props.isLoading}
          createMachine={props.createMachine}
          show={props.show}
        />
      )
    case ModalStateType.EditMachine:
      return (
        <EditMachineForm
          isLoading={props.isLoading}
          machine={props.editMachineData.machine}
          setMachine={props.setMachine}
          show={props.show}
        />
      )
    case ModalStateType.EditMachineRelation:
      return (
        <EditMachineRelationForm
          isLoading={props.isLoading}
          stations={props.editMachineRelationData.stations}
          soracomSIMs={props.editMachineRelationData.soracomSIMs}
          machines={props.editMachineRelationData.machines}
          machine={props.editMachineRelationData.machine}
          setMachineRelation={props.setMachineRelation}
          show={props.show}
        />
      )
    case ModalStateType.EditKioskRelation:
      return (
        <EditKioskRelationForm
          isLoading={props.isLoading}
          stations={props.editKioskRelationData.stations}
          soracomSIMs={props.editKioskRelationData.soracomSIMs}
          kiosks={props.editKioskRelationData.kiosks}
          kiosk={props.editKioskRelationData.kiosk}
          setKioskRelation={props.setKioskRelation}
          show={props.show}
        />
      )
    case ModalStateType.EditTimeSlotPresets:
      return (
        <EditTimeSlotPresetsForm
          isLoading={props.isLoading}
          timeSlots={props.editTimeSlotPresetsData.timeSlots}
          timeSlotPresets={props.editTimeSlotPresetsData.timeSlotPresets}
          setTimeSlotPresets={props.setTimeSlotPresets}
          show={props.show}
        />
      )
    case ModalStateType.EditStationWithItemRanks:
      return (
        <EditStationWithItemRanksForm
          isLoading={props.isLoading}
          items={props.editStationWithItemRanksData.items}
          stationWithItemRanks={
            props.editStationWithItemRanksData.stationWithItemRanks
          }
          setStationWithItemRanks={props.setStationWithItemRanks}
          show={props.show}
        />
      )
    case ModalStateType.CreateKiosk:
      return (
        <CreateKioskForm
          isLoading={props.isLoading}
          createKiosk={props.createKiosk}
          show={props.show}
        />
      )
    case ModalStateType.EditKioskItemPrice:
      return (
        <EditKioskItemPriceForm
          isLoading={props.isLoading}
          kioskItemPrice={props.editKioskItemPriceData.kioskItemPrice}
          setKioskItemPrice={props.setKioskItemPrice}
          show={props.show}
        />
      )
    case ModalStateType.CreatePointItem:
      return (
        <CreatePointItemForm
          isLoading={props.isLoading}
          show={props.show}
          createPointItem={props.createPointItem}
        />
      )
    case ModalStateType.ShowImage:
      return (
        <ShowImageModal
          imageUrl={props.showImageData.imageUrl}
          alt={props.showImageData.alt}
        />
      )
  }
}

class Modal extends React.PureComponent<Props> {
  componentDidMount = () => {
    document.addEventListener(
      'keydown',
      event => this.handleOnPressEsc(event),
      false
    )
  }

  handleOnClickClose = () => {
    const caution =
      this.props.type === ModalStateType.ShowImage
        ? strings.closeShowCaution
        : strings.closeEditCaution
    const response = window.confirm(caution)
    if (!response) {
      return
    }
    this.props.hide()
  }

  handleOnPressEsc = (event: KeyboardEvent) => {
    if (event.keyCode === 27 && this.props.isVisible) {
      this.handleOnClickClose()
    }
  }

  render() {
    const { isVisible } = this.props

    return (
      <div
        className={classnames({
          [styles.container]: true,
          [styles.isVisible]: isVisible,
        })}
      >
        <div
          className={styles.overlay}
          onClick={() => this.handleOnClickClose()}
        />
        <div className={styles.wrapper}>
          <header className={styles.header}>
            <div
              className={styles.button}
              onClick={() => this.handleOnClickClose()}
            >
              <span />
            </div>
          </header>
          {isVisible && <Form {...this.props} />}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state: RootState) => ({
  isVisible: state.modal.isVisible,
  isLoading: state.stations.isLoading,
  type: state.modal.type,
  createEmergencyData: state.modal.createEmergencyData,
  createPromotionCampaignData: state.modal.createPromotionCampaignData,
  editPromotionCampaignStatusData: state.modal.editPromotionCampaignStatusData,
  editPromotionCampaignData: state.modal.editPromotionCampaignData,
  editStarterCampaignData: state.modal.editStarterCampaignData,
  editStampCampaignData: state.modal.editStampCampaignData,
  editOrderCampaignData: state.modal.editOrderCampaignData,
  createGivePointsData: state.modal.createGivePointsData,
  createExpirePointsData: state.modal.createExpirePointsData,
  createStationAlertData: state.modal.createStationAlertData,
  editStationAlertData: state.modal.editStationAlertData,
  editLockerBoxCommentData: state.modal.editLockerBoxCommentData,
  createLockerBoxCommentData: state.modal.createLockerBoxCommentData,
  editSoracomSIMData: state.modal.editSoracomSIMData,
  editMachineData: state.modal.editMachineData,
  editMachineRelationData: state.modal.editMachineRelationData,
  editKioskRelationData: state.modal.editKioskRelationData,
  editTimeSlotPresetsData: state.modal.editTimeSlotPresetsData,
  editSignageBannerSequenceData: state.modal.editSignageBannerSequenceData,
  editPromotionImageData: state.modal.editPromotionImageData,
  editStationWithItemRanksData: state.modal.editStationWithItemRanksData,
  editKioskItemPriceData: state.modal.editKioskItemPriceData,
  showImageData: state.modal.showImageData,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  hide: () => dispatch(modalActions.hide()),
  show: (config: Config) => dispatch(toastActions.show(config)),
  createEmergency: (
    stationId: number,
    message: string,
    canOpenLockerBox: boolean
  ) =>
    dispatch(
      stationsActions.createEmergency(stationId, message, canOpenLockerBox)
    ),
  createPromotionCampaign: (
    code: string,
    name: string,
    discountAmount: number,
    memo: string,
    availableCount: number,
    entryCountLimit: number,
    expiresAt: string,
    eligibleStationIds?: number[]
  ) =>
    dispatch(
      campaignsActions.createPromotionCampaign(
        code,
        name,
        discountAmount,
        memo,
        availableCount,
        entryCountLimit,
        expiresAt,
        eligibleStationIds
      )
    ),
  setPromotionCampaign: (campaignId: number, campaign: Campaign) =>
    dispatch(campaignsActions.setPromotionCampaign(campaignId, campaign)),
  setStarterCampaign: (campaignId: number, campaign: Campaign) =>
    dispatch(campaignsActions.setStarterCampaign(campaignId, campaign)),
  setStampCampaign: (campaignId: number, campaign: Campaign) =>
    dispatch(campaignsActions.setStampCampaign(campaignId, campaign)),
  setOrderCampaign: (campaignId: number, campaign: Campaign) =>
    dispatch(campaignsActions.setOrderCampaign(campaignId, campaign)),
  setStationItemAvailable: (itemId: number, stationId: number) =>
    dispatch(stationsActions.setStationItemAvailable(itemId, stationId)),
  setStationItemUnavailable: (itemId: number, stationId: number) =>
    dispatch(stationsActions.setStationItemUnavailable(itemId, stationId)),
  createPromotionImage: (
    file: File,
    linkUrl: string,
    title: string,
    description: string
  ) =>
    dispatch(
      promotionImagesActions.createPromotionImage(
        file,
        linkUrl,
        title,
        description
      )
    ),
  editPromotionImage: (
    promotionImageId: number,
    file: File,
    linkUrl: string,
    title: string,
    description: string
  ) =>
    dispatch(
      promotionImagesActions.updatePromotionImage(
        promotionImageId,
        file,
        linkUrl,
        title,
        description
      )
    ),
  createSignageBanner: (
    file: File,
    title: string,
    extension: string,
    mimeType: string,
    duration: number,
    base64File: string,
    adCaption: string
  ) =>
    dispatch(
      signageBannersActions.createSignageBanners(
        file,
        title,
        extension,
        mimeType,
        duration,
        base64File,
        adCaption
      )
    ),
  updateSignageBannerDuration: (id: number, index: number, isShow: boolean) =>
    dispatch(signageBannersActions.updateSignageBanners(id, index, isShow)),
  createPoints: (
    userId: number,
    point: number,
    description: string,
    calculationType: 'give' | 'expire'
  ) =>
    dispatch(
      pointActions.createPoints(userId, point, description, calculationType)
    ),
  createStationAlert: (stationID: number, title: string, body: string) =>
    dispatch(stationAlertActions.createStationAlert(stationID, title, body)),
  setStationAlert: (
    stationAlertID: number,
    stationID: number,
    title: string,
    body: string
  ) =>
    dispatch(
      stationAlertActions.updateStationAlert(
        stationAlertID,
        stationID,
        title,
        body
      )
    ),
  deleteStationAlert: (stationAlertID: number) =>
    dispatch(stationAlertActions.deleteStationAlert(stationAlertID)),
  restoreStationAlert: (stationAlertID: number) =>
    dispatch(stationAlertActions.restoreStationAlert(stationAlertID)),
  createLockerBoxComment: (
    stationId: number,
    lockerBoxId: number,
    comment: string
  ) =>
    dispatch(
      stationsActions.createLockerBoxComment(stationId, lockerBoxId, comment)
    ),
  setLockerBoxComment: (
    stationId: number,
    lockerBoxId: number,
    lockerBoxCommentId: number,
    comment: string
  ) =>
    dispatch(
      stationsActions.setLockerBoxComment(
        stationId,
        lockerBoxId,
        lockerBoxCommentId,
        comment
      )
    ),
  removeLockerBoxComment: (
    stationId: number,
    lockerBoxId: number,
    lockerBoxCommentId: number
  ) =>
    dispatch(
      stationsActions.removeLockerBoxComment(
        stationId,
        lockerBoxId,
        lockerBoxCommentId
      )
    ),
  createStation: (
    name: string,
    extra: string,
    fullAddress: string,
    openingHours: string,
    latitude: number,
    longitude: number
  ) =>
    dispatch(
      stationsActions.createStation(
        name,
        extra,
        fullAddress,
        openingHours,
        latitude,
        longitude
      )
    ),
  createSoracomSIM: (imsi: string, serial: string) =>
    dispatch(soracomSIMsActions.createSoracomSIM(imsi, serial)),
  setSoracomSIM: (soracomSIMId: number, imsi: string, serial: string) =>
    dispatch(soracomSIMsActions.updateSoracomSIM(soracomSIMId, imsi, serial)),
  createMachine: (
    serial: string,
    manufacturedDate: Date,
    type: string,
    version: string,
    factoryName: string
  ) =>
    dispatch(
      machinesActions.createMachine(
        serial,
        manufacturedDate,
        type,
        version,
        factoryName
      )
    ),
  setMachine: (
    machineId: number,
    serial: string,
    manufacturedDate: Date,
    type: string,
    version: string,
    factoryName: string,
    shouldBeLatest: boolean
  ) =>
    dispatch(
      machinesActions.updateMachine(
        machineId,
        serial,
        manufacturedDate,
        type,
        version,
        factoryName,
        shouldBeLatest
      )
    ),
  setMachineRelation: (
    machineId: number,
    stationId: number,
    soracomSIMId: number
  ) =>
    dispatch(
      machinesActions.updateMachineRelation(machineId, stationId, soracomSIMId)
    ),
  setKioskRelation: (
    kioskId: number,
    stationId: number,
    soracomSIMId: number
  ) =>
    dispatch(
      kiosksActions.updateKioskRelation(kioskId, stationId, soracomSIMId)
    ),
  setTimeSlotPresets: (stationId: number, timeSlotPresets: TimeSlotPreset[]) =>
    dispatch(
      timeSlotPresetsActions.updateTimeSlotPresets(stationId, timeSlotPresets)
    ),
  setPromotionImages: (
    promotionImageId: number,
    file: File,
    linkUrl: string,
    title: string,
    description: string
  ) =>
    dispatch(
      promotionImagesActions.updatePromotionImage(
        promotionImageId,
        file,
        linkUrl,
        title,
        description
      )
    ),
  setStationWithItemRanks: (body: UpdateStationWithItemRanksBody) =>
    dispatch(stationWithItemRanksActions.updateStationWithItemRanks(body)),
  createKiosk: (serial: string) => dispatch(kiosksActions.createKiosk(serial)),
  setKioskItemPrice: (kioskItemPrice: KioskItemPrice, price: number) =>
    dispatch(
      kioskItemPricesActions.updateKioskItemPrice(kioskItemPrice, price)
    ),
  createPointItem: (
    name: string,
    imageFile: File,
    isDeliveryRequired: boolean,
    pointsPrice: number,
    stock: number,
    isAvailable: boolean,
    isInfiniteStock: boolean
  ) =>
    dispatch(
      pointItemsActions.createPointItem(
        name,
        imageFile,
        isDeliveryRequired,
        pointsPrice,
        stock,
        isAvailable,
        isInfiniteStock
      )
    ),
})

export default connect(mapStateToProps, mapDispatchToProps)(Modal)
