import React from 'react'
import classnames from 'classnames'

import { Item } from '../../../core/items'
import { matchItemInfoTableContent } from './each-item-content'
import styles from '../../styles/modules/table.module.scss'
import formStyles from '../../styles/modules/each-item/each-item-forms.module.scss'

interface Props {
  item: Item
  onChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => void
}

const MatchItemInfoForm: React.FC<Props> = props => (
  <div
    className={classnames({
      [styles.wrapper]: true,
      [styles.stocker]: true,
    })}
  >
    <table className={styles.container}>
      <tbody>
        <tr className={styles.inputRowStatic}>
          <td className={styles.inputCell}>
            {matchItemInfoTableContent.bitterness}
          </td>
          <td className={styles.inputCell}>
            <input
              className={formStyles.input}
              value={props.item.matchItemParam.bitterness}
              onChange={props.onChange}
              name="bitterness"
            />
          </td>
        </tr>
        <tr className={styles.inputRowStatic}>
          <td className={styles.inputCell}>
            {matchItemInfoTableContent.fragrance}
          </td>
          <td className={styles.inputCell}>
            <input
              className={formStyles.input}
              value={props.item.matchItemParam.fragrance}
              onChange={props.onChange}
              name="fragrance"
            />
          </td>
        </tr>
        <tr className={styles.inputRowStatic}>
          <td className={styles.inputCell}>
            {matchItemInfoTableContent.acidity}
          </td>
          <td className={styles.inputCell}>
            <input
              className={formStyles.input}
              value={props.item.matchItemParam.acidity}
              onChange={props.onChange}
              name="acidity"
            />
          </td>
        </tr>
        <tr className={styles.inputRowStatic}>
          <td className={styles.inputCell}>
            {matchItemInfoTableContent.sweetness}
          </td>
          <td className={styles.inputCell}>
            <input
              className={formStyles.input}
              value={props.item.matchItemParam.sweetness}
              onChange={props.onChange}
              name="sweetness"
            />
          </td>
        </tr>
        <tr className={styles.inputRowStatic}>
          <td className={styles.inputCell}>
            {matchItemInfoTableContent.richness}
          </td>
          <td className={styles.inputCell}>
            <input
              className={formStyles.input}
              value={props.item.matchItemParam.richness}
              onChange={props.onChange}
              name="richness"
            />
          </td>
        </tr>
        <tr className={styles.inputRowStatic}>
          <td className={styles.inputCell}>
            {matchItemInfoTableContent.uniqueness}
          </td>
          <td className={styles.inputCell}>
            <input
              className={formStyles.input}
              value={props.item.matchItemParam.uniqueness}
              onChange={props.onChange}
              name="uniqueness"
            />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
)

export default MatchItemInfoForm
