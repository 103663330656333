import React from 'react'

import { strings } from './users-content'
import styles from '../../styles/modules/users/users-query.module.scss'

interface Props {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  id: number
}

const QueryId: React.FC<Props> = props => (
  <div className={styles.container}>
    <span className={styles.title}>{strings.queryIdTitle}</span>
    <div className={styles.box}>
      <div className={styles.wrapper}>
        <input
          placeholder="ユーザーID"
          value={props.id || ''}
          onChange={props.onChange}
        />
      </div>
    </div>
  </div>
)

export default QueryId
