import { put, call, takeLatest, fork, select, delay } from 'redux-saga/effects'
import RootState from '../root-state'
import { stationAlertActionType, stationAlertActions } from './actions'
import { getStationAlerts, postStationAlert, putStationAlert, deleteStationAlert, restoreStationAlert } from '../api'
import { toastActions } from '../toast'
import { modalActions } from '../modal'

export function* fetchStationAlerts(
  action: ReturnType<typeof stationAlertActions.fetchStationAlerts>
){
  try {
    const state: RootState = yield select()
    const token = state.auth0.auth.idToken
    const response = yield call(getStationAlerts, token)
    yield put(stationAlertActions.fetchStationAlertsSucceeded(response.stationAlerts))
  } catch (error) {
    yield put(stationAlertActions.throwError(error))
  }
}

export function* createStationAlert(
  action: ReturnType<typeof stationAlertActions.createStationAlert>
){
  try {
    const state: RootState = yield select()
    const token = state.auth0.auth.idToken
    yield call(
      postStationAlert,
      token,
      action.payload.stationId,
      action.payload.title,
      action.payload.body
    )
    yield delay(100)
    yield put(stationAlertActions.fetchStationAlerts())
    yield put(
      toastActions.show({
        title: '成功！',
        body: 'お知らせを登録しました',
      })
    )
    yield put(modalActions.hide())
  }catch(error){
    yield put(stationAlertActions.throwError(error))
    yield put(
      toastActions.show({
        type: 'error',
        title: '失敗...',
        body: 'お知らせの登録に失敗しました',
      })
    )

  }
}

export function* updateStationAlert(
  action: ReturnType<typeof stationAlertActions.updateStationAlert>
){
  try {
    const state: RootState = yield select()
    const token = state.auth0.auth.idToken
    yield call(
      putStationAlert,
      token,
      action.payload.stationAlertId,
      action.payload.stationId,
      action.payload.title,
      action.payload.body
    )
    yield delay(100)
    yield put(stationAlertActions.fetchStationAlerts())
    yield put(
      toastActions.show({
        title: '成功！',
        body: 'お知らせを更新しました',
      })
    )
  yield put(modalActions.hide())
  }catch(error){
    yield put(stationAlertActions.throwError(error))
    yield put(
      toastActions.show({
        type: 'error',
        title: '失敗...',
        body: 'お知らせの更新に失敗しました',
      })
    )

  }

}

export function* destroyStationAlert(
  action: ReturnType<typeof stationAlertActions.deleteStationAlert>
){
  try {
    const state: RootState = yield select()
    const token = state.auth0.auth.idToken
    yield call(
      deleteStationAlert,
      token,
      action.payload.stationAlertId,
    )
    yield delay(100)
    yield put(stationAlertActions.fetchStationAlerts())
    yield put(
      toastActions.show({
        title: '成功！',
        body: 'お知らせを削除しました',
      })
    )
  yield put(modalActions.hide())
  }catch(error){
    yield put(stationAlertActions.throwError(error))
    yield put(
      toastActions.show({
        type: 'error',
        title: '失敗...',
        body: 'お知らせの削除に失敗しました',
      })
    )

  }

}

export function* restoreDeletedStationAlert(
  action: ReturnType<typeof stationAlertActions.deleteStationAlert>
){
  try {
    const state: RootState = yield select()
    const token = state.auth0.auth.idToken
    yield call(
      restoreStationAlert,
      token,
      action.payload.stationAlertId,
    )
    yield delay(100)
    yield put(stationAlertActions.fetchStationAlerts())
    yield put(
      toastActions.show({
        title: '成功！',
        body: 'お知らせの削除を取り消しました',
      })
    )
  yield put(modalActions.hide())
  }catch(error){
    yield put(stationAlertActions.throwError(error))
    yield put(
      toastActions.show({
        type: 'error',
        title: '失敗...',
        body: 'お知らせの削除取り消しに失敗しました',
      })
    )

  }

}


export function* watchFetchStationAlerts() {
  yield takeLatest(stationAlertActionType.FETCH_STATION_ALERTS, fetchStationAlerts)
}

export function* watchCreateStationAlert() {
  yield takeLatest(stationAlertActionType.CREATE_STATION_ALERT, createStationAlert)
}

export function* watchUpdateStationAlert() {
  yield takeLatest(stationAlertActionType.UPDATE_STATION_ALERT, updateStationAlert)
}

export function* watchDeleteStationAlert() {
  yield takeLatest(stationAlertActionType.DELETE_STATION_ALERT, destroyStationAlert)
}

export function* watchRestoreDeletedStationAlert() {
  yield takeLatest(stationAlertActionType.RESTORE_STATION_ALERT, restoreDeletedStationAlert)
}



export const stationAlertsSagas = [
  fork(watchFetchStationAlerts),
  fork(watchCreateStationAlert),
  fork(watchUpdateStationAlert),
  fork(watchDeleteStationAlert),
  fork(watchRestoreDeletedStationAlert)
]