import { put, call, takeLatest, fork, select, delay } from 'redux-saga/effects'

import RootState from '../root-state'
import { toastActions } from '../toast'
import { ordersActionType, ordersActions } from './actions'
import { auth0Actions } from '../auth0'
import * as api from '../api'

export function* fetchOrders(
  action: ReturnType<typeof ordersActions.fetchOrders>
) {
  try {
    yield put(auth0Actions.checkSession())
    const state: RootState = yield select()
    const token = state.auth0.auth.idToken
    const response = yield call(api.getOrders, token, action.payload.param)
    yield put(ordersActions.fetchOrdersSucceeded(response.orders))
  } catch (error) {
    yield put(ordersActions.throwError(error))
  }
}

export function* fetchOrdersEvery(
  action: ReturnType<typeof ordersActions.fetchOrdersEvery>
) {
  try {
    while (true) {
      const state: RootState = yield select()
      const active = state.orders.active
      if (!active) {
        return
      }
      yield put(ordersActions.fetchOrders(action.payload.param))
      yield delay(5000)
    }
  } catch (error) {
    yield put(ordersActions.throwError(error))
  }
}

export function* cancelOrder(
  action: ReturnType<typeof ordersActions.cancelOrder>
) {
  try {
    const { id } = action.payload
    yield put(auth0Actions.checkSession())
    const state: RootState = yield select()
    const token = state.auth0.auth.idToken
    yield call(api.cancelOrder, token, id)
    yield put(
      toastActions.show({
        title: '成功！',
        body: 'キャンセルしました',
      })
    )
  } catch (error) {
    yield put(ordersActions.throwError(error))
    yield put(
      toastActions.show({
        type: 'error',
        title: '失敗...',
        body: '更新に失敗しました',
      })
    )
  }
}

export function* watchFetchOrders() {
  yield takeLatest(ordersActionType.FETCH_ORDERS, fetchOrders)
}

export function* watchFetchOrdersEvery() {
  yield takeLatest(ordersActionType.FETCH_ORDERS_EVERY, fetchOrdersEvery)
}

export function* watchCancelOrder() {
  yield takeLatest(ordersActionType.CANCEL_ORDER, cancelOrder)
}

export const ordersSagas = [
  fork(watchFetchOrders),
  fork(watchFetchOrdersEvery),
  fork(watchCancelOrder),
]
