import React, { useState } from 'react'

import Button from '../button'
import Input from '../input'
import FileInput from '../file-input'
import styles from '../../styles/modules/modal/modal.module.scss'
import { strings } from './modal-content'
import { Config } from '../../../core/toast'

interface Props {
  isLoading: boolean
  createSignageBanner: (
    file: File,
    title: string,
    extension: string,
    mimeType: string,
    duration: number,
    base64File: string,
    adCaption: string
  ) => void
  show: (config: Config) => void
}

interface State {
  readonly file: File
  readonly title: string
  readonly extension: string
  readonly mimeType: string
  readonly duration: number
  readonly base64File: string
  readonly adCaption: string
}

export const CreateSignageBannerForm: React.FC<Props> = ({
  isLoading,
  createSignageBanner,
  show,
}) => {
  const [state, setState] = useState<State>({
    file: null,
    title: '',
    extension: '',
    mimeType: '',
    duration: 0,
    base64File: '',
    adCaption: '',
  })

  const handleOnClickSubmit = () => {
    const {
      file,
      title,
      extension,
      mimeType,
      duration,
      base64File,
      adCaption,
    } = state

    if (!file || title === '') {
      const config: Config = {
        type: 'error',
        title: strings.toastError.title,
        body: strings.toastError.body,
      }
      show(config)
      return
    }

    if (!file.type.startsWith('image/') && !file.type.startsWith('video/')) {
      const config: Config = {
        type: 'error',
        title: strings.toastError.title,
        body: strings.signageBanner.errorBody,
      }
      show(config)
      return
    }
    createSignageBanner(
      file,
      title,
      extension,
      mimeType,
      duration,
      base64File,
      adCaption
    )
  }

  async function fileToBase64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)

      reader.onload = () => {
        const base64Data = (reader.result as string).split(',')[1]
        resolve(base64Data)
      }

      reader.onerror = error => {
        reject(error)
      }
    })
  }

  const handleFileChange = async event => {
    const file = event.target.files.item(0)
    const base64File = await fileToBase64(file)

    if (file.type.startsWith('image/')) {
      // 画像の標準表示時間は30秒とする
      setState({
        file,
        title: state.title,
        extension: file.name.split('.').pop(),
        mimeType: file.type,
        duration: 30,
        base64File,
        adCaption: state.adCaption,
      })
    } else {
      const video = document.createElement('video')
      const updateState = () => {
        if (isNaN(video.duration)) {
          window.alert(strings.signageBanner.invalidVideoDuration)
          return
        }

        const duration = Math.round(video.duration)

        setState({
          file,
          title: state.title,
          extension: file.name.split('.').pop(),
          mimeType: file.type,
          duration,
          base64File,
          adCaption: state.adCaption,
        })

        video.removeEventListener('loadedmetadata', updateState)
      }

      video.addEventListener('loadedmetadata', updateState)

      const fileURL = URL.createObjectURL(file)
      video.src = fileURL
    }
  }

  return (
    <main className={styles.main}>
      <p className={styles.text}>{strings.signageBanner.note}</p>
      <FileInput
        label={strings.signageBanner.image}
        type={'file'}
        onChange={handleFileChange}
      />
      <Input
        label={strings.signageBanner.title}
        type={'text'}
        onChange={event => {
          setState({
            ...state,
            title: event.target.value,
          })
        }}
        value={state.title}
      />
      <Input
        label={strings.signageBanner.caption}
        type={'text'}
        onChange={event => {
          setState({
            ...state,
            adCaption: event.target.value,
          })
        }}
        value={state.adCaption}
      />
      <Button
        title={strings.signageBanner.button}
        onClick={handleOnClickSubmit}
        className={styles.report}
        isLoading={isLoading}
        loadingClassName={styles.loadingReport}
      />
    </main>
  )
}
