import { ActionType } from 'typesafe-actions'
import { soracomSIMsActionType, soracomSIMsActions } from './actions'
import { SoracomSIM } from './soracom-sims'


export interface SoracomSIMsState {
  readonly error: Error
  readonly isLoading: boolean
  readonly soracomSIMs: SoracomSIM[]
}

export const initialState: SoracomSIMsState = {
  error: null,
  isLoading: false,
  soracomSIMs: [],
}

export const soracomSIMsReducer = (
  state: SoracomSIMsState = initialState,
  action: ActionType<typeof soracomSIMsActions>
) => {
  switch (action.type) {
    case soracomSIMsActionType.FETCH_SORACOM_SIMS:
      return {
        ...state,
        isLoading: true,
        error: null
      }
    case soracomSIMsActionType.FETCH_SORACOM_SIMS_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        error: null,
        soracomSIMs: action.payload.soracomSIMs
      }
    case soracomSIMsActionType.CREATE_SORACOM_SIM:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case soracomSIMsActionType.UPDATE_SORACOM_SIM:
        return {
          ...state,
          isLoading: true,
          error: null,
        }
    case soracomSIMsActionType.THROW_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      }
    default:
      return state
    }
}
