import { action } from 'typesafe-actions'
import {
  Machine,
  MachineRelationHistory,
  MachineRelationHistoriesParam,
} from './machines'

export enum machinesActionType {
  FETCH_MACHINES = 'FETCH_MACHINES',
  FETCH_MACHINES_SUCCEEDED = 'FETCH_MACHINES_SUCCEEDED',
  CREATE_MACHINE = 'CREATE_MACHINE',
  UPDATE_MACHINE = 'UPDATE_MACHINE',
  FETCH_MACHINE_RELATION_HISTRIES = 'FETCH_MACHINE_RELATION_HISTRIES',
  FETCH_MACHINE_RELATION_HISTRIES_SUCCEEDED = 'FETCH_MACHINE_RELATION_HISTRIES_SUCCEEDED',
  UPDATE_MACHINE_RELATION = 'UPDATE_MACHINE_RELATION',
  CLEAR_MACHINE_RELATION = 'CLEAR_MACHINE_RELATION',
  THROW_ERROR = 'THROW_ERROR',
}

export const machinesActions = {
  fetchMachines: () => action(machinesActionType.FETCH_MACHINES),
  fetchMachinesSucceeded: (machines: Machine[]) =>
    action(machinesActionType.FETCH_MACHINES_SUCCEEDED, { machines }),
  createMachine: (
    serial: string,
    manufacturedDate: Date,
    type: string,
    version: string,
    factoryName: string
  ) =>
    action(machinesActionType.CREATE_MACHINE, {
      serial,
      manufacturedDate,
      type,
      version,
      factoryName,
    }),
  updateMachine: (
    machineId: number,
    serial: string,
    manufacturedDate: Date,
    type: string,
    version: string,
    factoryName: string,
    shouldBeLatest: boolean
  ) =>
    action(machinesActionType.UPDATE_MACHINE, {
      machineId,
      serial,
      manufacturedDate,
      type,
      version,
      factoryName,
      shouldBeLatest
    }),
  fetchMachineRelationHistories: (param: MachineRelationHistoriesParam) =>
    action(machinesActionType.FETCH_MACHINE_RELATION_HISTRIES, { param }),
  fetchMachineRelationHistoriesSucceeded: (
    machineRelationHistories: MachineRelationHistory[]
  ) =>
    action(machinesActionType.FETCH_MACHINE_RELATION_HISTRIES_SUCCEEDED, {
      machineRelationHistories,
    }),
  updateMachineRelation: (
    machineId: number,
    stationId: number,
    soracomSIMId: number
  ) =>
    action(machinesActionType.UPDATE_MACHINE_RELATION, {
      machineId,
      stationId,
      soracomSIMId,
    }),
  clearMachineRelation: (machineId: number) =>
    action(machinesActionType.CLEAR_MACHINE_RELATION, { machineId }),
  throwError: (error: Error) =>
    action(machinesActionType.THROW_ERROR, { error }),
}
