import { action } from 'typesafe-actions'
import { SoracomSIM } from './soracom-sims'

export enum soracomSIMsActionType {
  FETCH_SORACOM_SIMS = 'FETCH_SORACOM_SIMS',
  FETCH_SORACOM_SIMS_SUCCEEDED = 'FETCH_SORACOM_SIMS_SUCCEEDED',
  CREATE_SORACOM_SIM = 'CREATE_SORACOM_SIM',
  UPDATE_SORACOM_SIM = 'UPDATE_SORACOM_SIM',
  THROW_ERROR = 'THROW_ERROR',
}

export const soracomSIMsActions = {
  fetchSoracomSIMs: () =>
    action(soracomSIMsActionType.FETCH_SORACOM_SIMS),
  fetchSoracomSIMsSucceeded: (soracomSIMs: SoracomSIM[]) =>
    action(soracomSIMsActionType.FETCH_SORACOM_SIMS_SUCCEEDED, {soracomSIMs}),
  createSoracomSIM: (imsi: string, serial: string) =>
    action(soracomSIMsActionType.CREATE_SORACOM_SIM, {imsi, serial}),
  updateSoracomSIM: (soracomSIMId: number, imsi: string, serial: string) =>
    action(soracomSIMsActionType.UPDATE_SORACOM_SIM, {soracomSIMId, imsi, serial}),
  throwError: (error: Error) =>
    action(soracomSIMsActionType.THROW_ERROR, { error }),
}
