import { action } from 'typesafe-actions'

import { PointItem } from './point-items'

export enum pointItemsActionType {
  FETCH_POINT_ITEMS = 'FETCH_POINT_ITEMS',
  FETCH_POINT_ITEMS_SUCCEEDED = 'FETCH_POINT_ITEMS_SUCCEEDED',
  CREATE_POINT_ITEM = 'CREATE_POINT_ITEM',
  UPDATE_POINT_ITEM = 'UPDATE_POINT_ITEM',
  SORT_POINT_ITEMS = 'SORT_POINT_ITEMS',
  SET_POINT_ITEMS = 'SET_POINT_ITEMS',
  THROW_ERROR = 'THROW_ERROR',
}

export const pointItemsActions = {
  fetchPointItems: () => action(pointItemsActionType.FETCH_POINT_ITEMS),
  fetchPointItemsSucceeded: (pointItems: PointItem[]) =>
    action(pointItemsActionType.FETCH_POINT_ITEMS_SUCCEEDED, { pointItems }),
  createPointItem: (
    name: string,
    imageFile: File,
    isDeliveryRequired: boolean,
    pointsPrice: number,
    stock: number,
    isAvailable: boolean,
    isInfiniteStock: boolean
  ) =>
    action(pointItemsActionType.CREATE_POINT_ITEM, {
      name,
      imageFile,
      isDeliveryRequired,
      pointsPrice,
      stock,
      isAvailable,
      isInfiniteStock,
    }),
  updatePointItem: (
    id: number,
    imageFileName: string,
    name: string,
    isDeliveryRequired: boolean,
    pointsPrice: number,
    stock: number,
    isAvailable: boolean,
    isInfiniteStock: boolean
  ) =>
    action(pointItemsActionType.UPDATE_POINT_ITEM, {
      id,
      imageFileName,
      name,
      isDeliveryRequired,
      pointsPrice,
      stock,
      isAvailable,
      isInfiniteStock,
    }),
  sortPointItems: (ids: number[]) =>
    action(pointItemsActionType.SORT_POINT_ITEMS, {
      ids,
    }),
  setPointItems: (pointItems: PointItem[]) =>
    action(pointItemsActionType.SET_POINT_ITEMS, {
      pointItems,
    }),
  throwError: (error: Error) =>
    action(pointItemsActionType.THROW_ERROR, { error }),
}
