import React from 'react'
import moment from 'moment'
import classnames from 'classnames'

import { strings, subscriptionInfoTableContent } from './subscriptions-content'
import { Subscription } from '../../../core/subscription'
import styles from '../../styles/modules/table.module.scss'

interface Props {
  subscription?: Subscription
}

const SubscriptionInfoTable: React.FC<Props> = props => (
  <>
    <h3 className={styles.title}>{strings.subscriptionTableTitle}</h3>
    {props.subscription && (
      <div
        className={classnames({
          [styles.wrapper]: true,
        })}
      >
        <table className={styles.container}>
          <tbody>
            <tr className={styles.rowStatic}>
              <td className={styles.cell}>{subscriptionInfoTableContent.currentSubscriptionInfo.id}</td>
              <td className={styles.cell}>{props.subscription.id}</td>
            </tr>
            <tr className={styles.rowStatic}>
              <td className={styles.cell}>{subscriptionInfoTableContent.currentSubscriptionInfo.userId}</td>
              <td className={styles.cell}>{props.subscription.userID}</td>
            </tr>
            <tr className={styles.rowStatic}>
              <td className={styles.cell}>{subscriptionInfoTableContent.currentSubscriptionInfo.payJpId}</td>
              <td className={styles.cell}>{props.subscription.payJpID}</td>
            </tr>
            <tr className={styles.rowStatic}>
              <td className={styles.cell}>
                {subscriptionInfoTableContent.currentSubscriptionInfo.planName}
              </td>
              <td className={styles.cell}>
                {props.subscription.plan.name}
              </td>
            </tr>
            <tr className={styles.rowStatic}>
              <td className={styles.cell}>
                {subscriptionInfoTableContent.currentSubscriptionInfo.subscribedAt}
              </td>
              <td className={styles.cell}>
                {moment(props.subscription.subscribedAt).format('YYYY/MM/DD/ HH:mm:ss')}
              </td>
            </tr>
            <tr className={styles.rowStatic}>
              <td className={styles.cell}>
                {subscriptionInfoTableContent.currentSubscriptionInfo.canceledAt}
              </td>
              <td className={styles.cell}>
                {props.subscription.cancelledAt && (moment(props.subscription.cancelledAt).format('YYYY/MM/DD/ HH:mm:ss'))}
              </td>
            </tr>
            <tr className={styles.rowStatic}>
              <td className={styles.cell}>{subscriptionInfoTableContent.currentSubscriptionInfo.status}</td>
              <td className={styles.cell}>{props.subscription.status}</td>
            </tr>
          </tbody>
        </table>
        <h4 className={styles.title}>{strings.switchGracePeriodTitle}</h4>
        {props.subscription.switchGracePeriod ? (
          <div
            className={classnames({
              [styles.wrapper]: true,
            })}
          >
            <table className={styles.container}>
              <tbody>
              <tr className={styles.rowStatic}>
                  <td className={styles.cell}>
                    {subscriptionInfoTableContent.switchGracePeriod.expirationDatetime}
                  </td>
                  <td className={styles.cell}>
                    {moment(props.subscription.switchGracePeriod.expirationDatetime).format('YYYY/MM/DD/ HH:mm:ss')}
                  </td>
                </tr>
                <tr className={styles.rowStatic}>
                  <td className={styles.cell}>
                    {subscriptionInfoTableContent.switchGracePeriod.planName}
                  </td>
                  <td className={styles.cell}>
                    {props.subscription.switchGracePeriod.plan.name}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        ):
        <div>
          {strings.NoSwitchGracePeriod}
        </div>
        }
      </div>
    )}
  </>
)

export default SubscriptionInfoTable
