import { ActionType } from 'typesafe-actions'

import { CameraStreamingViewerCredentials } from './camera-streaming-viewer-credentials'
import {
  cameraStreamingViewerCredentialsActionType,
  cameraStreamingViewerCredentialsActions,
} from './actions'

export interface CameraStreamingViewerCredentialsState {
  readonly error: Error
  readonly isLoading: boolean
  readonly cameraStreamingViewerCredentials: CameraStreamingViewerCredentials
}

export const initialState: CameraStreamingViewerCredentialsState = {
  cameraStreamingViewerCredentials: null,
  error: null,
  isLoading: false,
}

export const cameraStreamingViewerCredentialsReducer = (
  state: CameraStreamingViewerCredentialsState = initialState,
  action: ActionType<typeof cameraStreamingViewerCredentialsActions>
) => {
  switch (action.type) {
    case cameraStreamingViewerCredentialsActionType.FETCH_CAMERA_STREAMING_VIEWER_CREDENTIALS:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case cameraStreamingViewerCredentialsActionType.FETCH_CAMERA_STREAMING_VIEWER_CREDENTIALS_SUCCEEDED:
      return {
        ...state,
        cameraStreamingViewerCredentials:
          action.payload.cameraStreamingViewerCredentials,
        isLoading: false,
        error: null,
      }
    case cameraStreamingViewerCredentialsActionType.THROW_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      }
    default:
      return state
  }
}
