import React, { useEffect } from 'react'
import {
  strings,
  kioskItemPricesTableContent,
} from './each-kiosk-item-prices-content'
import styles from '../../styles/modules/table.module.scss'
import classnames from 'classnames'
import RootState from '../../../core/root-state'
import { useDispatch, useSelector } from 'react-redux'
import { kioskItemPricesActions } from '../../../core/kiosk-item-prices'
import Button from '../button'
import { modalActions } from '../../../core/modal'

interface Props {
  stationId: number
}

const EachKioskItemPricesTable: React.FC<Props> = props => {
  const dispatch = useDispatch()
  const { hotKioskItemPrices, iceKioskItemPrices } = useSelector(
    (state: RootState) => state.kioskItemPrices
  )
  useEffect(() => {
    dispatch(kioskItemPricesActions.fetchKioskItemPrices(props.stationId))
  }, [dispatch, props.stationId])

  return (
    <>
      <h3 className={styles.title}>{strings.hotKioskItemPricesTableTitle}</h3>
      <div
        className={classnames({
          [styles.wrapper]: true,
          [styles.zebra]: true,
          [styles.stocker]: true,
        })}
      >
        <table className={styles.container}>
          <tbody>
            <tr className={styles.row}>
              {kioskItemPricesTableContent.map(item => (
                <th key={item} className={styles.cell}>
                  {item}
                </th>
              ))}
            </tr>
            {hotKioskItemPrices.map(kioskItemPrice => (
              <tr className={styles.row} key={kioskItemPrice.id}>
                <td className={styles.cell}>{kioskItemPrice.id}</td>
                <td className={styles.cell}>{kioskItemPrice.itemName}</td>
                <td className={styles.cell}>{kioskItemPrice.price}</td>
                <td className={styles.buttonCell}>
                  <Button
                    title={strings.editKioskItemPriceButton}
                    onClick={() => {
                      dispatch(
                        modalActions.showEditKioskItemPrice(kioskItemPrice)
                      )
                    }}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <h3 className={styles.title}>{strings.iceKioskItemPricesTableTitle}</h3>
      <div
        className={classnames({
          [styles.wrapper]: true,
          [styles.zebra]: true,
          [styles.stocker]: true,
        })}
      >
        <table className={styles.container}>
          <tbody>
            <tr className={styles.row}>
              {kioskItemPricesTableContent.map(item => (
                <th key={item} className={styles.cell}>
                  {item}
                </th>
              ))}
            </tr>
            {iceKioskItemPrices.map(kioskItemPrice => (
              <tr className={styles.row} key={kioskItemPrice.id}>
                <td className={styles.cell}>{kioskItemPrice.id}</td>
                <td className={styles.cell}>{kioskItemPrice.itemName}</td>
                <td className={styles.cell}>{kioskItemPrice.price}</td>
                <td className={styles.buttonCell}>
                  <Button
                    title={strings.editKioskItemPriceButton}
                    onClick={() => {
                      dispatch(
                        modalActions.showEditKioskItemPrice(kioskItemPrice)
                      )
                    }}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  )
}

export default EachKioskItemPricesTable
