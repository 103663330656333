import React from 'react'
import classnames from 'classnames'

import { emergenciesTableContent } from './emergencies-content'
import { Emergency } from '../../../core/emergencies'
import emergenciesTableStyles from '../../styles/modules/emergencies/emergencies-table.module.scss'
import styles from '../../styles/modules/table.module.scss'
import EmergenciesTableItem from './emergencies-table-item'

interface Props {
  emergencies: [Emergency]
}

const EmergenciesTable: React.FC<Props> = props => (
  <div
    className={classnames({
      [styles.wrapper]: true,
      [styles.zebra]: true,
      [styles.stocker]: true,
    })}
  >
    <table
      className={classnames(styles.container, emergenciesTableStyles.table)}
    >
      <tbody>
        <tr className={styles.row}>
          {emergenciesTableContent.map(item => (
            <th key={item} className={styles.cell}>
              {item}
            </th>
          ))}
        </tr>
        {props.emergencies
          .sort((a, b) => {
            if (a.id > b.id) {
              return -1
            } else {
              return 1
            }
          })
          .map(item => (
            <EmergenciesTableItem emergency={item} key={item.id} />
          ))}
      </tbody>
    </table>
  </div>
)

export default EmergenciesTable
