import { put, call, takeLatest, fork, select, delay } from 'redux-saga/effects'

import RootState from '../root-state'
import { kiosksActionType, kiosksActions } from './actions'
import {
  getKiosks,
  postKiosk,
  putKioskRelation,
  deleteKioskRelation,
} from '../api'
import { stationsActions } from '../stations'
import { soracomSIMsActions } from '../soracom-sims'
import { machinesActions } from '../machines'
import { toastActions } from '../toast'
import { modalActions } from '../modal'

export function* fetchKiosks() {
  try {
    const state: RootState = yield select()
    const token = state.auth0.auth.idToken
    const response = yield call(getKiosks, token)
    yield put(kiosksActions.fetchKiosksSucceeded(response))
  } catch (error) {
    yield put(kiosksActions.throwError(error))
  }
}

export function* createKiosk(
  action: ReturnType<typeof kiosksActions.createKiosk>
) {
  try {
    const state: RootState = yield select()
    const token = state.auth0.auth.idToken
    yield call(postKiosk, token, action.payload.serial)
    yield put(kiosksActions.fetchKiosks())
    yield put(
      toastActions.show({
        title: '成功！',
        body: 'KIOSKを登録しました',
      })
    )
    yield put(modalActions.hide())
  } catch (error) {
    yield put(kiosksActions.throwError(error))
    yield put(
      toastActions.show({
        title: 'エラー',
        body: 'KIOSKの登録に失敗しました',
      })
    )
  }
}

export function* updateKioskRelation(
  action: ReturnType<typeof kiosksActions.updateKioskRelation>
) {
  try {
    const state: RootState = yield select()
    const token = state.auth0.auth.idToken
    yield call(
      putKioskRelation,
      token,
      action.payload.kioskId,
      action.payload.stationId,
      action.payload.soracomSIMId
    )
    yield delay(100)
    yield put(kiosksActions.fetchKiosks())
    yield put(stationsActions.fetchStations())
    yield put(soracomSIMsActions.fetchSoracomSIMs())
    yield put(
      toastActions.show({
        title: '成功！',
        body: '紐付けを更新しました',
      })
    )
    yield put(modalActions.hide())
  } catch (error) {
    yield put(machinesActions.throwError(error))
    yield put(
      toastActions.show({
        type: 'error',
        title: '失敗...',
        body: '紐付けの更新に失敗しました',
      })
    )
  }
}

export function* clearKioskRelation(
  action: ReturnType<typeof kiosksActions.clearKioskRelation>
) {
  try {
    const state: RootState = yield select()
    const token = state.auth0.auth.idToken

    yield call(deleteKioskRelation, token, action.payload.kioskId)
    yield delay(100)
    yield put(kiosksActions.fetchKiosks())
    yield put(stationsActions.fetchStations())
    yield put(soracomSIMsActions.fetchSoracomSIMs())
    yield put(
      toastActions.show({
        title: '成功！',
        body: '紐付けを解除しました',
      })
    )
  } catch (error) {
    yield put(kiosksActions.throwError(error))
    yield put(
      toastActions.show({
        type: 'error',
        title: '失敗...',
        body: '紐付けの解除に失敗しました',
      })
    )
  }
}

export function* watchFetchKiosks() {
  yield takeLatest(kiosksActionType.FETCH_KIOSKS, fetchKiosks)
}

export function* watchCreateKiosk() {
  yield takeLatest(kiosksActionType.CREATE_KIOSK, createKiosk)
}

export function* watchUpdateKioskRelation() {
  yield takeLatest(kiosksActionType.UPDATE_KIOSK_RELATION, updateKioskRelation)
}

export function* watchClearKioskRelation() {
  yield takeLatest(kiosksActionType.CLEAR_KIOSK_RELATION, clearKioskRelation)
}

export const kiosksSagas = [
  fork(watchFetchKiosks),
  fork(watchCreateKiosk),
  fork(watchUpdateKioskRelation),
  fork(watchClearKioskRelation),
]
