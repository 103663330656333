import React from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router'

import RootState from '../../core/root-state'

interface Props {
  children: React.ReactNode
  isAuthenticated: boolean
}

class Auth extends React.PureComponent<Props, {}> {
  render() {
    const { children, isAuthenticated } = this.props

    return isAuthenticated ? children : <Redirect to={'/login'} />
  }
}

const mapStateToProps = (state: RootState) => ({
  isAuthenticated: state.auth0.isAuthenticated,
})

export default connect(
  mapStateToProps,
  {}
)(Auth)
