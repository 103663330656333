import React from 'react'
import { PointItemSupportLog } from '../../../core/point-item-support-logs'
import pointItemSupportLogsStyles from '../../styles/modules/point-item-support-logs/point-item-support-logs-table.module.scss'
import styles from '../../styles/modules/table.module.scss'
import classnames from 'classnames'
import { pointItemSupportLogsTableContent } from './point-item-support-logs-content'
import { Link } from 'react-router-dom'
import moment from 'moment'

interface Props {
  pointItemSupportLogs: PointItemSupportLog[]
}

const PointItemSupportLogsTable: React.FC<Props> = props => {
  return (
    <div
      className={classnames({
        [styles.wrapper]: true,
        [pointItemSupportLogsStyles.zebra]: true,
        [styles.stocker]: true,
      })}
    >
      <table
        className={classnames(
          styles.container,
          pointItemSupportLogsStyles.table
        )}
      >
        <tr className={styles.row}>
          {pointItemSupportLogsTableContent.map(pointItemSupportLog => (
            <th key={pointItemSupportLog} className={styles.cell}>
              {pointItemSupportLog}
            </th>
          ))}
        </tr>
        {props.pointItemSupportLogs.map(log => (
          <tr
            key={log.id}
            className={classnames({
              [pointItemSupportLogsStyles.row]: true,
              [pointItemSupportLogsStyles.isCanceledRow]:
                log.status === 'canceled',
            })}
          >
            <th className={styles.cell}>{log.id}</th>
            <th className={styles.cell}>{log.pointItem.id}</th>
            <th className={styles.cell}>{log.pointItem.name}</th>
            <th className={styles.cell}>
              <Link
                to={{
                  pathname: `/users/${log.userAddress.user.id}`,
                }}
              >
                {log.userAddress.user.id}
              </Link>
            </th>
            <th className={styles.cell}>{log.status}</th>
            <th className={styles.cell} />
            <th className={styles.cell}>
              {log.completedAt
                ? moment(log.completedAt).format('YYYY/MM/DD HH:mm')
                : '-'}
            </th>
            <th className={styles.cell}>{log.userAddress.postalCode}</th>
            <th className={styles.cell}>{log.userAddress.stateProvince}</th>
            <th className={styles.cell}>{log.userAddress.city}</th>
            <th className={styles.cell}>{log.userAddress.fullAddress}</th>
            <th className={styles.cell}>{log.userAddress.recipientName}</th>
            <th className={styles.cell}>{log.userAddress.phoneNumber}</th>
            <th className={styles.cell} />
          </tr>
        ))}
      </table>
    </div>
  )
}

export default PointItemSupportLogsTable
