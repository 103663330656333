import React from 'react'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import moment from 'moment'

import RootState from '../../core/root-state'
import { Station, stationsActions } from '../../core/stations'
import { Machine, machinesActions } from '../../core/machines'
import {
  ordersActions,
  Order,
  OrdersParam,
  OrderProvisionStatus,
} from '../../core/orders'
import Layout from '../layouts/layout'
import Wrapper from '../layouts/wrapper'
import OrdersTable from '../components/orders/orders-table'
import QueryStation from '../components/orders/query-station'
import QueryDate from '../components/orders/query-date'
import QueryId from '../components/orders/query-id'
import QueryEmail from '../components/orders/query-email'
import QueryStatus from '../components/orders/query-status'
import Button from '../components/button'
import { strings } from '../components/orders/orders-content'
import styles from '../styles/modules/orders/orders.module.scss'
import { emergenciesActions, Emergency } from '../../core/emergencies'
import { modalActions } from '../../core/modal'

interface Props {
  stations: Station[]
  machines: Machine[]
  orders: Order[]
  emergencies: Emergency[]
  isLoading: boolean
  active: boolean
  fetchStations: () => void
  fetchMachines: () => void
  fetchOrders: (param: OrdersParam) => void
  fetchOrdersEvery: (param: OrdersParam) => void
  fetchOrdersEveryFinished: () => void
  cancelOrder: (id: number) => void
  resetOrders: () => void
  createEmergency: (
    stationId: number,
    message: string,
    canOpenLockerBox: boolean
  ) => void
  fetchEmergencies: () => void
  showKioskPurchaserImage: (imageUrl: string, alt: string) => void
}

interface State {
  readonly date_from: Date
  readonly date_to: Date
  readonly pushed: boolean
  readonly station_id: string
  readonly provision_status: string
  readonly id: number
  readonly email: string
  readonly count: number
}

class OrdersScreen extends React.Component<Props, State> {
  readonly state = {
    date_from: new Date(),
    date_to: new Date(),
    pushed: false,
    station_id: '@all',
    provision_status: '@all',
    id: null,
    email: null,
    count: 1000,
  }

  convertDateToString = date => {
    const converted = moment(date).format('YYYY-MM-DD')
    return converted
  }

  componentDidMount = () => {
    const param: OrdersParam = {
      date_from: this.convertDateToString(this.state.date_from),
      date_to: this.convertDateToString(this.state.date_to),
      count: this.state.count,
    }
    this.props.fetchStations()
    this.props.fetchMachines()
    this.props.fetchEmergencies()
    this.props.fetchOrders(param)
    this.props.fetchOrdersEvery(param)
  }

  componentWillUnmount = () => {
    this.setState({ pushed: false })
    this.props.fetchOrdersEveryFinished()
  }

  componentDidUpdate = () => {
    if (this.state.pushed) {
      if (this.props.orders.length === this.state.count) {
        window.alert(strings.alert)
        this.setState({ pushed: false })
      }
    }
  }

  handleOnChangeId = (event: React.ChangeEvent<HTMLInputElement>) => {
    const re = /^[0-9\b]+$/
    if (event.target.value === '') {
      this.setState({ id: null })
    }
    if (re.test(event.target.value)) {
      this.setState({ id: parseInt(event.target.value, 10) })
    }
    // ユーザID入力時にメールアドレスのインプット欄をリセットする
    this.setState({ email: null })
  }

  handleOnChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === '') {
      this.setState({ email: null })
    }
    this.setState({ email: event.target.value.trim() })
    // メールアドレス入力した際にユーザIDのインプット欄をリセットする
    this.setState({ id: null })
  }

  handleOnChangeFrom = (from: Date) => {
    if (from < this.state.date_to) {
      this.setState({ date_from: from })
    } else {
      const now = new Date()
      if (from < now) {
        this.setState({ date_from: from, date_to: from })
      } else {
        this.setState({ date_from: now, date_to: now })
      }
    }
  }

  handleOnChangeTo = (to: Date) => {
    if (to > this.state.date_from) {
      const now = new Date()
      if (to < now) {
        this.setState({ date_to: to })
      } else {
        this.setState({ date_to: now })
      }
    } else {
      this.setState({ date_from: to, date_to: to })
    }
  }

  handleOnClickQuery = () => {
    const { station_id, id, email, provision_status } = this.state
    this.setState({ pushed: true })
    this.props.resetOrders()
    this.props.fetchStations()
    this.props.fetchMachines()
    this.props.fetchEmergencies()
    if (station_id === '@all' && provision_status === '@all') {
      const param: OrdersParam = {
        user_id: id,
        email,
        date_from: this.convertDateToString(this.state.date_from),
        date_to: this.convertDateToString(this.state.date_to),
        count: 1000,
      }
      this.props.fetchOrders(param)
      this.props.fetchOrdersEvery(param)
    } else if (station_id === '@all') {
      const param: OrdersParam = {
        user_id: id,
        email,
        provision_status: this.state.provision_status as OrderProvisionStatus,
        date_from: this.convertDateToString(this.state.date_from),
        date_to: this.convertDateToString(this.state.date_to),
        count: 1000,
      }
      this.props.fetchOrders(param)
      this.props.fetchOrdersEvery(param)
    } else if (provision_status === '@all') {
      const param: OrdersParam = {
        user_id: id,
        email,
        station_id: parseInt(station_id, 10),
        date_from: this.convertDateToString(this.state.date_from),
        date_to: this.convertDateToString(this.state.date_to),
        count: 1000,
      }
      this.props.fetchOrders(param)
      this.props.fetchOrdersEvery(param)
    } else {
      const param: OrdersParam = {
        user_id: id,
        email,
        station_id: parseInt(station_id, 10),
        provision_status: this.state.provision_status as OrderProvisionStatus,
        date_from: this.convertDateToString(this.state.date_from),
        date_to: this.convertDateToString(this.state.date_to),
        count: 1000,
      }
      this.props.fetchOrders(param)
      this.props.fetchOrdersEvery(param)
    }
  }

  handleOnCancelOrder = (id: number) => {
    const response = window.confirm('本当にキャンセルしますか？')
    if (response) {
      this.props.cancelOrder(id)
    }
  }

  handleOnClickKioskPurchaserImageButton = (imageUrl: string, alt: string) => {
    this.props.showKioskPurchaserImage(imageUrl, alt)
  }

  render() {
    const { stations, orders, isLoading, active } = this.props
    const {
      date_from,
      date_to,
      pushed,
      station_id,
      provision_status,
      id,
    } = this.state
    return (
      <Layout location={'orders'}>
        <Wrapper title={strings.title}>
          <div className={styles.queryContainer}>
            <QueryDate
              from={date_from}
              to={date_to}
              onChangeFrom={from => this.handleOnChangeFrom(from)}
              onChangeTo={to => this.handleOnChangeTo(to)}
            />
            <QueryStation
              options={stations}
              value={station_id}
              onChange={event =>
                this.setState({ station_id: event.target.value })
              }
            />
            <QueryId id={id} onChange={event => this.handleOnChangeId(event)} />
            <QueryEmail
              email={this.state.email}
              onChange={event => this.handleOnChangeEmail(event)}
            />
            <QueryStatus
              value={provision_status}
              onChange={event =>
                this.setState({ provision_status: event.target.value })
              }
            />
            <Button
              title={strings.queryButton}
              onClick={() => this.handleOnClickQuery()}
              className={styles.queryButton}
              isLoading={isLoading && pushed && !active}
            />
          </div>
          <OrdersTable
            orders={orders}
            onClickCancelButton={this.handleOnCancelOrder}
            onClickKioskPurchaserImageButton={
              this.handleOnClickKioskPurchaserImageButton
            }
          />
        </Wrapper>
      </Layout>
    )
  }
}

const mapStateToProps = (state: RootState) => ({
  stations: state.stations.stations,
  machines: state.machines.machines,
  orders: state.orders.orders,
  emergencies: state.emergencies.emergencies,
  isLoading: state.orders.isLoading,
  active: state.orders.active,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchStations: () => dispatch(stationsActions.fetchStations()),
  fetchMachines: () => dispatch(machinesActions.fetchMachines()),
  fetchOrders: (param: OrdersParam) =>
    dispatch(ordersActions.fetchOrders(param)),
  fetchOrdersEvery: (param: OrdersParam) =>
    dispatch(ordersActions.fetchOrdersEvery(param)),
  fetchOrdersEveryFinished: () =>
    dispatch(ordersActions.fetchOrdersEveryFinished()),
  cancelOrder: (id: number) => dispatch(ordersActions.cancelOrder(id)),
  resetOrders: () => dispatch(ordersActions.resetOrders()),
  createEmergency: (
    stationId: number,
    message: string,
    canOpenLockerBox: boolean
  ) =>
    dispatch(
      stationsActions.createEmergency(stationId, message, canOpenLockerBox)
    ),
  fetchEmergencies: () => dispatch(emergenciesActions.fetchEmergencies()),
  showKioskPurchaserImage: (imageUrl: string, alt: string) =>
    dispatch(modalActions.showImage(imageUrl, alt)),
})

export default connect(mapStateToProps, mapDispatchToProps)(OrdersScreen)
