import React, { useCallback } from 'react'
import classnames from 'classnames'

import { PromotionImage } from '../../../core/promotion-images'
import { strings, promotionImageItemContent } from './promotion-images-content'
import Button from '../button'
import { ReactSortable } from 'react-sortablejs'
import styles from '../../styles/modules/table.module.scss'
import promotionImageItemTableStyle from '../../styles/modules/promotion-images/promotion-image-item-table.module.scss'

interface Props {
  promotionImages: PromotionImage[]
  onClickDeletePromotionImage: (id: number) => void
  showEditPromotionImage: (promotionImage: PromotionImage) => void
  setPromotionImages: (promotionImages: PromotionImage[]) => void
  updatePromotionImageIndex: (ids: number[]) => void
}

const PromotionImageItemTable: React.FC<Props> = props => {
  // handleOnSort関数で、setPromotionImagesのみを依存配列に追加するためにpropsを分解
  const { setPromotionImages } = props

  // 画面のちらつきを防ぐためにuseCallbackを使用
  const handleOnSort = useCallback(
    (promotionImages: PromotionImage[]) => {
      setPromotionImages(promotionImages)
    },
    [setPromotionImages]
  )

  return (
    <div
      className={classnames({
        [styles.wrapper]: true,
        [styles.zebra]: true,
        [styles.stocker]: true,
      })}
    >
      <table className={styles.container}>
        <thead>
          <tr className={promotionImageItemTableStyle.row}>
            {promotionImageItemContent.map((item, index) => (
              <th key={index} className={styles.cell}>
                {item}
              </th>
            ))}
          </tr>
        </thead>
        <ReactSortable
          tag="tbody"
          list={props.promotionImages}
          setList={handleOnSort}
          handle=".handle"
          onSort={() =>
            props.updatePromotionImageIndex(
              props.promotionImages.map(item => item.id)
            )
          }
        >
          {props.promotionImages.map((promotionImage, index) => (
            <tr className={styles.row} key={promotionImage.id}>
              <td className={`${promotionImageItemTableStyle.cell} handle`}>
                <div className={promotionImageItemTableStyle.orderIcon}>
                  <span />
                  <span />
                  <span />
                </div>
              </td>
              <td className={styles.cell}>{promotionImage.id}</td>
              <td className={styles.cell}>{index + 1}</td>
              <td className={styles.cell}>
                <a href={promotionImage.linkUrl}>{promotionImage.linkUrl}</a>
              </td>
              <td className={styles.cell}>
                {promotionImage.title ? promotionImage.title : strings.noData}
              </td>
              <td className={styles.longTextCell}>
                {promotionImage.description
                  ? promotionImage.description
                  : strings.noData}
              </td>
              <td className={promotionImageItemTableStyle.imgCell}>
                <img
                  alt="promotion"
                  src={promotionImage.url}
                  className={promotionImageItemTableStyle.image}
                />
              </td>
              <td className={promotionImageItemTableStyle.buttonCell}>
                <Button
                  title={strings.updatePromotionImageButton}
                  onClick={() => {
                    props.showEditPromotionImage(promotionImage)
                  }}
                  className={promotionImageItemTableStyle.updateButton}
                />
              </td>
              <td className={promotionImageItemTableStyle.buttonCell}>
                <Button
                  title={strings.deletePromotionImageButton}
                  onClick={() => {
                    props.onClickDeletePromotionImage(promotionImage.id)
                  }}
                  className={promotionImageItemTableStyle.deleteButton}
                />
              </td>
            </tr>
          ))}
        </ReactSortable>
      </table>
    </div>
  )
}

export default React.memo(PromotionImageItemTable)
