export const strings = {
  title: '手動発行クーポン',
  createPromotionCampaignButton: 'クーポンを作成',
  setPromotionCampaignStatusButton: '変更',
  isAllStationEligible: '全店舗',
  hasEligibleStations: '限定',
  showEligibleStationButton: '確認する',
  setPromotionCampaignButton: '編集',
  setPromotionCampaignExpireButton: '失効させる',
  queryIssuedDateTitle: '発行日を期間で絞り込む',
  queryExpiresDateTitle: '失効日を期間で絞り込む',
  queryStatusTitle: 'ステータスで絞り込む',
  queryButton: '絞り込む',
  queryNoStatus: '条件なし',
  promotionCampaignExpirationAlert:
    '本当にこのクーポンを本日付で失効にしますか？',
}

export const promotionCampaignsTableContent = [
  'ID',
  'コード',
  'クーポン名',
  '割引額',
  '使用可能回数',
  '適用回数',
  '適用回数上限',
  'ステータス',
  'ステータス切替',
  '発行日',
  '失効予定日',
  '失効操作(本日付)',
  '対象ステーション',
  'メモ',
  '発行者',
  '',
]
