import { action } from 'typesafe-actions'
import { StationAlert } from './station-alerts'

export enum stationAlertActionType {
  FETCH_STATION_ALERTS = 'FETCH_STATION_ALERTS',
  FETCH_STATION_ALERTS_SUCCEEDED = 'FETCH_STATION_ALERTS_SUCCEEDED',
  CREATE_STATION_ALERT = 'CREATE_STATION_ALERT',
  UPDATE_STATION_ALERT = 'UPDATE_STATION_ALERT',
  DELETE_STATION_ALERT = 'DELETE_STATION_ALERT',
  RESTORE_STATION_ALERT = 'RESTORE_STATION_ALERT',
  THROW_ERROR = 'THROW_ERROR',
}

export const stationAlertActions = {
  fetchStationAlerts: () =>
    action(stationAlertActionType.FETCH_STATION_ALERTS),
  fetchStationAlertsSucceeded: (stationAlerts: StationAlert[]) =>
    action(stationAlertActionType.FETCH_STATION_ALERTS_SUCCEEDED, {stationAlerts}),
  createStationAlert: (stationId: number, title: string, body: string) =>
    action(stationAlertActionType.CREATE_STATION_ALERT, {stationId, title, body}),
  updateStationAlert: (stationAlertId: number, stationId: number, title: string, body: string) =>
    action(stationAlertActionType.UPDATE_STATION_ALERT, {stationAlertId, stationId, title, body}),
  deleteStationAlert: (stationAlertId: number) =>
    action(stationAlertActionType.DELETE_STATION_ALERT, {stationAlertId}),
  restoreStationAlert: (stationAlertId: number) =>
    action(stationAlertActionType.RESTORE_STATION_ALERT, {stationAlertId}),
  throwError: (error: Error) =>
    action(stationAlertActionType.THROW_ERROR, { error }),
}