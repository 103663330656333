import { put, call, takeLatest, fork, select, delay } from 'redux-saga/effects'

import RootState from '../root-state'
import {
  stationWithItemRanksActionType,
  stationWithItemRanksActions,
} from './actions'
import { StationWithItemRanksParam } from './station-with-item-ranks'
import { getStationWithItemRanks, putStationWithItemRanks } from '../api'
import { toastActions } from '../toast'
import { modalActions } from '../modal'

export function* fetchStationWithItemRanks(
  action: ReturnType<
    typeof stationWithItemRanksActions.fetchStationWithItemRanks
  >
) {
  try {
    const state: RootState = yield select()
    const token = state.auth0.auth.idToken
    const response = yield call(
      getStationWithItemRanks,
      token,
      action.payload.param
    )
    yield put(
      stationWithItemRanksActions.fetchStationWithItemRanksSucceeded(response)
    )
  } catch (error) {
    yield put(stationWithItemRanksActions.throwError(error))
  }
}

export function* updateStationWithItemRanks(
  action: ReturnType<
    typeof stationWithItemRanksActions.updateStationWithItemRanks
  >
) {
  try {
    const state: RootState = yield select()
    const token = state.auth0.auth.idToken
    yield call(putStationWithItemRanks, token, action.payload.body)
    yield delay(100)
    const param: StationWithItemRanksParam = {}
    yield put(stationWithItemRanksActions.fetchStationWithItemRanks(param))
    yield put(
      toastActions.show({
        title: '成功！',
        body: '対象ステーションのランキングを設定しました',
      })
    )
    yield put(modalActions.hide())
  } catch (error) {
    yield put(stationWithItemRanksActions.throwError(error))
    yield put(
      toastActions.show({
        title: 'エラー',
        body: '対象ステーションのランキングの設定に失敗しました',
      })
    )
  }
}

export function* watchFetchStationWithItemRanks() {
  yield takeLatest(
    stationWithItemRanksActionType.FETCH_STATION_WITH_ITEM_RANKS,
    fetchStationWithItemRanks
  )
}

export function* watchUpdateStationWithItemRanks() {
  yield takeLatest(
    stationWithItemRanksActionType.UPDATE_STATION_WITH_ITEM_RANKS,
    updateStationWithItemRanks
  )
}

export const stationWithItemRanksSagas = [
  fork(watchFetchStationWithItemRanks),
  fork(watchUpdateStationWithItemRanks),
]
