import React from 'react'
import classnames from 'classnames'

import { Item, Feature } from '../../../core/items'
import { strings } from './each-item-content'
import styles from '../../styles/modules/table.module.scss'
import formStyles from '../../styles/modules/each-item/each-item-forms.module.scss'
import Button from '../button'

interface Props {
  item: Item
  newFeature: Feature
  onChangeFeatures: (event: React.ChangeEvent<HTMLInputElement>) => void
  onChangeNewFeature: (event: React.ChangeEvent<HTMLInputElement>) => void
  onAddNewFeature: () => void
  onDeleteFeature: (event: React.MouseEvent<HTMLElement>) => void
}

const FeaturesForm: React.FC<Props> = props => (
  <div
    className={classnames({
      [styles.wrapper]: true,
      [styles.stocker]: true,
    })}
  >
    <table className={styles.container}>
      <tbody>
        {props.item.features.map((elem, i) => (
          <tr className={styles.rowStatic} key={i}>
            <td className={styles.cell}>
              <input
                className={formStyles.input}
                value={elem.name}
                placeholder={'その他の特徴名'}
                name="name"
                data-num={i}
                onChange={props.onChangeFeatures}
              />
            </td>
            <td className={styles.cell}>
              <input
                className={formStyles.input}
                value={elem.content}
                placeholder={'内容'}
                name="content"
                data-num={i}
                onChange={props.onChangeFeatures}
              />
            </td>
            <td className={styles.cell}>
              <Button
                className={formStyles.deleteButton}
                title={strings.delete}
                data-num={i}
                onClick={props.onDeleteFeature}
              />
            </td>
          </tr>
        ))}
        <tr className={styles.rowStatic}>
          <td className={styles.cell}>
            <input
              className={formStyles.input}
              value={props.newFeature.name}
              placeholder={'その他の特徴名'}
              name="name"
              onChange={props.onChangeNewFeature}
            />
          </td>
          <td className={styles.cell}>
            <input
              className={formStyles.input}
              value={props.newFeature.content}
              placeholder={'内容'}
              name="content"
              onChange={props.onChangeNewFeature}
            />
          </td>
          <td className={styles.cell}>
            <Button
              className={formStyles.addButton}
              title={strings.add}
              disabled={
                props.newFeature.name === '' || props.newFeature.content === ''
              }
              onClick={props.onAddNewFeature}
            />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
)

export default FeaturesForm
