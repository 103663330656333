import React from 'react'
import classnames from 'classnames'

import styles from '../styles/modules/duration-select.module.scss'

interface Props extends React.InputHTMLAttributes<HTMLSelectElement> {
  label: string
  durationValue?: number | ''
  durationUnitValue?: string
  onChangeDuration?: (event: React.ChangeEvent<HTMLInputElement>) => void
  onChangeDurationUnit?: (event: React.ChangeEvent<HTMLSelectElement>) => void
}

const durationUnitOptions = [
  { value: 'day', label: '日間' },
  { value: 'week', label: '週間' },
  { value: 'month', label: 'カ月間' },
]

const DurationSelect: React.FC<Props> = ({
  label,
  durationValue,
  durationUnitValue,
  onChangeDuration,
  onChangeDurationUnit,
  ...rest
}) => {
  return (
    <div className={classnames({ [styles.form]: true })}>
      <label className={styles.label}>{label}</label>
      <div className={styles.inputWrapper}>
        <input
          type="number"
          value={durationValue}
          onChange={onChangeDuration}
          className={styles.input}
        />
        <select
          {...rest}
          value={durationUnitValue}
          onChange={onChangeDurationUnit}
          className={styles.select}
        >
          {durationUnitOptions.map(item => (
            <option key={item.value} value={item.value}>
              {item.label}
            </option>
          ))}
        </select>
      </div>
    </div>
  )
}

export default DurationSelect
