import React from 'react'
import classnames from 'classnames'

import {
  strings,
  orderCampaignsTableContent,
  getDurationToDisplay,
} from './auto-entry-campaign-content'
import { Campaign } from '../../../core/campaigns'
import styles from '../../styles/modules/table.module.scss'
import Tooltip from '../tooltip'
import Button from '../button'

interface Props {
  orderCampaigns: Campaign[]
  onClickSetOrderCampaign: (orderCampaign: Campaign) => void
}

const OrderCampaignsTable: React.FC<Props> = props => (
  <div
    className={classnames({
      [styles.wrapper]: true,
      [styles.zebra]: true,
      [styles.stocker]: true,
    })}
  >
    <table className={classnames(styles.container)}>
      <tbody>
        <tr className={styles.row}>
          {orderCampaignsTableContent.map(item => (
            <th key={item} className={styles.cell}>
              {item}
            </th>
          ))}
        </tr>
        {props.orderCampaigns
          .filter(item => !item.ownerStation?.isClosed)
          .sort((a, b) => {
            return a.ownerStation.id > b.ownerStation.id ? 1 : -1
          })
          .map(item => (
            <tr className={styles.row} key={item.id}>
              <td className={styles.cell}>{item.id}</td>
              <td
                className={styles.cell}
              >{`ID:${item.ownerStation.id} ${item.ownerStation.name}`}</td>
              <td className={styles.cell}>{item.code}</td>
              <td className={styles.cell}>{item.name}</td>
              <td className={styles.cell}>{item.discountAmount}</td>
              <td className={styles.cell}>{item.availableCount}</td>
              <td className={styles.cell}>
                {getDurationToDisplay(item.duration, item.durationUnit)}
              </td>
              <td className={styles.cell}>{item.status}</td>
              <td className={styles.cell}>
                {item.isAllStationsEligible ? (
                  <Tooltip
                    id={`campaign-eligible-stations-tooltip-${item.id}`}
                    triggerNode={strings.isAllStationEligible}
                    contentNode={strings.isAllStationEligible}
                  />
                ) : (
                  <Tooltip
                    id={`campaign-eligible-stations-tooltip-${item.id}`}
                    triggerNode={strings.hasEligibleStations}
                    contentNode={item.eligibleStations.map(station => (
                      <div key={station.id}>{station.name}</div>
                    ))}
                  />
                )}
              </td>
              <td className={styles.buttonCell}>
                <Button
                  title={strings.setAutoEntryCampaignButton}
                  onClick={() => {
                    props.onClickSetOrderCampaign(item)
                  }}
                />
              </td>
            </tr>
          ))}
      </tbody>
    </table>
  </div>
)

export default OrderCampaignsTable
