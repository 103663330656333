import { put, call, takeLatest, fork, delay, select } from 'redux-saga/effects'
import axios from 'axios'

import RootState from '../root-state'
import { promotionImagesActionType, promotionImagesActions } from './actions'
import { auth0Actions } from '../auth0'
import { toastActions } from '../toast'
import { modalActions } from '../modal'
import {
  getPromotionImages,
  newPromotionImage,
  putPromotionImage,
  deletePromotionImage,
  presignPromotionImageUrl,
  updatePromotionImageIndex
} from '../api'

export function* fetchPromotionImages() {
  try {
    yield put(auth0Actions.checkSession())
    const state: RootState = yield select()
    const token = state.auth0.auth.idToken
    const response = yield call(getPromotionImages, token)
    yield put(promotionImagesActions.fetchPromotionImagesSucceeded(response.promotionImages))
  } catch (error) {
    yield put(promotionImagesActions.throwError(error))
  }
}

export function* createPromotionImage(action: ReturnType<typeof promotionImagesActions.createPromotionImage>) {
  try {
    yield put(auth0Actions.checkSession())
    const state: RootState = yield select()
    const token = state.auth0.auth.idToken
    const presignResponse = yield call(presignPromotionImageUrl, token)
    const { url, fileName } = presignResponse.result
    yield call(axios.put, url, action.payload.file, {
      headers: {
        'Content-Type': action.payload.file.type
      }
    })

    yield call(
      newPromotionImage,
      token,
      fileName,
      action.payload.linkUrl,
      action.payload.title,
      action.payload.description
    )
    yield delay(100)
    yield put(promotionImagesActions.fetchPromotionImages())
    yield put(
      toastActions.show({
        title: '成功！',
        body: 'バナーを作成しました',
      })
    )
    yield put(modalActions.hide())
  } catch (error) {
    yield put(promotionImagesActions.throwError(error))
    yield put(
      toastActions.show({
        type: 'error',
        title: '失敗...',
        body: '作成に失敗しました',
      })
    )
  }
}

export function* updatePromotionImage(action: ReturnType<typeof promotionImagesActions.updatePromotionImage>) {
  try {
    yield put(auth0Actions.checkSession())
    const state: RootState = yield select()
    const token = state.auth0.auth.idToken

    if (action.payload.file) {
      const presignResponse = yield call(presignPromotionImageUrl, token)
      const { url, fileName } = presignResponse.result
      yield call(axios.put, url, action.payload.file, {
        headers: {
          'Content-Type': action.payload.file.type
        }
      })
      yield call(
        putPromotionImage,
        token,
        action.payload.id,
        action.payload.linkUrl,
        action.payload.title,
        action.payload.description,
        fileName
      )
    } else {
      yield call(
        putPromotionImage,
        token,
        action.payload.id,
        action.payload.linkUrl,
        action.payload.title,
        action.payload.description
      )
    }

    yield delay(100)
    yield put(promotionImagesActions.fetchPromotionImages())
    yield put(
      toastActions.show({
        title: '成功！',
        body: 'バナーを更新しました',
      })
    )
    yield put(modalActions.hide())
  } catch (error) {
    yield put(promotionImagesActions.throwError(error))
    yield put(
      toastActions.show({
        type: 'error',
        title: '失敗...',
        body: '更新に失敗しました',
      })
    )
  }
}

export function* removePromotionImage(action: ReturnType<typeof promotionImagesActions.deletePromotionImage>) {
  try {
    yield put(auth0Actions.checkSession())
    const state: RootState = yield select()
    const token = state.auth0.auth.idToken

    yield call(deletePromotionImage, token, action.payload.id)
    yield delay(100)
    yield put(promotionImagesActions.fetchPromotionImages())
    yield put(
      toastActions.show({
        title: '成功！',
        body: 'バナーを削除しました',
      })
    )
    yield put(modalActions.hide())
  } catch (error) {
    yield put(promotionImagesActions.throwError(error))
    yield put(
      toastActions.show({
        type: 'error',
        title: '失敗...',
        body: '削除に失敗しました',
      })
    )
  }
}

export function* indexSortPromotionImage(action: ReturnType<typeof promotionImagesActions.updatePromotionImageIndex>) {
  try {
    yield put(auth0Actions.checkSession())
    const state: RootState = yield select()
    const token = state.auth0.auth.idToken

    yield call(updatePromotionImageIndex, token, action.payload.ids)
  } catch (error) {
    yield put(promotionImagesActions.throwError(error))
  }
}


export function* watchFetchPromotionImages() {
  yield takeLatest(promotionImagesActionType.FETCH_PROMOTION_IMAGE, fetchPromotionImages)
}

export function* watchCreatePromotionImage() {
  yield takeLatest(promotionImagesActionType.CREATE_PROMOTION_IMAGE, createPromotionImage)
}

export function* watchUpdatePromotionImage() {
  yield takeLatest(promotionImagesActionType.UPDATE_PROMOTION_IMAGE, updatePromotionImage)
}

export function* watchRemovePromotionImage() {
  yield takeLatest(promotionImagesActionType.DELETE_PROMOTION_IMAGE, removePromotionImage)
}

export function* watchIndexSortPromotionImage() {
  yield takeLatest(promotionImagesActionType.UPDATE_PROMOTION_IMAGES_INDEX, indexSortPromotionImage)
}

export const promotionImagesSagas = [
  fork(watchFetchPromotionImages),
  fork(watchCreatePromotionImage),
  fork(watchUpdatePromotionImage),
  fork(watchRemovePromotionImage),
  fork(watchIndexSortPromotionImage),
]
