import React, { useState } from 'react'

import Button from '../button'
import Input from '../input'
import FileInput from '../file-input'
import styles from '../../styles/modules/modal/modal.module.scss'
import { strings } from './modal-content'
import { Config } from '../../../core/toast'
import { PromotionImage } from '../../../core/promotion-images'

interface Props {
  isLoading: boolean
  promotionImage: PromotionImage
  setPromotionImages: (Id: number, file: File, linkUrl: string, title: string, description: string) => void
  show: (config: Config) => void
}

export const EditPromotionImageForm: React.FC<Props> = (props: Props) => {
  const [file, setFile] = useState<File>(null)
  const [linkUrl, setLinkUrl] = useState<string>(props.promotionImage.linkUrl)
  const [title, setTitle] = useState<string>(props.promotionImage.title)
  const [description, setDescription] = useState<string>(props.promotionImage.description)

  const handleOnClickSubmit = () => {
    if ( !linkUrl || !title || !description) {
      const config: Config = {
        type: 'error',
        title: strings.toastError.title,
        body: strings.toastError.body
      }
      props.show(config)
      return
    }
    props.setPromotionImages(props.promotionImage.id, file, linkUrl, title, description)
  }

  return (
    <main className={styles.main}>
      <Input
        label={strings.promotionImage.title}
        type={'text'}
        onChange={event => {
          setTitle(event.target.value)
        }}
        value={title}
      />
      <Input
        label={strings.promotionImage.description}
        type={'text'}
        onChange={event => {
          setDescription(event.target.value)
        }}
        value={description}
      />
      <FileInput
        label={strings.promotionImage.image}
        type={'file'}
        onChange={event => {
          setFile(event.target.files.item(0))
        }}
      />
      <Input
        label={strings.promotionImage.linkUrl}
        type={'text'}
        onChange={event => {
          setLinkUrl(event.target.value)
        }}
        value={linkUrl}
      />
      <Button
        title={strings.promotionImage.updateButton}
        onClick={handleOnClickSubmit}
        className={styles.report}
        isLoading={props.isLoading}
        loadingClassName={styles.loadingReport}
      />
    </main>
  )
}
