import React from 'react'

import signageBannersItemsStyles from '../../styles/modules/signage-banners/signage-banner-items.module.scss'

interface Props {
  fileType: 'video' | 'image'
  url: string
}

const SignageFileTypeContainer: React.FC<Props> = ({ fileType, url }) => {
  return (
    <>
      {fileType === 'video' && (
        <video
          controls
          src={url}
          muted
          className={signageBannersItemsStyles.image}
        />
      )}
      {fileType === 'image' && (
        <img
          alt="signage_banner"
          src={url}
          className={signageBannersItemsStyles.image}
        />
      )}
    </>
  )
}

export default SignageFileTypeContainer
