import { ActionType } from 'typesafe-actions'
import { kioskItemPricesActionType, kioskItemPricesActions } from './actions'
import { KioskItemPrice } from './kiosk-item-prices'

export interface KioskItemPricesState {
  readonly error: Error
  readonly isLoading: boolean
  readonly hotKioskItemPrices: KioskItemPrice[]
  readonly iceKioskItemPrices: KioskItemPrice[]
}

export const initialState: KioskItemPricesState = {
  error: null,
  isLoading: false,
  hotKioskItemPrices: [],
  iceKioskItemPrices: [],
}

export const kioskItemPricesReducer = (
  state: KioskItemPricesState = initialState,
  action: ActionType<typeof kioskItemPricesActions>
) => {
  switch (action.type) {
    case kioskItemPricesActionType.FETCH_KIOSK_ITEM_PRICES:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case kioskItemPricesActionType.FETCH_KIOSK_ITEM_PRICES_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        error: null,
        hotKioskItemPrices: action.payload.hotKioskItemPrices,
        iceKioskItemPrices: action.payload.iceKioskItemPrices,
      }
    case kioskItemPricesActionType.UPDATE_KIOSK_ITEM_PRICE:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case kioskItemPricesActionType.THROW_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      }
    default:
      return state
  }
}
