import React from 'react'
import classnames from 'classnames'

import HeaderTooltip from './header-tooltip'
import styles from '../../styles/modules/header/header.module.scss'

interface Props {
  user: string
  isOpen: boolean
  onClickHamburger: () => void
  onClickLogout: () => void
}

interface State {
  readonly isVisible: boolean
}

export default class Header extends React.PureComponent<Props, State> {
  readonly state = {
    isVisible: false,
  }

  handleOnClickUser = () => {
    this.setState({ isVisible: !this.state.isVisible })
  }

  render() {
    return (
      <>
        <header
          className={classnames({
            [styles.container]: true,
            [styles.visible]: this.props.isOpen,
          })}
        >
          <div>
            <div className={styles.pc} />
            <button
              onClick={() => this.props.onClickHamburger()}
              className={classnames({
                [styles.mobile]: true,
                [styles.open]: this.props.isOpen,
              })}
            >
              <span />
              <span />
            </button>
          </div>
          <button
            className={styles.user}
            onClick={() => this.handleOnClickUser()}
          >
            <span>{this.props.user}</span>
          </button>
          <HeaderTooltip
            onClickLogout={() => this.props.onClickLogout()}
            isVisible={this.state.isVisible}
          />
        </header>
        <div
          className={classnames({
            [styles.recognizer]: true,
            [styles.visibleRecognizer]: this.state.isVisible,
          })}
          onClick={() => this.handleOnClickUser()}
        />
      </>
    )
  }
}
