import { ActionType } from 'typesafe-actions'

import { stationsActionType, stationsActions } from './actions'
import { Station, StationImage } from './stations'

export interface StationsState {
  readonly error: Error
  readonly isLoading: boolean
  readonly stations: Station[]
  readonly station: Station
  readonly stationImage: StationImage
  readonly active: 'stations' | 'station' | 'none'
}

export const initialState: StationsState = {
  error: null,
  isLoading: false,
  stations: [],
  station: null,
  stationImage: null,
  active: 'none',
}

export const stationsReducer = (
  state: StationsState = initialState,
  action: ActionType<typeof stationsActions>
) => {
  switch (action.type) {
    case stationsActionType.FETCH_STATIONS:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case stationsActionType.FETCH_STATIONS_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        error: null,
        stations: action.payload.stations,
      }
    case stationsActionType.FETCH_STATIONS_EVERY:
      return {
        ...state,
        error: null,
        active: 'stations',
      }
    case stationsActionType.FETCH_STATIONS_EVERY_FINISHED:
      return {
        ...state,
        error: null,
        active: 'none',
      }
    case stationsActionType.FETCH_STATION:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case stationsActionType.FETCH_STATION_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        error: null,
        station: action.payload.station,
      }
    case stationsActionType.FETCH_STATION_EVERY:
      return {
        ...state,
        error: null,
        active: 'station',
      }
    case stationsActionType.FETCH_STATION_EVERY_FINISHED:
      return {
        ...state,
        error: null,
        active: 'none',
      }
    case stationsActionType.CREATE_STATION:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case stationsActionType.BOOT_MACHINE:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case stationsActionType.BOOT_MACHINE_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        error: null,
      }
    case stationsActionType.SHUT_MACHINE:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case stationsActionType.SHUT_MACHINE_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        error: null,
      }
    case stationsActionType.RESOLVE_EMERGENCY:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case stationsActionType.RESOLVE_EMERGENCY_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        error: null,
      }
    case stationsActionType.CREATE_EMERGENCY:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case stationsActionType.CREATE_EMERGENCY_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        error: null,
      }
    case stationsActionType.FILL_ICE:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case stationsActionType.SET_STATION_ITEM_AVAILABLE:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case stationsActionType.SET_STATION_ITEM_AVAILABLE_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        error: null,
      }
    case stationsActionType.SET_STATION_ITEM_AVAILABLE_TEMPORARILY:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case stationsActionType.SET_STATION_ITEM_UNAVAILABLE:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case stationsActionType.SET_STATION_ITEM_UNAVAILABLE_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        error: null,
      }
    case stationsActionType.SET_STATION_ITEM_UNAVAILABLE_TEMPORARILY:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case stationsActionType.OPEN_TIME_SLOT:
      return {
        ...state,
        isLoading: false,
        error: null,
      }
    case stationsActionType.CLOSE_TIME_SLOT:
      return {
        ...state,
        isLoading: false,
        error: null,
      }
    case stationsActionType.OPEN_ALL_TIME_SLOTS:
      return {
        ...state,
        isLoading: false,
        error: null,
      }
    case stationsActionType.CLOSE_ALL_TIME_SLOTS:
      return {
        ...state,
        isLoading: false,
        error: null,
      }
    case stationsActionType.OPEN_ALL_LOCKER_BOXES:
      return {
        ...state,
        isLoading: false,
        error: null,
      }
    case stationsActionType.SET_STATION:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case stationsActionType.SET_STATION_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        error: null,
      }
    case stationsActionType.UPLOAD_STATION_IMAGE:
      return {
        ...state,
        stationImage: null,
        isLoading: true,
        error: null,
      }
    case stationsActionType.SET_STATION_IMAGE_URL:
      return {
        ...state,
        stationImage: {
          fileName: action.payload.fileName,
          url: action.payload.url,
          stationImageType: action.payload.stationImageType,
        },
      }
    case stationsActionType.SET_STATION_LOCKER_BOX_AVAILABLE:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case stationsActionType.SET_STATION_LOCKER_BOX_UNAVAILABLE:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case stationsActionType.CLEAR_LOCKER_BOX:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case stationsActionType.OPEN_LOCKER_BOX:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case stationsActionType.CREATE_LOCKER_BOX_COMMENT:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case stationsActionType.SET_LOCKER_BOX_COMMENT:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case stationsActionType.REMOVE_LOCKER_BOX_COMMENT:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case stationsActionType.THROW_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      }
    default:
      return state
  }
}
