import React from 'react'
import classnames from 'classnames'

import {
  promotionCampaignsTableContent,
  strings,
} from './promotion-campaigns-content'
import { Campaign } from '../../../core/campaigns'
import Button from '../button'
import promotionCampaignsTableStyles from '../../styles/modules/promotion-campaigns/promotion-campaigns-table.module.scss'
import styles from '../../styles/modules/table.module.scss'
import moment from 'moment'
import Tooltip from '../tooltip'

interface Props {
  promotionCampaigns: [Campaign]
  onClickCreatePromotionCampaign: () => void
  onClickSetPromotionCampaignStatus: (promotionCampaign: Campaign) => void
  onClickSetPromotionCampaign: (promotionCampaign: Campaign) => void
  onClickSetPromotionCampaignExpiresAt: (promotionCampaignId: number) => void
}

const PromotionCampaignsTable: React.FC<Props> = props => {
  const canExpire = (expiresAt: string) => {
    if (!expiresAt) {
      return true
    }

    const expiresAtDate = new Date(expiresAt)
    const expiresYear = expiresAtDate.getFullYear()
    const expiresMonth = expiresAtDate.getMonth()
    const expiresDate = expiresAtDate.getDate()

    const now = new Date()
    const nowYear = now.getFullYear()
    const nowMouth = now.getMonth()
    const nowDate = now.getDate()
    if (expiresYear === nowYear) {
      if (expiresMonth === nowMouth) {
        return expiresDate > nowDate
      } else {
        return expiresMonth > nowMouth
      }
    } else {
      return expiresYear > nowYear
    }
  }

  const isExpired = (expiresAt: string) => {
    if (!expiresAt) {
      return false
    }
    const expiresAtDate = new Date(expiresAt)
    const now = new Date()
    return expiresAtDate < now
  }

  return (
    <div
      className={classnames({
        [styles.wrapper]: true,
        [promotionCampaignsTableStyles.zebra]: true,
        [styles.stocker]: true,
      })}
    >
      <h1>【割引額について】</h1>
      <div> PayJPの決済金額下限は50円です。</div>
      <div>
        割引後の最終支払い金額が1円から49円の範囲内であればエラーとなりますが、それ以外の範囲ではエラーは発生しません。
      </div>
      <br />
      <h3>【ステータスについて】</h3>
      <div>
        クーポンのステータスにはdraft(適用不可/使用不可),
        live(適用可能/使用可能),
        closed(適用不可/使用可能)の3種類があり、クーポン作成後にdraftからliveに変更することで適応可能になります。
      </div>
      <div>
        失効日が過ぎたクーポンはグレーアウトになっておりステータスに関わらず使用することができません。
      </div>
      <Button
        title={strings.createPromotionCampaignButton}
        onClick={() => {
          props.onClickCreatePromotionCampaign()
        }}
        className={promotionCampaignsTableStyles.createPromotionCampaignButton}
      />
      <div>表示件数: {props.promotionCampaigns.length}件</div>
      <table
        className={classnames(
          styles.container,
          promotionCampaignsTableStyles.table
        )}
      >
        <tbody>
          <tr className={styles.row}>
            {promotionCampaignsTableContent.map(item => (
              <th key={item} className={styles.cell}>
                {item}
              </th>
            ))}
          </tr>
          {props.promotionCampaigns.map(item => (
            <tr
              key={item.id}
              className={classnames({
                [promotionCampaignsTableStyles.row]: true,
                [promotionCampaignsTableStyles.isExpireRow]: isExpired(
                  item.expiresAt
                ),
              })}
            >
              <td className={styles.cell}>{item.id}</td>
              <td className={styles.cell}>{item.code}</td>
              <td className={styles.cell}>{item.name}</td>
              <td className={styles.cell}>{item.discountAmount}</td>
              <td className={styles.cell}>{item.availableCount}</td>
              <td className={styles.cell}>{item.entryCount}</td>
              <td className={styles.cell}>{item.entryCountLimit}</td>
              <td className={styles.cell}>{item.status}</td>
              <td className={styles.buttonCell}>
                <Button
                  title={strings.setPromotionCampaignStatusButton}
                  onClick={() => {
                    props.onClickSetPromotionCampaignStatus(item)
                  }}
                />
              </td>
              <td className={styles.cell}>
                {item.issuedAt
                  ? moment(item.issuedAt).format('YYYY/MM/DD')
                  : '-'}
              </td>
              <td className={styles.cell}>
                {item.expiresAt
                  ? moment(item.expiresAt).format('YYYY/MM/DD')
                  : '-'}
              </td>
              <td className={styles.buttonCell}>
                {canExpire(item.expiresAt) ? (
                  <Button
                    title={strings.setPromotionCampaignExpireButton}
                    onClick={() => {
                      props.onClickSetPromotionCampaignExpiresAt(item.id)
                    }}
                    className={promotionCampaignsTableStyles.accentButton}
                  />
                ) : (
                  ''
                )}
              </td>
              <td className={styles.cell}>
                {item.isAllStationsEligible ? (
                  <Tooltip
                    id={`campaign-eligible-stations-tooltip-${item.id}`}
                    triggerNode={strings.isAllStationEligible}
                    contentNode={strings.isAllStationEligible}
                  />
                ) : (
                  <Tooltip
                    id={`campaign-eligible-stations-tooltip-${item.id}`}
                    triggerNode={strings.hasEligibleStations}
                    contentNode={item.eligibleStations.map(station => (
                      <div key={station.id}>{station.name}</div>
                    ))}
                  />
                )}
              </td>
              <td className={styles.cell}>{item.memo}</td>
              <td className={styles.cell}>{item.issuerEmail}</td>
              <td className={styles.buttonCell}>
                <Button
                  title={strings.setPromotionCampaignButton}
                  onClick={() => {
                    props.onClickSetPromotionCampaign(item)
                  }}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default PromotionCampaignsTable
