import React from 'react'
import classnames from 'classnames'

import { Item, Taste } from '../../../core/items'
import { strings } from './each-item-content'
import styles from '../../styles/modules/table.module.scss'
import formStyles from '../../styles/modules/each-item/each-item-forms.module.scss'
import Button from '../button'

interface Props {
  item: Item
  newTaste: Taste
  onChangeTastes: (event: React.ChangeEvent<HTMLInputElement>) => void
  onChangeNewTaste: (event: React.ChangeEvent<HTMLInputElement>) => void
  onAddNewTaste: () => void
  onDeleteTaste: (event: React.MouseEvent<HTMLElement>) => void
}

const TastesForm: React.FC<Props> = props => (
  <div
    className={classnames({
      [styles.wrapper]: true,
      [styles.stocker]: true,
    })}
  >
    <table className={styles.container}>
      <tbody>
        {props.item.tastes.map((elem, i) => (
          <tr className={styles.rowStatic} key={i}>
            <td className={styles.cell}>
              <input
                className={formStyles.input}
                value={elem.name || ''}
                placeholder={'味の特徴名'}
                name="name"
                data-num={i}
                onChange={props.onChangeTastes}
              />
            </td>
            <td className={styles.cell}>
              <input
                className={formStyles.input}
                value={elem.value || ''}
                placeholder={'値'}
                name="value"
                data-num={i}
                onChange={props.onChangeTastes}
              />
            </td>
            <td className={styles.cell}>
              <Button
                className={formStyles.deleteButton}
                title={strings.delete}
                data-num={i}
                onClick={props.onDeleteTaste}
              />
            </td>
          </tr>
        ))}
        <tr className={styles.rowStatic}>
          <td className={styles.cell}>
            <input
              className={formStyles.input}
              value={props.newTaste.name || ''}
              placeholder={'味の特徴名'}
              name="name"
              onChange={props.onChangeNewTaste}
            />
          </td>
          <td className={styles.cell}>
            <input
              className={formStyles.input}
              value={props.newTaste.value || ''}
              placeholder={'値'}
              name="value"
              onChange={props.onChangeNewTaste}
            />
          </td>
          <td className={styles.cell}>
            <Button
              className={formStyles.addButton}
              title={strings.add}
              disabled={
                props.newTaste.name === '' || props.newTaste.value === null
              }
              onClick={props.onAddNewTaste}
            />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
)

export default TastesForm
