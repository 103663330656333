import { action } from 'typesafe-actions'
import { SalesData } from './sales-data'

export enum salesDataActionType {
  FETCH_MONTHLY_SALES_DATA = 'FETCH_MONTHLY_SALES_DATA',
  FETCH_MONTHLY_SALES_DATA_SUCCEEDED = 'FETCH_MONTHLY_SALES_DATA_SUCCEEDED',
  FETCH_DAILY_SALES_DATA = 'FETCH_DAILY_SALES_DATA',
  FETCH_DAILY_SALES_DATA_SUCCEEDED = 'FETCH_DAILY_SALES_DATA_SUCCEEDED',
  THROW_ERROR = 'THROW_ERROR',
}

export const salesDataActions = {
  fetchMonthlySalesData: () =>
    action(salesDataActionType.FETCH_MONTHLY_SALES_DATA),
  fetchMonthlySalesDataSucceeded: (monthlySalesData: SalesData) =>
    action(salesDataActionType.FETCH_MONTHLY_SALES_DATA_SUCCEEDED, {
      monthlySalesData,
    }),
  fetchDailySalesData: () => action(salesDataActionType.FETCH_DAILY_SALES_DATA),
  fetchDailySalesDataSucceeded: (dailySalesData: SalesData) =>
    action(salesDataActionType.FETCH_DAILY_SALES_DATA_SUCCEEDED, {
      dailySalesData,
    }),
  throwError: (error: Error) =>
    action(salesDataActionType.THROW_ERROR, { error }),
}
