import React from 'react'
import classnames from 'classnames'

import { Kiosk } from '../../../core/kiosks'
import { strings, kiosksTableContent } from './kiosks-content'
import styles from '../../styles/modules/table.module.scss'

interface Props {
  kiosks: Kiosk[]
}

const KiosksTable: React.FC<Props> = props => {

  return (
    <>
      <h3 className={styles.title}>{strings.kiosksTableTitle}</h3>
      <div
        className={classnames({
          [styles.wrapper]: true,
          [styles.zebra]: true,
          [styles.stocker]: true,
        })}
      >
        <br />
        <div>表示件数: {props.kiosks && props.kiosks.length}件</div>
        <br />
        <table className={styles.container}>
          <tbody>
            <tr className={styles.row}>
              {kiosksTableContent.map(item => (
                <th key={item} className={styles.cell}>
                  {item}
                </th>
              ))}
            </tr>
            { props.kiosks && props.kiosks.map(target => (
              <tr className={styles.row} key={target.id}>
                <td className={styles.cell}>{target.id}</td>
                <td className={styles.cell}>{target.serial}</td>
                <td className={styles.cell}>{target.stationID}</td>
                <td className={styles.cell}>
                  {target.isReceiptAvailable ? strings.receiptAvailable : strings.receiptUnavailable}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  )
}
export default KiosksTable
