import React from 'react'

import Button from '../button'
import Input from '../input'
import styles from '../../styles/modules/modal/modal.module.scss'
import optionalInputStyles from '../../styles/modules/optional-date-input.module.scss'
import { strings } from './modal-content'
import { Station } from '../../../core/stations'
import { isOverV3Version } from '../../../core/machines'
import Switch from 'react-switch'

interface Props {
  station: Station
  isLoading: boolean
  createEmergency: (
    stationId: number,
    message: string,
    canOpenLockerBox: boolean
  ) => void
}

interface State {
  readonly message: string
  readonly canOpenLockerBox: boolean
}

export class CreateEmergencyForm extends React.PureComponent<Props, State> {
  readonly state = {
    message: '',
    // マシンv3以降のバージョンに全て置き換えが完了すれば初期値をtrueにする
    canOpenLockerBox: false,
  }

  handleOnClickReport = () => {
    if (this.state.message !== '') {
      this.props.createEmergency(
        this.props.station.id,
        this.state.message,
        this.state.canOpenLockerBox
      )
    }
  }

  render() {
    const { isLoading } = this.props
    return (
      <main className={styles.main}>
        <Input
          label={strings.emergency.message}
          type={'text'}
          onChange={event => {
            this.setState({ message: event.target.value })
          }}
          value={this.state.message}
          placeholder={strings.emergency.placeholder}
        />

        {this.props.station.machine &&
          isOverV3Version(this.props.station.machine.version) && (
            <div className={optionalInputStyles.form}>
              <div className={optionalInputStyles.optionalTitleContainer}>
                <div className={optionalInputStyles.optionalTitle}>
                  {this.state.canOpenLockerBox
                    ? strings.emergency.availableOpenLockerBox
                    : strings.emergency.unavailableOpenLockerBox}
                </div>
                <Switch
                  onChange={e => {
                    this.setState({ canOpenLockerBox: e })
                  }}
                  checked={this.state.canOpenLockerBox}
                />
              </div>
            </div>
          )}

        <Button
          title={strings.emergency.button}
          onClick={() => this.handleOnClickReport()}
          className={styles.report}
          isLoading={isLoading}
          loadingClassName={styles.loadingReport}
        />
      </main>
    )
  }
}
