import React from 'react'
import moment from 'moment'

import { Subscription } from '../../../core/subscription';
import { Link } from 'react-router-dom'
import styles from '../../styles/modules/emergencies/emergencies-table.module.scss'

interface Props {
  subscription: Subscription
}

const SubscriptionsTableItem: React.FC<Props> = (props: Props) => {

  const setSwitchGracePeriodSentence = (subscription: Subscription) => {
    if (subscription.switchGracePeriod) {
      const planName = subscription.switchGracePeriod.plan.name
      const expirationDatetime = moment(props.subscription.switchGracePeriod.expirationDatetime).format('YYYY/MM/DD HH:mm:ss')
      return `前プラン(${planName})は${expirationDatetime}まで有効`
    }
  }

  const setCancelledDate = (subscription: Subscription) => (
    subscription.cancelledAt ?
    moment(props.subscription.cancelledAt).format('YYYY/MM/DD HH:mm:ss') : ''
  )

  return (
    <tr className={styles.row} key={props.subscription.id}>
      <td className={styles.cell}>
      <Link to={`/subscriptions/${props.subscription.id}`} className={styles.container}>
        <span>{props.subscription.id}</span>
      </Link>
      </td>
      <td className={styles.cell}>{props.subscription.userID}</td>
      <td className={styles.cell}>{props.subscription.payJpID}</td>
      <td className={styles.cell}>{props.subscription.plan.name}</td>

      <td className={styles.cell}>
        {moment(props.subscription.subscribedAt).format('YYYY/MM/DD HH:mm:ss')}
      </td>
      <td className={styles.cell}>
        {setCancelledDate(props.subscription)}
      </td>
      <td className={styles.cell}>{props.subscription.status}</td>
      <td className={styles.cell}>{setSwitchGracePeriodSentence(props.subscription)}</td>
    </tr>
  )
}

export default SubscriptionsTableItem