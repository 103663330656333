import React from 'react'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import classnames from 'classnames'
import { enableBodyScroll, disableBodyScroll } from 'body-scroll-lock'
import { Auth0UserProfile } from 'auth0-js'

import RootState from '../../core/root-state'
import { auth0Actions, roleType } from '../../core/auth0'
import Sidebar from '../components/sidebar/sidebar'
import Header from '../components/header/header'
import '../styles/layout.scss'
import styles from '../styles/modules/layout.module.scss'

interface Props {
  children: React.ReactNode
  location?: string
  isVisible: boolean
  isGuest?: boolean
  logout: () => void
  user: Auth0UserProfile
  role: roleType
}

interface State {
  readonly isOpen: boolean
}

class Layout extends React.Component<Props, State> {
  private ref: HTMLDivElement

  readonly state = {
    isOpen: false,
  }

  componentWillUnmount = () => {
    enableBodyScroll(this.ref)
  }

  handleOnClickHamburger = () => {
    this.setState({ isOpen: !this.state.isOpen })
    if (this.state.isOpen) {
      enableBodyScroll(this.ref)
    } else {
      disableBodyScroll(this.ref)
    }
  }

  handleOnClickLogout = () => {
    const response = window.confirm('本当にログアウトしますか？')
    if (response) {
      this.props.logout()
    }
  }

  render() {
    const { isVisible, isGuest, location, user, children, role } = this.props

    return (
      <div
        className={classnames({
          [styles.wrapper]: true,
          [styles.blur]: isVisible,
          [styles.guestWrapper]: isGuest,
        })}
        ref={ref => (this.ref = ref)}
      >
        {!isGuest && (
          <>
            <Sidebar
              selected={location}
              isOpen={this.state.isOpen}
              role={role}
            />
            <Header
              user={user ? user.email : 'エラー'}
              isOpen={this.state.isOpen}
              onClickHamburger={() => this.handleOnClickHamburger()}
              onClickLogout={() => this.handleOnClickLogout()}
            />
          </>
        )}

        <div
          className={classnames({
            [styles.recognizer]: true,
            [styles.visible]: this.state.isOpen,
          })}
          onClick={() => this.handleOnClickHamburger()}
        />
        <div
          className={classnames({
            [styles.container]: true,
            [styles.open]: this.state.isOpen,
            [styles.guest]: isGuest,
          })}
        >
          {children}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state: RootState) => ({
  isVisible: state.modal.isVisible,
  user: state.auth0.user,
  role: state.auth0.role,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  logout: () => dispatch(auth0Actions.logout()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Layout)
