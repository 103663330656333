import { put, call, takeLatest, fork, select } from 'redux-saga/effects'
import RootState from '../root-state'
import { machineErrorsActionType, machineErrorsActions } from './actions'
import { getMachineErrors } from '../api'

export function* fetchMachineErrors() {
  try {
    const state: RootState = yield select()
    const token = state.auth0.auth.idToken
    const response = yield call(getMachineErrors, token)
    console.log(response)
    console.log(response.machine_errors)
    yield put(
      machineErrorsActions.fetchMachineErrorsSucceeded(response.machineErrors)
    )
  } catch (error) {
    yield put(machineErrorsActions.throwError(error))
  }
}

export function* watchFetchMachineErrors() {
  yield takeLatest(
    machineErrorsActionType.FETCH_MACHINE_ERRORS,
    fetchMachineErrors
  )
}

export const machineErrorsSagas = [fork(watchFetchMachineErrors)]
