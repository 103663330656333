export const emergenciesTableContent = [
  'ID',
  '発生起因',
  'メッセージ',
  '発生時刻',
  'ロッカー開錠可否',
  '',
]

export const orderLimitationTableContent = ['発生原因', '詳細', '']

export const itemTableContent = ['ID', 'アイテム名', '取扱可否', '購入可否']

export const timeSlotTableContent = [
  'ID',
  'From',
  'To',
  'オーダー',
  'オーダー可否',
]

export const stockerContent = [
  '番号',
  '状態',
  'ドア',
  'ドア解錠',
  'オーダーID',
  'HashID',
  'アイテム名',
  '調理開始日時',
  '破棄予定日時',
  '内容物',
  '使用可否',
  '使用可否切替',
  'クリア',
  '備考',
]

export const strings = {
  emergencies: 'エマージェンシー',
  orderLimitations: {
    title: '注文制限',
    lackOfBeans: '豆不足',
    lackOfCups: 'カップ不足',
    lackOfIce: '氷不足',
  },
  item: 'アイテム',
  itemIce: 'ICE',
  itemHot: 'HOT',
  itemDetails: '【取扱/購入可否について】',
  upcomingOrders: '調理開始待オーダー',
  usualTimeSlotPreset: '曜日指定の受取時間スロットプリセット',
  selectedTimeSlotPreset: '日付指定の受取時間スロットプリセット',
  realTimeSlot: '当日の受取時間スロット',
  lockerBox: 'ロッカー',
  stationDetails: '詳細',
  setItemButton: '編集',
  resolveEmergencyButton: '解決',
  resolveAlert: '本当に解決済みにしますか？',
  createEmergencyButton: 'エマージェンシーを発生させる',
  fillIceButton: '解決',
  fillIceAlert: '氷の補充を報告しますか？',
  availableOpenLockerBox: 'ロッカー解錠可',
  unavailableOpenLockerBox: 'ロッカー解錠不可',
  openAllLockerBoxesButton: 'ドアを開ける',
  openAllLockerBoxesButtonAlert: '本当にドアを開けますか？',
  timeSlotOpenAlert: '本当にオーダー停止スロットを解除しますか？',
  timeSlotCloseAlert: '本当にオーダー停止スロットにしますか？',
  openAllTimeSlotButton: '全スロットのオーダー停止を解除',
  closeAllTimeSlotButton: '全スロットをオーダー停止',
  editTimeSlotPresetsMonday: '月曜日',
  editTimeSlotPresetsTuesday: '火曜日',
  editTimeSlotPresetsWednesday: '水曜日',
  editTimeSlotPresetsThursday: '木曜日',
  editTimeSlotPresetsFriday: '金曜日',
  editTimeSlotPresetsSaturday: '土曜日',
  editTimeSlotPresetsSunday: '日曜日',
  editTimeSlotPresetsSelectedDate: '日付指定プリセットの新規作成',
  allTimeSlotOpenAlert: '本当にすべてのオーダー停止スロットを解除しますか？',
  allTimeSlotCloseAlert: '本当にすべてのスロットをオーダー停止にしますか？',
  edit: '編集',
  save: '保存',
  quit: 'やめる',
  noData: '-----',
}

export const lockerBoxContent = {
  makeAvailable: '再開する',
  makeUnavailable: '停止する',
  unavailableNow: '使用停止中',
  availableNow: '-',
  clearButton: 'クリア',
  clearConfirmWord: 'クリア',
  changeCommentButton: '備考あり',
  addCommentButton: '追加',
  openButton: '解錠',
  full: 'あり',
  notFull: '-',
}

export const stationDetailsTableContent = {
  id: 'ステーションID',
  name: 'ステーション名',
  soracomSIM: {
    title: 'soracom SIM',
    id: 'id',
    imsi: 'SIM IMSI',
  },
  machine: {
    serial: 'マシンシリアル',
    power: 'power',
  },
  extra: '備考',
  fullAddress: '住所',
  openingHours: '営業時間',
  location: {
    title: 'ロケーション',
    latitude: '緯度',
    longitude: '経度',
  },
  isClosed: '開店閉店',
  image: {
    title: '画像',
    stationImage: 'ステーション画像',
    mapImage: 'ステーション地図画像',
  },
  placedDate: '設置日',
  shippedDate: '出荷日',
  permittedDate: '営業許可日',
  startOperationDate: '営業開始日',
  lastOperationDate: '最終営業日',
  removedDate: '撤去日',
  orderEnablingHours: 'オーダー可能時間',
  operationHours: 'オペレーション時間',
  operationDays: ' オペレーション日',
}

export const upcomingOrdersTableContent = [
  'オーダー日時',
  'オーダーID',
  'HashID',
  'ユーザーID',
  'KIOSK ID',
  '受取時間スロット',
  'アイテム名',
  '支払額',
  'ポイント使用額',
  'クーポン使用額',
]
