import React, { useState } from 'react'
import Button from '../button'
import styles from '../../styles/modules/modal/modal.module.scss'
import { strings } from './modal-content'
import { Config } from '../../../core/toast'
import { Station } from '../../../core/stations'


interface Props {
  isLoading: boolean
  stations: Station[]
  createStationAlert: (stationId: number, title: string, body: string) => void
  show: (config: Config) => void
}

export const CreateStationAlertForm: React.FC<Props> = (props: Props) => {

  const [stationId, setStationId] = useState<number>(0)
  const [title, setTitle] = useState<string>('')
  const [body, setBody] = useState<string>('')

  const handleOnClickSubmit = () => {

    if (!stationId || !title || !body) {
      const config: Config = {
        type: 'error',
        title: strings.toastError.title,
        body: strings.toastError.body
      }
      props.show(config)
      return
    }
    props.createStationAlert(stationId, title, body)
  }


  return (
    <main className={styles.main}>
      <label className={styles.label}>{strings.stationAlert.station}</label>
      <div>
        <select
          className={styles.input}
          value={stationId}
          onChange={event => setStationId(Number(event.target.value))}
        >
          {stationId ? <></> : <option value={null}>{strings.stationAlert.stationUnset}</option> }
          {props.stations.map(station => (
            <option value={station.id} key={station.id}>
              {`ID: ${station.id} ${station.name}`}
            </option>
          ))}
        </select>
      </div>
      <div className={styles.form}>
        <label className={styles.label}>{strings.stationAlert.title}</label>
        <input
          type="text"
          value={title}
          className={styles.input}
          onChange={event => {
          setTitle(event.target.value)
        }}
          />
      </div>
      <div className={styles.form}>
        <label className={styles.label}>{strings.stationAlert.body}</label>
        <textarea
          className={styles.textarea}
          value={body}
          onChange={event => {
            setBody(event.target.value)}
          }
        />
      </div>
      <Button
        title={strings.stationAlert.createButton}
        onClick={() => handleOnClickSubmit()}
        className={styles.report}
        isLoading={props.isLoading}
        loadingClassName={styles.loadingReport}
      />
    </main>
  )
}
