export const strings = {
  title: 'アイテムランキング',
  stationWithItemRankTableTitle: 'アイテムランキング情報',
  queryStationIdTitle: 'ステーションIDで絞り込む',
  updateStationWithItemRanks: '設定する',
}

export const stationWithItemRankTableContent = [
  'ステーションID',
  'ステーション名',
  '1位のアイテム',
  '2位のアイテム',
  '3位のアイテム',
  '',
]
