import React from 'react'

import { Machine } from '../../../core/machines'
import { strings } from './machines-content'
import MachinesTable from './machines-table'

import Button from '../button'
import styles from '../../styles/modules/machines/machines.module.scss'

interface Props {
  machines: Machine[]
  showCreateMachine: () => void
  updateMachine: (
    machineId: number,
    serial: string,
    manufacturedDate: Date,
    type: string,
    version: string,
    factoryName: string,
    shouldBeLatest: boolean
  ) => void
  showEditMachine: (machine: Machine) => void
}

const MachinesIndex: React.FC<Props> = (props: Props) => {
  return (
    <>
      <Button
        title={strings.createMachineButton}
        onClick={() => props.showCreateMachine()}
        className={styles.machineCreateButton}
      />
      <MachinesTable
        machines={props.machines}
        updateMachine={props.updateMachine}
        showEditMachine={props.showEditMachine}
      />
    </>
  )
}

export default MachinesIndex
