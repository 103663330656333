import React from 'react'
import classnames from 'classnames'
import moment from 'moment'

import { Machine, MachineStatus, isOverV3Version } from '../../../core/machines'
import { strings, machinesTableContent } from '../machines/machines-content'
import Button from '../button'

import styles from '../../styles/modules/table.module.scss'
import machinesStyles from '../../styles/modules/machines/machines.module.scss'

interface Props {
  machines: Machine[]
  updateMachine: (
    machineId: number,
    serial: string,
    manufacturedDate: Date,
    type: string,
    version: string,
    factoryName: string,
    shouldBeLatest: boolean
  ) => void
  showEditMachine: (machine: Machine) => void
}

const MachinesTable: React.FC<Props> = (props: Props) => {
  const handleOnClickEdit = (machineId: number) => {
    const machine = props.machines.find(i => i.id === Number(machineId))
    props.showEditMachine(machine)
  }

  const handleOnClickUpdate = (machineId: number) => {
    const response = window.confirm('本当に更新しますか？')
    if (response) {
      const machine = props.machines.find(i => i.id === Number(machineId))
      props.updateMachine(
        machine.id,
        machine.serial,
        machine.manufacturedDate,
        machine.type,
        machine.version,
        machine.factoryName,
        true
      )
    }
  }

  const handleOnClickUpdateStop = (machineId: number) => {
    // const response = window.confirm('本当にしますか？')
    const machine = props.machines.find(i => i.id === Number(machineId))
    props.updateMachine(
      machine.id,
      machine.serial,
      machine.manufacturedDate,
      machine.type,
      machine.version,
      machine.factoryName,
      false
    )
  }

  const getMachineStatusLabel = (status: MachineStatus) => {
    switch (status) {
      case 'active':
        return strings.machineStatus.active
      case 'config_mode':
        return strings.machineStatus.configMode
      case 'fs_mode':
        return strings.machineStatus.fsMode
      case 'operation_mode':
        return strings.machineStatus.operationMode
      case 'critical_stop':
        return strings.machineStatus.criticalStop
      case 'station_stop':
        return strings.machineStatus.stationStop
      case 'communication_loss':
        return strings.machineStatus.connectionLost
      default:
        return ''
    }
  }

  return (
    <>
      <h3 className={styles.title}>{strings.machinesTableTitle}</h3>
      <div
        className={classnames({
          [styles.wrapper]: true,
          [styles.zebra]: true,
          [styles.stocker]: true,
        })}
      >
        <table className={styles.container}>
          <tbody>
            <tr className={styles.row}>
              {machinesTableContent.map(item => (
                <th key={item} className={styles.cell}>
                  {item}
                </th>
              ))}
            </tr>
            {props.machines
              .sort((a, b) => {
                if (a.id < b.id) {
                  return -1
                } else {
                  return 1
                }
              })
              .map(machine => (
                <tr className={styles.row} key={machine.id}>
                  <td className={styles.cell}>{machine.id}</td>
                  <td className={styles.cell}>{machine.serial}</td>
                  <td className={styles.cell}>
                    {isOverV3Version(machine.version)
                      ? getMachineStatusLabel(machine.status)
                      : '-'}
                  </td>
                  <td className={styles.cell}>{machine.power}</td>
                  <td className={styles.cell}>{machine.stationID}</td>
                  <td className={styles.cell}>
                    {machine.manufacturedDate
                      ? moment(machine.manufacturedDate).format('YYYY/MM/DD')
                      : null}
                  </td>
                  <td className={styles.cell}>{machine.type}</td>
                  <td className={styles.cell}>{machine.version}</td>
                  <td className={styles.cell}>{machine.factoryName}</td>
                  <td className={styles.cell}>{machine.nucVersion}</td>
                  <td className={styles.cell}>
                    {machine.shouldBeLatest
                      ? '最新バージョンを自動適用'
                      : '現在のバージョンを維持'}
                  </td>
                  <td className={styles.cell}>
                    {machine.shouldBeLatest ? (
                      <Button
                        title={strings.updateStopButton}
                        onClick={() => {
                          handleOnClickUpdateStop(machine.id)
                        }}
                        className={machinesStyles.editUpdateStatusButton}
                      />
                    ) : (
                      <Button
                        title={strings.updateButton}
                        onClick={() => {
                          handleOnClickUpdate(machine.id)
                        }}
                        className={machinesStyles.editUpdateStatusButton}
                      />
                    )}
                  </td>
                  <td className={styles.cell}>
                    {machine.updateAcceptedAt
                      ? moment(machine.updateAcceptedAt).format(
                          'YYYY/MM/DD HH:MM'
                        )
                      : null}
                  </td>
                  <td className={styles.cell}>
                    <Button
                      title={strings.editMachineButton}
                      onClick={() => {
                        handleOnClickEdit(machine.id)
                      }}
                      className={machinesStyles.machineEditButton}
                    />
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </>
  )
}

export default MachinesTable
