export interface PointTransaction {
  id: number
  point: number
  description: string
  occurredAt: string
  type: TransactionType
}

export enum TransactionType {
  UsedByOrder = 'used_by_order',
  GivenByOrder = 'given_by_order',
  UserRegistration = 'user_registration',
  FirstRootcMatch = 'first_rootc_match',
  FirstUserInvitation = 'first_user_invitation',
  UserInvitation = 'user_invitation',
  TwiceSameStationOrder = 'twice_same_station_order',
  FirstOrder = 'first_order',
  ThirdOrder = 'third_order',
  FifthOrder = 'fifth_order',
  TenthOrder = 'tenth_order',
  TwentiethOrder = 'twentieth_order',
  ThirtiethOrder = 'thirtieth_order',
  FiftiethOrder = 'fiftieth_order',
  HundredthOrder = 'hundredth_order',
  FiveHundredthOrder = 'five_hundredth_order',
  ThousandthOrder = 'thousandth_order',
  loginBonus = 'login_bonus',
  GivenByAdmin = 'given_by_admin',
  ExpiredByAdmin = 'expired_by_admin',
  Expired = 'expired',
  UserDeleted = 'user_deleted',
}
