import React from 'react'

import Button from '../button'
import Input from '../input'
import styles from '../../styles/modules/modal/modal.module.scss'
import { strings } from './modal-content'
import { Config } from '../../../core/toast'
import { validation } from '../../../core/soracom-sims'

interface Props {
  isLoading: boolean
  createSoracomSIM: (imsi: string, serial: string) => void
  show: (config: Config) => void
}

interface State {
  readonly imsi: string
  readonly serial: string
}

export class CreateSoracomSIMForm extends React.PureComponent<Props, State> {
  readonly state = {
    imsi: '',
    serial: '',
  }

  handleOnClickSubmit = () => {
    const { imsi, serial } = this.state

    if (!validation.imsiRegex.test(imsi)) {
      const config: Config = {
        type: 'error',
        title: strings.toastError.title,
        body: strings.toastError.body,
      }
      this.props.show(config)
      return
    }
    this.props.createSoracomSIM(imsi, serial)
  }

  render() {
    const { isLoading } = this.props
    return (
      <main className={styles.main}>
        <Input
          label={strings.soracomSIM.imsi}
          type={'text'}
          onChange={event => {
            this.setState({ imsi: event.target.value })
          }}
          value={this.state.imsi}
        />
        <Input
          label={strings.soracomSIM.serial}
          type={'text'}
          onChange={event => {
            this.setState({ serial: event.target.value })
          }}
          value={this.state.serial}
        />
        <Button
          title={strings.soracomSIM.createButton}
          onClick={this.handleOnClickSubmit}
          className={styles.report}
          isLoading={isLoading}
          loadingClassName={styles.loadingReport}
        />
      </main>
    )
  }
}
