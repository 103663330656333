import React from 'react'
import classnames from 'classnames'
import { Machine, MachineRelationHistory } from '../../../core/machines'
import { Station } from '../../../core/stations'
import { SoracomSIM } from '../../../core/soracom-sims'
import styles from '../../styles/modules/table.module.scss'
import {
  strings,
  machineRelationHistoriesTableContent,
} from './machine-relations-content'
import machineRelationsTableStyles from '../../styles/modules/machine-relations/machine-relations-table.module.scss'
import moment from 'moment'

interface Props {
  machineRelationHistories: MachineRelationHistory[]
  stations: Station[]
  soracomSIMs: SoracomSIM[]
  machines: Machine[]
}

const MachineRelationHistoriesTable: React.FC<Props> = (props: Props) => {
  const printStationName = (stationID: number | null) => {
    if (stationID == null) {
      return strings.noData
    }
    return props.stations.find(station => station.id === stationID).name
  }

  const printMachineSerial = (machineID: number) => {
    const targetmachine = props.machines.find(
      machine => machine.id === machineID
    )
    return targetmachine ? targetmachine.serial : strings.noData
  }

  const printSoracomSimSerial = (soracomSIMID: number) => {
    const targetSoracomSIM = props.soracomSIMs.find(
      soracomSIM => soracomSIM.id === soracomSIMID
    )
    return targetSoracomSIM ? targetSoracomSIM.serial : strings.noData
  }

  return (
    <div
      className={classnames({
        [styles.wrapper]: true,
        [styles.zebra]: true,
        [styles.stocker]: true,
      })}
    >
      <table
        className={classnames(
          styles.container,
          machineRelationsTableStyles.table
        )}
      >
        <tbody>
          <tr className={styles.row}>
            {machineRelationHistoriesTableContent.map(item => (
              <th key={item} className={styles.cell}>
                {item}
              </th>
            ))}
          </tr>
          {props.machineRelationHistories
            .sort((a, b) => {
              if (a.savedAt > b.savedAt) {
                return -1
              } else if (a.savedAt < b.savedAt) {
                return 1
              } else {
                return 0
              }
            })
            .map(item => (
              <tr className={styles.row} key={item.id}>
                <td className={styles.cell}>{item.machineID}</td>
                <td className={styles.cell}>
                  {printMachineSerial(item.machineID)}
                </td>
                <td className={styles.cell}>
                  {printStationName(item.stationID ? item.stationID : null)}
                </td>
                <td className={styles.cell}>
                  {item.soracomSIMID ? item.soracomSIMID : strings.noData}
                </td>
                <td className={styles.cell}>
                  {printSoracomSimSerial(item.soracomSIMID)}
                </td>
                <td className={styles.cell}>
                  {moment(item.savedAt).format('YYYY/MM/DD HH:mm:ss')}
                </td>
                <td className={styles.cell}>
                  {item.clearAt
                    ? moment(item.clearAt).format('YYYY/MM/DD HH:mm:ss')
                    : strings.noData}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  )
}

export default MachineRelationHistoriesTable
