export const strings = {
  title: 'サブスクリプション',
  tableTitle: 'サブスクリプション履歴',
  subscriptionTableTitle: 'サブスクリプション情報',
  switchGracePeriodTitle: '前プラン失効日時',
  queryButton: '絞り込む',
  NoSwitchGracePeriod: 'なし'
}

export const subscriptionsTableContent = [
  'ID',
  'ユーザ ID',
  'PayJP ID',
  'プラン名',
  '加入日時',
  '解約日時',
  'ステータス',
  ''
]
export const subscriptionInfoTableContent = {
  currentSubscriptionInfo : {
    id: 'ID',
    payJpId: 'PayJP ID',
    userId: 'ユーザ ID',
    subscribedAt : '加入日時',
    canceledAt: '解約日時',
    status: 'ステータス',
    planName: 'プラン名',
    price: '金額',
    maxMonthlyOrderCount: '月間最大オーダー上限'
  },
  switchGracePeriod : {
    planName: 'プラン名',
    expirationDatetime: '失効日時',
    price: '金額',
    maxMonthlyOrderCount: '月間最大オーダー上限'
  }
}
