import React from 'react'
import classnames from 'classnames'

import { Subscription } from '../../../core/subscription';
import { subscriptionsTableContent, strings } from './subscriptions-content'
import SubscriptionsTableItem from './subscriptions-table-item';
import styles from '../../styles/modules/table.module.scss'



interface Props {
  subscriptions: Subscription[]
}

const SubscriptionsTable: React.FC<Props> = (props: Props) => {


  return (
    <>
      <h3 className={styles.title}>{strings.tableTitle}</h3>
      <div
        className={classnames({
          [styles.wrapper]: true,
          [styles.zebra]: true,
          [styles.stocker]: true,
        })}
      >
        <table className={styles.container}>
          <tbody>
            <tr className={styles.row}>
              {subscriptionsTableContent.map(item => (
                <th key={item} className={styles.cell}>
                  {item}
                </th>
              ))}
            </tr>
            {props.subscriptions
              .sort((a, b) => {
                if (a.id > b.id) {
                  return -1
                } else {
                  return 1
                }
              })
              .map(subscription => (
                <SubscriptionsTableItem
                  subscription={subscription}
                  key={subscription.id}
                />
              ))
            }
          </tbody>
        </table>
      </div>
    </>
  )
}

export default SubscriptionsTable
