import React, { useCallback } from 'react'
import classnames from 'classnames'

import Button from '../button'
import { SignageBanner } from '../../../core/signage-banners'
import styles from '../../styles/modules/table.module.scss'
import signageBannersItemsStyles from '../../styles/modules/signage-banners/signage-banner-items.module.scss'
import { strings, SignageTableContent } from './signage-banners-content'
import Switch from 'react-switch'
import { ReactSortable } from 'react-sortablejs'
import SignageFileTypeContainer from './signage-file-type-container'

interface Props {
  signageBanners: SignageBanner[]
  setSignageBanners: React.Dispatch<React.SetStateAction<SignageBanner[]>>
  onClickCreateSignageBanner: () => void
  onClickDeleteSignageBanner: (id: number) => void
  sortSignageBanners: (ids: number[]) => void
  updateSignageBanners: (id: number, duration: number, isShow: boolean) => void
  showEditSignageBannerDuration: (
    id: number,
    duration: number,
    isShow: boolean
  ) => void
  handleOnClickEditSignageBannerDuration: (
    id: number,
    duration: number,
    isShow: boolean
  ) => void
}

const SignageBannerTable: React.FC<Props> = props => {
  // handleOnSort関数で、setPromotionImagesのみを依存配列に追加するためにpropsを分解
  const { setSignageBanners } = props

  // 画面のちらつきを防ぐためにuseCallbackを使用
  const handleOnSort = useCallback(
    (signageBanners: SignageBanner[]) => {
      setSignageBanners(signageBanners)
    },
    [setSignageBanners]
  )

  return (
    <>
      <Button
        title={strings.createSignageBannerButton}
        onClick={() => {
          props.onClickCreateSignageBanner()
        }}
        className={signageBannersItemsStyles.signageBannerCreateButton}
      />

      <p className={signageBannersItemsStyles.description}>
        {strings.description}
      </p>

      <div
        className={classnames({
          [styles.wrapper]: true,
          [styles.zebra]: true,
          [styles.stocker]: true,
        })}
      >
        <table className={styles.container}>
          <thead>
            <tr className={signageBannersItemsStyles.row}>
              {SignageTableContent.map((item, index) => (
                <th key={index} className={styles.cell}>
                  {item}
                </th>
              ))}
            </tr>
          </thead>
          <ReactSortable
            tag="tbody"
            list={props.signageBanners}
            setList={handleOnSort}
            handle=".handle"
            onSort={() =>
              props.sortSignageBanners(
                props.signageBanners.map(item => item.signageBannerSequenceID)
              )
            }
          >
            {props.signageBanners.map((item, index) => (
              <tr className={styles.row} key={item.signageBannerSequenceID}>
                <td className={`${signageBannersItemsStyles.cell} handle`}>
                  <div className={signageBannersItemsStyles.orderIcon}>
                    <span />
                    <span />
                    <span />
                  </div>
                </td>
                <td className={styles.cell}>{index + 1}</td>
                <td className={styles.cell}>{item.id}</td>
                <td className={styles.cell}>{item.title}</td>
                <td className={signageBannersItemsStyles.imgCell}>
                  <SignageFileTypeContainer
                    fileType={item.fileType}
                    url={item.url}
                  />
                </td>
                <td className={styles.longTextCell}>{item.adCaption}</td>
                <td className={signageBannersItemsStyles.buttonCell}>
                  {item.duration}
                </td>
                <td className={signageBannersItemsStyles.buttonCell}>
                  <div className={signageBannersItemsStyles.switchContainer}>
                    <Switch
                      onChange={() => {
                        props.updateSignageBanners(
                          item.signageBannerSequenceID,
                          item.duration,
                          !item.isShow
                        )
                      }}
                      checked={item.isShow}
                    />
                  </div>
                </td>
                <td className={signageBannersItemsStyles.buttonCell}>
                  {item.fileType === 'image' && (
                    <Button
                      className={signageBannersItemsStyles.updateButton}
                      title={'編集'}
                      onClick={() =>
                        props.handleOnClickEditSignageBannerDuration(
                          item.signageBannerSequenceID,
                          item.duration,
                          item.isShow
                        )
                      }
                    />
                  )}
                </td>
                <td className={signageBannersItemsStyles.buttonCell}>
                  <Button
                    className={signageBannersItemsStyles.deleteButton}
                    title={strings.deleteSignageBannerButton}
                    onClick={() => {
                      props.onClickDeleteSignageBanner(item.id)
                    }}
                  />
                </td>
              </tr>
            ))}
          </ReactSortable>
        </table>
      </div>
    </>
  )
}

export default React.memo(SignageBannerTable)
