import { PointItem } from '../../../core/point-items/point-items'
import classnames from 'classnames'

import styles from '../../styles/modules/table.module.scss'
import { pointItemsTableContent, strings } from './point-items-content'
import React, { useCallback } from 'react'
import pointItemsTableStyles from '../../styles/modules/point-items/point-items-table.module.scss'
import { ReactSortable } from 'react-sortablejs'
import Button from '../button'
import Switch from 'react-switch'

interface Props {
  pointItems: PointItem[]
  onClickCreatePointItem: () => void
  updatePointItem: (
    id: number,
    imageFileName: string,
    name: string,
    isDeliveryRequired: boolean,
    pointsPrice: number,
    stock: number,
    isAvailable: boolean,
    isInfiniteStock: boolean
  ) => void
  sortPointItems: (ids: number[]) => void
  setPointItems: (pointItems: PointItem[]) => void
}

const PointItemsTable: React.FC<Props> = props => {
  const { setPointItems } = props
  const handleOnSort = useCallback(
    (items: PointItem[]) => {
      setPointItems(items)
    },
    [setPointItems]
  )

  return (
    <>
      <Button
        title={strings.createPointItemButton}
        onClick={props.onClickCreatePointItem}
        className={pointItemsTableStyles.createPointItemButton}
      />
      <div
        className={classnames({
          [styles.wrapper]: true,
          [styles.zebra]: true,
          [styles.stocker]: true,
        })}
      >
        <table className={styles.container}>
          <thead>
            <tr className={pointItemsTableStyles.row}>
              {pointItemsTableContent.map((pointItem, index) => (
                <th key={index} className={styles.cell}>
                  {pointItem}
                </th>
              ))}
            </tr>
          </thead>
          <ReactSortable
            tag="tbody"
            list={props.pointItems}
            setList={handleOnSort}
            handle=".handle"
            onSort={() =>
              props.sortPointItems(
                props.pointItems.map(pointItem => pointItem.id)
              )
            }
          >
            {props.pointItems.map((pointItem, index) => (
              <tr className={styles.row} key={pointItem.id}>
                <td className={`${pointItemsTableStyles.cell} handle`}>
                  <div className={pointItemsTableStyles.orderIcon}>
                    <span />
                    <span />
                    <span />
                  </div>
                </td>
                <td className={styles.cell}>{pointItem.id}</td>
                <td className={styles.cell}>{pointItem.index}</td>
                <td className={styles.cell}>{pointItem.name}</td>
                <td className={pointItemsTableStyles.imgCell}>
                  <img
                    alt="pointItem"
                    src={pointItem.imageURL}
                    className={pointItemsTableStyles.image}
                  />
                </td>
                <td className={styles.cell}>
                  {pointItem.isDeliveryRequired ? '要' : '不要'}
                </td>
                <td className={styles.cell}>{pointItem.pointsPrice}</td>
                <td className={pointItemsTableStyles.buttonCell}>
                  <div className={pointItemsTableStyles.switchContainer}>
                    <Switch
                      onChange={e => {
                        props.updatePointItem(
                          pointItem.id,
                          pointItem.imageFileName,
                          pointItem.name,
                          pointItem.isDeliveryRequired,
                          pointItem.pointsPrice,
                          pointItem.stock,
                          e,
                          pointItem.isInfiniteStock
                        )
                      }}
                      checked={pointItem.isAvailable}
                    />
                  </div>
                </td>
                <td className={styles.cell}>
                  {pointItem.isInfiniteStock
                    ? '無限'
                    : pointItem.stock.toString()}
                </td>
              </tr>
            ))}
          </ReactSortable>
        </table>
      </div>
    </>
  )
}

export default React.memo(PointItemsTable)
