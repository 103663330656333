import { CampaignDurationUnit } from '../../../core/campaigns'

export const strings = {
  screenTitle: '自動発行クーポン',
  starterCampaignsTitle: '新規会員特典クーポン',
  stampCampaignsTitle: 'スタンプ特典クーポン',
  orderCampaignsTitle: '注文特典クーポン',
  isAllStationEligible: '全店舗',
  hasEligibleStations: '限定',
  setAutoEntryCampaignButton: '編集',
}

export const starterCampaignsTableContent = [
  'ID',
  '発行元ステーション',
  'コード',
  'クーポン名',
  '割引額',
  '使用可能回数',
  '有効期間',
  '',
]

export const stampCampaignsTableContent = [
  'ID',
  'コード',
  'クーポン名',
  '割引額',
  '使用可能回数',
  '有効期間',
  '',
]

export const orderCampaignsTableContent = [
  'ID',
  '発行元ステーション',
  'コード',
  'クーポン名',
  '割引額',
  '使用可能回数',
  '有効期間',
  'ステータス',
  '対象ステーション',
  '',
]

export const getDurationToDisplay = (
  duration: number,
  durationUnit: CampaignDurationUnit
): string => {
  let durationUnitToDisplay: string
  switch (durationUnit) {
    case CampaignDurationUnit.Day:
      durationUnitToDisplay = '日'
      break
    case CampaignDurationUnit.Week:
      durationUnitToDisplay = '週間'
      break
    case CampaignDurationUnit.Month:
      durationUnitToDisplay = 'ヶ月'
      break
    default:
      durationUnitToDisplay = ''
  }

  return `${duration}${durationUnitToDisplay}`
}
