import React from 'react'
import classnames from 'classnames'
import moment from 'moment'
import { StationAlert } from '../../../core/station-alerts'
import { Station } from '../../../core/stations'
import styles from '../../styles/modules/table.module.scss'
import { strings, stationAlertsTableContent} from './station-alerts-content'
import stationAlertsTableStyles from '../../styles/modules/station-alerts/station-alerts.module.scss'

import Button from '../button'

interface Props {
  stationAlerts: StationAlert[]
  stations: Station[]
  showEditStationAlert: (stations: Station[], stationAlert: StationAlert) => void
}


const StationAlertsTable: React.FC<Props> = (props: Props) => {

  const handleOnClickStationAlert = (stationId: number) => {
    const stationAlert = props.stationAlerts.find(i => i.id === Number(stationId))
    props.showEditStationAlert(props.stations, stationAlert)
  }

  return (
    <>
      <h3 className={styles.title}>{strings.stationAlertsTableTitle}</h3>
      <div
        className={classnames({
          [styles.wrapper]: true,
          [styles.zebra]: true,
          [styles.stocker]: true,
        })}
      >
        <table className={styles.container}>
          <tbody>
            <tr className={styles.row} >
              {stationAlertsTableContent.map(item => (
                <th key={item} className={styles.cell}>
                  {item}
                </th>
              ))}
            </tr>
            {props.stationAlerts
              .sort((a, b) => {
                if (a.id > b.id) {
                  return -1
                } else {
                  return 1
                }
              })
              .map(stationAlert => (
            <tr className={styles.row} key={stationAlert.id}>
              <td className={styles.cell}>{stationAlert.stationName}</td>
              <td className={styles.cell}>{stationAlert.title}</td>
              <td className={styles.cell}>
                {moment(stationAlert.happenedAt).format('YYYY/MM/DD HH:mm:ss')}
              </td>
              <td className={styles.cell}>{stationAlert.isDeleted ? "済" : null }</td>
              <td className={styles.cell}>
              <Button
                  title={strings.showStationAlertButton}
                  onClick={() => { handleOnClickStationAlert(stationAlert.id) }}
                  className={stationAlertsTableStyles.container}
                />
              </td>
            </tr>
          ))}
          </tbody>
        </table>
      </div>

    </>
  )
}

export default StationAlertsTable
