import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import RootState from '../../core/root-state'
import { itemsActions } from '../../core/items'
import {
  stationWithItemRanksActions,
  StationWithItemRanksParam,
  StationWithItemRanks,
} from '../../core/station-with-item-ranks'
import { modalActions } from '../../core/modal'
import { strings } from '../components/station-with-item-ranks/station-with-item-ranks-content'
import Layout from '../layouts/layout'
import Wrapper from '../layouts/wrapper'
import StationWithItemRanksTable from '../components/station-with-item-ranks/station-with-item-ranks-table'
import QueryStationId from '../components/station-with-item-ranks/query-station-id'
import styles from '../styles/modules/station-with-item-ranks/station-with-item-ranks.module.scss'

const StationWithItemRankScreen = () => {
  const [stationId, setStationId] = useState('')

  const dispatch = useDispatch()
  const stationWithItemRanks = useSelector(
    (state: RootState) => state.stationWithItemRanks.stationWithItemRanks
  )
  const items = useSelector((state: RootState) => state.items.items)

  const handleOnChangeStationId = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStationId(e.target.value)
  }

  useEffect(() => {
    const fetchStationWithItemRanks = (param: StationWithItemRanksParam) => {
      dispatch(stationWithItemRanksActions.fetchStationWithItemRanks(param))
    }

    fetchStationWithItemRanks({ station_id: Number(stationId) })
  }, [dispatch, stationId])

  useEffect(() => {
    const fetchItems = () => {
      dispatch(itemsActions.fetchItems())
    }

    fetchItems()
  }, [dispatch])

  const handleOnClickOpenModal = (
    targetStationItemRank: StationWithItemRanks
  ) => {
    dispatch(
      modalActions.showEditStationWithItemRank(targetStationItemRank, items)
    )
  }

  return (
    <Layout location="station_with_item_ranks">
      <Wrapper title={strings.title}>
        <div className={styles.queryContainer}>
          <QueryStationId
            onChange={handleOnChangeStationId}
            id={Number(stationId)}
          />
        </div>

        <div>
          <StationWithItemRanksTable
            items={items}
            handleClickOnOpenCreateModal={handleOnClickOpenModal}
            stationWithItemRanks={stationWithItemRanks}
          />
        </div>
      </Wrapper>
    </Layout>
  )
}

export default StationWithItemRankScreen
