import React from 'react'
import classnames from 'classnames'
import Select, { MultiValue } from 'react-select';

import styles from '../styles/modules/auto-complete-select.module.scss'

export interface SelectOption {
  value: string
  label: string
}

interface Props {
  onChange: (value: SelectOption | MultiValue<SelectOption>) => void
  className?: string
  options: SelectOption[]
  label?: string
  isMulti?: boolean
  placeholder?: string
}


const AutoCompleteSelect: React.FC<Props> = ({
  onChange,
  options,
  label,
  className,
  isMulti,
  placeholder,
}) => {
  return (
    <div className={classnames({ [styles.form]: true, [className]: true })}>
      {label && <label className={styles.label}>{label}</label>}
      <Select
        options={options}
        isMulti={isMulti}
        onChange={(value) => { onChange(value) }}
        placeholder={placeholder ? placeholder : ''}
        styles={{
          control: (baseStyles, _) => ({
            ...baseStyles,
            border: 'none',
            backgroundColor: '#ecf0f1',
          })
        }}
      />
    </div>
  )
}

export default AutoCompleteSelect
