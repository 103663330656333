import React from 'react'

import styles from '../../styles/modules/stations/id-title.module.scss'

interface Props {
  id: number
  name: string
}

const IdTitle: React.FC<Props> = props => (
  <div className={styles.container}>
    <span>{`ID: ${props.id}`}</span>
    <h4>{props.name}</h4>
  </div>
)

export default IdTitle
