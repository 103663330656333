import { ActionType } from 'typesafe-actions'
import { machineEmbeddedComputersActionType, machineEmbeddedComputersActions } from './actions'
import { MachineEmbeddedComputer } from "./machine-embedded-computers";

export interface MachineEmbeddedComputersState {
  readonly error: Error
  readonly isLoading: boolean
  readonly machineEmbeddedComputers: MachineEmbeddedComputer[]
}

export const initialState: MachineEmbeddedComputersState = {
  error: null,
  isLoading: false,
  machineEmbeddedComputers: [],
}

export const machineEmbeddedComputersReducer = (
  state: MachineEmbeddedComputersState = initialState,
  action: ActionType<typeof machineEmbeddedComputersActions>
) => {
  switch (action.type) {
    case machineEmbeddedComputersActionType.FETCH_MACHINE_EMBEDDED_COMPUTERS:
      return {
        ...state,
        isLoading: true,
        error: null
      }
    case machineEmbeddedComputersActionType.FETCH_MACHINE_EMBEDDED_COMPUTERS_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        error: null,
        machineEmbeddedComputers: action.payload.machineEmbeddedComputers
      }
    case machineEmbeddedComputersActionType.DELETE_MACHINE_EMBEDDED_COMPUTER:
      return {
        ...state,
        isLoading: true,
        error: null
      }
    case machineEmbeddedComputersActionType.THROW_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error
      }
    default:
      return state
  }
}
