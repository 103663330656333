import React, { useEffect, useState } from 'react'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import RootState from '../../core/root-state'
import Layout from '../layouts/layout'
import Wrapper from '../layouts/wrapper'
import { strings } from '../components/camera-streaming-viewer/camera-streaming-viewer-content'
import Select from '../components/select'
import Button from '../components/button'
import StreamingViewer from '../components/camera-streaming-viewer/camera-streaming-viewer-main'
import { KinesisVideo } from 'aws-sdk'

import styles from '../styles/modules/camera-streaming-viewer/camera-streaming-viewer.module.scss'
import {
  CameraStreamingViewerCredentials,
  cameraStreamingViewerCredentialsActions,
} from '../../core/camera-streaming-viewer'

interface Props {
  isLoading: boolean
  cameraStreamingViewerCredentials: CameraStreamingViewerCredentials
  fetchCameraStreamingViewerCredentials: () => void
}

const CameraStreamingViewerScreen: React.FC<Props> = ({
  fetchCameraStreamingViewerCredentials,
  cameraStreamingViewerCredentials,
}) => {
  const [channelInfoList, setChannelInfoList] = useState<any[]>([])
  const [selectedChannelInfo, setSelectedChannelInfo] = useState<any>({})
  const [viewingChannelInfo, setViewingChannelInfo] = useState<any>({})
  const [
    kinesisVideoClient,
    setKinesisVideoClient,
  ] = useState<KinesisVideo | null>(null)

  useEffect(() => {
    fetchCameraStreamingViewerCredentials()
  }, [fetchCameraStreamingViewerCredentials])

  useEffect(() => {
    if (cameraStreamingViewerCredentials) {
      const client = new KinesisVideo(cameraStreamingViewerCredentials)
      setKinesisVideoClient(client)
    }
  }, [cameraStreamingViewerCredentials])

  useEffect(() => {
    if (!kinesisVideoClient) {
      return
    }
    kinesisVideoClient.listSignalingChannels({}, (err, data) => {
      if (err) {
        alert(err)
      } else {
        setChannelInfoList(data.ChannelInfoList)
      }
    })
  }, [kinesisVideoClient])

  return (
    <Layout location={'streaming_viewer'}>
      <Wrapper title={strings.title}>
        <div className={styles.container}>
          <h1>{strings.note}</h1>
          <Select
            onChange={event => {
              setSelectedChannelInfo(
                channelInfoList.find(e => e.ChannelName === event.target.value)
              )
            }}
            label={strings.chanelLabel}
            value={
              selectedChannelInfo
                ? selectedChannelInfo.ChannelName
                : strings.noData
            }
            options={channelInfoList.map(info => {
              return {
                value: info.ChannelName,
                label: info.ChannelName,
              }
            })}
          />
        </div>

        <Button
          title={strings.startWatchingButton}
          onClick={() => {
            setViewingChannelInfo(selectedChannelInfo)
          }}
        />
        {!!viewingChannelInfo?.ChannelName ? (
          <StreamingViewer
            channelName={viewingChannelInfo.ChannelName}
            channelARN={viewingChannelInfo.ChannelARN}
            cameraStreamingViewerCredentials={cameraStreamingViewerCredentials}
          />
        ) : (
          <div />
        )}
      </Wrapper>
    </Layout>
  )
}

const mapStateToProps = (state: RootState) => ({
  cameraStreamingViewerCredentials:
    state.cameraStreamingViewerCredentials.cameraStreamingViewerCredentials,
  isLoading: state.cameraStreamingViewerCredentials.isLoading,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchCameraStreamingViewerCredentials: () =>
    dispatch(
      cameraStreamingViewerCredentialsActions.fetchCameraStreamingViewerCredentials()
    ),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CameraStreamingViewerScreen)
