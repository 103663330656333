import React, {useState} from 'react'
import { Dispatch } from 'redux'
import { connect } from "react-redux";

import Layout from '../layouts/layout'
import Wrapper from '../layouts/wrapper'
import {
  Item,
  itemsActions
} from '../../core/items'
import RootState from '../../core/root-state'
import ManufacturingInfoForm from '../components/new-item/manufacturing-info-form'
import { strings } from '../components/new-item/new-item-content'
import styles from '../styles/modules/new-item/new-item.module.scss'
import ItemInfoForm from '../components/new-item/item-info-form'
import Button from '../components/button'

interface Props {
  isLoading: boolean
  createItem: (item: Item) => void
}

const NewItemScreen: React.FC<Props> = (props: Props) => {
  const [item, setItem] = useState<Item>({
    id: null,
    beanAmount: null,
    beanTypeIndex: 1,
    machineButtonIndex: 1,
    name: '',
    price: null,
    disposalSeconds: null,
    category: 'hot',
    cupType: 1,
    description: '',
    story: '',
  })

  const isSavable = () => {
    const {
      beanAmount,
      beanTypeIndex,
      machineButtonIndex,
      name,
      price,
      disposalSeconds,
      category,
      cupType,
      description,
      story,
    } = item
    if (
      name === '' ||
      category === null ||
      description === '' ||
      story === ''
    ) {
      return false
    } else if (
      price === null ||
      price === 0 ||
      disposalSeconds === null ||
      disposalSeconds === 0 ||
      beanAmount === null ||
      beanAmount === 0 ||
      beanTypeIndex === null ||
      beanTypeIndex === 0 ||
      machineButtonIndex === null ||
      cupType === null
    ) {
      return false
    } else {
      return true
    }
  }

  const handleOnChangeManufacturingInfo = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const name = event.target.name
    const value = event.target.value
    if (
      name === 'machineButtonIndex' ||
      name === 'beanTypeIndex' ||
      name === 'beanAmount'
    ) {
      const re = /^[0-9\b]+$/
      if (value === '') {
        setItem({...item, [name]: null})
      }
      if (re.test(value)) {
        setItem({...item, [name]: Number(value)})
      }
    }
  }

  const handleOnChangeItemInfo = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const name = event.target.name
    const value = event.target.value
    if (name === 'name' || name === 'category' || name === 'description' || name === 'story') {
      setItem({...item, [name]: value})
    } else if (name === 'price' || name === 'disposalSeconds' || name === 'cupType') {
      const re = /^[0-9\b]+$/
      if (value === '') {
        setItem({...item, [name]: null})
      }
      if (re.test(value)) {
        setItem({...item, [name]: Number(value)})
      }
    }
  }

  const handleOnClickSave = () => {
    props.createItem(item)
    setItem({
      id: null,
      beanAmount: null,
      beanTypeIndex: 1,
      machineButtonIndex: 1,
      name: '',
      price: null,
      disposalSeconds: null,
      category: 'hot',
      cupType: 1,
      description: '',
      story: '',
    })
  }

  return (
    <Layout location={'new_item'}>
      <Wrapper title="アイテム追加">
      <Button
        className={styles.itemSaveButton}
        title={strings.save}
        disabled={!isSavable()}
        onClick={handleOnClickSave}
      />
      <h3 className={styles.title}>{strings.manufacturingInfo}</h3>
      <ManufacturingInfoForm
        item={item}
        onChange={handleOnChangeManufacturingInfo}
      />
      <h3 className={styles.title}>{strings.itemInfo}</h3>
      <ItemInfoForm
        item={item}
        onChange={handleOnChangeItemInfo}
      />
      </Wrapper>
    </Layout>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    isLoading: state.items.isLoading,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  createItem: (item: Item) => dispatch(itemsActions.createItem(item)),
})

export default connect(mapStateToProps, mapDispatchToProps)(NewItemScreen)
