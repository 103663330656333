import { action } from 'typesafe-actions'

import { Campaign } from './campaigns'
import { CampaignParam } from './campaigns'

export enum campaignsActionType {
  FETCH_PROMOTION_CAMPAIGNS = 'FETCH_PROMOTION_CAMPAIGNS',
  FETCH_PROMOTION_CAMPAIGNS_SUCCEEDED = 'FETCH_PROMOTION_CAMPAIGNS_SUCCEEDED',
  FETCH_STARTER_CAMPAIGNS = 'FETCH_STARTER_CAMPAIGNS',
  FETCH_STARTER_CAMPAIGNS_SUCCEEDED = 'FETCH_STARTER_CAMPAIGNS_SUCCEEDED',
  FETCH_STAMP_CAMPAIGNS = 'FETCH_STAMP_CAMPAIGNS',
  FETCH_STAMP_CAMPAIGNS_SUCCEEDED = 'FETCH_STAMP_CAMPAIGNS_SUCCEEDED',
  FETCH_ORDER_CAMPAIGNS = 'FETCH_ORDER_CAMPAIGNS',
  FETCH_ORDER_CAMPAIGNS_SUCCEEDED = 'FETCH_ORDER_CAMPAIGNS_SUCCEEDED',
  CREATE_PROMOTION_CAMPAIGN = 'CREATE_PROMOTION_CAMPAIGN',
  SET_PROMOTION_CAMPAIGN = 'SET_PROMOTION_CAMPAIGN',
  SET_PROMOTION_CAMPAIGN_EXPIRES_AT = 'SET_PROMOTION_CAMPAIGN_EXPIRES_AT',
  SET_STARTER_CAMPAIGN = 'SET_STARTER_CAMPAIGN',
  SET_STAMP_CAMPAIGN = 'SET_STAMP_CAMPAIGN',
  SET_ORDER_CAMPAIGN = 'SET_ORDER_CAMPAIGN',
  THROW_ERROR = 'THROW_ERROR',
}

export const campaignsActions = {
  fetchPromotionCampaigns: (campaignParam: CampaignParam) =>
    action(campaignsActionType.FETCH_PROMOTION_CAMPAIGNS, { campaignParam }),
  fetchPromotionCampaignsSucceeded: (promotionCampaigns: Campaign[]) =>
    action(campaignsActionType.FETCH_PROMOTION_CAMPAIGNS_SUCCEEDED, {
      promotionCampaigns,
    }),
  fetchStarterCampaigns: () =>
    action(campaignsActionType.FETCH_STARTER_CAMPAIGNS),
  fetchStarterCampaignsSucceeded: (starterCampaigns: Campaign[]) =>
    action(campaignsActionType.FETCH_STARTER_CAMPAIGNS_SUCCEEDED, {
      starterCampaigns,
    }),
  fetchStampCampaigns: () => action(campaignsActionType.FETCH_STAMP_CAMPAIGNS),
  fetchStampCampaignsSucceeded: (stampCampaigns: Campaign[]) =>
    action(campaignsActionType.FETCH_STAMP_CAMPAIGNS_SUCCEEDED, {
      stampCampaigns,
    }),
  fetchOrderCampaigns: () => action(campaignsActionType.FETCH_ORDER_CAMPAIGNS),
  fetchOrderCampaignsSucceeded: (orderCampaigns: Campaign[]) =>
    action(campaignsActionType.FETCH_ORDER_CAMPAIGNS_SUCCEEDED, {
      orderCampaigns,
    }),
  createPromotionCampaign: (
    code: string,
    name: string,
    discountAmount: number,
    memo: string,
    availableCount: number,
    entryCountLimit: number,
    expiresAt: string,
    eligibleStationIds?: number[]
  ) =>
    action(campaignsActionType.CREATE_PROMOTION_CAMPAIGN, {
      code,
      name,
      discountAmount,
      memo,
      availableCount,
      entryCountLimit,
      expiresAt,
      eligibleStationIds,
    }),
  setPromotionCampaign: (campaignId: number, campaign: Campaign) =>
    action(campaignsActionType.SET_PROMOTION_CAMPAIGN, {
      campaignId,
      campaign,
    }),
  setPromotionCampaignExpiresAt: (campaignId: number) =>
    action(campaignsActionType.SET_PROMOTION_CAMPAIGN_EXPIRES_AT, {
      campaignId,
    }),
  setStarterCampaign: (campaignId: number, campaign: Campaign) =>
    action(campaignsActionType.SET_STARTER_CAMPAIGN, {
      campaignId,
      campaign,
    }),
  setStampCampaign: (campaignId: number, campaign: Campaign) =>
    action(campaignsActionType.SET_STAMP_CAMPAIGN, {
      campaignId,
      campaign,
    }),
  setOrderCampaign: (campaignId: number, campaign: Campaign) =>
    action(campaignsActionType.SET_ORDER_CAMPAIGN, {
      campaignId,
      campaign,
    }),
  throwError: (error: Error) =>
    action(campaignsActionType.THROW_ERROR, { error }),
}
