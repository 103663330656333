import React, { useState } from 'react'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'

import RootState from '../../core/root-state'
import { pointActions, PointTransaction } from '../../core/points'
import { UserInfo } from '../../core/users'
import Layout from '../layouts/layout'
import Wrapper from '../layouts/wrapper'
import QueryId from '../components/points/query-id'
import Button from '../components/button'
import { strings } from '../components/points/points-content'
import styles from '../styles/modules//points/points.module.scss'
import PointsItem from '../components/points/points-item'
import UserNotFound from '../components/users/user-not-found'
import { modalActions } from '../../core/modal'

interface Props {
  isLoading: boolean
  user: UserInfo
  amount: number
  pointTransactions: PointTransaction[]
  fetchPointTransactions: (id: number) => void
  showCreateGivePoints: (id: number) => void
  showCreateExpirePoints: (id: number) => void
}

const PointsScreen: React.FC<Props> = (props: Props) => {
  const [userId, setUserId] = useState<number>()
  const [didSearchFinish, setDidSearchFinish] = useState<boolean>(false)

  const handleOnChangeId = (event: React.ChangeEvent<HTMLInputElement>) => {
    const re = /^[0-9\b]+$/
    if (event.target.value === '') {
      setUserId(null)
    }
    if (re.test(event.target.value)) {
      setUserId(parseInt(event.target.value, 10))
    }
  }

  const handleOnClickQueryId = () => {
    props.fetchPointTransactions(userId)
    setDidSearchFinish(true)
  }

  const handleOnClickCreateGivePoints = () => {
    props.showCreateGivePoints(userId)
  }

  const handleOnClickCreateExpirePoints = () => {
    props.showCreateExpirePoints(userId)
  }

  return (
    <Layout location="points">
      <Wrapper title={strings.title}>
        <div className={styles.queryContainer}>
          <QueryId id={userId} onChange={event => handleOnChangeId(event)} />
          <Button
            title={strings.queryButton}
            onClick={handleOnClickQueryId}
            className={styles.queryButton}
          />
        </div>
        {props.user && (
          <PointsItem
            userInfo={props.user}
            amount={props.amount}
            pointTransactions={props.pointTransactions}
            onClickCreateGivePoints={() => handleOnClickCreateGivePoints()}
            onClickCreateExpirePoints={() => handleOnClickCreateExpirePoints()}
          />
        )}
        {!props.user && !props.isLoading && didSearchFinish && <UserNotFound />}
      </Wrapper>
    </Layout>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    isLoading: state.pointTransactions.isLoading,
    user: state.pointTransactions.user,
    amount: state.pointTransactions.amount,
    pointTransactions: state.pointTransactions.pointTransactions,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchPointTransactions: (userId: number) => {
    dispatch(pointActions.fetchPointTransactions(userId))
  },
  showCreateGivePoints: (userId: number) =>
    dispatch(modalActions.showCreateGivePoints(userId)),
  showCreateExpirePoints: (userId: number) =>
    dispatch(modalActions.showCreateExpirePoints(userId)),
})

export default connect(mapStateToProps, mapDispatchToProps)(PointsScreen)
