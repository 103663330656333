export const strings = {
  machineEmbeddedComputersTableTitle: 'SDカード一覧',
  noData: '-----',
}

export const machineEmbeddedComputersTableContent = [
  'ID',
  'SDカード ID',
  'node ID',
  'マシン ID',
  'ステーション ID',
  'ステーション名',
  '',
]
