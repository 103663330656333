import React, { useState } from 'react'

import Button from '../button'
import Input from '../input'
import styles from '../../styles/modules/modal/modal.module.scss'
import { strings } from './modal-content'
import { Config } from '../../../core/toast'
import { SoracomSIM, validation } from '../../../core/soracom-sims'

interface Props {
  isLoading: boolean
  soracomSIM: SoracomSIM
  setSoracomSIM: (soracomSIMId: number, imsi: string, serial: string) => void
  show: (config: Config) => void
}

export const EditSoracomSIMForm: React.FC<Props> = (props: Props) => {
  const [imsi, setImsi] = useState<string>(props.soracomSIM.imsi)
  const [serial, setSerial] = useState<string>(props.soracomSIM.serial)

  const handleOnClickSubmit = () => {
    if (!validation.imsiRegex.test(imsi)) {
      const config: Config = {
        type: 'error',
        title: strings.toastError.title,
        body: strings.toastError.body
      }
      props.show(config)
      return
    }
    props.setSoracomSIM(props.soracomSIM.id, imsi, serial)
  }

  return (
    <main className={styles.main}>
      <Input
        label={strings.soracomSIM.imsi}
        type={'text'}
        onChange={event => {
          setImsi(event.target.value)
        }}
        value={imsi}
      />
      <Input
        label={strings.soracomSIM.serial}
        type={'text'}
        onChange={event => {
          setSerial(event.target.value)
        }}
        value={serial}
      />
      <Button
        title={strings.soracomSIM.updateButton}
        onClick={handleOnClickSubmit}
        className={styles.report}
        isLoading={props.isLoading}
        loadingClassName={styles.loadingReport}
      />
    </main>
  )
}
