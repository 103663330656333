import { action } from 'typesafe-actions'
import { KioskItemPrice } from './kiosk-item-prices'

export enum kioskItemPricesActionType {
  FETCH_KIOSK_ITEM_PRICES = 'FETCH_KIOSK_ITEM_PRICES',
  FETCH_KIOSK_ITEM_PRICES_SUCCEEDED = 'FETCH_KIOSK_ITEM_PRICES_SUCCEEDED',
  UPDATE_KIOSK_ITEM_PRICE = 'UPDATE_KIOSK_ITEM_PRICE',
  THROW_ERROR = 'THROW_ERROR',
}

export const kioskItemPricesActions = {
  fetchKioskItemPrices: (stationId: number) =>
    action(kioskItemPricesActionType.FETCH_KIOSK_ITEM_PRICES, { stationId }),
  fetchKioskItemPricesSucceeded: (
    hotKioskItemPrices: KioskItemPrice[],
    iceKioskItemPrices: KioskItemPrice[]
  ) =>
    action(kioskItemPricesActionType.FETCH_KIOSK_ITEM_PRICES_SUCCEEDED, {
      hotKioskItemPrices,
      iceKioskItemPrices,
    }),
  updateKioskItemPrice: (kioskItemPrice: KioskItemPrice, price: number) =>
    action(kioskItemPricesActionType.UPDATE_KIOSK_ITEM_PRICE, {
      kioskItemPrice,
      price,
    }),
  throwError: (error: Error) =>
    action(kioskItemPricesActionType.THROW_ERROR, { error }),
}
