export interface Item {
  name?: string
  id: number
  beanAmount?: number
  beanTypeIndex?: number
  machineButtonIndex?: number
  price?: number
  disposalSeconds?: number
  cupType?: ItemCupType
  index?: number
  imageURL?: string
  imageFileName?: string
  thumbnailImageURL?: string
  thumbnailImageFileName?: string
  description?: string
  story?: string
  category?: ItemCategory
  tastes?: Taste[]
  features?: Feature[]
  matchItemParam?: MatchItemParam
  color?: string
}

export type ItemCupType = ItemCupTypeHot | ItemCupTypeIce
type ItemCupTypeHot = 1
type ItemCupTypeIce = 2

export type ItemCategory = ItemCategoryHot | ItemCategoryIce | ItemCategoryHotLatte | ItemCategoryIceLatte | ItemCategoryHotMilk | ItemCategoryIceMilk
type ItemCategoryHot = "hot"
type ItemCategoryIce = "ice"
type ItemCategoryHotLatte = "hot_latte"
type ItemCategoryIceLatte = "ice_latte"
type ItemCategoryHotMilk = "hot_milk"
type ItemCategoryIceMilk = "ice_milk"

export interface Taste {
  name: string
  value: number
}

export interface Feature {
  name: string
  content?: string
}

export interface MatchItemParam {
  bitterness: number
  fragrance: number
  acidity: number
  sweetness: number
  richness: number
  uniqueness: number
}

export enum ItemImageType {
  Image = 'image',
  ThumbnailImage = 'thumbnail_image',
}

export interface ItemImage {
  fileName?: string
  url?: string
  itemImageType?: ItemImageType
}
