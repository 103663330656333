export const strings = {
  manufacturingInfo: 'コーヒー抽出設定',
  itemInfo: 'アイテム情報',
  save: '保存',
}

export const itemInfoTableContent = {
  name: '名前',
  price: '価格',
  disposalSeconds: 'ストック時間(秒)',
  category: 'カテゴリ',
  cupType: 'カップタイプ',
  description: 'ディスクリプション',
  story: 'コーヒーストーリー',
}

export const manufacturingInfoTableContent = {
  beanAmount: '豆投下量',
  beanTypeIndex: '豆レーン番号',
  machineButtonIndex: 'コーヒーマシンボタン番号',
}
