import React, { useEffect } from 'react'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'

import RootState from '../../core/root-state'
import Layout from '../layouts/layout'
import Wrapper from '../layouts/wrapper'
import { strings } from '../components/point-items/point-items-content'
import styles from '../styles/modules/point-items/point-items.module.scss'
import PointItemsTable from '../components/point-items/point-items-table'
import { PointItem } from '../../core/point-items/point-items'
import { pointItemsActions } from '../../core/point-items/actions'
import { modalActions } from '../../core/modal'

interface Props {
  pointItems: PointItem[]
  isLoading: boolean
  fetchPointItems: () => void
  showCreatePointItem: () => void
  updatePointItem: (
    id: number,
    imageFileName: string,
    name: string,
    isDeliveryRequired: boolean,
    pointsPrice: number,
    stock: number,
    isAvailable: boolean,
    isInfiniteStock: boolean
  ) => void
  sortPointItems: (ids: number[]) => void
  setPointItems: (pointItems: PointItem[]) => void
}

const PointItemsScreen: React.FC<Props> = ({
  pointItems,
  fetchPointItems,
  showCreatePointItem,
  updatePointItem,
  sortPointItems,
  setPointItems,
}) => {
  useEffect(() => {
    fetchPointItems()
  }, [fetchPointItems])

  const handleOnClickCreatePointItem = () => {
    showCreatePointItem()
  }

  return (
    <Layout location={'point_items'}>
      <Wrapper title={strings.title}>
        <div className={styles.container}>
          <PointItemsTable
            pointItems={pointItems}
            onClickCreatePointItem={handleOnClickCreatePointItem}
            updatePointItem={updatePointItem}
            setPointItems={setPointItems}
            sortPointItems={sortPointItems}
          />
        </div>
      </Wrapper>
    </Layout>
  )
}

const mapStateToProps = (state: RootState) => ({
  pointItems: state.pointItems.pointItems,
  isLoading: state.pointItems.isLoading,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchPointItems: () => dispatch(pointItemsActions.fetchPointItems()),
  showCreatePointItem: () => dispatch(modalActions.showCreatePointItem()),
  updatePointItem: (
    id: number,
    imageFileName: string,
    name: string,
    isDeliveryRequired: boolean,
    pointsPrice: number,
    stock: number,
    isAvailable: boolean,
    isInfiniteStock: boolean
  ) =>
    dispatch(
      pointItemsActions.updatePointItem(
        id,
        imageFileName,
        name,
        isDeliveryRequired,
        pointsPrice,
        stock,
        isAvailable,
        isInfiniteStock
      )
    ),
  sortPointItems: (ids: number[]) =>
    dispatch(pointItemsActions.sortPointItems(ids)),
  setPointItems: (pointItems: PointItem[]) =>
    dispatch(pointItemsActions.setPointItems(pointItems)),
})

export default connect(mapStateToProps, mapDispatchToProps)(PointItemsScreen)
