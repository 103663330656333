import React, { useEffect } from 'react'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'

import { itemsActions } from '../../core/items'
import RootState from '../../core/root-state'
import Layout from '../layouts/layout'
import Wrapper from '../layouts/wrapper'
import { Item } from '../../core/items'
import { strings } from '../components/items/items-content'
import styles from '../styles/modules/items/items.module.scss'
import ItemTable from '../components/items/item-table'

interface Props {
  hotItems: Item[]
  iceItems: Item[]
  isLoading: boolean
  fetchItems: () => void
  fetchItemsEvery: () => void
  fetchItemsEveryFinished: () => void
  changeItemsIndex: (ids: number[]) => void
  setHotItems: (hotItems: Item[]) => void
  setIceItems: (iceItems: Item[]) => void
}

const ItemsScreen: React.FC<Props> = ({
  hotItems,
  iceItems,
  fetchItems,
  fetchItemsEvery,
  fetchItemsEveryFinished,
  changeItemsIndex,
  setHotItems,
  setIceItems,
}) => {
  useEffect(() => {
    fetchItems()
  }, [fetchItems])

  useEffect(() => {
    fetchItemsEvery()
    return () => {
      fetchItemsEveryFinished()
    }
  }, [fetchItemsEvery, fetchItemsEveryFinished])

  return (
    <Layout location={'items'}>
      <Wrapper title={strings.title}>
        <div className={styles.container}>
          <h3 className={styles.title}>{strings.hot}</h3>
          <ItemTable
            items={hotItems}
            changeItemsIndex={changeItemsIndex}
            setItems={setHotItems}
          />
        </div>
        <div className={styles.container}>
          <h3 className={styles.title}>{strings.ice}</h3>
          <ItemTable
            items={iceItems}
            changeItemsIndex={changeItemsIndex}
            setItems={setIceItems}
          />
        </div>
      </Wrapper>
    </Layout>
  )
}

const mapStateToProps = (state: RootState) => ({
  hotItems: state.items.hotItems,
  iceItems: state.items.iceItems,
  isLoading: state.items.isLoading,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchItems: () => dispatch(itemsActions.fetchItems()),
  fetchItemsEvery: () => dispatch(itemsActions.fetchItemsEvery()),
  fetchItemsEveryFinished: () =>
    dispatch(itemsActions.fetchItemsEveryFinished()),
  changeItemsIndex: (ids: number[]) =>
    dispatch(itemsActions.changeItemsIndex(ids)),
  setHotItems: (hotItems: Item[]) => dispatch(itemsActions.setHotItems(hotItems)),
  setIceItems: (iceItems: Item[]) => dispatch(itemsActions.setIceItems(iceItems)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ItemsScreen)
