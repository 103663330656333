import React from 'react'
import moment from 'moment'

import { PointTransaction, TransactionType } from '../../../core/points'
import styles from '../../styles/modules/table.module.scss'

interface Props {
  pointTransaction: PointTransaction
}

const PointsTableItem: React.FC<Props> = (props: Props) => {
  const { pointTransaction } = props

  const addPlusOrMinusSignToPoints = (
    type: TransactionType,
    point: number
  ): string => {
    if (
      type === TransactionType.UsedByOrder ||
      type === TransactionType.Expired ||
      type === TransactionType.UserDeleted ||
      type === TransactionType.ExpiredByAdmin
    ) {
      return `${point}`
    } else {
      return `+${point}`
    }
  }

  return (
    <tr className={styles.rowStatic} key={pointTransaction.id}>
      <td className={styles.cell}>
        {addPlusOrMinusSignToPoints(
          pointTransaction.type,
          pointTransaction.point
        )}
        P
      </td>
      <td className={styles.cell}>{pointTransaction.description}</td>
      <td className={styles.cell}>
        {moment(pointTransaction.occurredAt).format('YYYY/MM/DD HH:mm:ss')}
      </td>
    </tr>
  )
}

export default PointsTableItem
