import React from 'react'
import classnames from 'classnames'

import Button from '../button'
import OnOff from './on-off'
import IdTitle from './id-title'
import DetailButton from './detail-button'
import { Station } from '../../../core/stations'
import {
  powers,
  Power,
  machineStatuses,
  MachineStatus as MachineStatusType,
  Machine,
} from '../../../core/machines'
import { strings } from './stations-content'
import styles from '../../styles/modules/stations/station-item.module.scss'
import MachineStatus from './machine-status'
import { isOverV3Version } from '../../../core/machines'

interface Props {
  station: Station
  powerIsLoading: boolean
  onClickPower: () => void
  changeStatusIsLoading: boolean
  onClickChangeStatus: () => void
}

const StationItem: React.FC<Props> = props => {
  const machine = props.station.machine

  const switchPowerLabel = (status: Power) => {
    switch (status) {
      case powers.Off:
        return strings.runButton.off
      case powers.On:
        return strings.runButton.on
      case powers.ShouldBeOff:
        return strings.runButton.off
      case powers.ShouldBeOn:
        return strings.runButton.on
      default:
        return ''
    }
  }

  const switchStatusLabel = (status: MachineStatusType) => {
    switch (status) {
      case machineStatuses.StationStop:
        return strings.changeStatusButton.start
      default:
        return strings.changeStatusButton.stop
    }
  }

  const switchAlertedEmergencyLabel = (station: Station) => {
    return station.alertedEmergencies.length > 0
      ? strings.emergencyStatus.occur
      : ''
  }

  const hasOrderLimitation = (machine: Machine) => {
    return (
      machine.unavailableBeanTypeIndexes.length > 0 ||
      machine.isHotCupShortage ||
      machine.isIceCupShortage ||
      machine.isIceShortage
    )
  }

  const switchOrderLimitationLabel = (station: Station) => {
    const machine = station.machine
    return machine && hasOrderLimitation(machine)
      ? strings.orderLimitation.occur
      : ''
  }

  return (
    <div
      className={classnames({
        [styles.container]: true,
        [styles.grayContainer]: !machine,
      })}
    >
      <div className={styles.power}>
        {!machine ? (
          // マシンが存在しない場合は何も表示しない
          <></>
        ) : isOverV3Version(machine.version) ? (
          <>
            <MachineStatus status={machine.status} />
            <Button
              title={switchStatusLabel(props.station.machine.status)}
              onClick={() => props.onClickChangeStatus()}
              isLoading={props.changeStatusIsLoading}
              isGray={true}
              className={styles.run}
              loadingClassName={styles.runLoading}
            />
          </>
        ) : (
          // マシンのバージョンがv2以下の場合はpowerの情報を表示する
          <>
            <OnOff status={machine.power} />
            <Button
              title={switchPowerLabel(props.station.machine.power)}
              onClick={() => props.onClickPower()}
              isLoading={props.changeStatusIsLoading}
              isGray={true}
              className={styles.run}
              loadingClassName={styles.runLoading}
            />
          </>
        )}
      </div>
      <div className={styles.emergencyContainer}>
        <div className={styles.emergencyLabel}>
          <span>{switchAlertedEmergencyLabel(props.station)}</span>
        </div>
        <div className={styles.emergencyLabel}>
          <span>{switchOrderLimitationLabel(props.station)}</span>
        </div>
      </div>
      <IdTitle id={props.station.id} name={props.station.name} />
      <DetailButton stationId={props.station.id} />
    </div>
  )
}

export default StationItem
