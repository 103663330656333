import React from 'react'
import classnames from 'classnames'
import moment from 'moment'

import Button from '../button'
import { Station, Emergency } from '../../../core/stations'
import { emergenciesTableContent, strings } from './each-station-content'
import styles from '../../styles/modules/table.module.scss'
import emergenciesTableStyles from '../../styles/modules/each-station/emergencies-table.module.scss'

interface Props {
  station: Station
  onClickItem: (item: Emergency) => void
}

const EmergenciesTable: React.FC<Props> = props => (
  <div
    className={classnames({
      [styles.wrapper]: true,
      [styles.zebra]: true,
      [styles.stocker]: true
    })}
  >
    <table className={styles.container}>
      <tbody>
        <tr className={styles.row}>
          {emergenciesTableContent.map(item => (
            <th key={item} className={styles.cell}>
              {item}
            </th>
          ))}
        </tr>
        {props.station.alertedEmergencies.map(item => (
          <tr className={styles.row} key={item.id}>
            <td className={styles.cell}>{item.id}</td>
            <td className={styles.cell}>{item.group}</td>
            <td className={styles.cell}>{item.message}</td>
            <td className={styles.cell}>
              {moment(item.reportedAt).format('YYYY/MM/DD/ HH:mm:ss')}
            </td>
            <td className={styles.cell}>{
              item.canOpenLockerBox
              ? strings.availableOpenLockerBox
              : strings.unavailableOpenLockerBox
              }
            </td>
            <td className={styles.cell}>
              <Button
                title={strings.resolveEmergencyButton}
                onClick={() => props.onClickItem(item)}
                className={emergenciesTableStyles.container}
              />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
)

export default EmergenciesTable
