import React, { useEffect } from 'react'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'

import RootState from '../../core/root-state'
import { Station, stationsActions } from '../../core/stations'
import { SoracomSIM, soracomSIMsActions } from '../../core/soracom-sims'
import {
  Machine,
  MachineRelationHistory,
  machinesActions,
  MachineRelationHistoriesParam,
} from '../../core/machines'
import { modalActions } from '../../core/modal'
import Layout from '../layouts/layout'
import Wrapper from '../layouts/wrapper'
import Button from '../components/button'
import { strings } from '../components/machine-relations/machine-relations-content'
import styles from '../styles/modules/machine-relations/machine-relations.module.scss'
import MachineRelationsTable from '../components/machine-relations/machine-relations-table'
import MachineRelationHistoriesTable from '../components/machine-relations/machine-relation-histories-table'
import QueryMachine from '../components/machine-relations/query-machine'
import QuerySim from '../components/machine-relations/query-sim'
import QueryStation from '../components/machine-relations/query-station'

interface Props {
  stations: Station[]
  isLoadingStation: boolean
  fetchStations: () => void
  soracomSIMs: SoracomSIM[]
  isLoadingSoracomSIM: boolean
  fetchSoracomSIMs: () => void
  machines: Machine[]
  isLoadingMachine: boolean
  fetchMachines: () => void
  fetchMachineRelationHistories: (param: MachineRelationHistoriesParam) => void
  machineRelationHistories: MachineRelationHistory[]
  isLoadingMachineRelationHistories: boolean
  showEditMachineRelation: (
    stations: Station[],
    soracomSIMs: SoracomSIM[],
    machines: Machine[],
    machine: Machine
  ) => void
  clearMachineRelation: (machineID: number) => void
}

const initialParam: MachineRelationHistoriesParam = {
  stationID: 0,
  machineID: 0,
  soracomSIMID: 0,
}

const MachineRelationsScreen: React.FC<Props> = props => {
  const {
    fetchStations,
    fetchSoracomSIMs,
    fetchMachines,
    fetchMachineRelationHistories,
  } = props
  const [stationID, setStationID] = React.useState<number>(0)
  const [machineID, setMachineID] = React.useState<number>(0)
  const [soracomSIMID, setSoracomSIMID] = React.useState<number>(0)

  useEffect(() => {
    fetchStations()
    fetchSoracomSIMs()
    fetchMachines()
  }, [fetchStations, fetchSoracomSIMs, fetchMachines])

  useEffect(() => {
    const param: MachineRelationHistoriesParam = { ...initialParam }
    fetchMachineRelationHistories(param)
  }, [fetchMachineRelationHistories])

  const handleOnClickQuery = () => {
    const param: MachineRelationHistoriesParam = {
      stationID,
      machineID,
      soracomSIMID,
    }
    fetchMachineRelationHistories(param)
  }

  return (
    <Layout location="machine_relations">
      <Wrapper title={strings.title}>
        <div className={styles.container}>
          <MachineRelationsTable
            stations={props.stations}
            soracomSIMs={props.soracomSIMs}
            machines={props.machines}
            showEditMachineRelation={props.showEditMachineRelation}
            clearMachineRelation={props.clearMachineRelation}
          />
        </div>
      </Wrapper>
      <Wrapper title={strings.machineRelationHistoryTitle}>
        <div className={styles.queryContainer}>
          <QueryMachine
            options={props.machines}
            value={machineID}
            onChange={event => setMachineID(parseInt(event.target.value, 10))}
          />
          <QueryStation
            options={props.stations}
            value={stationID}
            onChange={event => setStationID(parseInt(event.target.value, 10))}
          />
          <QuerySim
            options={props.soracomSIMs}
            value={soracomSIMID}
            onChange={event =>
              setSoracomSIMID(parseInt(event.target.value, 10))
            }
          />
          <Button
            title={strings.queryButton}
            onClick={() => handleOnClickQuery()}
            className={styles.queryButton}
          />
        </div>
        <div className={styles.container}>
          <MachineRelationHistoriesTable
            stations={props.stations}
            soracomSIMs={props.soracomSIMs}
            machines={props.machines}
            machineRelationHistories={props.machineRelationHistories}
          />
        </div>
      </Wrapper>
    </Layout>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    stations: state.stations.stations,
    isLoadingStation: state.stations.isLoading,
    soracomSIMs: state.soracomSIMs.soracomSIMs,
    isLoadingSoracomSIM: state.soracomSIMs.isLoading,
    machines: state.machines.machines,
    isLoadingMachine: state.machines.isLoading,
    machineRelationHistories: state.machines.machineRelationHistories,
    isLoadingMachineRelationHistories: state.machines.isLoading,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchStations: () => dispatch(stationsActions.fetchStations()),
  fetchSoracomSIMs: () => dispatch(soracomSIMsActions.fetchSoracomSIMs()),
  fetchMachines: () => dispatch(machinesActions.fetchMachines()),
  fetchMachineRelationHistories: (param: MachineRelationHistoriesParam) =>
    dispatch(machinesActions.fetchMachineRelationHistories(param)),
  showEditMachineRelation: (
    stations: Station[],
    soracomSIMs: SoracomSIM[],
    machines: Machine[],
    machine: Machine
  ) =>
    dispatch(
      modalActions.showEditMachineRelation(
        stations,
        soracomSIMs,
        machines,
        machine
      )
    ),
  clearMachineRelation: (machineID: number) =>
    dispatch(machinesActions.clearMachineRelation(machineID)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MachineRelationsScreen)
