import React from 'react'
import moment from 'moment'
import classnames from 'classnames'
import { Link } from 'react-router-dom'
import { Station } from '../../../core/stations'
import { upcomingOrdersTableContent } from './each-station-content'
import styles from '../../styles/modules/table.module.scss'

interface Props {
  station: Station
}

const upcomingOrdersTable: React.FC<Props> = props => (
  <>
    <div
      className={classnames({
        [styles.wrapper]: true,
        [styles.zebra]: true,
        [styles.stocker]: true,
      })}
    >
      <table className={styles.container}>
        <tbody>
          <tr className={styles.row}>
            {upcomingOrdersTableContent.map(order => (
              <th key={order} className={styles.cell}>
                {order}
              </th>
            ))}
          </tr>
          {props.station.orders &&
            props.station.orders.map(order => (
              <tr className={styles.row} key={order.id}>
                <td className={styles.cell}>
                  {moment(new Date(order.orderedAt)).format(
                    'YYYY/MM/DD/ HH:mm:ss'
                  )}
                </td>
                <td className={styles.cell}>{order.id}</td>
                <td className={styles.cell}>{order.hashid}</td>
                <td className={styles.cell}>
                  <Link
                    to={{
                      pathname: `/users/${order.userID}`,
                    }}
                  >
                    {order.userID}
                  </Link>
                </td>
                <td className={styles.cell}>{order.kioskID}</td>
                <td className={styles.cell}>
                  {moment(order.timeSlotFrom).format('HH:mm')}
                </td>
                <td className={styles.cell}>{order.item.name}</td>
                <td className={styles.cell}>{order.paidAmount}</td>
                <td className={styles.cell}>{order.pointDiscountAmount}</td>
                <td className={styles.cell}>{order.campaignDiscountAmount}</td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  </>
)

export default upcomingOrdersTable
