import React from 'react'

import { MachineEmbeddedComputer } from '../../../core/machine-embedded-computers'
import MachineEmbeddedComputersTable from './machine-embedded-computers-table'

interface Props {
  machineEmbeddedComputers: MachineEmbeddedComputer[]
  deleteMachineEmbeddedComputer: (machineEmbeddedComputerId: number) => void
}

const MachineEmbeddedComputersIndex: React.FC<Props> = (props: Props) => {

  return (
    <>
      <MachineEmbeddedComputersTable
        machineEmbeddedComputers={props.machineEmbeddedComputers}
        deleteMachineEmbeddedComputer={props.deleteMachineEmbeddedComputer}
      />
    </>
  )
}

export default MachineEmbeddedComputersIndex
