import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import classnames from 'classnames'

import { SidebarContent } from './sidebar-content'
import arrowRightIcon from "../../assets/images/icon-toggle-arrow-right.png"
import arrowDownIcon from "../../assets/images/icon-toggle-arrow-down.png"
import styles from '../../styles/modules/sidebar/sidebar-item.module.scss'

interface Props {
  content: SidebarContent
  selected: string
  isSubMenu: boolean
}

const SidebarItem: React.FC<Props> = props => {
  if (props.content.subMenu) {
    return <HasSubMenuItem content={props.content} selected={props.selected} isSubMenu={props.isSubMenu} />
  } else {
    return <MenuItem content={props.content} selected={props.selected} isSubMenu={props.isSubMenu} />
  }
}

const HasSubMenuItem: React.FC<Props> = props => {
  const [subnav, setSubnav] = useState(props.content.subMenu.some((item) => item.id === props.selected));
  const showSubnav = () => setSubnav(!subnav);

  return (
    <div>
      <div
        className={styles.menuItemContainer}
        onClick={showSubnav}
      >
        <span
          className={`${styles.icon} ${styles.subMenuIcon}`}
          style={{ backgroundImage: `url(${subnav ? arrowDownIcon : arrowRightIcon})` }}
        />
        <span className={styles.label}>{props.content.label}</span>
      </div>
      {subnav &&
        props.content.subMenu.map(item => (
          <SidebarItem
            key={item.id}
            content={item}
            selected={props.selected}
            isSubMenu={true}
          />
        ))
      }
    </div>
  )
}

const MenuItem: React.FC<Props> = props => (
  <Link
    to={props.content.url}
    className={classnames({
      [styles.menuItemContainer]: true,
      [styles.subMenuItemContainer]: props.isSubMenu,
      [styles.selected]: props.content.id === props.selected,
    })}
  >
    <span
      className={styles.icon}
      style={{ backgroundImage: `url(${props.content.icon})` }}
    />
    <span className={styles.label}>{props.content.label}</span>
  </Link>
)

export default SidebarItem
