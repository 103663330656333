import React from 'react'
import classnames from 'classnames'

import {
  stampCampaignsTableContent,
  getDurationToDisplay,
  strings,
} from './auto-entry-campaign-content'
import { Campaign } from '../../../core/campaigns'
import styles from '../../styles/modules/table.module.scss'
import Button from '../button'

interface Props {
  stampCampaigns: Campaign[]
  onClickSetStampCampaign: (stampCampaign: Campaign) => void
}

const StampCampaignsTable: React.FC<Props> = props => (
  <div
    className={classnames({
      [styles.wrapper]: true,
      [styles.zebra]: true,
      [styles.stocker]: true,
    })}
  >
    <table className={classnames(styles.container)}>
      <tbody>
        <tr className={styles.row}>
          {stampCampaignsTableContent.map(item => (
            <th key={item} className={styles.cell}>
              {item}
            </th>
          ))}
        </tr>
        {props.stampCampaigns.map(item => (
          <tr className={styles.row} key={item.id}>
            <td className={styles.cell}>{item.id}</td>
            <td className={styles.cell}>{item.code}</td>
            <td className={styles.cell}>{item.name}</td>
            <td className={styles.cell}>{item.discountAmount}</td>
            <td className={styles.cell}>{item.availableCount}</td>
            <td className={styles.cell}>
              {getDurationToDisplay(item.duration, item.durationUnit)}
            </td>
            <td className={styles.buttonCell}>
              <Button
                title={strings.setAutoEntryCampaignButton}
                onClick={() => {
                  props.onClickSetStampCampaign(item)
                }}
              />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
)

export default StampCampaignsTable
