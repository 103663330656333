import React from 'react'
import classnames from 'classnames'

import styles from '../styles/modules/select.module.scss'

interface SelectOption {
  value: string
  label: string
}

interface Props extends React.InputHTMLAttributes<HTMLSelectElement> {
  className?: string
  options: SelectOption[]
  label: string
}

export default class Select extends React.PureComponent<Props, {}> {
  render() {
    const { className, options, label, ...rest} = this.props
    return (
      <div className={classnames({ [styles.form]: true, [className]: true })}>
        <label className={styles.label}>{label}</label>
        <select {...rest} className={styles.select}>
          {this.props.options.map(item => 
            <option key={item.value} value={item.value}>{item.label}</option>
          )}
        </select>
      </div>
    )
  }
}