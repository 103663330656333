import { put, call, takeLatest, fork, select, delay } from 'redux-saga/effects'
import RootState from '../root-state'

import { machinesActionType, machinesActions } from './actions'
import { stationsActions } from '../stations/actions'
import { soracomSIMsActions } from '../soracom-sims/actions'
import {
  getMachines,
  postMachine,
  putMachine,
  putMachineRelation,
  deleteMachineRelation,
  getMachineRelationHistories,
} from '../api'

import { toastActions } from '../toast'
import { modalActions } from '../modal'

export function* fetchMachines(
  action: ReturnType<typeof machinesActions.fetchMachines>
) {
  try {
    const state: RootState = yield select()
    const token = state.auth0.auth.idToken
    const response = yield call(getMachines, token)
    yield put(machinesActions.fetchMachinesSucceeded(response.machines))
  } catch (error) {
    yield put(machinesActions.throwError(error))
  }
}

export function* createMachine(
  action: ReturnType<typeof machinesActions.createMachine>
) {
  try {
    const state: RootState = yield select()
    const token = state.auth0.auth.idToken
    yield call(
      postMachine,
      token,
      action.payload.serial,
      action.payload.manufacturedDate,
      action.payload.type,
      action.payload.version,
      action.payload.factoryName
    )
    yield delay(100)
    yield put(machinesActions.fetchMachines())
    yield put(
      toastActions.show({
        title: '成功！',
        body: 'マシンを新規作成しました',
      })
    )
    yield put(modalActions.hide())
  } catch (error) {
    yield put(machinesActions.throwError(error))
    yield put(
      toastActions.show({
        type: 'error',
        title: '失敗...',
        body: 'マシンの新規作成に失敗しました',
      })
    )
  }
}

export function* updateMachine(
  action: ReturnType<typeof machinesActions.updateMachine>
) {
  try {
    const state: RootState = yield select()
    const token = state.auth0.auth.idToken
    yield call(
      putMachine,
      token,
      action.payload.machineId,
      action.payload.serial,
      action.payload.manufacturedDate,
      action.payload.type,
      action.payload.version,
      action.payload.factoryName,
      action.payload.shouldBeLatest
    )
    yield delay(100)
    yield put(machinesActions.fetchMachines())
    yield put(
      toastActions.show({
        title: '成功！',
        body: 'machineを更新しました',
      })
    )
    yield put(modalActions.hide())
  } catch (error) {
    yield put(machinesActions.throwError(error))
    yield put(
      toastActions.show({
        type: 'error',
        title: '失敗...',
        body: 'machineの更新に失敗しました',
      })
    )
  }
}

export function* fetchMachineRelationHistories(
  action: ReturnType<typeof machinesActions.fetchMachineRelationHistories>
) {
  try {
    const state: RootState = yield select()
    const token = state.auth0.auth.idToken
    const response = yield call(
      getMachineRelationHistories,
      token,
      action.payload.param
    )
    yield put(
      machinesActions.fetchMachineRelationHistoriesSucceeded(
        response.machineRelationHistories
      )
    )
  } catch (error) {
    yield put(machinesActions.throwError(error))
  }
}

export function* updateMachineRelation(
  action: ReturnType<typeof machinesActions.updateMachineRelation>
) {
  try {
    const state: RootState = yield select()
    const token = state.auth0.auth.idToken
    yield call(
      putMachineRelation,
      token,
      action.payload.machineId,
      action.payload.stationId,
      action.payload.soracomSIMId
    )
    yield delay(100)
    yield put(machinesActions.fetchMachines())
    yield put(stationsActions.fetchStations())
    yield put(soracomSIMsActions.fetchSoracomSIMs())
    yield put(
      toastActions.show({
        title: '成功！',
        body: '紐付けを更新しました',
      })
    )
    yield put(modalActions.hide())
  } catch (error) {
    yield put(machinesActions.throwError(error))
    yield put(
      toastActions.show({
        type: 'error',
        title: '失敗...',
        body: '紐付けの更新に失敗しました',
      })
    )
  }
}

export function* clearMachineRelation(
  action: ReturnType<typeof machinesActions.clearMachineRelation>
) {
  try {
    const state: RootState = yield select()
    const token = state.auth0.auth.idToken

    yield call(deleteMachineRelation, token, action.payload.machineId)
    yield delay(100)
    yield put(machinesActions.fetchMachines())
    yield put(stationsActions.fetchStations())
    yield put(soracomSIMsActions.fetchSoracomSIMs())
    yield put(
      toastActions.show({
        title: '成功！',
        body: '紐付けを解除しました',
      })
    )
  } catch (error) {
    yield put(machinesActions.throwError(error))
    yield put(
      toastActions.show({
        type: 'error',
        title: '失敗...',
        body: '紐付けの解除に失敗しました',
      })
    )
  }
}

export function* watchFetchMachines() {
  yield takeLatest(machinesActionType.FETCH_MACHINES, fetchMachines)
}

export function* watchCreateMachine() {
  yield takeLatest(machinesActionType.CREATE_MACHINE, createMachine)
}

export function* watchUpdateMachine() {
  yield takeLatest(machinesActionType.UPDATE_MACHINE, updateMachine)
}

export function* watchFetchMachineRelationHistories() {
  yield takeLatest(
    machinesActionType.FETCH_MACHINE_RELATION_HISTRIES,
    fetchMachineRelationHistories
  )
}

export function* watchUpdateMachineRelation() {
  yield takeLatest(
    machinesActionType.UPDATE_MACHINE_RELATION,
    updateMachineRelation
  )
}

export function* watchClearMachineRelation() {
  yield takeLatest(
    machinesActionType.CLEAR_MACHINE_RELATION,
    clearMachineRelation
  )
}

export const machinesSagas = [
  fork(watchFetchMachines),
  fork(watchCreateMachine),
  fork(watchUpdateMachine),
  fork(watchFetchMachineRelationHistories),
  fork(watchUpdateMachineRelation),
  fork(watchClearMachineRelation),
]
