import { Station } from '../stations'
export interface Campaign {
  id: number
  code: string
  name: string
  type: CampaignType
  ownerStation?: Station
  discountAmount: number
  availableCount: number
  entryCount: number
  entryCountLimit: number
  status: CampaignStatus
  memo: string
  issuerEmail: string
  issuedAt?: string
  expiresAt?: string
  duration?: number
  durationUnit: CampaignDurationUnit
  isAllStationsEligible: boolean
  eligibleStations: Station[]
}

export enum CampaignStatus {
  Draft = 'draft',
  Live = 'live',
  Closed = 'closed',
}

export enum CampaignType {
  Promotion = 'promotion',
  Invitation = 'invitation',
  Starter = 'starter',
  Stamp = 'stamp_card_completed',
  Order = 'order',
}

export enum CampaignDurationUnit {
  Day = 'day',
  Week = 'week',
  Month = 'month',
  NA = 'NA',
}

export interface CampaignParam {
  issuedFrom?: string
  issuedTo?: string
  expiresFrom?: string
  expiresTo?: string
  status?: CampaignStatus
  types?: CampaignType[]
}
