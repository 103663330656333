import React from 'react'
import classnames from 'classnames'

import SidebarHeader from './sidebar-header'
import SidebarItem from './sidebar-item'
import { sidebarContent, sidebarHeaderContent } from './sidebar-content'
import { roleType } from '../../../core/auth0'
import styles from '../../styles/modules/sidebar/sidebar.module.scss'

interface Props {
  selected: string
  isOpen: boolean
  role: roleType
}

const Sidebar: React.FC<Props> = props => (
  <aside
    className={classnames({
      [styles.container]: true,
      [styles.open]: props.isOpen,
    })}
  >
    <SidebarHeader content={sidebarHeaderContent} />
    <nav className={styles.navigation}>
      {sidebarContent.map(item =>
        props.role !== roleType.ADMIN &&
        (item.id === 'sales_data' ||
          item.id === 'orders' ||
          item.id === 'users' ||
          item.id === 'subscription' ||
          item.id === 'items' ||
          item.id === 'promotion_campaigns' ||
          item.id === 'auto_entry_campaigns' ||
          item.id === 'promotion_images' ||
          item.id === 'points' ||
          item.id === 'station_alerts' ||
          item.id === 'signage' ||
          item.id === 'camera' ||
          item.id === 'vnc_link') ? null : (
          <SidebarItem
            key={item.id}
            content={item}
            selected={props.selected}
            isSubMenu={false}
          />
        )
      )}
    </nav>
  </aside>
)

export default Sidebar
