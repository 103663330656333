export const strings = {
  title: '売上データ',
}

export const monthlySalesDataContent = {
  title: '月次売上データ',
  height: '536px', // iframeのスクロールバーが出ないようにするために高さを指定する
}

export const dailySalesDataContent = {
  title: '日次売上データ',
  height: '1072px', // iframeのスクロールバーが出ないようにするために高さを指定する
}
