import stationIcon from '../../assets/images/icon-station.png'
import machinesIcon from '../../assets/images/icon-machines.png'
import simCardIcon from '../../assets/images/icon-sim-card.png'
import sdCardIcon from '../../assets/images/icon-sd-card.png'
import relationIcon from '../../assets/images/icon-relation.png'
import salesDataIcon from '../../assets/images/icon-sales-data.png'
import ordersIcon from '../../assets/images/icon-orders.png'
import itemsIcon from '../../assets/images/icon-items.png'
import plusIcon from '../../assets/images/icon-plus.png'
import rankingIcon from '../../assets/images/icon-ranking.png'
import promotionCampaignsIcon from '../../assets/images/icon-promotion-campaigns.png'
import autoEntryCampaignsIcon from '../../assets/images/icon-auto-entry-campaigns.png'
import usersIcon from '../../assets/images/icon-users.png'
import promotionImagesIcon from '../../assets/images/promotion-images.png'
import emergenciesIcon from '../../assets/images/icon-emergencies.png'
import machineErrorIcon from '../../assets/images/icon-machine-errors.png'
import pointsIcon from '../../assets/images/icon-points.png'
import stationAlertIcon from '../../assets/images/icon-station-alert.png'
import subscriptionIcon from '../../assets/images/icon-subscription.png'
import signageBannerIcon from '../../assets/images/icon-signage-banner.png'
import streamingIcon from '../../assets/images/icon-streaming.png'
import screenShareIcon from '../../assets/images/icon-screen-share.png'
import kioskIcon from '../../assets/images/icon-kiosk.png'
import kioskItemPriceIcon from '../../assets/images/icon-kiosk-item-price.png'
import deliveryIcon from '../../assets/images/icon-delivery.png'

export interface SidebarContent {
  id: string
  label: string
  url: string
  icon: string
  subMenu?: SidebarContent[]
}

export interface SidebarHeaderContent {
  company: string
  service: string
}

export const sidebarContent: SidebarContent[] = [
  {
    id: 'sales_data',
    label: '売上データ',
    url: '/sales_data',
    icon: salesDataIcon,
  },
  {
    id: 'station',
    label: 'デバイス',
    url: '',
    icon: '',
    subMenu: [
      {
        id: 'stations',
        label: 'ステーション',
        url: '/stations',
        icon: stationIcon,
      },
      {
        id: 'machines',
        label: 'マシン',
        url: '/machines',
        icon: machinesIcon,
      },
      {
        id: 'kiosks',
        label: 'KIOSK',
        url: '/kiosks',
        icon: kioskIcon,
      },
      {
        id: 'soracom_sims',
        label: 'SIM',
        url: '/soracom_sims',
        icon: simCardIcon,
      },
      {
        id: 'machine_embedded_computers',
        label: 'SDカード',
        url: '/machine_embedded_computers',
        icon: sdCardIcon,
      },
      {
        id: 'machine_relations',
        label: 'マシン紐付け',
        url: '/machine_relations',
        icon: relationIcon,
      },
      {
        id: 'kiosk_relations',
        label: 'KIOSK紐付け',
        url: '/kiosk_relations',
        icon: relationIcon,
      },
    ],
  },
  {
    id: 'camera',
    label: 'リアルタイム配信',
    url: '/streaming_viewer',
    icon: streamingIcon,
  },
  {
    id: 'signage_banner',
    label: 'サイネージバナー',
    url: '/signage_banner',
    icon: signageBannerIcon,
  },
  {
    id: 'orders',
    label: 'オーダー',
    url: '/orders',
    icon: ordersIcon,
  },
  {
    id: 'users',
    label: 'ユーザー',
    url: '/users',
    icon: usersIcon,
  },
  {
    id: 'points',
    label: 'ポイント',
    url: '/points',
    icon: pointsIcon,
  },
  {
    id: 'campaigns',
    label: 'クーポン',
    url: '',
    icon: '',
    subMenu: [
      {
        id: 'promotion_campaigns',
        label: '手動発行クーポン',
        url: '/promotion_campaigns',
        icon: promotionCampaignsIcon,
      },
      {
        id: 'auto_entry_campaigns',
        label: '自動発行クーポン',
        url: '/auto_entry_campaigns',
        icon: autoEntryCampaignsIcon,
      },
    ],
  },
  {
    id: 'item',
    label: 'アイテム',
    url: '',
    icon: '',
    subMenu: [
      {
        id: 'items',
        label: 'アイテム',
        url: '/items',
        icon: itemsIcon,
      },
      {
        id: 'new_item',
        label: 'アイテム追加',
        url: '/items/new',
        icon: plusIcon,
      },
      {
        id: 'station_with_item_ranks',
        label: 'ランキング',
        url: '/station_with_item_ranks',
        icon: rankingIcon,
      },
      {
        id: 'kiosk_item_prices',
        label: 'KIOSK価格',
        url: '/kiosk_item_prices',
        icon: kioskItemPriceIcon,
      },
    ],
  },
  {
    id: 'point_exchange',
    label: 'ポイント交換',
    url: '',
    icon: '',
    subMenu: [
      {
        id: 'point_items',
        label: 'グッズ',
        url: '/point_items',
        icon: itemsIcon,
      },
      {
        id: 'point_item_support_logs',
        label: 'グッズ発送管理',
        url: '/point_item_support_logs',
        icon: deliveryIcon,
      },
      {
        id: 'point_item_orders',
        label: 'グッズ受注管理',
        url: '/point_item_orders',
        icon: deliveryIcon,
      },
    ],
  },
  {
    id: 'promotion_images',
    label: 'アプリバナー',
    url: '/promotion_images',
    icon: promotionImagesIcon,
  },
  {
    id: 'station_alerts',
    label: 'アプリお知らせ',
    url: '/station_alerts',
    icon: stationAlertIcon,
  },
  {
    id: 'emergencies',
    label: 'エマージェンシー履歴',
    url: '/emergencies',
    icon: emergenciesIcon,
  },
  {
    id: 'machineErrors',
    label: 'マシンエラー履歴',
    url: '/machine_errors',
    icon: machineErrorIcon,
  },
  {
    id: 'subscriptions',
    label: 'サブスクリプション',
    url: '/subscriptions',
    icon: subscriptionIcon,
  },
  {
    id: 'vnc_link',
    label: 'VNC接続',
    url: '/vnc_link',
    icon: screenShareIcon,
  },
]

export const sidebarHeaderContent: SidebarHeaderContent = {
  company: 'root C Dashboard',
  service: 'New Innovations',
}
