import { action } from 'typesafe-actions'
import { TimeSlot } from '../stations/stations'
import { TimeSlotPreset } from './time-slot-presets'

export enum timeSlotPresetsActionType {
  FETCH_TIME_SLOT_PRESETS = 'FETCH_TIME_SLOT_PRESETS',
  FETCH_TIME_SLOT_PRESETS_SUCCEEDED = 'FETCH_TIME_SLOT_PRESETS_SUCCEEDED',
  UPDATE_TIME_SLOT_PRESETS = 'UPDATE_TIME_SLOT_PRESETS',
  THROW_ERROR = 'THROW_ERROR',
}

export const timeSlotPresetsActions = {
  fetchTimeSlotPresets: (stationId: number) => action(timeSlotPresetsActionType.FETCH_TIME_SLOT_PRESETS, { stationId }),
  fetchTimeSlotPresetsSucceeded: (timeSlots: TimeSlot[], timeSlotPresets: TimeSlotPreset[]) =>
    action(timeSlotPresetsActionType.FETCH_TIME_SLOT_PRESETS_SUCCEEDED, { timeSlots, timeSlotPresets }),
  updateTimeSlotPresets: (stationId: number, timeSlotPresets: TimeSlotPreset[]) =>
    action(timeSlotPresetsActionType.UPDATE_TIME_SLOT_PRESETS, { stationId, timeSlotPresets }),
  throwError: (error: Error) =>
    action(timeSlotPresetsActionType.THROW_ERROR, { error }),
}
