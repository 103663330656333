import React, { useState } from 'react'
import styles from '../../styles/modules/modal/modal.module.scss'
import Button from '../button'
import { strings } from './modal-content'
import { Config } from '../../../core/toast'



interface Props {
  isLoading: boolean
  stationId: number
  lockerBoxId: number
  lockerBoxIndex: number
  createLockerBoxComment: (stationId: number, lockerBoxId: number, comment: string) => void
  show: (config: Config) => void
}

export const CreateLockerBoxCommentForm: React.FC<Props> = (props: Props) => {
  const lockerLabel = `番号：${props.lockerBoxIndex}のロッカー備考`

  const [comment, setComment] = useState<string>()

  const handleOnClickSubmit = () => {

    if (!comment) {
      const config: Config = {
        type: 'error',
        title: strings.toastError.title,
        body: strings.toastError.body
      }
      props.show(config)
      return
    }
    props.createLockerBoxComment(props.stationId, props.lockerBoxId, comment)
  }



  return (
    <main className={styles.main}>
      <label className={styles.label}>{lockerLabel}</label>
      <div className={styles.form}>
        <label className={styles.label}>{strings.lockerBoxComment.comment}</label>
        <textarea
          className={styles.textarea}
          onChange={event => {setComment(event.target.value)}}
          value={comment}
        />
      </div>
      <Button
        title={strings.lockerBoxComment.createButton}
        onClick={() => handleOnClickSubmit()}
        className={styles.report}
        isLoading={props.isLoading}
        loadingClassName={styles.loadingReport}
      />
    </main>
  )
}
