import { ActionType } from 'typesafe-actions'

import { subscriptionActionType, subscriptionActions } from './actions'
import { Subscription } from './subscription'

export interface SubscriptionState {
  readonly error: Error
  readonly isLoading: boolean
  readonly subscriptions: Subscription[]
  readonly subscription: Subscription
  readonly active: boolean
}

export const initialState: SubscriptionState = {
  error: null,
  isLoading: false,
  subscriptions: [],
  subscription: null,
  active: false,
}

export const subscriptionReducer = (
  state: SubscriptionState = initialState,
  action: ActionType<typeof subscriptionActions>
) => {
  switch (action.type) {
    case subscriptionActionType.FETCH_SUBSCRIPTIONS:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case subscriptionActionType.FETCH_SUBSCRIPTIONS_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        error: null,
        subscriptions: action.payload.subscriptions,
      }
    case subscriptionActionType.RESET_SUBSCRIPTIONS:
      return {
        ...state,
        subscriptions: [],
        error: null,
      }
    case subscriptionActionType.FETCH_SUBSCRIPTION:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
      case subscriptionActionType.FETCH_SUBSCRIPTION_SUCCEEDED:
        return {
          ...state,
          isLoading: false,
          error: null,
          subscription: action.payload.subscription
        }
      case subscriptionActionType.THROW_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      }
    default:
      return state
  }
}
