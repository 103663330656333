import React from 'react'
import { Link } from 'react-router-dom'

import { strings } from './stations-content'
import styles from '../../styles/modules/stations/detail-button.module.scss'

interface Props {
  stationId: number
}

const DetailButton: React.FC<Props> = props => (
  <Link to={`/stations/${props.stationId}`} className={styles.container}>
    <span>{strings.detailButton}</span>
  </Link>
)

export default DetailButton
