import React from 'react'

import PointsTable from './points-table'
import UserInfoTable from './user-info-table'
import { UserInfo } from '../../../core/users'
import { PointTransaction } from '../../../core/points'
import Button from '../button'
import { strings } from './points-content'
import styles from '../../styles/modules/points/points.module.scss'

interface Props {
  userInfo: UserInfo
  amount: number
  pointTransactions: PointTransaction[]
  onClickCreateGivePoints: () => void
  onClickCreateExpirePoints: () => void
}

const PointsItem: React.FC<Props> = props => (
  <>
    <Button
      title={strings.createGivePointsButton}
      onClick={() => {
        props.onClickCreateGivePoints()
      }}
      className={styles.createGivePointsButton}
    />
    <Button
      title={strings.createExpirePointsButton}
      onClick={() => {
        props.onClickCreateExpirePoints()
      }}
      className={styles.createExpirePointsButton}
    />
    <UserInfoTable userInfo={props.userInfo} amount={props.amount} />
    <PointsTable pointTransactions={props.pointTransactions} />
  </>
)

export default PointsItem
