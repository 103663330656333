import { ActionType } from 'typesafe-actions'

import { modalActionType, modalActions } from './actions'
import { Campaign } from '../campaigns'
import { Station, TimeSlot } from '../stations'
import { StationAlert } from '../station-alerts'
import { LockerBoxComment } from '../machines'
import { SoracomSIM } from '../soracom-sims'
import { Machine } from '../machines'
import { TimeSlotPreset } from '../time-slot-presets'
import { PromotionImage } from '../promotion-images'
import { Kiosk } from '../kiosks'
import { StationWithItemRanks } from '../station-with-item-ranks'
import { Item } from '../items'
import { KioskItemPrice } from '../kiosk-item-prices'

export interface ModalState {
  readonly type?: ModalStateType
  readonly isVisible: boolean
  readonly createEmergencyData?: CreateEmergencyData
  readonly createPromotionCampaignData?: CreatePromotionCampaignData
  readonly editPromotionCampaignStatusData?: EditPromotionCampaignStatusData
  readonly editPromotionCampaignData?: EditPromotionCampaignData
  readonly editStarterCampaignData?: EditStarterCampaignData
  readonly editStampCampaignData?: EditStampCampaignData
  readonly editOrderCampaignData?: EditOrderCampaignData
  readonly createGivePointsData?: CreateGivePointsData
  readonly createExpirePointsData?: CreateExpirePointsData
  readonly createStationAlertData?: CreateStationAlertData
  readonly editStationAlertData?: EditStationAlertData
  readonly editLockerBoxCommentData?: EditLockerBoxCommentData
  readonly createLockerBoxCommentData?: CreateLockerBoxCommentData
  readonly editSoracomSIMData?: EditSoracomSIMData
  readonly editMachineData?: EditMachineData
  readonly editMachineRelationData?: EditMachineRelationData
  readonly editKioskRelationData?: EditKioskRelationData
  readonly editTimeSlotPresetsData?: EditTimeSlotPresetsData
  readonly editSignageBannerSequenceData?: EditSignageBannerSequenceData
  readonly editPromotionImageData?: EditPromotionImageData
  readonly editStationWithItemRanksData?: EditStationWithItemRanksData
  readonly editKioskItemPriceData?: EditKioskItemPriceData
  readonly showImageData?: SHowImageData
}

export enum ModalStateType {
  CreateStation = 'create_station',
  CreateEmergency = 'create_emergency',
  CreatePromotionCampaign = 'create_promotion_campaign',
  EditPromotionCampaignStatus = 'edit_promotion_campaign_status',
  EditPromotionCampaign = 'edit_promotion_campaign',
  EditStarterCampaign = 'edit_starter_campaign',
  EditStampCampaign = 'edit_stamp_campaign',
  EditOrderCampaign = 'edit_order_campaign',
  CreatePromotionImage = 'create_promotion_image',
  EditPromotionImage = 'edit_promotion_image',
  CreateSignageBanner = 'create_signage_banner',
  EditSignageBannerSequence = 'edit_signage_banner_sequence',
  CreateGivePoints = 'create_give_points',
  CreateExpirePoints = 'create_expire_points',
  CreateStationAlert = 'create_station_alert',
  EditStationAlert = 'edit_station_alert',
  EditLockerBoxComment = 'edit_locker_box_comment',
  CreateLockerBoxComment = 'create_locker_box_comment',
  CreateSoracomSIM = 'create_soracom_sim',
  EditSoracomSIM = 'edit_soracom_sim',
  CreateMachine = 'create_machine',
  EditMachine = 'edit_machine',
  EditMachineRelation = 'edit_machine_relation',
  EditKioskRelation = 'edit_kiosk_relation',
  EditTimeSlotPresets = 'edit_time_slot_presets',
  EditStationWithItemRanks = 'edit_station_with_item_ranks',
  CreateKiosk = 'create_kiosk',
  EditKioskItemPrice = 'edit_kiosk_item_price',
  CreatePointItem = 'create_point_item',
  ShowImage = 'show_image',
}

export interface CreateEmergencyData {
  readonly station: Station
}

export interface CreatePromotionCampaignData {
  readonly stations: Station[]
}

export interface EditPromotionCampaignStatusData {
  readonly promotionCampaign: Campaign
}

export interface EditPromotionCampaignData {
  readonly promotionCampaign: Campaign
}

export interface EditStarterCampaignData {
  readonly starterCampaign: Campaign
}

export interface EditStampCampaignData {
  readonly stampCampaign: Campaign
}

export interface EditOrderCampaignData {
  readonly orderCampaign: Campaign
}
export interface CreateGivePointsData {
  readonly userId: number
}

export interface CreateExpirePointsData {
  readonly userId: number
}

export interface CreateStationAlertData {
  readonly stations: Station[]
}

export interface EditStationAlertData {
  readonly stations: Station[]
  readonly stationAlert: StationAlert
}

export interface EditLockerBoxCommentData {
  readonly stationId: number
  readonly lockerBoxId: number
  readonly lockerBoxIndex: number
  readonly lockerBoxComment: LockerBoxComment
}

export interface CreateLockerBoxCommentData {
  readonly stationId: number
  readonly lockerBoxId: number
  readonly lockerBoxIndex: number
}

export interface EditSoracomSIMData {
  readonly soracomSIM: SoracomSIM
}

export interface EditMachineData {
  readonly machine: Machine
}

export interface EditSignageBannerSequenceData {
  readonly id: number
  readonly duration: number
  readonly isShow: boolean
}

export interface EditMachineRelationData {
  readonly stations: Station[]
  readonly soracomSIMs: SoracomSIM[]
  readonly machines: Machine[]
  readonly machine: Machine
}

export interface EditKioskRelationData {
  readonly stations: Station[]
  readonly soracomSIMs: SoracomSIM[]
  readonly kiosks: Kiosk[]
  readonly kiosk: Kiosk
}

export interface EditTimeSlotPresetsData {
  readonly timeSlots: TimeSlot[]
  readonly timeSlotPresets: TimeSlotPreset[]
}

export interface EditPromotionImageData {
  readonly promotionImage: PromotionImage
}

export interface EditStationWithItemRanksData {
  readonly items: Item[]
  readonly stationWithItemRanks: StationWithItemRanks
}

export interface EditKioskItemPriceData {
  readonly kioskItemPrice: KioskItemPrice
}

export interface SHowImageData {
  readonly imageUrl: string
  readonly alt: string
}

export const initialState: ModalState = {
  isVisible: false,
  type: null,
}

export const modalReducer = (
  state: ModalState = initialState,
  action: ActionType<typeof modalActions>
) => {
  switch (action.type) {
    case modalActionType.SHOW_CREATE_STATION:
      return {
        ...state,
        isVisible: true,
        type: ModalStateType.CreateStation,
      }
    case modalActionType.SHOW_CREATE_EMERGENCY:
      const createEmergencyData = {
        station: action.payload.station,
      } as CreateEmergencyData
      return {
        ...state,
        isVisible: true,
        type: ModalStateType.CreateEmergency,
        createEmergencyData,
      }
    case modalActionType.SHOW_CREATE_PROMOTION_CAMPAIGN:
      const createPromotionCampaignData = {
        stations: action.payload.stations,
      } as CreatePromotionCampaignData
      return {
        ...state,
        isVisible: true,
        type: ModalStateType.CreatePromotionCampaign,
        createPromotionCampaignData,
      }
    case modalActionType.SHOW_EDIT_PROMOTION_CAMPAIGN_STATUS:
      const editPromotionCampaignStatusData = {
        promotionCampaign: action.payload.promotionCampaign,
      } as EditPromotionCampaignData
      return {
        ...state,
        isVisible: true,
        type: ModalStateType.EditPromotionCampaignStatus,
        editPromotionCampaignStatusData,
      }
    case modalActionType.SHOW_EDIT_PROMOTION_CAMPAIGN:
      const editPromotionCampaignData = {
        promotionCampaign: action.payload.promotionCampaign,
      } as EditPromotionCampaignData
      return {
        ...state,
        isVisible: true,
        type: ModalStateType.EditPromotionCampaign,
        editPromotionCampaignData,
      }
    case modalActionType.SHOW_EDIT_STARTER_CAMPAIGN:
      const editStarterCampaignData = {
        starterCampaign: action.payload.starterCampaign,
      } as EditStarterCampaignData
      return {
        ...state,
        isVisible: true,
        type: ModalStateType.EditStarterCampaign,
        editStarterCampaignData,
      }
    case modalActionType.SHOW_EDIT_STAMP_CAMPAIGN:
      const editStampCampaignData = {
        stampCampaign: action.payload.stampCampaign,
      } as EditStampCampaignData
      return {
        ...state,
        isVisible: true,
        type: ModalStateType.EditStampCampaign,
        editStampCampaignData,
      }
    case modalActionType.SHOW_EDIT_ORDER_CAMPAIGN:
      const editOrderCampaignData = {
        orderCampaign: action.payload.orderCampaign,
      } as EditOrderCampaignData
      return {
        ...state,
        isVisible: true,
        type: ModalStateType.EditOrderCampaign,
        editOrderCampaignData,
      }
    case modalActionType.SHOW_CREATE_PROMOTION_IMAGE:
      return {
        ...state,
        isVisible: true,
        type: ModalStateType.CreatePromotionImage,
      }
    case modalActionType.SHOW_EDIT_PROMOTION_IMAGE:
      const editPromotionImageData = {
        promotionImage: action.payload.promotionImage,
      } as EditPromotionImageData
      return {
        ...state,
        isVisible: true,
        type: ModalStateType.EditPromotionImage,
        editPromotionImageData,
      }
    case modalActionType.SHOW_CREATE_SIGNAGE_BANNER:
      return {
        ...state,
        isVisible: true,
        type: ModalStateType.CreateSignageBanner,
      }
    case modalActionType.SHOW_EDIT_SIGNAGE_BANNER_DURATION:
      const editSignageBannerSequenceData = {
        id: action.payload.id,
        duration: action.payload.duration,
        isShow: action.payload.isShow,
      } as EditSignageBannerSequenceData
      return {
        ...state,
        isVisible: true,
        type: ModalStateType.EditSignageBannerSequence,
        editSignageBannerSequenceData,
      }
    case modalActionType.SHOW_CREATE_GIVE_POINTS:
      const createGivePointsData = {
        userId: action.payload.userId,
      } as CreateGivePointsData
      return {
        ...state,
        isVisible: true,
        type: ModalStateType.CreateGivePoints,
        createGivePointsData,
      }
    case modalActionType.SHOW_CREATE_EXPIRE_POINTS:
      const createExpirePointsData = {
        userId: action.payload.userId,
      } as CreateExpirePointsData
      return {
        ...state,
        isVisible: true,
        type: ModalStateType.CreateExpirePoints,
        createExpirePointsData,
      }
    case modalActionType.SHOW_CREATE_STATION_ALERT:
      const createStationAlertData = {
        stations: action.payload.stations,
      } as CreateStationAlertData
      return {
        ...state,
        isVisible: true,
        type: ModalStateType.CreateStationAlert,
        createStationAlertData,
      }
    case modalActionType.SHOW_EDIT_STATION_ALERT:
      const editStationAlertData = {
        stations: action.payload.stations,
        stationAlert: action.payload.stationAlert,
      } as EditStationAlertData
      return {
        ...state,
        isVisible: true,
        type: ModalStateType.EditStationAlert,
        editStationAlertData,
      }
    case modalActionType.SHOW_EDIT_LOCKER_BOX_COMMENT:
      const editLockerBoxCommentData = {
        stationId: action.payload.stationID,
        lockerBoxId: action.payload.lockerBoxID,
        lockerBoxIndex: action.payload.lockerBoxIndex,
        lockerBoxComment: action.payload.lockerBoxComment,
      }
      return {
        ...state,
        isVisible: true,
        type: ModalStateType.EditLockerBoxComment,
        editLockerBoxCommentData,
      }
    case modalActionType.SHOW_CREATE_LOCKER_BOX_COMMENT:
      const createLockerBoxCommentData = {
        stationId: action.payload.stationID,
        lockerBoxId: action.payload.lockerBoxID,
        lockerBoxIndex: action.payload.lockerBoxIndex,
      }
      return {
        ...state,
        isVisible: true,
        type: ModalStateType.CreateLockerBoxComment,
        createLockerBoxCommentData,
      }
    case modalActionType.SHOW_CREATE_SORACOM_SIM:
      return {
        ...state,
        isVisible: true,
        type: ModalStateType.CreateSoracomSIM,
      }
    case modalActionType.SHOW_EDIT_SORACOM_SIM:
      const editSoracomSIMData = {
        soracomSIM: action.payload.soracomSIM,
      } as EditSoracomSIMData
      return {
        ...state,
        isVisible: true,
        type: ModalStateType.EditSoracomSIM,
        editSoracomSIMData,
      }
    case modalActionType.SHOW_CREATE_MACHINE:
      return {
        ...state,
        isVisible: true,
        type: ModalStateType.CreateMachine,
      }
    case modalActionType.SHOW_EDIT_MACHINE:
      const editMachineData = {
        machine: action.payload.machine,
      } as EditMachineData
      return {
        ...state,
        isVisible: true,
        type: ModalStateType.EditMachine,
        editMachineData,
      }
    case modalActionType.SHOW_EDIT_MACHINE_RELATION:
      const editMachineRelationData = {
        stations: action.payload.stations,
        soracomSIMs: action.payload.soracomSIMs,
        machines: action.payload.machines,
        machine: action.payload.machine,
      } as EditMachineRelationData
      return {
        ...state,
        isVisible: true,
        type: ModalStateType.EditMachineRelation,
        editMachineRelationData,
      }
    case modalActionType.SHOW_EDIT_KIOSK_RELATION:
      const editKioskRelationData = {
        stations: action.payload.stations,
        soracomSIMs: action.payload.soracomSIMs,
        kiosks: action.payload.kiosks,
        kiosk: action.payload.kiosk,
      } as EditKioskRelationData
      return {
        ...state,
        isVisible: true,
        type: ModalStateType.EditKioskRelation,
        editKioskRelationData,
      }
    case modalActionType.SHOW_EDIT_TIME_SLOT_PRESETS:
      const editTimeSlotPresetsData = {
        timeSlots: action.payload.timeSlots,
        timeSlotPresets: action.payload.timeSlotPresets,
      } as EditTimeSlotPresetsData
      return {
        ...state,
        isVisible: true,
        type: ModalStateType.EditTimeSlotPresets,
        editTimeSlotPresetsData,
      }
    case modalActionType.SHOW_EDIT_STATION_WITH_ITEM_RANKS:
      const editStationWithItemRanksData = {
        items: action.payload.items,
        stationWithItemRanks: action.payload.stationWithItemRanks,
      } as EditStationWithItemRanksData
      return {
        ...state,
        isVisible: true,
        type: ModalStateType.EditStationWithItemRanks,
        editStationWithItemRanksData,
      }
    case modalActionType.SHOW_CREATE_KIOSK:
      return {
        ...state,
        isVisible: true,
        type: ModalStateType.CreateKiosk,
      }
    case modalActionType.SHOW_EDIT_KIOSK_ITEM_PRICE:
      const editKioskItemPriceData = {
        kioskItemPrice: action.payload.kioskItemPrice,
      } as EditKioskItemPriceData
      return {
        ...state,
        isVisible: true,
        type: ModalStateType.EditKioskItemPrice,
        editKioskItemPriceData,
      }
    case modalActionType.SHOW_CREATE_POINT_ITEM:
      return {
        ...state,
        isVisible: true,
        type: ModalStateType.CreatePointItem,
      }
    case modalActionType.SHOW_IMAGE:
      const showImageData = {
        imageUrl: action.payload.imageURL,
        alt: action.payload.alt,
      } as SHowImageData
      return {
        ...state,
        isVisible: true,
        type: ModalStateType.ShowImage,
        showImageData,
      }
    case modalActionType.HIDE:
      return {
        ...state,
        isVisible: false,
      }
    default:
      return state
  }
}
