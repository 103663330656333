import React from 'react'

import { strings } from './machine-relations-content'
import styles from '../../styles/modules/machine-relations/machine-relations-query.module.scss'
import { Machine } from '../../../core/machines'

interface Props {
  options: Machine[]
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void
  value: number
}

const QueryMachine: React.FC<Props> = props => (
  <div className={styles.container}>
    <span className={styles.title}>{strings.queryMachineTitle}</span>
    <div className={styles.wrapper}>
      <select
        className={styles.input}
        value={props.value}
        onChange={event => props.onChange(event)}
      >
        <option value={'@all'}>{strings.queryPlaceHolder}</option>
        {props.options.map(item => (
          <option value={item.id} key={item.id}>
            {`ID: ${item.id} ${item.serial}`}
          </option>
        ))}
      </select>
    </div>
  </div>
)

export default QueryMachine
