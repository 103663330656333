import { ActionType } from 'typesafe-actions'
import { pointActionType, pointActions } from './actions'
import { PointTransaction } from './points'
import { UserInfo } from '../users'

export interface PointTransactionsState {
  readonly error: Error
  readonly isLoading: boolean
  readonly user: UserInfo
  readonly amount: number
  readonly pointTransactions: PointTransaction[]
}

export const initialState: PointTransactionsState ={
  error: null,
  isLoading: false,
  user: null,
  amount: null,
  pointTransactions: []
}

export const pointsReducer = (
  state: PointTransactionsState = initialState,
  action: ActionType<typeof pointActions>
) => {
  switch (action.type) {
    case pointActionType.FETCH_POINT_TRANSACTIONS:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case pointActionType.FETCH_POINT_TRANSACTIONS_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        error: null,
        user: action.payload.user,
        amount: action.payload.amount,
        pointTransactions: action.payload.pointTransactions
      }
    case pointActionType.CREATE_POINTS:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case pointActionType.CREATE_POINTS_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      }
    case pointActionType.THROW_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
        user: null,
      }
    default:
      return state
  }
}