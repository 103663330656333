import { action } from 'typesafe-actions'

import { Item, ItemImageType } from './items'

export enum itemsActionType {
  FETCH_ITEMS = 'FETCH_ITEMS',
  FETCH_ITEMS_SUCCEEDED = 'FETCH_ITEMS_SUCCEEDED',
  FETCH_ITEMS_EVERY = 'FETCH_ITEMS_EVERY',
  FETCH_ITEMS_EVERY_FINISHED = 'FETCH_ITEMS_EVERY_FINISHED',
  SET_ITEM = 'SET_ITEM',
  SET_ITEM_SUCCEEDED = 'SET_ITEM_SUCCEEDED',
  UPLOAD_ITEM_IMAGE = 'UPLOAD_ITEM_IMAGE',
  SET_ITEM_IMAGE_URL = 'SET_ITEM_IMAGE_URL',
  CREATE_ITEM = 'CREATE_ITEM',
  CREATE_ITEM_SUCCEEDED = 'CREATE_ITEM_SUCCEEDED',
  CHANGE_ITEMS_INDEX = 'CHANGE_ITEMS_INDEX',
  SET_HOT_ITEMS = 'SET_HOT_ITEMS',
  SET_ICE_ITEMS = 'SET_ICE_ITEMS',
  THROW_ERROR = 'THROW_ERROR',
}

export const itemsActions = {
  fetchItems: () => action(itemsActionType.FETCH_ITEMS),
  fetchItemsSucceeded: (items: Item[], hotItems: Item[], iceItems: Item[]) =>
    action(itemsActionType.FETCH_ITEMS_SUCCEEDED, { items, hotItems, iceItems }),
  fetchItemsEvery: () => action(itemsActionType.FETCH_ITEMS_EVERY),
  fetchItemsEveryFinished: () =>
    action(itemsActionType.FETCH_ITEMS_EVERY_FINISHED),
  setItem: (itemId: number, item: Item) =>
    action(itemsActionType.SET_ITEM, { itemId, item }),
  setItemSucceeded: () => action(itemsActionType.SET_ITEM_SUCCEEDED),
  uploadItemImage: (
    itemId: number,
    itemImageType: ItemImageType,
    file: File
  ) =>
    action(itemsActionType.UPLOAD_ITEM_IMAGE, {
      itemId,
      itemImageType,
      file,
    }),
  setImageUrl: (
    fileName: string,
    url: string,
    itemImageType: ItemImageType
  ) =>
    action(itemsActionType.SET_ITEM_IMAGE_URL, {
      fileName,
      url,
      itemImageType,
    }),
  createItem: (item: Item) => action(itemsActionType.CREATE_ITEM, { item }),
  createItemSucceeded: () => action(itemsActionType.CREATE_ITEM_SUCCEEDED),
  changeItemsIndex: (ids: number[]) =>
    action(itemsActionType.CHANGE_ITEMS_INDEX, { ids }),
  setHotItems: (hotItems: Item[]) => action(itemsActionType.SET_HOT_ITEMS, { hotItems }),
  setIceItems: (iceItems: Item[]) => action(itemsActionType.SET_ICE_ITEMS, { iceItems }),
  throwError: (error: Error) =>
    action(itemsActionType.THROW_ERROR, { error }),
}
