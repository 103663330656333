import React from 'react'
import classnames from 'classnames'

import Button from '../button'
import { PromotionImage } from '../../../core/promotion-images'
import { strings } from './promotion-images-content'
import styles from '../../styles/modules/table.module.scss'
import promotionImagesTableStyles from '../../styles/modules/promotion-images/promotion-images.module.scss'
import PromotionImageItemTable from './promotion-image-item-table'

interface Props {
  promotionImages: PromotionImage[]
  onClickCreatePromotionImage: () => void
  onClickDeletePromotionImage: (id: number) => void
  showEditPromotionImage: (promotionImage: PromotionImage) => void
  setPromotionImages: (promotionImages: PromotionImage[]) => void
  updatePromotionImageIndex: (ids: number[]) => void
}

const PromotionImageItems: React.FC<Props> = props => (
  <div
    className={classnames({
      [styles.wrapper]: true,
      [styles.stocker]: true,
    })}
  >
    <Button
      title={strings.createPromotionImageButton}
      onClick={() => {
        props.onClickCreatePromotionImage()
      }}
      className={promotionImagesTableStyles.promotionImageCreateButton}
    />
    <PromotionImageItemTable
      promotionImages={props.promotionImages}
      onClickDeletePromotionImage={props.onClickDeletePromotionImage}
      showEditPromotionImage={props.showEditPromotionImage}
      setPromotionImages={props.setPromotionImages}
      updatePromotionImageIndex={props.updatePromotionImageIndex}
    />
  </div>
)

export default PromotionImageItems
