import { ActionType } from 'typesafe-actions'

import { kiosksActionType, kiosksActions } from './actions'
import { Kiosk } from './kiosks'

export interface KiosksState {
  readonly error: Error
  readonly isLoading: boolean
  readonly kiosks: Kiosk[]
}

export const initialState: KiosksState = {
  error: null,
  isLoading: false,
  kiosks: [],
}

export const kiosksReducer = (
  state: KiosksState = initialState,
  action: ActionType<typeof kiosksActions>
) => {
  switch (action.type) {
    case kiosksActionType.FETCH_KIOSKS:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case kiosksActionType.FETCH_KIOSKS_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        error: null,
        kiosks: action.payload.kiosks,
      }
    case kiosksActionType.CREATE_KIOSK:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case kiosksActionType.UPDATE_KIOSK_RELATION:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case kiosksActionType.CLEAR_KIOSK_RELATION:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case kiosksActionType.THROW_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      }
    default:
      return state
  }
}
