import React from 'react'

import { Station } from '../../../core/stations'
import { strings } from './machine-relations-content'
import styles from '../../styles/modules/orders/orders-query.module.scss'

interface Props {
  options: Station[]
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void
  value: number
}

const QueryStation: React.FC<Props> = props => (
  <div className={styles.container}>
    <span className={styles.title}>{strings.queryStationTitle}</span>
    <div className={styles.stationsWrapper}>
      <select
        className={styles.input}
        value={props.value}
        onChange={event => props.onChange(event)}
      >
        <option value={'@all'}>{strings.queryPlaceHolder}</option>
        {props.options.map(item => (
          <option value={item.id} key={item.id}>
            {`ID: ${item.id} ${item.name}`}
          </option>
        ))}
      </select>
    </div>
  </div>
)

export default QueryStation
