import { action } from 'typesafe-actions'

import { Station, StationImageType } from './stations'
import { LockerBox } from '../machines'

export enum stationsActionType {
  FETCH_STATIONS = 'FETCH_STATIONS',
  FETCH_STATIONS_SUCCEEDED = 'FETCH_STATIONS_SUCCEEDED',
  FETCH_STATIONS_EVERY = 'FETCH_STATIONS_EVERY',
  FETCH_STATIONS_EVERY_FINISHED = 'FETCH_STATIONS_EVERY_FINISHED',
  FETCH_STATION = 'FETCH_STATION',
  FETCH_STATION_SUCCEEDED = 'FETCH_STATION_SUCCEEDED',
  FETCH_STATION_EVERY = 'FETCH_STATION_EVERY',
  FETCH_STATION_EVERY_FINISHED = 'FETCH_STATION_EVERY_FINISHED',
  CREATE_STATION = 'CREATE_STATION',
  OPEN_ALL_LOCKER_BOXES = 'OPEN_ALL_LOCKER_BOXES',
  BOOT_MACHINE = 'BOOT_MACHINE',
  BOOT_MACHINE_SUCCEEDED = 'BOOT_MACHINE_SUCCEEDED',
  SHUT_MACHINE = 'SHUT_MACHINE',
  SHUT_MACHINE_SUCCEEDED = 'SHUT_MACHINE_SUCCEEDED',
  RESOLVE_EMERGENCY = 'RESOLVE_EMERGENCY',
  RESOLVE_EMERGENCY_SUCCEEDED = 'RESOLVE_EMERGENCY_SUCCEEDED',
  CREATE_EMERGENCY = 'CREATE_EMERGENCY',
  CREATE_EMERGENCY_SUCCEEDED = 'CREATE_EMERGENCY_SUCCEEDED',
  FILL_ICE = 'FILL_ICE',
  SET_STATION_ITEM_AVAILABLE = 'SET_STATION_ITEM_AVAILABLE',
  SET_STATION_ITEM_AVAILABLE_SUCCEEDED = 'SET_STATION_ITEM_AVAILABLE_SUCCEEDED',
  SET_STATION_ITEM_AVAILABLE_TEMPORARILY = 'SET_STATION_ITEM_AVAILABLE_TEMPORARILY',
  SET_STATION_ITEM_UNAVAILABLE = 'SET_STATION_ITEM_UNAVAILABLE',
  SET_STATION_ITEM_UNAVAILABLE_SUCCEEDED = 'SET_STATION_ITEM_UNAVAILABLE_SUCCEEDED',
  SET_STATION_ITEM_UNAVAILABLE_TEMPORARILY = 'SET_STATION_ITEM_UNAVAILABLE_TEMPORARILY',
  OPEN_TIME_SLOT = 'OPEN_TIME_SLOT',
  CLOSE_TIME_SLOT = 'CLOSE_TIME_SLOT',
  OPEN_ALL_TIME_SLOTS = 'OPEN_ALL_TIME_SLOTS',
  CLOSE_ALL_TIME_SLOTS = 'CLOSE_ALL_TIME_SLOTS',
  SET_STATION = 'SET_STATION',
  SET_STATION_SUCCEEDED = 'SET_STATION_SUCCEEDED',
  UPLOAD_STATION_IMAGE = 'UPLOAD_STATION_IMAGE',
  SET_STATION_IMAGE_URL = 'SET_STATION_IMAGE_URL',
  SET_STATION_LOCKER_BOX_AVAILABLE = 'SET_STATION_LOCKER_BOX_AVAILABLE',
  SET_STATION_LOCKER_BOX_UNAVAILABLE = 'SET_STATION_LOCKER_BOX_UNAVAILABLE',
  CLEAR_LOCKER_BOX = 'CLEAR_LOCKER_BOX',
  OPEN_LOCKER_BOX = 'OPEN_LOCKER_BOX',
  CREATE_LOCKER_BOX_COMMENT = 'CREATE_LOCKER_BOX_COMMENT',
  SET_LOCKER_BOX_COMMENT = 'UPDATE_CREATE_LOCKER_BOX_COMMENT',
  REMOVE_LOCKER_BOX_COMMENT = 'REMOVE_LOCKER_BOX_COMMENT',
  THROW_ERROR = 'THROW_ERROR',
}

export const stationsActions = {
  fetchStations: () => action(stationsActionType.FETCH_STATIONS),
  fetchStationsSucceeded: (stations: Station[]) =>
    action(stationsActionType.FETCH_STATIONS_SUCCEEDED, { stations }),
  fetchStationsEvery: () => action(stationsActionType.FETCH_STATIONS_EVERY),
  fetchStationsEveryFinished: () =>
    action(stationsActionType.FETCH_STATIONS_EVERY_FINISHED),
  fetchStation: (stationId: number) =>
    action(stationsActionType.FETCH_STATION, { stationId }),
  fetchStationSucceeded: (station: Station) =>
    action(stationsActionType.FETCH_STATION_SUCCEEDED, { station }),
  fetchStationEvery: (stationId: number) =>
    action(stationsActionType.FETCH_STATION_EVERY, { stationId }),
  fetchStationEveryFinished: () =>
    action(stationsActionType.FETCH_STATION_EVERY_FINISHED),
  createStation: (
    name: string,
    extra: string,
    fullAddress: string,
    openingHours: string,
    latitude: number,
    longitude: number
  ) =>
    action(stationsActionType.CREATE_STATION, {
      name,
      extra,
      fullAddress,
      openingHours,
      latitude,
      longitude,
    }),
  openAllLockerBoxes: (stationId: number) =>
    action(stationsActionType.OPEN_ALL_LOCKER_BOXES, { stationId }),
  bootMachine: (stationId: number) =>
    action(stationsActionType.BOOT_MACHINE, { stationId }),
  bootMachineSucceeded: () => action(stationsActionType.BOOT_MACHINE_SUCCEEDED),
  shutMachine: (stationId: number) =>
    action(stationsActionType.SHUT_MACHINE, { stationId }),
  shutMachineSucceeded: () => action(stationsActionType.SHUT_MACHINE_SUCCEEDED),
  resolveEmergency: (stationId: number, emergencyId: number) =>
    action(stationsActionType.RESOLVE_EMERGENCY, { stationId, emergencyId }),
  resolveEmergencySucceeded: () =>
    action(stationsActionType.RESOLVE_EMERGENCY_SUCCEEDED),
  createEmergency: (
    stationId: number,
    message: string,
    canOpenLockerBox: boolean
  ) =>
    action(stationsActionType.CREATE_EMERGENCY, {
      stationId,
      message,
      canOpenLockerBox,
    }),
  createEmergencySucceeded: () =>
    action(stationsActionType.CREATE_EMERGENCY_SUCCEEDED),
  fillIce: (stationId: number) =>
    action(stationsActionType.FILL_ICE, { stationId }),
  setStationItemAvailable: (itemId: number, stationId: number) =>
    action(stationsActionType.SET_STATION_ITEM_AVAILABLE, {
      itemId,
      stationId,
    }),
  setStationItemAvailableSucceeded: () =>
    action(stationsActionType.SET_STATION_ITEM_AVAILABLE_SUCCEEDED),
  setStationItemAvailableTemporarily: (itemId: number, stationId: number) =>
    action(stationsActionType.SET_STATION_ITEM_AVAILABLE_TEMPORARILY, {
      itemId,
      stationId,
    }),
  setStationItemUnavailable: (itemId: number, stationId: number) =>
    action(stationsActionType.SET_STATION_ITEM_UNAVAILABLE, {
      itemId,
      stationId,
    }),
  setStationItemUnavailableSucceeded: () =>
    action(stationsActionType.SET_STATION_ITEM_UNAVAILABLE_SUCCEEDED),
  setStationItemUnavailableTemporarily: (itemId: number, stationId: number) =>
    action(stationsActionType.SET_STATION_ITEM_UNAVAILABLE_TEMPORARILY, {
      itemId,
      stationId,
    }),
  openTimeSlot: (stationId: number, timeSlotId: number) =>
    action(stationsActionType.OPEN_TIME_SLOT, { stationId, timeSlotId }),
  closeTimeSlot: (stationId: number, timeSlotId: number) =>
    action(stationsActionType.CLOSE_TIME_SLOT, { stationId, timeSlotId }),
  openAllTimeSlots: (stationId: number) =>
    action(stationsActionType.OPEN_ALL_TIME_SLOTS, { stationId }),
  closeAllTimeSlots: (stationId: number) =>
    action(stationsActionType.CLOSE_ALL_TIME_SLOTS, { stationId }),
  setStation: (stationId: number, station: Station) =>
    action(stationsActionType.SET_STATION, { stationId, station }),
  setStationSucceeded: () => action(stationsActionType.SET_STATION_SUCCEEDED),
  uploadStationImage: (
    stationId: number,
    stationImageType: StationImageType,
    file: File
  ) =>
    action(stationsActionType.UPLOAD_STATION_IMAGE, {
      stationId,
      stationImageType,
      file,
    }),
  setImageUrl: (
    fileName: string,
    url: string,
    stationImageType: StationImageType
  ) =>
    action(stationsActionType.SET_STATION_IMAGE_URL, {
      fileName,
      url,
      stationImageType,
    }),
  setStationLockerBoxAvailable: (stationId: number, lockerBoxId: number) =>
    action(stationsActionType.SET_STATION_LOCKER_BOX_AVAILABLE, {
      stationId,
      lockerBoxId,
    }),
  setStationLockerBoxUnavailable: (stationId: number, lockerBoxId: number) =>
    action(stationsActionType.SET_STATION_LOCKER_BOX_UNAVAILABLE, {
      stationId,
      lockerBoxId,
    }),
  clearLockerBox: (stationId: number, lockerBox: LockerBox) =>
    action(stationsActionType.CLEAR_LOCKER_BOX, { stationId, lockerBox }),
  openLockerBox: (stationId: number, lockerBoxId: number) =>
    action(stationsActionType.OPEN_LOCKER_BOX, { stationId, lockerBoxId }),
  createLockerBoxComment: (
    stationId: number,
    lockerBoxId: number,
    comment: string
  ) =>
    action(stationsActionType.CREATE_LOCKER_BOX_COMMENT, {
      stationId,
      lockerBoxId,
      comment,
    }),
  setLockerBoxComment: (
    stationId: number,
    lockerBoxId: number,
    lockerBoxCommentId: number,
    comment: string
  ) =>
    action(stationsActionType.SET_LOCKER_BOX_COMMENT, {
      stationId,
      lockerBoxId,
      lockerBoxCommentId,
      comment,
    }),
  removeLockerBoxComment: (
    stationId: number,
    lockerBoxId: number,
    lockerBoxCommentId: number
  ) =>
    action(stationsActionType.REMOVE_LOCKER_BOX_COMMENT, {
      stationId,
      lockerBoxId,
      lockerBoxCommentId,
    }),
  throwError: (error: Error) =>
    action(stationsActionType.THROW_ERROR, { error }),
}
