import React, { useState } from 'react'
import classNames from 'classnames'
import ReactSwitch from 'react-switch'

import { strings } from './modal-content'
import Button from '../button'
import { Config } from '../../../core/toast'
import { TimeSlot } from '../../../core/stations'
import { TimeSlotPreset } from '../../../core/time-slot-presets'

import styles from '../../styles/modules/modal/modal.module.scss'
import tableStyles from '../../styles/modules/table.module.scss'

interface Props {
  isLoading: boolean
  timeSlots: TimeSlot[]
  timeSlotPresets: TimeSlotPreset[]
  setTimeSlotPresets: (
    stationId: number,
    timeSlotPresets: TimeSlotPreset[]
  ) => void
  show: (config: Config) => void
}

export const EditTimeSlotPresetsForm: React.FC<Props> = (props: Props) => {
  const [editTimeSlotPresets, setEditTimeSlotPresets] = useState<
    TimeSlotPreset[]
  >(props.timeSlotPresets)

  const handleOnClick = () => {
    props.setTimeSlotPresets(
      editTimeSlotPresets[0].stationID,
      editTimeSlotPresets
    )
  }

  const getLabel = (timeSlotPreset: TimeSlotPreset) => {
    switch (timeSlotPreset.dayOfWeek) {
      case 'monday':
        return '月曜日'
      case 'tuesday':
        return '火曜日'
      case 'wednesday':
        return '水曜日'
      case 'thursday':
        return '木曜日'
      case 'friday':
        return '金曜日'
      case 'saturday':
        return '土曜日'
      case 'sunday':
        return '日曜日'
      default:
        return ''
    }
  }

  return (
    <main className={styles.main}>
      <label className={styles.label}>
        {getLabel(props.timeSlotPresets[0])}
      </label>
      <div
        className={classNames({
          [tableStyles.wrapper]: true,
          [tableStyles.zebra]: true,
          [tableStyles.stocker]: true,
        })}
      >
        <table className={tableStyles.container}>
          <tbody>
            <tr className={tableStyles.rowStatic}>
              {strings.timeSlotPreset.timeSlotTableContent.map(item => (
                <th key={item} className={tableStyles.cell}>
                  {item}
                </th>
              ))}
            </tr>
            {props.timeSlots.map((timeSlot, i) => (
              <tr className={tableStyles.rowStatic} key={timeSlot.id}>
                <td className={tableStyles.cell}>{timeSlot.from}</td>
                <td className={tableStyles.cell}>{timeSlot.to}</td>
                <td className={tableStyles.cell}>
                  <div className={styles.switchContainer}>
                    <ReactSwitch
                      onChange={() => {
                        setEditTimeSlotPresets(prevTimeSlotPreset =>
                          prevTimeSlotPreset.map((timeSlotPreset, j) =>
                            i === j
                              ? {
                                  ...timeSlotPreset,
                                  isClosed: !timeSlotPreset.isClosed,
                                }
                              : timeSlotPreset
                          )
                        )
                      }}
                      checked={!editTimeSlotPresets[i].isClosed}
                    />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Button
        title={strings.timeSlotPreset.updateButton}
        onClick={handleOnClick}
        className={styles.report}
        isLoading={props.isLoading}
        loadingClassName={styles.loadingReport}
      />
    </main>
  )
}
