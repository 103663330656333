import React from 'react'

import Button from '../button'
import Input from '../input'
import AutoCompleteSelect, { SelectOption } from '../auto-complete-select'
import styles from '../../styles/modules/modal/modal.module.scss'
import optionalInputStyles from '../../styles/modules/optional-date-input.module.scss'
import { strings } from './modal-content'
import { Config } from '../../../core/toast'
import DateInput from '../date-input'
import Switch from 'react-switch'
import { Station } from '../../../core/stations'

interface Props {
  isLoading: boolean
  stations: Station[]
  createPromotionCampaign: (
    code: string,
    name: string,
    discountAmount: number,
    memo: string,
    availableCount: number,
    entryCountLimit: number,
    expiresAt?: string,
    eligibleStationIds?: number[]
  ) => void
  show: (config: Config) => void
}

interface State {
  readonly code: string
  readonly name: string
  readonly discountAmount: string
  readonly memo: string
  readonly availableCount: string
  readonly entryCountLimit: string
  readonly expiresAt?: Date
  readonly eligibleStationIds?: number[]
  shouldSetEligibleStationIds: boolean
}

export class CreatePromotionCampaignForm extends React.PureComponent<
  Props,
  State
> {
  readonly state = {
    code: '',
    name: '割引クーポン',
    discountAmount: '',
    availableCount: '1',
    entryCountLimit: '',
    memo: '',
    expiresAt: new Date(),
    eligibleStationIds: new Array<number>(0),
    shouldSetEligibleStationIds: false,
  }

  handleOnClickSubmit = () => {
    const { code, name, expiresAt, memo, eligibleStationIds } = this.state
    const discountAmount = parseInt(this.state.discountAmount, 10)
    const availableCount = parseInt(this.state.availableCount, 10)
    const entryCountLimit = parseInt(this.state.entryCountLimit, 10)
    const orderPrivilegeCouponDiscountAmount = 150

    if (
      !code ||
      !name ||
      !expiresAt ||
      isNaN(discountAmount) ||
      isNaN(availableCount) ||
      isNaN(entryCountLimit)
    ) {
      const config: Config = {
        type: 'error',
        title: strings.toastError.title,
        body: strings.toastError.body,
      }
      this.props.show(config)
      return
    }

    if (discountAmount < orderPrivilegeCouponDiscountAmount) {
      const response = window.confirm(strings.createCampaignAlert)
      if (!response) {
        return
      }
    }

    expiresAt.setHours(23)
    expiresAt.setMinutes(59)

    if (this.state.shouldSetEligibleStationIds) {
      // 対象ステーションを設定
      this.props.createPromotionCampaign(
        code,
        name,
        discountAmount,
        memo,
        availableCount,
        entryCountLimit,
        expiresAt.toJSON(),
        eligibleStationIds
      )
    } else {
      // 対象ステーションの設定なし（全ステーション対象）
      this.props.createPromotionCampaign(
        code,
        name,
        discountAmount,
        memo,
        availableCount,
        entryCountLimit,
        expiresAt.toJSON()
      )
    }
  }

  render() {
    const { isLoading } = this.props
    const options = this.props.stations.map(station => ({
      label: `ID: ${station.id} ${station.name}`,
      value: `${station.id}`,
    }))
    return (
      <main className={styles.main}>
        <Input
          label={strings.campaign.code}
          type={'text'}
          onChange={event => {
            this.setState({ code: event.target.value })
          }}
          value={this.state.code}
        />
        <Input
          label={strings.campaign.name}
          type={'text'}
          onChange={event => {
            this.setState({ name: event.target.value })
          }}
          value={this.state.name}
        />
        <Input
          label={strings.campaign.discountAmount}
          type={'text'}
          onChange={event => {
            this.setState({ discountAmount: event.target.value })
          }}
          value={this.state.discountAmount}
        />
        <Input
          label={strings.campaign.availableCount}
          type={'text'}
          onChange={event => {
            this.setState({ availableCount: event.target.value })
          }}
          value={this.state.availableCount}
        />
        <Input
          label={strings.campaign.entryCountLimit}
          type={'text'}
          onChange={event => {
            this.setState({ entryCountLimit: event.target.value })
          }}
          value={this.state.entryCountLimit}
        />
        <DateInput
          label={strings.campaign.expiresAt}
          type={'text'}
          onChangeDate={event => {
            this.setState({ expiresAt: event })
          }}
          date={this.state.expiresAt}
        />
        <Input
          label={strings.campaign.memoContent}
          type={'text'}
          onChange={event => {
            this.setState({ memo: event.target.value })
          }}
          value={this.state.memo}
        />

        {/* クーポン使用可能なステーションを入力させるフォーム */}
        <div className={optionalInputStyles.form}>
          <label className={optionalInputStyles.label}>
            {strings.campaign.eligibleStations}
          </label>
          <div className={optionalInputStyles.optionalTitleContainer}>
            <div className={optionalInputStyles.optionalTitle}>
              {strings.campaign.setEligibleStations}
            </div>
            <Switch
              onChange={e => {
                this.setState({ shouldSetEligibleStationIds: e })
              }}
              checked={this.state.shouldSetEligibleStationIds}
            />
          </div>
          {this.state.shouldSetEligibleStationIds ? (
            <AutoCompleteSelect
              options={options}
              isMulti
              onChange={value => {
                value = value as SelectOption[]
                const ids = value.map(v => parseInt(v.value, 10))
                this.setState({ eligibleStationIds: ids })
              }}
            />
          ) : null}
        </div>
        <Button
          title={strings.emergency.button}
          onClick={() => this.handleOnClickSubmit()}
          className={styles.report}
          isLoading={isLoading}
          loadingClassName={styles.loadingReport}
        />
      </main>
    )
  }
}
