export const strings = {
  title: 'KIOSK',
  kiosksTableTitle: 'KIOSK情報',
  createKioskButton: '新規作成',
  receiptUnavailable: '無',
  receiptAvailable: '有',
}

export const kiosksTableContent = [
  'ID',
  '製造番号',
  'ステーションID',
  'レシート残量',
]
