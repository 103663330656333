import React from 'react'
import classnames from 'classnames'

import { Station, StationItem } from '../../../core/stations'
import { itemTableContent } from './each-station-content'
import styles from '../../styles/modules/table.module.scss'
import itemTableStyles from '../../styles/modules/each-station/item-table.module.scss'
import Switch from 'react-switch'

interface Props {
  station: Station
  items: StationItem[]
  setStationItemAvailable: (itemId: number, stationId: number) => void
  setStationItemAvailableTemporarily: (
    itemId: number,
    stationId: number
  ) => void
  setStationItemUnavailable: (itemId: number, stationId: number) => void
  setStationItemUnavailableTemporarily: (
    itemId: number,
    stationId: number
  ) => void
}

const ItemTable: React.FC<Props> = props => {
  const handleOnClickSetStationItemUnavailable = (
    itemName: string,
    itemId: number,
    stationId: number
  ) => {
    const response = window.confirm(
      `本当に${itemName}のアイテムを取り扱い不可にしますか？`
    )
    if (response) {
      props.setStationItemUnavailable(itemId, stationId)
    }
  }

  const handleOnClickSetStationItemAvailable = (
    itemName: string,
    itemId: number,
    stationId: number
  ) => {
    const response = window.confirm(
      `本当に${itemName}のアイテムを取り扱い可能しますか？`
    )
    if (response) {
      props.setStationItemAvailable(itemId, stationId)
    }
  }

  return (
    <div
      className={classnames({
        [styles.wrapper]: true,
        [styles.zebra]: true,
        [styles.stocker]: true,
      })}
    >
      <table className={styles.container}>
        <tbody>
          <tr className={styles.row}>
            {itemTableContent.map(item => (
              <th key={item} className={styles.cell}>
                {item}
              </th>
            ))}
          </tr>
          {props.items.map(item => (
            <tr className={styles.row} key={item.id}>
              <td className={styles.cell}>{item.id}</td>
              <td className={styles.cell}>{item.name}</td>
              <td className={styles.cell}>
                <div className={itemTableStyles.switchContainer}>
                  <Switch
                    onChange={() => {
                      item.status !== 'unavailable'
                        ? handleOnClickSetStationItemUnavailable(
                            item.name,
                            item.id,
                            props.station.id
                          )
                        : handleOnClickSetStationItemAvailable(
                            item.name,
                            item.id,
                            props.station.id
                          )
                    }}
                    checked={item.status !== 'unavailable'}
                  />
                </div>
              </td>
              <td className={styles.cell}>
                <div className={itemTableStyles.switchContainer}>
                  {item.status !== 'unavailable' ? (
                    <Switch
                      onChange={() => {
                        item.status !== 'unavailable_temporarily'
                          ? props.setStationItemUnavailableTemporarily(
                              item.id,
                              props.station.id
                            )
                          : props.setStationItemAvailableTemporarily(
                              item.id,
                              props.station.id
                            )
                      }}
                      checked={item.status !== 'unavailable_temporarily'}
                    />
                  ) : (
                    <></>
                  )}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default ItemTable
