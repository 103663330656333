export const strings = {
  title: 'マシンエラー履歴',
}

export const machineErrorsTableContent = [
  '',
  'ID',
  'ステーションID',
  'マシンID',
  'オーダーID',
  'エラーコード',
  'エラーレベル',
  'メッセージ',
  '発生日時',
]
