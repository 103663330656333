import React from 'react'

import Iframe from 'react-iframe'
import styles from '../../styles/modules/sales-data/sales-data.module.scss'
import { SalesData } from '../../../core/sales-data'

interface Props {
  title: string
  salesData: SalesData
  height: string
}

const SalesDataFrame: React.FC<Props> = props => {
  const { title, salesData, height } = props
  return (
    <>
      <h3 className={styles.title}>{title}</h3>
      {salesData && (
        <div className={styles.wrapper}>
          <Iframe url={salesData.url} width="100%" height={height} />
        </div>
      )}
    </>
  )
}

export default SalesDataFrame
