import { Order } from '../orders'

export const powers = {
  On: 'on',
  Off: 'off',
  ShouldBeOn: 'should_be_on',
  ShouldBeOff: 'should_be_off',
} as const

export type Power = typeof powers[keyof typeof powers]

export const machineStatuses = {
  Active: 'active',
  ConfigMode: 'config_mode',
  FSMode: 'fs_mode',
  OperationMode: 'operation_mode',
  CriticalStop: 'critical_stop',
  StationStop: 'station_stop',
  ConnectionLost: 'communication_loss',
} as const

export type MachineStatus = typeof machineStatuses[keyof typeof machineStatuses]

// 通常営業している（=新規注文を受け付けている）マシンのステータス
export const activeMachineStatuses: MachineStatus[] = [
  machineStatuses.Active,
  machineStatuses.ConfigMode,
  machineStatuses.OperationMode,
]

// マシンのバージョンが3以上かどうかを判定する
export const isOverV3Version = (version: string) => {
  const matchStr = version.match(/\d/)
  if (!matchStr) {
    // この場合はバージョンが判断できないので適当にtrueを返す
    return true
  }
  // 最初の数字を取得し、整数に変換
  const firstDigit = parseInt(matchStr[0], 10)
  // 数字が3以上か3未満かをチェック
  if (firstDigit >= 3) {
    return true
  } else {
    return false
  }
}

export interface Machine {
  id: number
  serial: string
  status: MachineStatus
  power: Power
  stationID: number
  manufacturedDate: Date
  type: string
  version: string
  nucVersion: string
  factoryName: string
  lockerBoxes: LockerBox[]
  soracomSIM: {
    id: number
    imsi: string
  }
  shouldBeLatest: boolean
  updateAcceptedAt: Date
  unavailableBeanTypeIndexes: number[]
  isHotCupShortage: boolean
  isIceCupShortage: boolean
  isIceShortage: boolean
}

export interface LockerBox {
  id: number
  index: number
  axisX: number
  axisY: number
  order?: Order
  isDisable: boolean
  doorOpenStatus: string
  isFull: boolean
  lockerBoxComment?: LockerBoxComment
}

export interface LockerBoxComment {
  id: number
  comment: string
  isDeleted: boolean
  occurredAt: string
  changedAt?: string
}

export interface MachineRelationHistory {
  id: number
  machineID: number
  stationID: number
  soracomSIMID: number
  savedAt: string
  clearAt: string
}

export interface MachineRelationHistoriesParam {
  machineID?: number
  stationID?: number
  soracomSIMID?: number
}
