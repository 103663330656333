import React from 'react'
import classnames from 'classnames'
import { Station } from '../../../core/stations'
import { SoracomSIM } from '../../../core/soracom-sims'
import { Machine } from '../../../core/machines'
import styles from '../../styles/modules/table.module.scss'
import {
  strings,
  machineRelationsTableContent,
} from './machine-relations-content'
import machineRelationsTableStyles from '../../styles/modules/machine-relations/machine-relations-table.module.scss'
import Button from '../button'

interface Props {
  stations: Station[]
  soracomSIMs: SoracomSIM[]
  machines: Machine[]
  showEditMachineRelation: (
    stations: Station[],
    soracomSIMs: SoracomSIM[],
    machines: Machine[],
    machine: Machine
  ) => void
  clearMachineRelation: (machineId: number) => void
}

const createConfirmClearMachineRelationSentence = (machine: Machine) =>
  `
マシンID ${machine.id}の紐付けを解除します。
操作を確定する場合はテキストフィールドに「解除」と入力してください。

※注意
この操作は取り消すことができません。
紐付けを解除することでお客様に不利益を及ぼす可能性があります。
影響範囲を正しく理解した上で実行してください。
`

const MachineRelationsTable: React.FC<Props> = (props: Props) => {
  const handleOnClickEdit = (machineId: number) => {
    const machine = props.machines.find(i => i.id === Number(machineId))
    props.showEditMachineRelation(
      props.stations,
      props.soracomSIMs,
      props.machines,
      machine
    )
  }

  const printStationName = (stationID: number | null) => {
    if (stationID == null) {
      return strings.noData
    }
    return props.stations.find(station => station.id === stationID).name
  }

  const printSoracomSimID = (machineID: number) => {
    const targetSoracomSIM = props.soracomSIMs.find(
      soracomSIM => soracomSIM.machineID === machineID
    )
    return targetSoracomSIM ? targetSoracomSIM.id : strings.noData
  }

  const handleOnClickClearMachineRelation = (machine: Machine) => {
    const confirmClearMachineRelationSentence = createConfirmClearMachineRelationSentence(
      machine
    )
    const input = window.prompt(confirmClearMachineRelationSentence)
    if (input !== null) {
      if (input === '解除') {
        props.clearMachineRelation(machine.id)
      } else {
        handleOnClickClearMachineRelation(machine)
      }
    }
  }

  return (
    <>
      <div
        className={classnames({
          [styles.wrapper]: true,
          [styles.zebra]: true,
          [styles.stocker]: true,
        })}
      >
        <table
          className={classnames(
            styles.container,
            machineRelationsTableStyles.table
          )}
        >
          <tbody>
            <tr className={styles.row}>
              {machineRelationsTableContent.map(item => (
                <th key={item} className={styles.cell}>
                  {item}
                </th>
              ))}
            </tr>
            {props.machines
              .sort((a, b) => {
                if (a.id < b.id) {
                  return -1
                } else {
                  return 1
                }
              })
              .map(machine => (
                <tr className={styles.row} key={machine.id}>
                  <td className={styles.cell}>{machine.id}</td>
                  <td className={styles.cell}>{machine.serial}</td>
                  <td className={styles.cell}>
                    {printStationName(
                      machine.stationID ? machine.stationID : null
                    )}
                  </td>
                  <td className={styles.cell}>
                    {printSoracomSimID(machine.id)}
                  </td>
                  <td className={styles.cell}>
                    <Button
                      title={strings.editMachineRelationButton}
                      onClick={() => {
                        handleOnClickEdit(machine.id)
                      }}
                      className={machineRelationsTableStyles.container}
                    />
                  </td>
                  <td className={styles.cell}>
                    <Button
                      title={strings.deleteMachineRelationButton}
                      onClick={() => {
                        handleOnClickClearMachineRelation(machine)
                      }}
                      className={classnames(
                        machineRelationsTableStyles.container,
                        machineRelationsTableStyles.delete
                      )}
                    />
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </>
  )
}

export default MachineRelationsTable
