import React from 'react'
import { Link } from 'react-router-dom'

import styles from '../styles/modules/not-found.module.scss'

interface State {
  readonly mouseX: number
  readonly mouseY: number
}

export default class NotFoundScreen extends React.Component<{}, State> {
  readonly state = {
    mouseX: 0,
    mouseY: 0,
  }

  render() {
    return (
      <Link
        to={'/'}
        className={styles.container}
        onMouseMove={event =>
          this.setState({
            mouseX: event.nativeEvent.offsetX,
            mouseY: event.nativeEvent.offsetY,
          })
        }
      >
        <div className={styles.content}>
          <span
            className={styles.title}
            style={{
              backgroundPositionX: this.state.mouseX / 10,
              backgroundPositionY: this.state.mouseY / 10,
            }}
          >
            {'404'}
          </span>
        </div>
      </Link>
    )
  }
}
