import classnames from 'classnames'
import { PointItemOrder } from '../../../core/point-item-orders'
import pointItemOrdersTableStyles from '../../styles/modules/point-item-orders/point-item-orders-table.module.scss'
import styles from '../../styles/modules/table.module.scss'
import React from 'react'
// import Button from '../button'
import { Link } from 'react-router-dom'
import {
  strings,
  pointItemOrdersTableContent,
} from './point-item-orders-content'
import moment from 'moment'
import Button from '../button'

interface Props {
  pointItemOrders: PointItemOrder[]
  onClickComplete: (id: number) => void
  onClickCancel: (id: number) => void
  onClickReturn: (id: number) => void
}

const PointItemOrdersTable: React.FC<Props> = props => {
  return (
    <div
      className={classnames({
        [styles.wrapper]: true,
        [pointItemOrdersTableStyles.zebra]: true,
        [styles.stocker]: true,
      })}
    >
      <table
        className={classnames(
          styles.container,
          pointItemOrdersTableStyles.table
        )}
      >
        <tr className={styles.row}>
          {pointItemOrdersTableContent.map(pointItemSupportorder => (
            <th key={pointItemSupportorder} className={styles.cell}>
              {pointItemSupportorder}
            </th>
          ))}
        </tr>
        {props.pointItemOrders.map((order, i) =>
          order.pointItemOrderItems.map(orderItem => (
            <tr
              key={orderItem.id}
              className={classnames({
                [pointItemOrdersTableStyles.row]: true,
                [pointItemOrdersTableStyles.oddNumberedOrdersRow]: i % 2 === 1,
                [pointItemOrdersTableStyles.grayRow]:
                  order.status === 'canceled' || order.status === 'returned',
              })}
            >
              <th className={styles.cell}>{order.id}</th>
              <th className={styles.cell}>{orderItem.pointItem.id}</th>
              <th className={styles.cell}>{orderItem.pointItem.name}</th>
              <th className={styles.cell}>{orderItem.quantity}</th>
              <th className={styles.cell}>
                <Link
                  to={{
                    pathname: `/users/${order.userAddress.user.id}`,
                  }}
                >
                  {order.userAddress.user.id}
                </Link>
              </th>
              <th className={styles.cell}>
                {order.isFirstOrder ? strings.firstOrder : ''}
              </th>
              <th className={styles.cell}>{order.status}</th>
              <th className={styles.cell}>
                {order.status === 'not_completed' && (
                  <Button
                    onClick={() => props.onClickComplete(order.id)}
                    title={strings.completeButton}
                    className={pointItemOrdersTableStyles.button}
                  />
                )}
              </th>
              <th className={styles.cell}>
                {order.completedAt
                  ? moment(order.completedAt).format('YYYY/MM/DD HH:mm')
                  : '-'}
              </th>
              <th className={styles.cell}>{order.userAddress.postalCode}</th>
              <th className={styles.cell}>{order.userAddress.stateProvince}</th>
              <th className={styles.cell}>{order.userAddress.city}</th>
              <th className={styles.cell}>{order.userAddress.fullAddress}</th>
              <th className={styles.cell}>{order.userAddress.recipientName}</th>
              <th className={styles.cell}>{order.userAddress.phoneNumber}</th>
              <th className={styles.cell}>
                {order.status === 'not_completed' ? (
                  <Button
                    onClick={() => props.onClickCancel(order.id)}
                    title={strings.cancelButton}
                    className={classnames(
                      pointItemOrdersTableStyles.button,
                      pointItemOrdersTableStyles.cancelButton
                    )}
                  />
                ) : order.status === 'completed' ? (
                  <Button
                    onClick={() => props.onClickReturn(order.id)}
                    title={strings.returnButton}
                    className={classnames(
                      pointItemOrdersTableStyles.button,
                      pointItemOrdersTableStyles.returnButton
                    )}
                  />
                ) : (
                  ''
                )}
              </th>
            </tr>
          ))
        )}
      </table>
    </div>
  )
}

export default PointItemOrdersTable
