import axios from 'axios'

import { Item } from '../items'
import { OrdersParam } from '../orders'
import { SubscriptionParam } from '../subscription'
import { Campaign, CampaignParam } from '../campaigns'
import { ItemImageType } from '../items'
import { Station, StationImageType, TimeSlot } from '../stations'
import { LockerBox, MachineRelationHistoriesParam } from '../machines'
import { TimeSlotPreset } from '../time-slot-presets'
import {
  StationWithItemRanksParam,
  UpdateStationWithItemRanksBody,
} from '../station-with-item-ranks'

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
  timeout: 10000,
})

export async function getStations(token: string) {
  try {
    const response = await instance({
      method: 'get',
      url: '/stations',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    if (response.status === 200) {
      return response.data
    } else {
      throw new Error(response.data.message)
    }
  } catch (error) {
    throw error
  }
}

export async function getStation(token: string, stationId: number) {
  try {
    const response = await instance({
      method: 'get',
      url: `/stations/${stationId}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    if (response.status === 200) {
      return response.data
    } else {
      throw new Error(response.data.message)
    }
  } catch (error) {
    throw error
  }
}

export async function postStation(
  token: string,
  name: string,
  extra: string,
  fullAddress: string,
  openingHours: string,
  latitude: number,
  longitude: number
) {
  try {
    const response = await instance({
      method: 'post',
      url: `/stations`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        name,
        extra,
        fullAddress,
        openingHours,
        latitude,
        longitude,
      },
    })
    if (response.status === 200) {
      return response.data
    } else {
      throw new Error(response.data.message)
    }
  } catch (error) {
    throw error
  }
}

export async function startMachine(token: string, stationId: number) {
  try {
    const response = await instance({
      method: 'post',
      url: `/stations/${stationId}/start`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    if (response.status === 200) {
      return response.data
    } else {
      throw new Error(response.data.message)
    }
  } catch (error) {
    throw error
  }
}

export async function stopMachine(token: string, stationId: number) {
  try {
    const response = await instance({
      method: 'post',
      url: `/stations/${stationId}/stop`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    if (response.status === 200) {
      return response.data
    } else {
      throw new Error(response.data.message)
    }
  } catch (error) {
    throw error
  }
}

export async function changeStationItemAvailable(
  token: string,
  stationId: number,
  itemId: number
) {
  try {
    const response = await instance({
      method: 'post',
      url: `/stations/${stationId}/items/${itemId}/make_available`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    if (response.status === 200) {
      return response.data
    } else {
      throw new Error(response.data.message)
    }
  } catch (error) {
    throw error
  }
}

export async function changeStationItemUnavailable(
  token: string,
  stationId: number,
  itemId: number
) {
  try {
    const response = await instance({
      method: 'post',
      url: `/stations/${stationId}/items/${itemId}/make_unavailable`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    if (response.status === 200) {
      return response.data
    } else {
      throw new Error(response.data.message)
    }
  } catch (error) {
    throw error
  }
}

export async function changeStationItemUnavailableTemporarily(
  token: string,
  stationId: number,
  itemId: number
) {
  try {
    const response = await instance({
      method: 'post',
      url: `/stations/${stationId}/items/${itemId}/make_unavailable_temporarily`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    if (response.status === 200) {
      return response.data
    } else {
      throw new Error(response.data.message)
    }
  } catch (error) {
    throw error
  }
}

export async function changeStation(
  token: string,
  stationId: number,
  station: Station
) {
  try {
    const response = await instance({
      method: 'put',
      url: `/stations/${stationId}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        name: station.name,
        extra: station.extra,
        fullAddress: station.fullAddress,
        stationImageFileName: station.stationImageFileName,
        mapImageFileName: station.mapImageFileName,
        openingHours: station.openingHours,
        latitude: station.latitude,
        longitude: station.longitude,
        isClosed: station.isClosed,
        placedDate: station.placedDate,
        shippedDate: station.shippedDate,
        permittedDate: station.permittedDate,
        startOperationDate: station.startOperationDate,
        lastOperationDate: station.lastOperationDate,
        removedDate: station.removedDate,
        orderEnablingHours: station.orderEnablingHours,
        operationHours: station.operationHours,
        operationDays: station.operationDays,
      },
    })
    if (response.status === 200) {
      return response.data
    } else {
      throw new Error(response.data.message)
    }
  } catch (error) {
    throw error
  }
}

export async function presignStationImageUrl(
  token: string,
  stationId: number,
  stationImageType: StationImageType
) {
  try {
    const response = await instance({
      method: 'post',
      url: `/stations/${stationId}/images/presign`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        type: stationImageType,
      },
    })
    if (response.status === 200) {
      return response.data
    } else {
      throw new Error(response.data.message)
    }
  } catch (error) {
    throw error
  }
}

export async function getMonthlySalesData(token: string) {
  try {
    const response = await instance({
      method: 'get',
      url: `/monthly_sales_data`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    if (response.status === 200) {
      return response.data
    } else {
      throw new Error(response.data.message)
    }
  } catch (error) {
    throw error
  }
}

export async function getDailySalesData(token: string) {
  try {
    const response = await instance({
      method: 'get',
      url: `/daily_sales_data`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    if (response.status === 200) {
      return response.data
    } else {
      throw new Error(response.data.message)
    }
  } catch (error) {
    throw error
  }
}

export async function changeItem(token: string, itemId: number, item: Item) {
  try {
    const response = await instance({
      method: 'post',
      url: `/items/${itemId}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        price: item.price,
        name: item.name,
        disposalSeconds: item.disposalSeconds,
        imageFileName: item.imageFileName,
        thumbnailImageFileName: item.thumbnailImageFileName,
        description: item.description,
        story: item.story,
        tastes: item.tastes,
        features: item.features,
        machineButtonIndex: item.machineButtonIndex,
        beanTypeIndex: item.beanTypeIndex,
        beanAmount: item.beanAmount,
        matchItemParam: item.matchItemParam,
        color: item.color,
      },
    })
    if (response.status === 200) {
      return response.data
    } else {
      throw new Error(response.data.message)
    }
  } catch (error) {
    throw error
  }
}

export async function presignItemImageUrl(
  token: string,
  itemId: number,
  itemImageType: ItemImageType
) {
  try {
    const response = await instance({
      method: 'post',
      url: `/items/${itemId}/images/presign`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        type: itemImageType,
      },
    })
    if (response.status === 200) {
      return response.data
    } else {
      throw new Error(response.data.message)
    }
  } catch (error) {
    throw error
  }
}

export async function newItem(token: string, item: Item) {
  try {
    const response = await instance({
      method: 'post',
      url: `/items`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: item,
    })
    if (response.status === 200) {
      return response.data
    } else {
      throw new Error(response.data.message)
    }
  } catch (error) {
    throw error
  }
}

export async function updateItemsIndex(token: string, ids: number[]) {
  try {
    const response = await instance({
      method: 'put',
      url: `/items/index`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        ids,
      },
    })
    if (response.status === 200) {
      return response.data
    } else {
      throw new Error(response.data.message)
    }
  } catch (error) {
    throw error
  }
}

export async function solveEmergency(
  token: string,
  stationId: number,
  emergencyId: number
) {
  try {
    const response = await instance({
      method: 'post',
      url: `/stations/${stationId}/emergencies/${emergencyId}/resolve`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    if (response.status === 200) {
      return response
    } else {
      throw new Error(response.data.message)
    }
  } catch (error) {
    throw error
  }
}

export async function reportEmergency(
  token: string,
  stationId: number,
  message: string,
  canOpenLockerBox: boolean
) {
  try {
    const response = await instance({
      method: 'post',
      url: `/stations/${stationId}/emergencies`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        message,
        canOpenLockerBox,
      },
    })
    if (response.status === 200) {
      return response
    } else {
      throw new Error(response.data.message)
    }
  } catch (error) {
    throw error
  }
}

export async function fillIce(token: string, stationId: number) {
  try {
    const response = await instance({
      method: 'put',
      url: `/stations/${stationId}/ice/fill`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    if (response.status === 200) {
      return response
    } else {
      throw new Error(response.data.message)
    }
  } catch (error) {
    throw error
  }
}

export async function getOrders(token: string, param: OrdersParam) {
  try {
    const url =
      '/orders?' +
      `count=${param.count}` +
      `&offset=0` +
      `&date_from=${param.date_from}` +
      `&date_to=${param.date_to}` +
      (param.user_id ? `&user_id=${param.user_id}` : '') +
      (param.email ? `&email=${param.email}` : '') +
      (param.station_id ? `&station_id=${param.station_id}` : '') +
      (param.provision_status
        ? `&provision_status=${param.provision_status}`
        : '')
    const response = await instance({
      method: 'get',
      url,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    if (response.status === 200) {
      return response.data
    } else {
      throw new Error(response.data.message)
    }
  } catch (error) {
    throw error
  }
}

export async function cancelOrder(token: string, id: number) {
  try {
    const response = await instance({
      method: 'post',
      url: `/orders/${id}/cancel`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    if (response.status === 200) {
      return response.data
    } else {
      throw new Error(response.data.message)
    }
  } catch (error) {
    throw error
  }
}

export async function getItems(token: string) {
  try {
    const response = await instance({
      method: 'get',
      url: '/items',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    if (response.status === 200) {
      return response.data
    } else {
      throw new Error(response.data.message)
    }
  } catch (error) {
    throw error
  }
}

export async function getStationWithItemRanks(
  token: string,
  param: StationWithItemRanksParam
) {
  try {
    const url =
      '/station_item_ranks' +
      (param.station_id ? `?station_id=${param.station_id}` : '')
    const response = await instance({
      method: 'get',
      url,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    if (response.status === 200) {
      return response.data
    } else {
      throw new Error(response.data.message)
    }
  } catch (error) {
    throw error
  }
}

export async function putStationWithItemRanks(
  token: string,
  body: UpdateStationWithItemRanksBody
) {
  try {
    const response = await instance({
      method: 'put',
      url: `/station_item_ranks`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: body,
    })
    if (response.status === 200) {
      return response.data
    } else {
      throw new Error(response.data.message)
    }
  } catch (error) {
    throw error
  }
}

export async function getRole(token: string) {
  try {
    const response = await instance({
      method: 'get',
      url: '/me',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    if (response.status === 200) {
      return response.data
    } else {
      throw new Error(response.data.message)
    }
  } catch (error) {
    throw error
  }
}

export async function getCampaigns(token: string, param: CampaignParam) {
  try {
    const types =
      param && param.types.reduce((acc, cur) => `${acc}&types=${cur}`, '')
    const url =
      '/campaigns?count=100&offset=0' +
      (param && param.issuedFrom ? `&issued_from=${param.issuedFrom}` : '') +
      (param && param.issuedTo ? `&issued_to=${param.issuedTo}` : '') +
      (param && param.expiresFrom ? `&expires_from=${param.expiresFrom}` : '') +
      (param && param.expiresTo ? `&expires_to=${param.expiresTo}` : '') +
      (param && param.status ? `&status=${param.status}` : '') +
      types
    const response = await instance({
      method: 'get',
      url,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    if (response.status === 200) {
      return response.data
    } else {
      throw new Error(response.data.message)
    }
  } catch (error) {
    throw error
  }
}

export async function newCampaign(
  token: string,
  code: string,
  name: string,
  discountAmount: number,
  memo: string,
  availableCount: number,
  entryCountLimit: number,
  expiresAt?: string,
  eligibleStationIds?: number[]
) {
  try {
    const response = await instance({
      method: 'post',
      url: `/promotion_campaigns`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        code,
        name,
        discountAmount,
        memo,
        availableCount,
        entryCountLimit,
        expiresAt,
        eligibleStationIds,
      },
    })
    if (response.status === 200) {
      return response.data
    } else {
      throw new Error(response.data.message)
    }
  } catch (error) {
    throw error
  }
}

export async function changeCampaign(
  token: string,
  campaignId: number,
  campaign: Campaign
) {
  try {
    const response = await instance({
      method: 'put',
      url: `/campaigns/${campaignId}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        discountAmount: campaign.discountAmount,
        duration: campaign.duration,
        durationUnit: campaign.durationUnit,
        name: campaign.name,
        memo: campaign.memo,
        status: campaign.status,
      },
    })
    if (response.status === 200) {
      return response.data
    } else {
      throw new Error(response.data.message)
    }
  } catch (error) {
    throw error
  }
}

export async function changeCampaignExpiresAt(
  token: string,
  campaignId: number
) {
  try {
    const response = await instance({
      method: 'put',
      url: `/campaigns/${campaignId}/expire`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    if (response.status === 200) {
      return response.data
    } else {
      throw new Error(response.data.message)
    }
  } catch (error) {
    throw error
  }
}

export async function changeCampaignStatus(
  token: string,
  campaignId: number,
  status: string
) {
  try {
    const response = await instance({
      method: 'put',
      url: `/campaigns/${campaignId}/status`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        status,
      },
    })
    if (response.status === 200) {
      return response.data
    } else {
      throw new Error(response.data.message)
    }
  } catch (error) {
    throw error
  }
}

export async function openAllLockerBoxes(token: string, stationId: number) {
  try {
    const response = await instance({
      method: 'post',
      url: `/stations/${stationId}/open_all_locker_boxes`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    if (response.status === 200) {
      return response.data
    } else {
      throw new Error(response.data.message)
    }
  } catch (error) {
    throw error
  }
}

export async function openTimeSlot(
  token: string,
  stationId: number,
  timeSlotId: number
) {
  try {
    const response = await instance({
      method: 'post',
      url: `/stations/${stationId}/time_slots/${timeSlotId}/open`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    if (response.status === 200) {
      return response.data
    } else {
      throw new Error(response.data.message)
    }
  } catch (error) {
    throw error
  }
}

export async function closeTimeSlot(
  token: string,
  stationId: number,
  timeSlotId: number
) {
  try {
    const response = await instance({
      method: 'post',
      url: `/stations/${stationId}/time_slots/${timeSlotId}/close`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    if (response.status === 200) {
      return response.data
    } else {
      throw new Error(response.data.message)
    }
  } catch (error) {
    throw error
  }
}

export async function updateTimeSlots(
  token: string,
  stationId: number,
  timeSlots: TimeSlot[]
) {
  try {
    const response = await instance({
      method: 'put',
      url: `/stations/${stationId}/time_slots`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        timeSlots,
      },
    })
    if (response.status === 200) {
      return response.data
    } else {
      throw new Error(response.data.message)
    }
  } catch (error) {
    throw error
  }
}

export async function getPromotionImages(token: string) {
  try {
    const response = await instance({
      method: 'get',
      url: `/promotion_images`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    if (response.status === 200) {
      return response.data
    } else {
      throw new Error(response.data.message)
    }
  } catch (error) {
    throw error
  }
}

export async function newPromotionImage(
  token: string,
  fileName: string,
  linkUrl: string,
  title: string,
  description: string
) {
  try {
    const response = await instance({
      method: 'post',
      url: `/promotion_images`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        fileName,
        linkUrl,
        title,
        description,
      },
    })
    if (response.status === 200) {
      return response.data
    } else {
      throw new Error(response.data.message)
    }
  } catch (error) {
    throw error
  }
}

export async function presignPromotionImageUrl(token: string) {
  try {
    const response = await instance({
      method: 'post',
      url: `/promotion_images/presign`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    if (response.status === 200) {
      return response.data
    } else {
      throw new Error(response.data.message)
    }
  } catch (error) {
    throw error
  }
}

export async function putPromotionImage(
  token: string,
  id: number,
  linkUrl: string,
  title: string,
  description: string,
  fileName?: string
) {
  try {
    const response = await instance({
      method: 'put',
      url: `/promotion_images/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        linkUrl,
        title,
        description,
        fileName,
      },
    })
    if (response.status === 200) {
      return response.data
    } else {
      throw new Error(response.data.message)
    }
  } catch (error) {
    throw error
  }
}

export async function updatePromotionImageIndex(token: string, ids: number[]) {
  try {
    const response = await instance({
      method: 'put',
      url: `/promotion_images/index`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        ids,
      },
    })
    if (response.status === 200) {
      return response.data
    } else {
      throw new Error(response.data.message)
    }
  } catch (error) {
    throw error
  }
}

export async function deletePromotionImage(token: string, id: number) {
  try {
    const response = await instance({
      method: 'delete',
      url: `/promotion_images/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    if (response.status === 200) {
      return response.data
    } else {
      throw new Error(response.data.message)
    }
  } catch (error) {
    throw error
  }
}

export async function getSignageBanners(token: string) {
  try {
    const response = await instance({
      method: 'get',
      url: `/signage_banners`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    if (response.status === 200) {
      return response.data
    } else {
      throw new Error(response.data.message)
    }
  } catch (error) {
    throw error
  }
}

export async function newSignageBanner(
  token: string,
  fileName: string,
  title: string,
  mimeType: string,
  duration: number,
  adCaption: string
) {
  try {
    const response = await instance({
      method: 'post',
      url: `/signage_banners`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        fileName,
        title,
        mimeType,
        duration,
        adCaption,
      },
    })
    if (response.status === 200) {
      return response.data
    } else {
      throw new Error(response.data.message)
    }
  } catch (error) {
    throw error
  }
}

export async function uploadSignageBanner(
  token: string,
  base64File: string,
  mimeType: string,
  fileName: string
) {
  try {
    const response = await instance({
      method: 'post',
      url: `/signage_banners/upload`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: {
        base64File,
        mimeType,
        fileName,
      },
    })

    if (response.status === 200) {
      return response.data
    } else {
      throw new Error(response.data.message)
    }
  } catch (error) {
    throw error
  }
}

export async function presignSignageBannerUrl(
  token: string,
  extension: string
) {
  try {
    const response = await instance({
      method: 'post',
      url: `/signage_banners/presign`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        extension,
      },
    })
    if (response.status === 200) {
      return response.data
    } else {
      throw new Error(response.data.message)
    }
  } catch (error) {
    throw error
  }
}

export async function deleteSignageBanner(token: string, id: number) {
  try {
    const response = await instance({
      method: 'delete',
      url: `/signage_banners/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    if (response.status === 200) {
      return response.data
    } else {
      throw new Error(response.data.message)
    }
  } catch (error) {
    throw error
  }
}

export async function getSignageBannerSequences(token: string) {
  try {
    const response = await instance({
      method: 'get',
      url: `/signage_banner_sequences`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    if (response.status === 200) {
      return response.data
    } else {
      throw new Error(response.data.message)
    }
  } catch (error) {
    throw error
  }
}

export async function sortSignageBannerSequences(
  token: string,
  signageBannerSequenceIds: number[]
) {
  try {
    const response = await instance({
      method: 'put',
      url: `/signage_banner_sequences/sort`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        signageBannerSequenceIds,
      },
    })
    if (response.status === 200) {
      return response.data
    } else {
      throw new Error(response.data.message)
    }
  } catch (error) {
    throw error
  }
}

export async function putSignageBannerSequence(
  token: string,
  id: number,
  duration: number,
  isShow: boolean
) {
  try {
    const response = await instance({
      method: 'put',
      url: `/signage_banner_sequences/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        duration,
        isShow,
      },
    })
    if (response.status === 200) {
      return response.data
    } else {
      throw new Error(response.data.message)
    }
  } catch (error) {
    throw error
  }
}

export async function getUserById(token: string, id: number) {
  try {
    const response = await instance({
      method: 'get',
      url: `/users/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    if (response.status === 200) {
      return response.data
    } else {
      throw new Error(response.data.message)
    }
  } catch (error) {
    throw error
  }
}

export async function getUserByEmail(token: string, email: string) {
  try {
    const response = await instance({
      method: 'get',
      url: `/users?email=${email}&count=1&offset=0`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    if (response.status === 200) {
      return response.data
    } else {
      throw new Error(response.data.message)
    }
  } catch (error) {
    throw error
  }
}

export async function deleteUser(token: string, id: number) {
  try {
    const response = await instance({
      method: 'delete',
      url: `/users/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    if (response.status === 200) {
      return response.data
    } else {
      throw new Error(response.data.message)
    }
  } catch (error) {
    throw error
  }
}

export async function getSubscriptions(
  token: string,
  param: SubscriptionParam
) {
  try {
    const url =
      '/subscriptions?offset=0' +
      (param && param.count ? `&count=${param.count}` : '') +
      (param && param.user_id ? `&user_id=${param.user_id}` : '')
    const response = await instance({
      method: 'get',
      url,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    if (response.status === 200) {
      return response.data
    } else {
      throw new Error(response.data.message)
    }
  } catch (error) {
    throw error
  }
}

export async function getSubscription(token: string, id: number) {
  try {
    const response = await instance({
      method: 'get',
      url: `/subscriptions/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    if (response.status === 200) {
      return response.data
    } else {
      throw new Error(response.data.message)
    }
  } catch (error) {
    throw error
  }
}

export async function getEmergencies(token: string) {
  try {
    const response = await instance({
      method: 'get',
      url: '/emergencies?count=1000&offset=0',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    if (response.status === 200) {
      return response.data
    } else {
      throw new Error(response.data.message)
    }
  } catch (error) {
    throw error
  }
}

export async function getMachineErrors(token: string) {
  try {
    const response = await instance({
      method: 'get',
      url: '/machine_errors',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    if (response.status === 200) {
      return response.data
    } else {
      throw new Error(response.data.message)
    }
  } catch (error) {
    throw error
  }
}

export async function getCameraStreamingViewerCredentials(token: string) {
  try {
    const response = await instance({
      method: 'get',
      url: '/camera_streaming_viewer_credentials',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    if (response.status === 200) {
      return response.data
    } else {
      throw new Error(response.data.message)
    }
  } catch (error) {
    throw error
  }
}

export async function getPointTransactions(token: string, userId: number) {
  try {
    const response = await instance({
      method: 'get',
      url: `/users/${userId}/point_transactions`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    if (response.status === 200) {
      return response.data
    } else {
      throw new Error(response.data.message)
    }
  } catch (error) {
    throw error
  }
}

export async function givePointsToUser(
  token: string,
  userId: number,
  point: number,
  description: string,
  calculationType: 'give' | 'expire'
) {
  try {
    const response = await instance({
      method: 'post',
      url: `/points`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        userId,
        point,
        description,
        calculationType,
      },
    })
    if (response.status === 200) {
      return response.data
    } else {
      throw new Error(response.data.message)
    }
  } catch (error) {
    throw error
  }
}

export async function getStationAlerts(token: string) {
  try {
    const response = await instance({
      method: 'get',
      url: `/station_alerts`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    if (response.status === 200) {
      return response.data
    } else {
      throw new Error(response.data.message)
    }
  } catch (error) {
    throw error
  }
}

export async function postStationAlert(
  token: string,
  stationId: number,
  title: string,
  body: string
) {
  try {
    const response = await instance({
      method: 'post',
      url: `/station_alerts`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        stationId,
        title,
        body,
      },
    })
    if (response.status === 200) {
      return response.data
    } else {
      throw new Error(response.data.message)
    }
  } catch (error) {
    throw error
  }
}

export async function putStationAlert(
  token: string,
  id: number,
  stationId: number,
  title: string,
  body: string
) {
  try {
    const response = await instance({
      method: 'put',
      url: `/station_alerts/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        stationId,
        title,
        body,
      },
    })
    if (response.status === 200) {
      return response.data
    } else {
      throw new Error(response.data.message)
    }
  } catch (error) {
    throw error
  }
}

export async function deleteStationAlert(token: string, id: number) {
  try {
    const response = await instance({
      method: 'delete',
      url: `/station_alerts/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    if (response.status === 200) {
      return response.data
    } else {
      throw new Error(response.data.message)
    }
  } catch (error) {
    throw error
  }
}

export async function restoreStationAlert(token: string, id: number) {
  try {
    const response = await instance({
      method: 'put',
      url: `/station_alerts/${id}/restore`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    if (response.status === 200) {
      return response.data
    } else {
      throw new Error(response.data.message)
    }
  } catch (error) {
    throw error
  }
}

export async function changeStationLockerBoxAvailable(
  token: string,
  stationId: number,
  lockerBoxId: number
) {
  try {
    const response = await instance({
      method: 'put',
      url: `/stations/${stationId}/locker_boxes/${lockerBoxId}/make_available`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    if (response.status === 200) {
      return response.data
    } else {
      throw new Error(response.data.message)
    }
  } catch (error) {
    throw error
  }
}

export async function changeStationLockerBoxUnavailable(
  token: string,
  stationId: number,
  lockerBoxId: number
) {
  try {
    const response = await instance({
      method: 'put',
      url: `/stations/${stationId}/locker_boxes/${lockerBoxId}/make_unavailable`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    if (response.status === 200) {
      return response.data
    } else {
      throw new Error(response.data.message)
    }
  } catch (error) {
    throw error
  }
}

export async function lockerBoxOpener(
  token: string,
  stationId: number,
  lockerBoxId: number
) {
  try {
    const response = await instance({
      method: 'put',
      url: `/stations/${stationId}/locker_boxes/${lockerBoxId}/open`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    if (response.status === 200) {
      return response.data
    } else {
      throw new Error(response.data.message)
    }
  } catch (error) {
    throw error
  }
}

export async function deleteCoffeeLogOfLockerBox(
  token: string,
  stationId: number,
  lockerBox: LockerBox
) {
  try {
    const coffeeLogId =
      lockerBox.order && lockerBox.order.coffeeLog
        ? lockerBox.order.coffeeLog.id
        : null
    const response = await instance({
      method: 'put',
      url: `/stations/${stationId}/locker_boxes/${lockerBox.id}/clear`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        coffeeLogId,
      },
    })
    if (response.status === 200) {
      return response.data
    } else {
      throw new Error(response.data.message)
    }
  } catch (error) {
    throw error
  }
}

export async function newLockerBoxComment(
  token: string,
  stationId: number,
  lockerBoxId: number,
  comment: string
) {
  try {
    const response = await instance({
      method: 'post',
      url: `/stations/${stationId}/locker_boxes/${lockerBoxId}/comment`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        comment,
      },
    })
    if (response.status === 200) {
      return response.data
    } else {
      throw new Error(response.data.message)
    }
  } catch (error) {
    throw error
  }
}

export async function changeLockerBoxComment(
  token: string,
  stationId: number,
  lockerBoxId: number,
  lockerBoxCommentId: number,
  comment: string
) {
  try {
    const response = await instance({
      method: 'put',
      url: `/stations/${stationId}/locker_boxes/${lockerBoxId}/comments/${lockerBoxCommentId}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        comment,
      },
    })
    if (response.status === 200) {
      return response.data
    } else {
      throw new Error(response.data.message)
    }
  } catch (error) {
    throw error
  }
}

export async function deleteLockerBoxComment(
  token: string,
  stationId: number,
  lockerBoxId: number,
  lockerBoxCommentId: number
) {
  try {
    const response = await instance({
      method: 'delete',
      url: `/stations/${stationId}/locker_boxes/${lockerBoxId}/comments/${lockerBoxCommentId}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    if (response.status === 200) {
      return response.data
    } else {
      throw new Error(response.data.message)
    }
  } catch (error) {
    throw error
  }
}

export async function getSoracomSIMs(token: string) {
  try {
    const response = await instance({
      method: 'get',
      url: '/soracom_sims',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    if (response.status === 200) {
      return response.data
    } else {
      throw new Error(response.data.message)
    }
  } catch (error) {
    throw error
  }
}

export async function postSoracomSIM(
  token: string,
  imsi: string,
  serial: string
) {
  try {
    const response = await instance({
      method: 'post',
      url: `/soracom_sims`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        imsi,
        serial,
      },
    })
    if (response.status === 200) {
      return response.data
    } else {
      throw new Error(response.data.message)
    }
  } catch (error) {
    throw error
  }
}

export async function putSoracomSIM(
  token: string,
  id: number,
  imsi: string,
  serial: string
) {
  try {
    const response = await instance({
      method: 'put',
      url: `/soracom_sims/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        imsi,
        serial,
      },
    })
    if (response.status === 200) {
      return response.data
    } else {
      throw new Error(response.data.message)
    }
  } catch (error) {
    throw error
  }
}

export async function getMachineEmbeddedComputers(token: string) {
  try {
    const response = await instance({
      method: 'get',
      url: '/machine_embedded_computers',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    if (response.status === 200) {
      return response.data
    } else {
      throw new Error(response.data.message)
    }
  } catch (error) {
    throw error
  }
}

export async function postMachineEmbeddedComputer(token: string) {
  try {
    const response = await instance({
      method: 'post',
      url: `/machine_embedded_computers`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    if (response.status === 200) {
      return response.data
    } else {
      throw new Error(response.data.message)
    }
  } catch (error) {
    throw error
  }
}

export async function deleteMachineEmbeddedComputer(token: string, id: number) {
  try {
    const response = await instance({
      method: 'delete',
      url: `/machine_embedded_computers/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    if (response.status === 200) {
      return response.data
    } else {
      throw new Error(response.data.message)
    }
  } catch (error) {
    throw error
  }
}

export async function getMachines(token: string) {
  try {
    const response = await instance({
      method: 'get',
      url: '/machines',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    if (response.status === 200) {
      return response.data
    } else {
      throw new Error(response.data.message)
    }
  } catch (error) {
    throw error
  }
}

export async function postMachine(
  token: string,
  serial: string,
  manufacturedDate: Date,
  type: string,
  version: string,
  factoryName: string
) {
  try {
    const response = await instance({
      method: 'post',
      url: `/machines`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        serial,
        manufacturedDate,
        type,
        version,
        factoryName,
      },
    })
    if (response.status === 200) {
      return response.data
    } else {
      throw new Error(response.data.message)
    }
  } catch (error) {
    throw error
  }
}

export async function putMachine(
  token: string,
  id: number,
  serial: string,
  manufacturedDate: Date,
  type: string,
  version: string,
  factoryName: string,
  shouldBeLatest: boolean
) {
  try {
    const response = await instance({
      method: 'put',
      url: `/machines/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        serial,
        manufacturedDate,
        type,
        version,
        factoryName,
        shouldBeLatest,
      },
    })
    if (response.status === 200) {
      return response.data
    } else {
      throw new Error(response.data.message)
    }
  } catch (error) {
    throw error
  }
}

export async function getMachineRelationHistories(
  token: string,
  param: MachineRelationHistoriesParam
) {
  try {
    const url =
      '/machine_relation_histories?' +
      (param.machineID ? `&machineID=${param.machineID}` : '') +
      (param.stationID ? `&stationID=${param.stationID}` : '') +
      (param.soracomSIMID ? `&soracomSIMID=${param.soracomSIMID}` : '')
    const response = await instance({
      method: 'get',
      url,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    if (response.status === 200) {
      return response.data
    } else {
      throw new Error(response.data.message)
    }
  } catch (error) {
    throw error
  }
}

export async function putMachineRelation(
  token: string,
  id: number,
  stationId: number,
  soracomSIMId: number
) {
  try {
    const response = await instance({
      method: 'put',
      url: `/machines/${id}/relation`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        stationId,
        soracomSIMId,
      },
    })
    if (response.status === 200) {
      return response.data
    } else {
      throw new Error(response.data.message)
    }
  } catch (error) {
    throw error
  }
}

export async function putKioskRelation(
  token: string,
  id: number,
  stationId: number,
  soracomSIMId: number
) {
  try {
    const response = await instance({
      method: 'put',
      url: `/kiosks/${id}/relation`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        stationId,
        soracomSIMId,
      },
    })
    if (response.status === 200) {
      return response.data
    } else {
      throw new Error(response.data.message)
    }
  } catch (error) {
    throw error
  }
}

export async function deleteMachineRelation(token: string, id: number) {
  try {
    const response = await instance({
      method: 'delete',
      url: `/machines/${id}/relation`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    if (response.status === 200) {
      return response.data
    } else {
      throw new Error(response.data.message)
    }
  } catch (error) {
    throw error
  }
}

export async function deleteKioskRelation(token: string, id: number) {
  try {
    const response = await instance({
      method: 'delete',
      url: `/kiosks/${id}/relation`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    if (response.status === 200) {
      return response.data
    } else {
      throw new Error(response.data.message)
    }
  } catch (error) {
    throw error
  }
}

export async function getTimeSlotPresets(token: string, stationId: number) {
  try {
    const response = await instance({
      method: 'get',
      url: `/stations/${stationId}/time_slot_presets`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    if (response.status === 200) {
      return response.data
    } else {
      throw new Error(response.data.message)
    }
  } catch (error) {
    throw error
  }
}

export async function putTimeSlotPresets(
  token: string,
  stationId: number,
  timeSlotPresets: TimeSlotPreset[]
) {
  try {
    const response = await instance({
      method: 'put',
      url: `/stations/${stationId}/time_slot_presets`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        timeSlotPresets,
      },
    })
    if (response.status === 200) {
      return response.data
    } else {
      throw new Error(response.data.message)
    }
  } catch (error) {
    throw error
  }
}

export async function getKiosks(token: string) {
  try {
    const response = await instance({
      method: 'get',
      url: `/kiosks`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    if (response.status === 200) {
      return response.data
    } else {
      throw new Error(response.data.message)
    }
  } catch (error) {
    throw error
  }
}

export async function postKiosk(token: string, serial: string) {
  try {
    const response = await instance({
      method: 'post',
      url: `/kiosks`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        serial,
      },
    })
    if (response.status === 200) {
      return response.data
    } else {
      throw new Error(response.data.message)
    }
  } catch (error) {
    throw error
  }
}

export async function getKioskItemPrices(token: string, stationId: number) {
  try {
    const response = await instance({
      method: 'get',
      url: `/kiosk_item_prices?station_id=${stationId}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    if (response.status === 200) {
      return response.data
    } else {
      throw new Error(response.data.message)
    }
  } catch (error) {
    throw error
  }
}

export async function putKioskItemPrice(
  token: string,
  kioskItemPriceId: number,
  price: number
) {
  try {
    const response = await instance({
      method: 'put',
      url: `/kiosk_item_prices/${kioskItemPriceId}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        price,
      },
    })
    if (response.status === 200) {
      return response.data
    } else {
      throw new Error(response.data.message || response.data.error)
    }
  } catch (error) {
    throw error
  }
}

export async function getPointItems(token: string) {
  try {
    const response = await instance({
      method: 'get',
      url: '/point_items',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    if (response.status === 200) {
      return response.data
    } else {
      throw new Error(response.data.message)
    }
  } catch (error) {
    throw error
  }
}

export async function presignPointItemImageUrl(token: string) {
  try {
    const response = await instance({
      method: 'post',
      url: `/point_item_images/presign`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    if (response.status === 200) {
      return response.data
    } else {
      throw new Error(response.data.message)
    }
  } catch (error) {
    throw error
  }
}

export async function newPointItem(
  token: string,
  name: string,
  imageFileName: string,
  isDeliveryRequired: boolean,
  pointsPrice: number,
  stock: number,
  isAvailable: boolean,
  isInfiniteStock: boolean
) {
  try {
    const response = await instance({
      method: 'post',
      url: `/point_items`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        name,
        imageFileName,
        isDeliveryRequired,
        pointsPrice,
        stock,
        isAvailable,
        isInfiniteStock,
      },
    })
    if (response.status === 200) {
      return response.data
    } else {
      throw new Error(response.data.message)
    }
  } catch (error) {
    throw error
  }
}

export async function putPointItem(
  token: string,
  id: number,
  imageFileName: string,
  name: string,
  isDeliveryRequired: boolean,
  pointsPrice: number,
  stock: number,
  isAvailable: boolean,
  isInfiniteStock: boolean
) {
  try {
    const response = await instance({
      method: 'put',
      url: `/point_items/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        imageFileName,
        name,
        isDeliveryRequired,
        pointsPrice,
        stock,
        isAvailable,
        isInfiniteStock,
      },
    })
    if (response.status === 200) {
      return response.data
    } else {
      throw new Error(response.data.message)
    }
  } catch (error) {
    throw error
  }
}

export async function sortPointItems(token: string, ids: number[]) {
  try {
    const response = await instance({
      method: 'put',
      url: `/point_items/sort`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        ids,
      },
    })
    if (response.status === 200) {
      return response.data
    } else {
      throw new Error(response.data.message)
    }
  } catch (error) {
    throw error
  }
}

export async function getPointItemSupportLogs(token: string) {
  try {
    const response = await instance({
      method: 'get',
      url: '/point_item_support_logs',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    if (response.status === 200) {
      return response.data
    } else {
      throw new Error(response.data.message)
    }
  } catch (error) {
    throw error
  }
}

export async function getPointItemOrders(token: string) {
  try {
    const response = await instance({
      method: 'get',
      url: '/point_item_orders',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    if (response.status === 200) {
      return response.data
    } else {
      throw new Error(response.data.message)
    }
  } catch (error) {
    throw error
  }
}

export async function completePointItemOrder(token: string, id: number) {
  try {
    const response = await instance({
      method: 'put',
      url: `/point_item_orders/${id}/complete`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    if (response.status === 200) {
      return response.data
    } else {
      throw new Error(response.data.message)
    }
  } catch (error) {
    throw error
  }
}

export async function cancelPointItemOrder(token: string, id: number) {
  try {
    const response = await instance({
      method: 'put',
      url: `/point_item_orders/${id}/cancel`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    if (response.status === 200) {
      return response.data
    } else {
      throw new Error(response.data.message)
    }
  } catch (error) {
    throw error
  }
}

export async function returnPointItemOrder(token: string, id: number) {
  try {
    const response = await instance({
      method: 'put',
      url: `/point_item_orders/${id}/return`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    if (response.status === 200) {
      return response.data
    } else {
      throw new Error(response.data.message)
    }
  } catch (error) {
    throw error
  }
}
