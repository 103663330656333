import React, { useState } from 'react'

import Button from '../button'
import Select from '../select'
import styles from '../../styles/modules/modal/modal.module.scss'
import { Campaign, CampaignStatus } from '../../../core/campaigns/campaigns'
import { strings } from './modal-content'

interface Props {
  campaignId: number
  promotionCampaign: Campaign
  isLoading: boolean
  setPromotionCampaign: (campaignId: number, campaign: Campaign) => void
}

export const EditPromotionCampaignStatusForm: React.FC<Props> = ({
  campaignId,
  promotionCampaign,
  isLoading,
  setPromotionCampaign,
}) => {
  const [campaign, setCampaign] = useState<Campaign>(promotionCampaign)

  const handleOnClickSubmit = () => {
    setPromotionCampaign(campaignId, campaign)
  }

  const optionsStr =
    promotionCampaign.status === CampaignStatus.Draft
      ? [CampaignStatus.Draft, CampaignStatus.Live, CampaignStatus.Closed]
      : [CampaignStatus.Live, CampaignStatus.Closed]

  const options = optionsStr.map(o => ({
    label: o,
    value: o,
  }))

  return (
    <main className={styles.main}>
      <Select
        onChange={event =>
          setCampaign({
            ...campaign,
            status: event.target.value as CampaignStatus,
          })
        }
        label={strings.campaign.status}
        value={campaign.status}
        options={options}
      />
      <Button
        title={strings.button}
        onClick={handleOnClickSubmit}
        className={styles.decide}
        isLoading={isLoading}
      />
    </main>
  )
}
