import React, { useState } from 'react'

import Button from '../button'
import Input from '../input'
import styles from '../../styles/modules/modal/modal.module.scss'
import { strings } from './modal-content'
import { Config } from '../../../core/toast'

interface Props {
  isLoading: boolean
  userId: number
  createPoints: (
    userId: number,
    point: number,
    description: string,
    calculationType: 'give' | 'expire'
  ) => void
  show: (config: Config) => void
}

export const CreateExpirePointsForm: React.FC<Props> = (props: Props) => {
  const [inputPoint, setInputPoint] = useState<string>('')
  const [description, setDescription] = useState(
    strings.point.defaultExpireDescription
  )

  const handleOnClickSubmit = () => {
    const point = parseInt(inputPoint, 10)
    if (!props.userId || isNaN(point) || point < 1 || !description) {
      const config: Config = {
        type: 'error',
        title: strings.toastError.title,
        body: strings.toastError.body,
      }
      props.show(config)
      return
    }
    const response = window.confirm(strings.point.expirePointsMessage)
    if (response) {
      props.createPoints(props.userId, point, description, 'expire')
    }
  }

  return (
    <main className={styles.main}>
      <Input
        label={strings.point.userId}
        type={'text'}
        value={props.userId}
        readOnly
      />
      <Input
        label={strings.point.expirePoint}
        type={'text'}
        onChange={event => {
          setInputPoint(event.target.value)
        }}
        value={inputPoint}
      />
      <Input
        label={strings.point.description}
        type={'text'}
        onChange={event => {
          setDescription(event.target.value)
        }}
        value={description}
      />
      <Button
        title={strings.point.expireButton}
        onClick={() => handleOnClickSubmit()}
        className={styles.report}
        isLoading={props.isLoading}
        loadingClassName={styles.loadingReport}
      />
    </main>
  )
}
