import React from 'react'
import classnames from 'classnames'

import styles from '../../styles/modules/header/header-tooltip.module.scss'

interface Props {
  isVisible: boolean
  onClickLogout: () => void
}

const HeaderTooltip: React.FC<Props> = props => (
  <nav
    className={classnames({
      [styles.container]: true,
      [styles.visible]: props.isVisible,
    })}
  >
    <button onClick={() => props.onClickLogout()} className={styles.item}>
      <span>{'ログアウト'}</span>
    </button>
  </nav>
)

export default HeaderTooltip
