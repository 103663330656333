import React, { useState } from 'react'

import Button from '../button'
import Input from '../input'
import styles from '../../styles/modules/modal/modal.module.scss'
import { strings } from './modal-content'
import { Config } from '../../../core/toast'

interface Props {
  isLoading: boolean
  editSignageBannerSequenceData: {
    id: number
    duration: number
    isShow: boolean
  }
  updateSignageBannerDuration: (id: number, duration: number, isShow) => void
  show: (config: Config) => void
}

export const EditSignageBannerDurationForm: React.FC<Props> = ({
  isLoading,
  editSignageBannerSequenceData,
  updateSignageBannerDuration,
  show,
}) => {
  const [sequenceData, setSequenceData] = useState<string>(
    editSignageBannerSequenceData.duration.toString()
  )

  const handleOnClickSubmit = (id: number, index: string, isShow: boolean) => {
    if (isNaN(Number(index)) || !editSignageBannerSequenceData.duration) {
      const config: Config = {
        type: 'error',
        title: strings.toastError.title,
        body: strings.toastError.body,
      }
      show(config)
      return
    }
    updateSignageBannerDuration(id, Number(index), isShow)
  }

  return (
    <main className={styles.main}>
      <Input
        label={strings.signageBannerSequence.duration}
        type={'text'}
        onChange={event => {
          setSequenceData(event.target.value)
        }}
        value={sequenceData}
      />
      <Button
        title={strings.signageBannerSequence.button}
        onClick={() =>
          handleOnClickSubmit(
            editSignageBannerSequenceData.id,
            sequenceData,
            editSignageBannerSequenceData.isShow
          )
        }
        className={styles.report}
        isLoading={isLoading}
        loadingClassName={styles.loadingReport}
      />
    </main>
  )
}
