import React from 'react'
import { Dispatch } from 'redux'
import { connect } from "react-redux";

import RootState from '../../core/root-state'
import Layout from '../layouts/layout'
import Wrapper from '../layouts/wrapper'

import { modalActions } from '../../core/modal';
import { SoracomSIM, soracomSIMsActions } from '../../core/soracom-sims';
import SoracomSIMIndex from '../components/soracom-sims/soracom-sims-index';

interface Props {
  soracomSIMs: SoracomSIM[]
  isLoading: boolean
  fetchSoracomSIMs: () => void
  showCreateSoracomSIM: () => void
  showEditSoracomSIM: (soracomSIM: SoracomSIM) => void
}

class SoracomSIMsScreen extends React.Component<Props> {
  componentDidMount = () => {
    this.props.fetchSoracomSIMs()
  }

  render() {
    return (
      <Layout location='soracom_sims'>
        <Wrapper title='SIM'>
          <SoracomSIMIndex
            soracomSIMs={this.props.soracomSIMs}
            showCreateSoracomSIM={this.props.showCreateSoracomSIM}
            showEditSoracomSIM={this.props.showEditSoracomSIM}
          />
        </Wrapper>
      </Layout>
    )
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    soracomSIMs: state.soracomSIMs.soracomSIMs,
    isLoading: state.soracomSIMs.isLoading,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchSoracomSIMs: () => dispatch(soracomSIMsActions.fetchSoracomSIMs()),
  showCreateSoracomSIM: () => dispatch(modalActions.showCreateSoracomSIM()),
  showEditSoracomSIM: (soracomSIM: SoracomSIM) => dispatch(modalActions.showEditSoracomSIM(soracomSIM)),
})

export default connect(mapStateToProps,mapDispatchToProps) (SoracomSIMsScreen)
