import { action } from 'typesafe-actions'

import { Subscription, SubscriptionParam } from './subscription'

export enum subscriptionActionType {
  FETCH_SUBSCRIPTIONS = 'FETCH_SUBSCRIPTIONS',
  FETCH_SUBSCRIPTIONS_SUCCEEDED = 'FETCH_SUBSCRIPTIONS_SUCCEEDED',
  FETCH_SUBSCRIPTION = 'FETCH_SUBSCRIPTION',
  FETCH_SUBSCRIPTION_SUCCEEDED = 'FETCH_SUBSCRIPTION_SUCCEEDED',
  RESET_SUBSCRIPTIONS = 'RESET_SUBSCRIPTIONS',
  THROW_ERROR = 'THROW_ERROR',
}

export const subscriptionActions = {
  fetchSubscriptions: (subscriptionParam: SubscriptionParam) =>
    action(subscriptionActionType.FETCH_SUBSCRIPTIONS, { subscriptionParam }),
  fetchSubscriptionsSucceeded: (subscriptions: Subscription[]) =>
    action(subscriptionActionType.FETCH_SUBSCRIPTIONS_SUCCEEDED, {
      subscriptions,
    }),
  resetSubscriptions: () => action(subscriptionActionType.RESET_SUBSCRIPTIONS),
  fetchSubscription: (id : number) =>
    action(subscriptionActionType.FETCH_SUBSCRIPTION, { id }),
  fetchSubscriptionSucceeded: (subscription: Subscription) =>
    action(subscriptionActionType.FETCH_SUBSCRIPTION_SUCCEEDED, {subscription}),
  throwError: (error: Error) =>
    action(subscriptionActionType.THROW_ERROR, { error }),
}
