import { ActionType } from 'typesafe-actions'

import { toastActions, toastActionType } from './actions'

export interface ToastState {
  readonly isVisible: boolean
  readonly type: 'success' | 'error'
  readonly title: string
  readonly body: string
}

export const initialState: ToastState = {
  isVisible: false,
  type: 'success',
  title: '',
  body: '',
}

export const toastReducer = (
  state: ToastState = initialState,
  action: ActionType<typeof toastActions>
) => {
  switch (action.type) {
    case toastActionType.SHOW:
      return {
        ...state,
        isVisible: true,
        type: action.payload.type,
        title: action.payload.title,
        body: action.payload.body,
      }
    case toastActionType.HIDE:
      return {
        ...state,
        isVisible: false,
      }
    default:
      return state
  }
}
