import React from 'react'
import classnames from 'classnames'

import { Item } from '../../../core/items'
import { manufacturingInfoTableContent } from './each-item-content'
import styles from '../../styles/modules/table.module.scss'
import formStyles from '../../styles/modules/each-item/each-item-forms.module.scss'

interface Props {
  item: Item
  onChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => void
}

const ManufacturingInfoForm: React.FC<Props> = props => (
  <div
    className={classnames({
      [styles.wrapper]: true,
      [styles.stocker]: true,
    })}
  >
    <table className={styles.container}>
      <tbody>
        <tr className={styles.inputRowStatic}>
          <td className={styles.inputCell}>
            {manufacturingInfoTableContent.beanTypeIndex}
          </td>
          <td className={styles.inputCell}>
            <select
              className={formStyles.input}
              value={props.item.beanTypeIndex || ''}
              onChange={props.onChange}
              name="beanTypeIndex"
            >
              {[...Array(8)]
                .map((v, k) => String(k + 1))
                .map(i => (
                  <option key={`beanTypeIndexOption-` + i} value={i}>
                    {i}
                  </option>
                ))}
            </select>
          </td>
        </tr>
        <tr className={styles.inputRowStatic}>
          <td className={styles.inputCell}>
            {manufacturingInfoTableContent.beanAmount}
          </td>
          <td className={styles.inputCell}>
            <input
              className={formStyles.input}
              value={props.item.beanAmount || ''}
              onChange={props.onChange}
              name="beanAmount"
            />
          </td>
        </tr>
        <tr className={styles.inputRowStatic}>
          <td className={styles.inputCell}>
            {manufacturingInfoTableContent.machineButtonIndex}
          </td>
          <td className={styles.inputCell}>
            <select
              className={formStyles.input}
              value={props.item.machineButtonIndex || ''}
              onChange={props.onChange}
              name="machineButtonIndex"
            >
              {[...Array(10)]
                .map((v, k) => String(k + 1))
                .map(i => (
                  <option key={`machineButtonIndex-` + i} value={i}>
                    {i}
                  </option>
                ))}
            </select>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
)

export default ManufacturingInfoForm
