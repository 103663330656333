import React, { useState } from 'react'
import Button from '../button'
import styles from '../../styles/modules/modal/modal.module.scss'
import { strings } from './modal-content'
import { Config } from '../../../core/toast'
import { Station } from '../../../core/stations'
import { SoracomSIM } from '../../../core/soracom-sims'
import { Machine } from '../../../core/machines'

interface Props {
  isLoading: boolean
  stations: Station[]
  soracomSIMs: SoracomSIM[]
  machines: Machine[]
  machine: Machine
  setMachineRelation: (
    machineId: number,
    stationId: number,
    soracomSIMId: number
  ) => void
  show: (config: Config) => void
}

export const EditMachineRelationForm: React.FC<Props> = (props: Props) => {
  const [stationId, setStationId] = useState<number | undefined>(
    props.machine.stationID
  )
  const selectedSoracomSIM = props.soracomSIMs.find(
    soracomSIM => soracomSIM.machineID === props.machine.id
  )
  const [soracomSIMId, setSoracomSIMId] = useState<number | undefined>(
    selectedSoracomSIM ? selectedSoracomSIM.id : undefined
  )

  const handleOnClickSubmit = () => {
    if (!stationId && !soracomSIMId) {
      const config: Config = {
        type: 'error',
        title: strings.toastError.title,
        body: strings.toastError.body,
      }
      props.show(config)
      return
    }
    props.setMachineRelation(props.machine.id, stationId, soracomSIMId)
  }

  return (
    <main className={styles.main}>
      <div className={styles.form}>
        <label className={styles.label}>
          {strings.machineRelation.machineId}
        </label>
        <div className={styles.contentText}>{props.machine.id}</div>
      </div>
      <div className={styles.form}>
        <label className={styles.label}>
          {strings.machineRelation.machineSerial}
        </label>
        <div className={styles.contentText}>{props.machine.serial}</div>
      </div>
      <div className={styles.form}>
        <label className={styles.label}>
          {strings.machineRelation.station}
        </label>
        <div>
          <select
            className={styles.input}
            value={stationId}
            onChange={e =>
              setStationId(
                e.target.value ? parseInt(e.target.value, 10) : undefined
              )
            }
          >
            <option value="">{strings.machineRelation.unset}</option>
            {props.stations
              .filter(
                station =>
                  !props.machines
                    .map(machine => machine.stationID)
                    .find(id => id === station.id) ||
                  station.id === props.machine.stationID
              )
              .map(station => (
                <option value={`${station.id}`} key={station.id}>
                  {`ID: ${station.id} ${station.name}`}
                </option>
              ))}
          </select>
        </div>
      </div>
      <div className={styles.form}>
        <label className={styles.label}>
          {strings.machineRelation.soracomSIM}
        </label>
        <div>
          <select
            className={styles.input}
            value={soracomSIMId}
            onChange={e =>
              setSoracomSIMId(
                e.target.value ? parseInt(e.target.value, 10) : undefined
              )
            }
          >
            <option value="">{strings.machineRelation.unset}</option>
            {props.soracomSIMs
              .filter(
                soracomSIM =>
                  !soracomSIM.kioskID &&
                  (!soracomSIM.machineID ||
                    soracomSIM.machineID === props.machine.id)
              )
              .map(soracomSIM => (
                <option value={`${soracomSIM.id}`} key={soracomSIM.id}>
                  {`ID: ${soracomSIM.id}, SIM 製造番号: ${soracomSIM.serial}`}
                </option>
              ))}
          </select>
        </div>
      </div>
      <Button
        title={strings.machineRelation.editButton}
        onClick={() => handleOnClickSubmit()}
        className={styles.report}
        isLoading={props.isLoading}
        loadingClassName={styles.loadingReport}
      />
    </main>
  )
}
