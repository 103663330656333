export const strings = {
  title: 'アイテム',
  hot: 'HOT',
  ice: 'ICE',
}

export const itemTableContent = [
  '',
  'ID',
  'インデックス',
  '商品名',
  '画像',
]
