import React, { useEffect } from 'react'
import { Dispatch } from 'redux'
import { connect } from "react-redux";
import { match } from 'react-router-dom'
import RootState from '../../core/root-state'
import { Subscription, subscriptionActions } from '../../core/subscription';
import Layout from '../layouts/layout'
import Wrapper from '../layouts/wrapper'
import SubscriptionInfoTable from '../components/subscriptions/subscription-info-table'
import { strings } from "../components/subscriptions/subscriptions-content";

interface Props {
  match: match<any>
  subscription: Subscription
  fetchSubscription: (id: number) => void
}

const EachSubscriptionScreen: React.FC<Props> = (props: Props) => {

  const {subscription, fetchSubscription} = props
  const paramsId = props.match.params.id

  useEffect(() => {
    fetchSubscription(paramsId)
  }, [fetchSubscription, paramsId])

  return (
    <Layout location='subscriptions'>
      <Wrapper title={strings.title}>
        <SubscriptionInfoTable subscription={subscription}/>
      </Wrapper>
    </Layout>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    subscription: state.subscription.subscription
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchSubscription: (id: number) =>
    dispatch(subscriptionActions.fetchSubscription(id)),
})

export default connect(mapStateToProps,mapDispatchToProps) (EachSubscriptionScreen)
