import { put, call, takeLatest, fork, select } from 'redux-saga/effects'
import RootState from '../root-state'
import { subscriptionActionType, subscriptionActions } from './actions'
import { getSubscriptions, getSubscription } from '../api'

export function* fetchSubscriptions(
  action: ReturnType<typeof subscriptionActions.fetchSubscriptions>
) {
  try {
    const state: RootState = yield select()
    const token = state.auth0.auth.idToken
    const response = yield call(getSubscriptions, token, action.payload.subscriptionParam)
    yield put(
      subscriptionActions.fetchSubscriptionsSucceeded(response.subscriptions)
    )
  } catch (error) {
    yield put(subscriptionActions.throwError(error))
  }
}

export function* fetchSubscription(
  action: ReturnType<typeof subscriptionActions.fetchSubscription>
) {
  try {
    const state: RootState = yield select()
    const token = state.auth0.auth.idToken
    const response = yield call(getSubscription, token, action.payload.id)
    yield put(
      subscriptionActions.fetchSubscriptionSucceeded(response.subscription)
    )
  } catch (error) {
    yield put(subscriptionActions.throwError(error))
  }
}

export function* watchFetchSubscriptions() {
  yield takeLatest(
    subscriptionActionType.FETCH_SUBSCRIPTIONS,
    fetchSubscriptions
  )
}

export function* watchFetchSubscription() {
  yield takeLatest(
    subscriptionActionType.FETCH_SUBSCRIPTION,
    fetchSubscription
  )
}


export const subscriptionSagas = [fork(watchFetchSubscriptions), fork(watchFetchSubscription)]
