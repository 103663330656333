import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Station, stationsActions } from '../../../core/stations'
import styles from '../../styles/modules/table.module.scss'
import kioskItemPricesStyles from '../../styles/modules/kiosk-item-prices/kiosk-item-prices.module.scss'
import RootState from '../../../core/root-state'
import {
  kioskItemPricesTableContent,
  strings,
} from './kiosk-item-prices-content'
import classnames from 'classnames'
import { Link } from 'react-router-dom'

const KioskItemPricesTable: React.FC = () => {
  const dispatch = useDispatch()
  const { stations } = useSelector((state: RootState) => state.stations)
  const [openingStations, setOpeningStations] = useState<Station[]>([])
  const [closingStations, setClosingStations] = useState<Station[]>([])

  useEffect(() => {
    dispatch(stationsActions.fetchStations())
  }, [dispatch])

  useEffect(() => {
    const openingStations = stations.filter(
      station => station.machine && !station.isClosed
    )
    const closingStations = stations.filter(
      station => !station.machine || station.isClosed
    )
    setOpeningStations(openingStations)
    setClosingStations(closingStations)
  }, [stations])
  return (
    <>
      {/* 開店中のステーション一覧表示 */}
      <h3 className={styles.title}>
        {strings.openingKioskItemPricesTableTitle}
      </h3>
      <div
        className={classnames({
          [styles.wrapper]: true,
          [styles.zebra]: true,
          [styles.stocker]: true,
        })}
      >
        <table className={styles.container}>
          <tbody>
            <tr className={styles.row}>
              {kioskItemPricesTableContent.map(item => (
                <th key={item} className={styles.cell}>
                  {item}
                </th>
              ))}
            </tr>
            {openingStations.map(station => (
              <tr className={styles.row} key={station.id}>
                <td className={styles.cell} width="25%">
                  {station.id}
                </td>
                <td className={styles.cell} width="50%">
                  {station.name}
                </td>
                <td className={styles.buttonCell} width="25%">
                  <Link
                    to={`/kiosk_item_prices/${station.id}?station_name=${station.name}`}
                    className={kioskItemPricesStyles.linkButton}
                  >
                    {strings.showItemPricesButton}
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* 閉店中のステーション一覧表示 */}
      <h3 className={styles.title}>
        {strings.closingKioskItemPricesTableTitle}
      </h3>
      <div
        className={classnames({
          [styles.wrapper]: true,
          [styles.zebra]: true,
          [styles.stocker]: true,
        })}
      >
        <table className={styles.container}>
          <tbody>
            <tr className={styles.row}>
              {kioskItemPricesTableContent.map(item => (
                <th key={item} className={styles.cell}>
                  {item}
                </th>
              ))}
            </tr>
            {closingStations.map(station => (
              <tr className={styles.row} key={station.id}>
                <td className={styles.cell} width="25%">
                  {station.id}
                </td>
                <td className={styles.cell} width="50%">
                  {station.name}
                </td>
                <td className={styles.buttonCell} width="25%">
                  <Link
                    to={`/kiosk_item_prices/${station.id}?station_name=${station.name}`}
                    className={kioskItemPricesStyles.linkButton}
                  >
                    {strings.showItemPricesButton}
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  )
}

export default KioskItemPricesTable
