import React, { useState } from 'react'

import { Item } from '../../../core/items'
import {
  StationWithItemRanks,
  UpdateStationWithItemRanksBody,
  CreateItemRankBody,
} from '../../../core/station-with-item-ranks'

import styles from '../../styles/modules/modal/modal.module.scss'
import { strings } from './modal-content'
import Select from '../select'
import { Config } from '../../../core/toast'
import Button from '../button'

interface Props {
  isLoading: boolean
  stationWithItemRanks: StationWithItemRanks
  items: Item[]
  setStationWithItemRanks: (body: UpdateStationWithItemRanksBody) => void
  show: (config: Config) => void
}

const EditStationWithItemRanksForm: React.FC<Props> = ({
  isLoading,
  stationWithItemRanks,
  items,
  setStationWithItemRanks,
  show,
}) => {
  const [formData, setFormData] = useState<UpdateStationWithItemRanksBody>({
    stationID: stationWithItemRanks.stationID,
    itemRanks: stationWithItemRanks.itemRanks.map(itemRank => ({
      rank: itemRank.rank,
      itemID: itemRank.itemID,
    })),
  })
  const ranks = [1, 2, 3]
  const [itemRanks, setItemRanks] = useState<CreateItemRankBody[]>(
    ranks.map((rank, index) => ({
      rank,
      itemID: stationWithItemRanks.itemRanks[index]
        ? stationWithItemRanks.itemRanks[index].itemID
        : 0,
    }))
  )

  const handleOnChangeItemRank = (itemID: string, index: number) => {
    const newItemRanks = [...itemRanks]
    newItemRanks[index].itemID = Number(itemID)
    setItemRanks(newItemRanks)
    const newFormData = { ...formData }
    newFormData.itemRanks = newItemRanks
    setFormData(newFormData)
  }

  const options: Array<{ value: string; label: string }> = [
    { value: '', label: '未選択' },
  ]
  items.map(item =>
    options.push({ value: item.id.toString(), label: item.name })
  )

  const handleOnClickSubmit = () => {
    let isInvalid = false
    itemRanks.forEach((itemRank, index) => {
      if (itemRank.itemID === 0) {
        isInvalid = true
      }
      itemRanks.forEach((itemRank2, index2) => {
        if (index !== index2 && itemRank.itemID === itemRank2.itemID) {
          isInvalid = true
        }
      })
    })
    if (isInvalid) {
      const config: Config = {
        type: 'error',
        title: strings.toastError.title,
        body: strings.toastError.body,
      }
      show(config)
      return
    }
    setStationWithItemRanks(formData)
  }

  return (
    <main className={styles.main}>
      <label className={styles.label}>
        ステーションID: {stationWithItemRanks.stationID}
      </label>
      <label className={styles.label}>
        ステーション名: {stationWithItemRanks.stationName}
      </label>
      {itemRanks.map((_, index) => (
        <Select
          key={`itemRank_${index}`}
          label={strings.stationWithItemRanks.itemRanks[index]}
          onChange={event => handleOnChangeItemRank(event.target.value, index)}
          value={formData.itemRanks[index]?.itemID}
          options={options}
        />
      ))}
      <Button
        title={strings.stationWithItemRanks.createButton}
        onClick={() => handleOnClickSubmit()}
        className={styles.decide}
        isLoading={isLoading}
      />
    </main>
  )
}

export default EditStationWithItemRanksForm
