import React from 'react'
import classnames from 'classnames'

import { Item } from '../../../core/items'
import styles from '../../styles/modules/table.module.scss'

interface Props {
  item: Item
}

const FeaturesTable: React.FC<Props> = props => (
  <div
    className={classnames({
      [styles.wrapper]: true,
      [styles.stocker]: true,
    })}
  >
    <table className={styles.container}>
      <tbody>
        {props.item.features.map(elem => (
          <tr className={styles.rowStatic} key={elem.name}>
            <td className={styles.cell}>{elem.name}</td>
            <td className={styles.cell}>{elem.content}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
)

export default FeaturesTable
