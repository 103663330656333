export const strings = {
  title: 'ポイント',
  queryButton: '絞り込む',
  userInfoTableTitle: 'ユーザー情報',
  pointsTableTitle: 'ポイント履歴',
  createGivePointsButton: 'ポイントを付与',
  createExpirePointsButton: 'ポイントを失効',
}

export const userInfoTableContent = {
  id: 'ユーザーID',
  name: '名前',
  FirebaseUID: 'FirebaseUID',
  amount: 'ポイント残高',
}

export const pointsTableContent = ['ポイント増減', 'メッセージ', '取引日時']
