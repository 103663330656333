import React, { useEffect } from 'react'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'

import { promotionImagesActions } from '../../core/promotion-images'
import { modalActions } from '../../core/modal'
import RootState from '../../core/root-state'
import Layout from '../layouts/layout'
import Wrapper from '../layouts/wrapper'
import { PromotionImage } from '../../core/promotion-images'
import { strings } from '../components/promotion-images/promotion-images-content'
import styles from '../styles/modules/promotion-images/promotion-images.module.scss'
import PromotionImageItems from '../components/promotion-images/promotion-image-items'

interface Props {
  promotionImages: PromotionImage[]
  isLoading: boolean
  fetchPromotionImages: () => void
  showCreatePromotionImage: () => void
  deletePromotionImage: (id: number) => void
  showEditPromotionImage: (promotionImage: PromotionImage) => void
  updatePromotionImageIndex: (ids: number[]) => void
  setPromotionImages: (promotionImage: PromotionImage[]) => void
}

const PromotionImagesScreen: React.FC<Props> = ({
  promotionImages,
  fetchPromotionImages,
  showCreatePromotionImage,
  deletePromotionImage,
  showEditPromotionImage,
  updatePromotionImageIndex,
  setPromotionImages,
}) => {

  useEffect(() => {
    fetchPromotionImages()
  }, [fetchPromotionImages])

  useEffect(() => {
    setPromotionImages(promotionImages)
  }, [promotionImages, setPromotionImages])

  const handleOnClickCreatePromotionImage = () => {
    showCreatePromotionImage()
  }

  const handleOnClickDeletePromotionImage = (id: number) => {
    const response = window.confirm('本当に削除しますか？')
    if (response) {
      deletePromotionImage(id)
    }
  }

  return (
    <Layout location={'promotion_images'}>
      <Wrapper title={strings.title}>
        <div className={styles.container}>
          <PromotionImageItems
            promotionImages={promotionImages}
            onClickCreatePromotionImage={handleOnClickCreatePromotionImage}
            onClickDeletePromotionImage={handleOnClickDeletePromotionImage}
            showEditPromotionImage={showEditPromotionImage}
            setPromotionImages={setPromotionImages}
            updatePromotionImageIndex={updatePromotionImageIndex}
          />
        </div>
      </Wrapper>
    </Layout>
  )
}

const mapStateToProps = (state: RootState) => ({
  promotionImages: state.promotionImages.promotionImages,
  isLoading: state.promotionImages.isLoading,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchPromotionImages: () =>
    dispatch(promotionImagesActions.fetchPromotionImages()),
  showCreatePromotionImage: () =>
    dispatch(modalActions.showCreatePromotionImage()),
  showEditPromotionImage: (promotionImage: PromotionImage) =>
    dispatch(modalActions.showEditPromotionImage(promotionImage)),
  deletePromotionImage: (id: number) =>
    dispatch(promotionImagesActions.deletePromotionImage(id)),
  updatePromotionImageIndex: (ids: number[]) =>
    dispatch(promotionImagesActions.updatePromotionImageIndex(ids)),
  setPromotionImages: (promotionImage: PromotionImage[]) =>
    dispatch(promotionImagesActions.setPromotionImages(promotionImage)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PromotionImagesScreen)
