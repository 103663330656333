import React, { useState } from 'react'
import { Config } from '../../../core/toast'
import { strings } from './modal-content'
import styles from '../../styles/modules/modal/modal.module.scss'
import FileInput from '../file-input'
import Input from '../input'
import Button from '../button'
import Switch from 'react-switch'
import optionalInputStyles from '../../styles/modules/optional-date-input.module.scss'

interface Props {
  isLoading: boolean
  createPointItem: (
    name: string,
    imageFile: File,
    isDeliveryRequired: boolean,
    pointsPrice: number,
    stock: number,
    isAvailable: boolean,
    isInfiniteStock: boolean
  ) => void
  show: (config: Config) => void
}

interface State {
  readonly name: string
  readonly imageFile: File
  readonly isDeliveryRequired: boolean
  readonly pointsPrice: number
  readonly stock: number
  readonly isAvailable: boolean
  readonly isInfiniteStock: boolean
}

export const CreatePointItemForm: React.FC<Props> = ({
  isLoading,
  createPointItem,
  show,
}) => {
  const [state, setState] = useState<State>({
    name: '',
    imageFile: null,
    isDeliveryRequired: false,
    pointsPrice: 0,
    stock: 0,
    isAvailable: false,
    isInfiniteStock: false,
  })

  const handleOnClickSubmit = () => {
    const {
      name,
      imageFile,
      isDeliveryRequired,
      pointsPrice,
      stock,
      isAvailable,
      isInfiniteStock,
    } = state

    if (!imageFile || name === '') {
      const config: Config = {
        type: 'error',
        title: strings.toastError.title,
        body: strings.toastError.body,
      }
      show(config)
      return
    }

    if (!imageFile.type.startsWith('image/')) {
      const config: Config = {
        type: 'error',
        title: strings.toastError.title,
        body: strings.pointItem.errorBody,
      }
      show(config)
      return
    }
    createPointItem(
      name,
      imageFile,
      isDeliveryRequired,
      pointsPrice,
      stock,
      isAvailable,
      isInfiniteStock
    )
  }

  return (
    <main className={styles.main}>
      <Input
        label={strings.pointItem.name}
        type="text"
        onChange={event => {
          setState({
            ...state,
            name: event.target.value,
          })
        }}
      />
      <FileInput
        label={strings.pointItem.image}
        type={'file'}
        name={'image'}
        id={'image'}
        onChange={event => {
          setState({
            ...state,
            imageFile: event.target.files.item(0),
          })
        }}
      />
      <Input
        label={strings.pointItem.pointsPrice}
        type={'number'}
        onBlur={event => {
          setState({
            ...state,
            pointsPrice:
              event.target.valueAsNumber >= 0 ? event.target.valueAsNumber : 0,
          })
          event.target.value =
            event.target.valueAsNumber >= 0
              ? event.target.valueAsNumber.toString()
              : '0'
        }}
      />
      <Input
        label={strings.pointItem.stock}
        type={'number'}
        onBlur={event => {
          setState({
            ...state,
            stock:
              event.target.valueAsNumber >= 0 ? event.target.valueAsNumber : 0,
          })
          event.target.value =
            event.target.valueAsNumber >= 0
              ? event.target.valueAsNumber.toString()
              : '0'
        }}
        disabled={state.isInfiniteStock}
      />
      <div className={optionalInputStyles.optionalTitleContainer}>
        <label className={optionalInputStyles.label}>
          {strings.pointItem.isInfiniteStock}
        </label>
        <div>
          <Switch
            onChange={e => {
              setState({
                ...state,
                isInfiniteStock: e,
              })
            }}
            checked={state.isInfiniteStock}
          />
        </div>
      </div>
      <div className={optionalInputStyles.optionalTitleContainer}>
        <label className={optionalInputStyles.label}>
          {strings.pointItem.isAvailable}
        </label>
        <div>
          <Switch
            onChange={e => {
              setState({
                ...state,
                isAvailable: e,
              })
            }}
            checked={state.isAvailable}
          />
        </div>
      </div>
      <div className={optionalInputStyles.optionalTitleContainer}>
        <label className={optionalInputStyles.label}>
          {strings.pointItem.isDeliveryRequired}
        </label>
        <div>
          <Switch
            onChange={e => {
              setState({
                ...state,
                isDeliveryRequired: e,
              })
            }}
            checked={state.isDeliveryRequired}
          />
        </div>
      </div>
      <Button
        title={strings.pointItem.button}
        onClick={handleOnClickSubmit}
        className={styles.report}
        isLoading={isLoading}
        loadingClassName={styles.loadingReport}
      />
    </main>
  )
}
