import React from 'react'
import { connect } from 'react-redux'
import { Route } from 'react-router'
import { match, RouteComponentProps } from 'react-router-dom'
import { roleType } from '../../core/auth0'

import RootState from '../../core/root-state'
import NotFoundScreen from '../screen/404-screen'

interface Props {
    render?: ((props: RouteComponentProps<any>) => React.ReactNode)
    match?: match<any>
    role: roleType
    allowedRoles: roleType[]
    path: string
    exact?: boolean
}

class AuthRoute extends React.PureComponent<Props, {}> {
    render() {
        const { render, role, allowedRoles, path, exact } = this.props
        return (
            <Route
                exact={exact}
                path={path}
                render={
                    allowedRoles.includes(role) ?
                        render : () => <NotFoundScreen />
                }
            />
        )
    }
}

const mapStateToProps = (state: RootState) => ({
    role: state.auth0.role
})

export default connect(
    mapStateToProps,
    {}
)(AuthRoute)
