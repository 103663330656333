import { action } from 'typesafe-actions'
import { PointTransaction } from './points'
import { UserInfo } from '../users'

export enum pointActionType {
  FETCH_POINT_TRANSACTIONS = 'FETCH_POINT_TRANSACTIONS',
  FETCH_POINT_TRANSACTIONS_SUCCEEDED = 'FETCH_POINT_TRANSACTIONS_SUCCEEDED',
  CREATE_POINTS = 'CREATE_POINTS',
  CREATE_POINTS_FAILED = 'CREATE_POINTS_FAILED',
  THROW_ERROR = 'THROW_ERROR',
}

export const pointActions = {
  fetchPointTransactions: (id: number) =>
    action(pointActionType.FETCH_POINT_TRANSACTIONS, { id }),
  fetchPointTransactionsByUserIdSucceeded: (
    user: UserInfo,
    amount: number,
    pointTransactions: PointTransaction[]
  ) =>
    action(pointActionType.FETCH_POINT_TRANSACTIONS_SUCCEEDED, {
      user,
      amount,
      pointTransactions,
    }),
  createPoints: (
    userId: number,
    point: number,
    description: string,
    calculationType: 'give' | 'expire'
  ) =>
    action(pointActionType.CREATE_POINTS, {
      userId,
      point,
      description,
      calculationType,
    }),
  createPointsFailed: (error: Error) =>
    action(pointActionType.CREATE_POINTS_FAILED, { error }),
  throwError: (error: Error) => action(pointActionType.THROW_ERROR, { error }),
}
