import { put, takeLatest, delay, fork } from 'redux-saga/effects'

import { toastActionType, toastActions } from './actions'

export function* hideAfterDuration(
  action: ReturnType<typeof toastActions.show>
) {
  yield delay(action.payload.duration)
  yield put(toastActions.hide())
}

export function* watchHideAfterDuration() {
  yield takeLatest(toastActionType.SHOW, hideAfterDuration)
}

export const toastSagas = [fork(watchHideAfterDuration)]
