import { ActionType } from 'typesafe-actions'
import { Auth0UserProfile } from 'auth0-js'

import { auth0ActionType, auth0Actions } from './actions'
import { roleType } from './auth0'

export interface Auth0State {
  readonly error: Error
  readonly isAuthenticated: boolean
  readonly isLoading: boolean
  readonly user: Auth0UserProfile
  readonly auth: AuthResult
  readonly role: roleType
}

export const initialState: Auth0State = {
  error: null,
  isAuthenticated: false,
  isLoading: false,
  user: null,
  auth: null,
  role: roleType.NONE,
}

export const auth0Reducer = (
  state: Auth0State = initialState,
  action: ActionType<typeof auth0Actions>
) => {
  switch (action.type) {
    case auth0ActionType.LOGIN:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case auth0ActionType.AUTH_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        error: null,
        user: action.payload.user,
        auth: action.payload.auth,
        role: action.payload.role,
        isAuthenticated: true,
      }
    case auth0ActionType.CHECK_SESSION:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case auth0ActionType.CHECK_SESSION_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        error: null,
        auth: action.payload.auth,
        role: action.payload.role,
      }
    case auth0ActionType.LOGOUT:
      return {
        ...state,
        isLoading: false,
        error: null,
        isAuthenticated: false,
        user: null,
      }

    case auth0ActionType.THROW_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      }
    default:
      return state
  }
}
