import React from 'react'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import moment from 'moment'

import { Station, stationsActions } from '../../core/stations'
import { campaignsActions, CampaignStatus } from '../../core/campaigns'
import { modalActions } from '../../core/modal'
import RootState from '../../core/root-state'
import Layout from '../layouts/layout'
import Wrapper from '../layouts/wrapper'
import { Campaign, CampaignParam } from '../../core/campaigns'
import { strings } from '../components/promotion-campaigns/promotion-campaigns-content'
import styles from '../styles/modules/promotion-campaigns/promotion-campaigns.module.scss'
import PromotionCampaignsTable from '../components/promotion-campaigns/promotion-campaigns-table'
import QueryDate from '../components/promotion-campaigns/query-date'
import QueryStatus from '../components/promotion-campaigns/query-status'
import Button from '../components/button'

interface Props {
  stations: Station[]
  promotionCampaigns: [Campaign]
  isLoading: boolean
  fetchStations: () => void
  fetchPromotionCampaigns: (campaignParam: CampaignParam) => void
  showCreatePromotionCampaign: (stations: Station[]) => void
  showSetPromotionCampaignStatus: (campaign: Campaign) => void
  showSetPromotionCampaign: (campaign: Campaign) => void
  setPromotionCampaignExpiresAt: (campaignId: number) => void
}

class PromotionCampaignsScreen extends React.Component<Props, {}> {
  readonly state = {
    issuedFrom: null,
    issuedTo: null,
    expiresFrom: null,
    expiresTo: null,
    status: '',
    pushed: null,
    pushedId: null,
  }

  convertDateToString = date => moment(date).format('YYYY-MM-DD')

  getCampaignParam = (): CampaignParam => {
    const param: CampaignParam = {}
    if (this.state.issuedFrom) {
      param.issuedFrom = this.convertDateToString(this.state.issuedFrom)
    }
    if (this.state.issuedTo) {
      param.issuedTo = this.convertDateToString(this.state.issuedTo)
    }
    if (this.state.expiresFrom) {
      param.expiresFrom = this.convertDateToString(this.state.expiresFrom)
    }
    if (this.state.expiresTo) {
      param.expiresTo = this.convertDateToString(this.state.expiresTo)
    }
    if (this.state.status) {
      param.status = this.state.status as CampaignStatus
    }

    return param
  }

  componentDidMount = () => {
    this.props.fetchStations()
    this.props.fetchPromotionCampaigns(this.getCampaignParam())
  }

  handleOnClickCreatePromotionCampaign = (stations: Station[]) => {
    this.props.showCreatePromotionCampaign(stations)
  }

  handleOnClickSetPromotionCampaignStatus = (promotionCampaign: Campaign) => {
    this.props.showSetPromotionCampaignStatus(promotionCampaign)
  }

  handleOnClickSetPromotionCampaign = (promotionCampaign: Campaign) => {
    this.props.showSetPromotionCampaign(promotionCampaign)
  }

  handleOnClickSetPromotionCampaignExpiresAt = (
    promotionCampaignId: number
  ) => {
    const response = window.confirm(strings.promotionCampaignExpirationAlert)
    if (response) {
      this.props.setPromotionCampaignExpiresAt(promotionCampaignId)
    }
  }

  handleOnChangeIssuedFrom = (from: Date) => {
    const now = new Date()
    if (from < now) {
      this.setState({ issuedFrom: from })
    } else {
      this.setState({ issuedFrom: now, issuedTo: now })
    }
  }

  handleOnChangeIssuedTo = (to: Date) => {
    if (to > this.state.issuedFrom) {
      const now = new Date()
      if (to < now) {
        this.setState({ issuedTo: to })
      } else {
        this.setState({ issuedTo: now })
      }
    } else {
      this.setState({ issuedFrom: to, issuedTo: to })
    }
  }

  handleOnChangeExpiresFrom = (from: Date) => {
    this.setState({ expiresFrom: from })
  }

  handleOnChangeExpiresTo = (to: Date) => {
    this.setState({ expiresTo: to })
  }

  handleOnChangeStatus = (event: React.ChangeEvent<HTMLSelectElement>) => {
    this.setState({ status: event.target.value })
  }

  handleOnClickQuery = () => {
    this.setState({ pushed: true })
    this.props.fetchPromotionCampaigns(this.getCampaignParam())
  }

  render() {
    const {
      issuedFrom,
      issuedTo,
      expiresFrom,
      expiresTo,
      status,
      pushed,
    } = this.state
    const { isLoading } = this.props
    return (
      <Layout location={'promotion_campaigns'}>
        <Wrapper title={strings.title}>
          <div className={styles.queryContainer}>
            <QueryDate
              from={issuedFrom}
              to={issuedTo}
              title={strings.queryIssuedDateTitle}
              onChangeFrom={from => this.handleOnChangeIssuedFrom(from)}
              onChangeTo={to => this.handleOnChangeIssuedTo(to)}
            />
            <QueryStatus
              value={status}
              title={strings.queryStatusTitle}
              onChange={event => this.handleOnChangeStatus(event)}
            />
            <QueryDate
              from={expiresFrom}
              to={expiresTo}
              title={strings.queryExpiresDateTitle}
              onChangeFrom={from => this.handleOnChangeExpiresFrom(from)}
              onChangeTo={to => this.handleOnChangeExpiresTo(to)}
            />
            <Button
              title={strings.queryButton}
              onClick={() => this.handleOnClickQuery()}
              className={styles.queryButton}
              isLoading={isLoading && pushed}
            />
          </div>
          <PromotionCampaignsTable
            promotionCampaigns={this.props.promotionCampaigns}
            onClickCreatePromotionCampaign={() =>
              this.handleOnClickCreatePromotionCampaign(this.props.stations)
            }
            onClickSetPromotionCampaignStatus={(promotionCampaign: Campaign) =>
              this.handleOnClickSetPromotionCampaignStatus(promotionCampaign)
            }
            onClickSetPromotionCampaign={(promotionCampaign: Campaign) => {
              this.handleOnClickSetPromotionCampaign(promotionCampaign)
            }}
            onClickSetPromotionCampaignExpiresAt={(
              promotionCampaignId: number
            ) => {
              this.handleOnClickSetPromotionCampaignExpiresAt(
                promotionCampaignId
              )
            }}
          />
        </Wrapper>
      </Layout>
    )
  }
}

const mapStateToProps = (state: RootState) => ({
  stations: state.stations.stations,
  promotionCampaigns: state.campaigns.promotionCampaigns,
  isLoading: state.campaigns.isLoading,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchStations: () => dispatch(stationsActions.fetchStations()),
  fetchPromotionCampaigns: (campaignParam: CampaignParam) =>
    dispatch(campaignsActions.fetchPromotionCampaigns(campaignParam)),
  showCreatePromotionCampaign: (stations: Station[]) =>
    dispatch(modalActions.showCreatePromotionCampaign(stations)),
  showSetPromotionCampaignStatus: (promotionCampaign: Campaign) =>
    dispatch(modalActions.showEditPromotionCampaignStatus(promotionCampaign)),
  showSetPromotionCampaign: (promotionCampaign: Campaign) => {
    dispatch(modalActions.showEditPromotionCampaign(promotionCampaign))
  },
  setPromotionCampaignExpiresAt: (promotionCampaignId: number) => {
    dispatch(
      campaignsActions.setPromotionCampaignExpiresAt(promotionCampaignId)
    )
  },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PromotionCampaignsScreen)
