import React from 'react'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'

import { emergenciesActions } from '../../core/emergencies'

import RootState from '../../core/root-state'
import Layout from '../layouts/layout'
import Wrapper from '../layouts/wrapper'
import { Emergency } from '../../core/emergencies'
import { strings } from '../components/emergencies/emergencies-content'
import styles from '../styles/modules/emergencies/emergencies.module.scss'
import EmergenciesTable from '../components/emergencies/emergencies-item'

interface Props {
  emergencies: [Emergency]
  isLoading: boolean
  fetchEmergencies: () => void
}

class EmergenciesScreen extends React.Component<Props, {}> {
  readonly state = {
    pushed: null,
    pushedId: null,
  }

  componentDidMount = () => {
    this.props.fetchEmergencies()
  }

  render() {
    return (
      <Layout location={'emergencies'}>
        <Wrapper title={strings.title}>
          <div className={styles.container}>
            <EmergenciesTable emergencies={this.props.emergencies} />
          </div>
        </Wrapper>
      </Layout>
    )
  }
}

const mapStateToProps = (state: RootState) => ({
  emergencies: state.emergencies.emergencies,
  isLoading: state.emergencies.isLoading,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchEmergencies: () => dispatch(emergenciesActions.fetchEmergencies()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EmergenciesScreen)
