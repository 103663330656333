import React from 'react'
import { Link } from 'react-router-dom'

import { strings } from './users-content'
import styles from '../../styles/modules/stations/detail-button.module.scss'

interface Props {
  userId: number
}

const LinkToSubscriptionsButton: React.FC<Props> = props => (
  <Link to={`/subscriptions?user_id=${props.userId}`} className={styles.container}>
    <span>{strings.linkToSubscriptionsButton}</span>
  </Link>
)

export default LinkToSubscriptionsButton
