export const strings = {
  title: 'アプリバナー',
  createPromotionImageButton: 'バナーを作成',
  updatePromotionImageButton: '編集',
  deletePromotionImageButton: '削除',
  noData: '-----',
}

export const promotionImageItemContent = [
  '',
  'ID',
  'インデックス',
  'URL',
  'タイトル',
  '説明',
  '画像',
  '',
  '',
]
